import {
  TButtonTranslations,
} from './npm-controller-menu.ts';

const buttonTranslations: TButtonTranslations = {
  LOAD_JOB: {
    label: 'Load Job',
    confirmMessage: '',
    confirmLabel: '',
  },
  START_JOB: {
    label: 'Start Job',
    confirmMessage: 'Please confirm the start of {recipeCode} on {assetDisplayName}.',
    confirmLabel: 'Start {recipeCode}',
  },
  ABORT_JOB: {
    label: 'Abort Job',
    confirmMessage: 'Are you sure you want to abort job {jobNo} ({recipeCode}) on {assetDisplayName}?',
    confirmLabel: 'Abort {jobNo}',
  },
  RESUME_JOB: {
    label: 'Resume Job',
    confirmMessage: 'Are you sure you want to resume {recipeCode} on {assetDisplayName}?',
    confirmLabel: 'Resume {recipeCode}',
  },
};

export default {
  button: buttonTranslations,
};
