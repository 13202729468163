<template>
  <div
    ref="navbar"
    class="navbar"
  >
    <nav
      class="p-2 flex flex-col gap-2 items-stretch border-r h-full overflow-y-auto"
      style="border-color: var(--color-103)"
    >
      <template v-if="isAppListLoading">
        <prm-skeleton
          v-for="item in 6"
          :key="item"
          width="36px"
          height="36px"
        />
      </template>
      <template v-else>
        <prm-button
          size="small"
          text
          severity="primary"
          type="button"
          icon="pi pi-arrow-left"
          :disabled="appStore.isNavigating"
          @click="historyBack"
        />
        <separator-navbar />
        <button-navbar
          :label="i18n.global.t('page.home.title')"
          :icon="HomeIcon"
          :route-to="{
            name: ERouteName.HOME
          }"
        />
        <button-navbar
          :label="i18n.global.t('page.appOverview.title')"
          :icon="AppsIcon"
          :route-to="{
            name: ERouteName.APP_OVERVIEW
          }"
        />
        <separator-navbar />
        <prm-skeleton
          v-if="isCustomPinnedAppMapLoading"
          width="36px"
          height="36px"
        />
        <button-navbar-app
          v-for="(_, appId) in customUserStorage.customPinnedAppMap"
          v-else
          :key="appId"
          :app-id="appId"
        />
        <separator-navbar v-if="assetId" />
        <menu-asset-app-navigation
          v-if="assetId"
          :asset-id="assetId"
        />
        <a
          v-if="!appStore.isNavigating"
          class="bottom-2"
          style="margin-top: auto; position: sticky; bottom: 0;"
          target="_blank"
          :href="config.VITE_APP_USER_MANUAL_URL"
          :title="i18n.global.t('page.userManual.title')"
        >
          <prm-button
            size="small"
            text
            type="button"
            severity="secondary"
            style="color: var(--color-224)"
          >
            <template #icon>
              <help-icon class="navbar-icon__image" />
            </template>
          </prm-button>
        </a>
      </template>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import {
  computed, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import PrmButton from 'primevue/button';
import PrmSkeleton from 'primevue/skeleton';
import {
  i18n,
} from '@i18n';
import {
  useRoute,
} from 'vue-router';
import {
  useAppStore,
} from '@/store/pinia/app';
import HomeIcon from '@/assets/icon/home.svg?component';
import AppsIcon from '@/assets/icon/apps.svg?component';
import HelpIcon from '@/assets/icon/help.svg?component';
import {
  ERouteName,
} from '@/router/route-name';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage';
import config from '@/config/config';
import {
  rafCallback,
} from '@/utils/raf';
import ButtonNavbar from './components/button-navbar.vue';
import ButtonNavbarApp from './components/button-navbar-app.vue';
import SeparatorNavbar from '@/components/navbar/components/separator-navbar.vue';
import MenuAssetAppNavigation
  from '@/components/menu-asset-app-navigation/menu-asset-app-navigation.vue';

const appStore = useAppStore();
const customUserStorage = useCustomUserStorageStore();
const route = useRoute();
customUserStorage.loadCustomPinnedAppMap();

const isAppListLoading = computed(() => appStore.appListIsLoading);
const isCustomPinnedAppMapLoading = computed(() => customUserStorage.customPinnedAppMapIsLoading);

const navbar = ref<HTMLElement | null>(null);

const assetId = computed<number | undefined>(() => {
  if (route.params.assetId) {
    return parseInt(route.params.assetId as string, 10);
  }
  // if only one assetId is selected from the filters, that is clear and we can offer
  // to provide contextual navigation for that one asset.
  if (route.query.assetIdList && route.query.assetIdList.length && route.query.assetIdList.length === 1) {
    return parseInt(route.query.assetIdList[0] as string, 10);
  }
  return undefined;
});

function historyBack() {
  window.history.back();
}

const onResize = rafCallback(() => {
  if (!navbar.value) {
    return;
  }
  document.documentElement.style.setProperty('--navbar-sidebar-width', `${navbar.value!.offsetWidth}px`);
});
onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
  navbar.value!.addEventListener('resize', onResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
  navbar.value!.removeEventListener('resize', onResize);
});

watch([
  isCustomPinnedAppMapLoading,
  isAppListLoading,
], onResize);
</script>

<style scoped lang="scss">
.navbar {
  @media print {
    width: 0;
    overflow: none;
  }
}
.navbar-icon {
  color: var(--color-224) !important;
}
.navbar-icon__image {
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
</style>
