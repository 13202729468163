import {
  TAssetTreeNode,
} from '@/api/asset.ts';
import {
  FlattenedAssetTreeNode,
} from '@/types/asset-tree.ts';

export type TFlattenedAssetTreeNode = TAssetTreeNode & {
  displayNameWithPath: string;
  path: string[];
}

export function extractDisplayNamesFromAssetTree(assetTree: TAssetTreeNode[], assetPath: string[] = [], minChildrenLength: number = 0): TFlattenedAssetTreeNode[] {
  let assetFilterList: TFlattenedAssetTreeNode[] = [];

  assetTree.forEach((item) => {
    if (!item.children) {
      return;
    }
    if (assetTree.length > minChildrenLength) {
      assetFilterList.push({
        ...item,
        displayNameWithPath: [
          item.displayName,
          ...assetPath,
        ].join(' > '),
        path: [
          item.displayName,
          ...assetPath,
        ],
      });
      assetFilterList = [
        ...assetFilterList,
        ...extractDisplayNamesFromAssetTree(item.children, [
          item.displayName,
          ...assetPath,
        ]),
      ];
    } else {
      assetFilterList = [
        ...assetFilterList,
        ...extractDisplayNamesFromAssetTree(item.children, assetPath),
      ];
    }
  });

  return assetFilterList;
}

export function flattenAssetTree(assetTree: TAssetTreeNode[], path: FlattenedAssetTreeNode[] = []): FlattenedAssetTreeNode[] {
  const treeNodeList: FlattenedAssetTreeNode[] = [];
  const currentPath: FlattenedAssetTreeNode[] = [
    ...path,
  ];

  assetTree.forEach((node) => {
    const parent = {
      ...node,
      path: currentPath,
    };
    treeNodeList.push(parent);

    if (node.children) {
      treeNodeList.push(
        ...flattenAssetTree(node.children, [
          parent,
          ...currentPath,
        ]),
      );
    }
  });

  return treeNodeList;
}
