import {
  generalTerm,
  generalTermFilter,
} from './de.shared.ts';
import apiTranslations from './de.api.ts';
import npm from '@/generated/locales/de.npm.ts';
import {
  ETimeseriesMeaning,
} from '@/types/openapi-type';
import {
  ERouteName,
} from '@/router/route-name.ts';
import {
  EMaintenanceServiceTab,
  EMaintenanceRecurrencePeriod,
} from '@/types/calendar.ts';
import {
  TFurnacePanelStatus,
} from '@/components/furnace-panel/furnace-panel.ts';
import {
  EVisualisation,
} from '@/types/performance-dashboard.ts';
import {
  EMonth,
} from '@/types/date.ts';
import {
  EAttributeLabel,
} from '@/types/attribute-type.ts';
import {
  getComponentScopedTranslationMap,
} from '@/utils/i18n.ts';

const Visualisation: Record<EVisualisation, string> = {
  [EVisualisation.CLUSTERED_STACKED_BAR_CHART]: 'Gestapeltes Balkendiagramm gruppieren',
  [EVisualisation.PARETO_CHART]: 'Pareto-Diagramm',
  [EVisualisation.STACKED_BAR_CHART]: 'Gestapeltes Balkendiagramm',
  [EVisualisation.STACKED_POLAR_CHART]: 'Gestapeltes Polardiagramm',
  [EVisualisation.TABLE]: 'Tabelle',
};

export default {
  generalTermFilter,
  generalTerm,
  api: apiTranslations,
  npm,
  actions: {
    abort: 'Abbrechen',
    accept: 'Akzeptieren',
    addFilter: 'Filter hinzufügen',
    back: 'Zurück',
    cancel: 'Abbrechen',
    change: 'Ändern',
    clear: 'Leeren',
    close: 'Schließen',
    confirm: 'Bestätigen',
    delete: 'Löschen',
    deselectAll: 'Alle abwählen',
    edit: 'Bearbeiten',
    finish: 'Beenden',
    finishMaintenanceOccurrence: 'Wartungsauftrag beenden',
    goBack: 'Zurückgehen',
    login: 'Login',
    next: 'Nächste',
    ok: 'Ok',
    reject: 'Ablehnen',
    rejectMaintenanceOccurrence: 'Wartungsauftrag ablehnen',
    reset: 'Zurücksetzen',
    resume: 'Fortsetzen',
    restart: 'Neustart',
    restoreRecipe: 'Rezept wiederherstellen',
    retry: 'Wiederholen',
    save: 'Speichern',
    saveAs: 'Speichern zu',
    search: 'Suchen',
    select: 'Auswählen',
    selectAll: 'Alle auswählen',
    skip: 'überspringen',
    start: 'Start',
    stop: 'Stoppen',
    unlink: 'Verknüpfung aufheben',
    update: 'Update',
    validate: 'Bestätigen',
    validated: 'Bestätigt',
  },
  alarmStatistics: {
    alarmsCausingStop: 'Nur Alarme, die zum Anhalten führen',
    chartjsLimit: 'Chartjs kann nicht so viele Ereignisse verarbeiten',
    duration: 'Dauer',
    evaluationFrom: 'Datum von',
    history: 'Historie',
    list: 'Liste',
    noAlarms: 'Keine Alarme',
    paretoOccurrences: 'Pareto (Ereignisse)',
    paretoTime: 'Pareto (Zeit)',
    quantity: 'Menge',
    resultStatistics: 'Statistiken',
    resultVisualization: 'Visuelle Statistiken',
    to: 'Datum bis',
  },
  alarms: {
    ackTime: 'Ack',
    bit: 'Bit',
    byte: 'Byte',
    endTime: 'Ende',
    event: 'Ereignis',
    gate: 'Gate',
    id: 'Id',
    message: 'Nachricht',
    priority: 'Priorität',
    search: 'Alarmsuche',
    startTime: 'Start',
  },
  alerts: {
    cancelCurrentStage: 'Schließen Sie die aktuelle Behandlungsschritt ab, bevor Sie andere Behandlungsschritte bearbeiten.',
    cancelOrValidateEditedStage: 'Behandlungsschritt wurde bearbeitet. Bitte akzeptieren oder verwerfen Sie die Änderungen befor Sie fortfahren.',
    changeStateFailed: 'Statuswechsel fehlgeschlagen',
    default: '(Standard)',
    delete: 'Löschen!',
    deleteFilter: 'Möchten Sie den Filter löschen?',
    deleted: 'Gelöscht',
    digitsAbove: 'Der Wert sollte unter 9',
    digitsBelow: 'Der Wert sollte höher 0',
    edited: '(Bearbeitet)',
    filterSaved: 'Ihr Filter wurde gespeichert.',
    groupsDeleted: 'Materialiengruppe gelöscht',
    materialDeleted: 'Material gelöscht',
    missingChartData: 'Keine Diagrammdaten',
    needValidateOrClose: 'Sie müssen die geöffnete Phase validieren/schließen, bevor Sie fortfahren.',
    noDescription: 'Keine Beschreibung',
    noPermission: 'Kein NPM Benutzer hinterlegt, oder die Zugangsdaten sind falsch.',
    noStageSetup: 'Kein Stage Setup für diesen Stage Change',
    overwrite: 'Soll der Wert überschrieben werden?',
    rejectOccurrence: 'Bitte geben Sie einen Grund für die Ablehnung an.',
    saveFailed: 'Speichern fehlgeschlagen',
    saveSuccess: 'Erfolgreich gespeichert',
    selectElements: 'Bitte wählen Sie die Elemente in der Dropdown-Liste oben aus',
    stageValid: 'STAGE {stage} : Gültig',
    success: 'Erfolg',
    validReport: 'Bestätigen Sie die Validierung.',
    valueAboveMax: 'Der Wert muss kleiner als {value} sein.',
    warning: 'Warnung!',
  },
  anomalyDetection: {
    createRootCause: 'Grundursache erstellen',
    createSuggestedAction: 'Vorgeschlagene Aktion erstellen',
    detectionTime: 'Erkennungszeit',
    jobFeatures: 'Auftragsmerkmal',
    linkRootCause: 'Verknüpfen Sie eine Grundursache',
    linkSuggestedAction: 'Link Vorgeschlagene Aktionen',
    observations: 'Beobachtungen',
    possibleCauses: 'Mögliche Ursachen',
    rootCausesAnalysis: 'Analyse der Grundursachen',
    searchJobFeatures: 'Jobmerkmal suchen',
    searchObservationTypes: 'Suche Beobachtungsarten',
    searchRootCause: 'Suche RootCause',
    searchSuggestedAction: 'Suche vorgeschlagene Aktion',
    selectJobFeature: 'Auftragsmerkmal auswählen',
    selectObservationType: 'Beobachtungsart auswählen',
    selectRootCause: 'Grundursache auswählen',
    selectSuggestedAction: 'Vorgeschlagene Aktion auswählen',
  },
  appOverview: {
    search: 'Apps durchsuchen',
    dataInsight: 'Data/Insight',
    maxNumberOfPinnedApps: 'Maximale Anzahl ({max}) an gepinnten Apps ist erreich.',
    showOnlyAvailableApps: 'Zeige nur verfügbare Apps.',
  },
  assetManagement: {
    settings: {
      categories: {
        costs: 'Kosten',
        maintenance: 'Wartung',
        operations: 'Betrieb',
      },
    },
  },
  audit: {
    addBand: 'Band hinzufügen',
    audits: 'Prüfungen',
    band: 'Band',
    bandMinus: 'unteres Limit',
    bandPlus: 'oberes Limit',
    comment: 'Kommentar',
    completeRateComment: 'Bewerten und kommentieren',
    configureBands: 'Band hinzufügen und konfigurieren',
    createAudit: 'Prüfung erstellen',
    deleteBand: 'Band löschen',
    noAudits: 'Keine Audits',
    selectAudit: 'Audit auswählen',
    selectSensors: 'Wählen Sie Sensoren für die Prüfung aus',
    selectStartTimeOnChart: 'Klicken Sie auf den Diagrammbereich, um die Startzeit auszuwählen.',
    step: 'Schritt {step}:',
    title: 'Audit-Bericht',
    totalTimeInBand: 'Gesamtzeit im Band',
    viewAudit: 'Audit',
  },
  auth: {
    userDoesNotHavePermission: 'User does not have permission {0}',
  },
  batchRating: {
    areYouSure: 'Sind Sie sicher?',
    confirmation: 'Sie sind dabei, diese Charge zu bewerten',
    notRated: 'Nicht bewertet',
    ratedBy: 'Akzeptiert von',
  },
  batchStatuses: {
    allGood: 'Alles Gut',
    notGood: 'Nicht Gut',
    notPassed: 'Audit nicht bestanden',
    passed: 'Audit bestanden',
  },
  buttons: {
    SetInputEndCondition: 'Eingabe-Ende-Bedingung festlegen',
    ack: 'Quittieren',
    add: 'Hinzufügen',
    addAttachments: 'Anhänge hinzufügen',
    addComponent: 'Komponente hinzufügen',
    addEquipment: 'Asset hinzufügen',
    addNewPart: 'Fügen Sie ein neues Ersatzteil hinzu',
    addPlant: 'Standort hinzufügen',
    addStage: 'Behandlungsschritt hinzufügen',
    addUser: 'Nutzer hinzufügen',
    alarm: 'Alarm',
    analogInputs: 'analogeEingänge',
    apply: 'Anwenden',
    attachReport: 'Bericht beifügen',
    cancel: 'Abbrechen',
    changeDateFormat: 'Zeitformat ändern',
    changeLanguage: 'Sprache wechseln',
    changeNumberFormat: 'Dezimaltrennzeichen ändern',
    changePassword: 'Passwort ändern',
    chooseKPIs: 'KPIs auswählen',
    close: 'Schließen',
    connect: 'Verbinden',
    createNewPart: 'Neues Teil erstellen',
    createNewRecipe: 'Neues Rezept erstellen',
    createReport: 'Report erstellen',
    customerRequests: 'Serviceanfrage',
    delete: 'Löschen',
    digitalInputs: 'Digitale Eingänge',
    download: 'Herunterladen {fileName}',
    editOverview: 'Übersicht bearbeiten',
    export: 'Export',
    insertStage: 'Behandlungsschritt einfügen',
    loadList: 'Produktionsliste',
    logout: 'Ausloggen',
    manageSpareParts: 'Ersatzteile verwalten',
    modify: 'Bearbeiten',
    new: 'Neue',
    newCustomerRequests: 'Neue Serviceanfrage',
    pdf: 'PDF erstellen',
    preview: 'Vorschau',
    print: 'Drucken',
    rejectReason: 'Grund der Ablehnung',
    remove: 'Entfernen',
    save: 'Speichern',
    saveRecipe: 'Behandlungsschritt speichern',
    saveUser: 'Benutzer speichern',
    selectSensors: 'Sensoren auswählen',
    startCalculation: 'Berechnen',
    submit: 'Bestätigen',
    tryAgain: 'Versuchen Sie es nochmal',
    updateComponent: 'Komponente aktualisieren',
    updateEquimpent: 'Asset aktualisieren',
    updateSparePart: 'Ersatzteil aktualisieren',
    upload: 'Hochladen',
    uploadDocuments: 'Dokumente hochladen',
    uploadInstructions: 'Anleitungen hochladen',
    uploadPlural: 'Dateien hochladen | Dateien hochladen',
    view: 'Ansicht',
    viewReport: 'Report Ansehen',
  },
  calendar: {
    accepted: 'Angenommen',
    addJob: 'Wartungsjob hinzufügen',
    addOccurrence: 'Wartungsauftrag hinzufügen',
    addTask: 'Arbeitsschritt hinzufügen',
    after: 'nach',
    assignedTo: 'Zuordnen zu',
    color: 'Farbe',
    date: generalTerm.date,
    day: 'Tag',
    days: 'Tage',
    description: 'Beschreibung',
    done: 'Erledigt',
    downloadReport: 'Bericht herunterladen',
    durationOverhead: 'Zusatzaufwand',
    editMaintenanceJob: 'Wartungsjob bearbeiten',
    editMaintenanceTask: 'Arbeitsschritt bearbeiten',
    end: 'Ende',
    endTime: 'Endzeitpunkt',
    every: 'Jeder',
    first: 'Erste',
    fourth: 'Vierte',
    hideCompletedEvents: 'Abgeschlossene Aufträge ausblenden',
    hours: 'Stunden',
    inProgress: 'In Bearbeitung',
    invalidDate: 'Ungültiges Datum',
    last: 'Letzter',
    listMaintenance: 'Wartungsaufträge',
    maintenanceEnd: 'Wartungsende',
    maintenanceJob: 'Wartungsarbeit',
    maintenanceOccurrence: 'Wartungsauftrag: {id}',
    maintenanceStart: 'Wartungsbeginn',
    maintenanceType: 'Wartungsart',
    minutes: 'Minuten',
    month: 'Monat',
    monthS: 'Monat(e)',
    monthly: 'Monatlich',
    never: 'Niemals',
    occurrence: 'Ereignis | Ereignisse',
    occurs: 'Erfolgt',
    on: 'Ein',
    onThisDay: 'an diesem Tag',
    pending: 'Pending',
    projectedDate: 'Geplantes Datum',
    recurrence: 'Wiederholung',
    repeat: 'Wiederholung',
    restartOccurrenceWarningMessage: 'Das Neustarten löscht alle existierenden Reports für den Auftrag. Möchten Sie dennoch Neustarten?',
    searchReport: 'Suchbericht',
    second: 'Zweite',
    startTime: 'Startzeit',
    startingOn: 'beginnend am',
    status: 'Status',
    task: 'Aufgabe',
    taskName: 'Name der Aufgabe',
    tasks: 'Aufgaben',
    third: 'Dritter',
    to: 'Zu',
    today: 'Heute',
    validated: 'Validiert',
    view: 'Ansicht',
    week: 'Woche',
    weekS: 'Woche(n)',
    weekday: 'Wochentag',
    weekendDay: 'Wochenendtag',
    weekly: 'Wöchentlich',
    withoutEndDate: 'ohne Enddatum',
    year: 'Jahr',
    yearly: 'Jährlich',
  },
  cards: {
    criticality: 'Kritikalität: ',
    duration: 'Erwartete Dauer: ',
  },
  chartComparison: {
    chartDuration: 'Dauer der Grafik',
    clickOnFurnaceMsg: 'Klicken Sie auf die Ofen-ID, um zur Sensorzuordnung zu gelangen.',
    compareBtn: 'Vergleiche',
    create: 'Modellprozess erstellen',
    deselectBtn: 'Alle deaktivieren',
    loadingComplete: 'Laden abgeschlossen',
    loadingJob: 'Charge laden',
    noMainTC: "Keine 'Main TC'-Sensordaten in diesem Rezept gefunden",
    noMappedSensors: 'keine zugeordneten Sensoren',
    of: 'von',
    recurringData: 'Wiederkehrende Daten mit ID:',
    unavailableData: 'Daten nicht verfügbar für Chargen-ID : ',
    warningMsg: 'Warnung: Mehr als 10 Chargen ausgewählt. Es kann zu langen Wartezeiten und Leistungsproblemen kommen',
  },
  charts: {
    checkAll: 'Alle auswählen',
    fullscreen: 'Vollbild',
    groups: {
      derivedValues: 'Abgeleitete Werte',
      gasFlows: 'Gasflüsse',
      others: 'Andere',
      potentials: 'Potenziale',
      pressures: 'Drücke',
      temperatures: 'Temperaturen',
    },
    skaleTypes: {
      linear: 'linear',
      logarithmic: 'logarithmisch',
    },
    position: {
      left: 'links',
      right: 'rechts',
    },
    newAxis: 'Neue Achse',
    hoverOnGraph: 'Um Werte zu sehen über dem Graph hovern oder klicken',
    lineWidthSlider: 'Linienbreite',
    lostProductiveTime: 'Verlorene Produktionszeit',
    noAlarms: 'Keine Alarme gefunden',
    notRealizedByShiftModel: 'Nicht realisiert durch das Schichtmodell',
    plannedOutage: 'Geplanter Ausfall',
    productiveTime: 'Produktivzeit',
    tooltips: {
      lostProductiveTime: 'die Differenz in Tagen zwischen der geplanten Produktionszeit und der "produktiven" Zeit',
      notRealizedByShiftModel: 'gibt die Anzahl der Tage pro Monat an, die aufgrund des zugrunde liegenden Schichtmodells nicht durch die Arbeitszeit abgedeckt sind',
      plannedOutage: 'gibt die Anzahl der Tage pro Monat an, die im Durchschnitt für Wartung und Reparatur vorgesehen sind',
      productiveTime: 'die Summe aller Produktionszeiten der ausgeführten Chargen, angezeigt in Tagen pro Monat',
    },
    totalTime: 'Gesamtdauer',
    uncheckAll: 'Alle abwählen',
  },
  chartsVisualization: {
    checkSensorMapping1: 'Bitte prüfen',
    checkSensorMapping2: 'in der Anlagenverwaltung.',
    dataNotDisplayed: 'Es konnten keine Daten angezeigt werden. Bitte überprüfen Sie die Sensorzuordnung in der Anlagenverwaltung',
    noModelProcess: 'Kein Modell Prozess',
    noSensorMapping: 'Für diese Graph wurde keine Sensorzuordnung gefunden.',
    selectModelProcess: 'Modellprozess auswählen',
    sensorMapping: 'Sensorzuordnung',
  },
  measurementUnit: {
    noGroup: 'Andere',
  },
  attributeUnit: {
    [EAttributeLabel.WEIGHT]: 'Gewicht',
    [EAttributeLabel.LENGTH]: 'Länge',
    [EAttributeLabel.DIAMETER]: 'Durchmesser',
    [EAttributeLabel.SURFACE_HARDNESS]: 'Oberflächehärte',
    [EAttributeLabel.SURFACE_HARDNESS_MIN]: 'Minimale Oberflächehärte',
    [EAttributeLabel.SURFACE_HARDNESS_MAX]: 'Maximale Oberflächehärte',
    [EAttributeLabel.CORE_HARDNESS]: 'Kernhärte',
    [EAttributeLabel.CORE_HARDNESS_MIN]: 'Minimale Kernhärte',
    [EAttributeLabel.CORE_HARDNESS_MAX]: 'Maximale Kernhärte',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_MIN]: 'EGT min',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_MAX]: 'EGT max',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_HARDNESS]: 'EGT Härte',
    neuAttributePlaceholder: 'Attribut Label',
  },
  equipmentGasName: {
    [ETimeseriesMeaning.AIR_FLOW_SETPOINT]: 'Luft',
    [ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT]: 'Wasserstoff',
    [ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT]: 'Stickstoff',
    [ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT]: 'Sauerstoff',
    [ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT]: 'Ammoniak',
    [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT]: 'Dissoziiertes Ammoniak',
    [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT]: 'CO2',
    [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT]: 'CO',
    [ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT]: 'Methan',
    [ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT]: 'Propan',
    [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT]: 'N2O',
    [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT]: 'Methanol',
    [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT]: 'Ethylen',
    [ETimeseriesMeaning.ARGON_FLOW_SETPOINT]: 'Argon',
    [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT]: 'Endgas Natürlich',
    [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT]: 'Endgas Propan',
    [ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT]: 'Ethen',
    [ETimeseriesMeaning.HELIUM_FLOW_SETPOINT]: 'Helium',
    [ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT]: 'Erdgas',
    [ETimeseriesMeaning.WATER_FLOW_SETPOINT]: 'Wasser',
  },
  // single words or small phrases WITHOUT CONTEXT and NO LINK
  common: {
    fileName: 'Dateiname',
    ok: 'Ok',
    noAccessToAction: 'Du hast keinen Zugriff auf diese Aktion.',
    tiny: 'Klein',
    exampleText: 'Mein Text',
    large: 'Groß',
    size: 'Größe',
    profile: 'Profil',
    appearance: 'Erscheinungsbild',
    home: 'Startseite',
    notification: 'Meldung | Meldungen',
    meaning: 'Bedeutung | Bedeutungen',
    result: 'Ergebnis',
    toggle: 'Umschalten',
    statistics: 'Statistik',
    rating: 'Bewertung',
    showItem: 'Zeige {item}',
    showTable: 'Tabelle anzeigen',
    showChart: 'Diagramm anzeigen',
    table: 'Tabelle',
    configuration: 'Konfiguration',
    sensor: 'Sensor | Sensoren',
    passed: 'Bestanden',
    failed: 'Nicht Bestanden',
    section: 'Sektion',
    filterName: 'Filtername',
    capacity: 'Kapazität | Kapazitäten',
    gas: 'Gas | Gase',
    capability: 'Fähigkeit | Fähigkeiten',
    certificate: 'Zertifikat | Zertifikate',
    type: 'Typ | Typen',
    model: 'Modell | Modelle',
    createItem: 'Erstelle {item}',
    overview: 'Übersicht',
    documents: 'Dokumente',
    communications: 'Kommunikation',
    percentageC: '%C',
    legend: 'Legende',
    linkItem: 'Verknüpfe {item}',
    today: 'Heute',
    earlier: 'Früher',
    addItem: '{item} hinzufügen',
    min: 'Min',
    max: 'Max',
    avg: 'Durchschnitt',
    file: 'Datei',
    image: 'Bild',
    pasteFromClipboard: 'Aus Zwischenablage einfügen',
    noItemInClipboard: 'Keine {item} in der Zwischenablage',
    assetNavigation: 'Asset Navigation',
    noRecipeSelected: 'Kein Rezept ausgewählt',
    addAssessment: 'Bewertung hinzufügen',
    addRow: 'Zeile hinzufügen',
    description: 'Beschreibung',
    setAll: 'Alle setzen',
    resetAll: 'Alle zurücksetzen',
    unlock: 'Entsperren',
    set: 'Setzen',
    notAvailable: 'N/A',
    stop: 'Stopp',
    modify: 'Ändern',
    itemNotFound: '{item} nicht gefunden',
    user: 'User',
    read: 'Read',
    load: 'Laden',
    loadItem: '{item} laden',
    actions: 'Aktionen',
    add: 'Hinzufügen',
    valueAtTime: '{value} bei {time}',
    timeline: 'Zeitachse',
    duration: 'Dauer',
    temperature: 'Temperatur',
    Ferrite: 'Ferrit',
    Austenite: 'Austenit',
    Martensite: 'Martensit',
    labelInUnit: '{label} in {unit}',
    default: 'Standard',
    settings: 'Einstellungen',
    version: 'Version',
    logout: 'Abmelden',
    confirm: 'Bestätigen',
    edit: 'Bearbeiten',
    start: 'Starten',
    end: 'Ende',
    confirmUnlinkItem: 'Sind Sie sicher, dass Sie "{item}" entkoppeln möchten?',
    confirmDeleteItem: 'Bist du dir sicher, dass du "{item}" löschen möchtest?',
    confirmUpdateItem: 'Sind Sie sicher, dass Sie "{item}" aktualisieren möchten?',
    selectAll: 'Alle auswählen',
    selectItem: 'Wähle {item} aus',
    deselectAll: 'Nichts auswählen',
    value: 'Wert | Werte',
    username: 'Benutzername',
    name: 'Name',
    password: 'Passwort',
    validate: 'Überprüfen',
    selectEquipment: 'Bitte wählen Sie in Asset aus der Asset Navigation auf der linken Seite aus um Details zu sehen',
    required: 'Erforderlich',
    date: 'Datum',
    traveller: 'Reisender',
    new: 'Neu',
    calendarWeekAbbreviation: 'KW',
    unlink: 'Trennen',
    clear: 'Zurücksetzen',
    other: 'Andere',
    cancel: 'Abbrechen',
    error: 'Fehler',
    errorMessage: {
      errorSignalLoading: 'Fehler beim Laden von {displayName}',
      noAccess: '{entity} hat keinen "{accessType}" Zugang zu {item}',
      default: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu. Wenn der Fehler weiterhin besteht, wenden Sie sich bitte an den Support.',
      pt9800: {
        AxiosError401: 'Benutzerzuordnung nicht erfolgt oder ungültig',
        AxiosError401Item: 'Benutzerzuordnung nicht erfolgt oder ungültig für {item}',
        AxiosError403: 'Der Benutzer hat nicht die erforderlichen Berechtigungen für den Zugriff auf PT9800-Daten',
        AxiosError403Item: 'Der Benutzer hat nicht die erforderlichen Berechtigungen für den Zugriff auf PT9800-Daten für {item}',
      },
    },
    fileGeneration: {
      summary: 'Datei wird generiert',
      detail: 'Die generation von Datei kann etwas länger dauern. Bitte lassen Sie das Browserfenster ofen. Diese Nachricht schließt sich automatisch nachdem der Report erstellt wurde.',
    },
    defaultError: 'Fehler',
    update: 'Aktualisieren',
    create: 'Erstellen',
    createCopy: 'Kopie erstellen',
    delete: 'Löschen',
    save: 'Speichern',
    saveItem: '{item} speichern',
    saveAsNew: 'Speichern unter ...',
    yes: 'Ja',
    no: 'Nein',
    export: 'Export',
    import: 'Importieren',
    search: 'Suche',
    selected: 'Ausgewählt',
    back: 'Zurück',
    forward: 'Vorwärts',
    accept: 'Akzeptieren',
    print: 'Drucken',
    download: '{item} Herunterladen',
    sensorSuffix: {
      actual: '(ACT)',
      setpoint: '(SET)',
    },
    submit: 'Bestätigen',
    success: 'Erfolg',
    close: 'Schließen',
    from: 'von',
    to: 'bis',
    month: {
      [EMonth.JANUARY]: {
        long: 'Januar',
        short: 'Jan',
      },
      [EMonth.FEBRUARY]: {
        long: 'Februar',
        short: 'Feb',
      },
      [EMonth.MARCH]: {
        long: 'März',
        short: 'Mär',
      },
      [EMonth.APRIL]: {
        long: 'April',
        short: 'Apr',
      },
      [EMonth.MAY]: {
        long: 'Mai',
        short: 'Mai',
      },
      [EMonth.JUNE]: {
        long: 'Juni',
        short: 'Jun',
      },
      [EMonth.JULY]: {
        long: 'Juli',
        short: 'Jul',
      },
      [EMonth.AUGUST]: {
        long: 'August',
        short: 'Aug',
      },
      [EMonth.SEPTEMBER]: {
        long: 'September',
        short: 'Sep',
      },
      [EMonth.OCTOBER]: {
        long: 'Oktober',
        short: 'Okt',
      },
      [EMonth.NOVEMBER]: {
        long: 'November',
        short: 'Nov',
      },
      [EMonth.DECEMBER]: {
        long: 'Dezember',
        short: 'Dez',
      },
    },
    time: {
      second: 'Sekunde | Sekunden',
      day: 'Tag | Tage',
      week: 'Woche | Wochen',
      month: 'Monat | Monate',
      quarter: 'Quartal | Quartale',
      year: 'Jahr | Jahre',
    },
    timeAbbreviation: {
      days: 'd',
      hours: 'h',
      minutes: 'min',
      months: 'm',
      weeks: 'KW',
      years: 'a',
    },
    sum: 'Summe',
    average: {
      long: 'Durchschnitt',
      short: 'Avg',
    },
    reset: 'Zurücksetzen',
    comment: 'Bemerkung',
    none: 'None',
    copy: 'Kopieren',
    copyNoun: 'Kopie',
  },
  views: {
    loadList: {
      selectLoad: {
        selectPartButton: 'Teil auswählen',
        addPartButton: 'Teil hinzufügen',
      },
    },
  },
  component: {
    qualityForm: {
      jobSelection: 'Job auswählen',
      partSelection: 'Teil auswählen',
    },
    furnaceViewRecipe: {
      stopConfirm: 'Sind Sie sicher, dass Sie das Rezept "{0}" stoppen möchten?',
      startConfirm: 'Sind Sie sicher, dass Sie das Rezept "{0}" starten möchten?',
      modifyConfirm: 'Sind Sie sicher, dass Sie das Rezept "{0}" ändern möchten?',
    },
    createUser: {
      headline: 'Benutzer erstellen',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      role: 'Rolle',
      permissionsCheckbox: 'Benutzerdefinierte Berechtigungen',
      appPermissions: 'App Berechtigungen',
      assetPermissions: 'Asset Berechtigungen',
    },
    pt9800RecipeForm: {
      recipeName: 'Rezeptname',
      totalDuration: 'Gesamtdauer',
      processSteps: 'Prozessschritte',
      generalHeader: 'Allgemeine Informationen',
      diffusionModel: 'Diffusionsmodell',
      version: 'Version',
      parameters: 'Parameter',
      profile: 'Profil',
      recipeExists: 'Ein Rezept mit diesem Namen existiert bereits.',
    },
    userSettingsModal: {
      title: 'User settings',
      language: 'Language',
      timeFormat: 'Time format',
    },
    editOrCreateRoleModal: {
      editHeadline: 'Rolle bearbeiten',
      createHeadline: 'Rolle erstellen',
      appPermission: 'App-Berechtigungen',
      assetPermission: 'Asset-Berechtigungen',
    },
    appAndAssetPermissionTree: {
      appPermission: 'App-Berechtigungen',
      assetPermission: 'Asset-Berechtigungen',
    },
    rootCauseLinkModal: {
      title: 'Link root cause',
    },
    sensorTable: {
      tableHeader: 'Asset-Signale',
      showHidden: 'Versteckte Asset-Signale anzeigen',
      dataFormat: 'Typ',
    },
    materialSelect: {
      placeholder: 'Material auswählen',
      unit: 'Materialien',
    },
    materialGroupTable: {
      tableHeader: 'Material groups',
    },
    dashboardPanel: {
      add: 'Panel hinzufügen',
      defaultTitle: 'Mein Panel',
    },
    presetSelection: {
      modal: {
        defaultTile: 'Mein Preset',
      },
    },
    userUpdateModal: {
      tab: {
        permissionList: 'Berechtigungen',
        controllerUserMapping: 'Controller-Benutzerzuordnung',
      },
      permissionList: 'Berechtigungsliste',
      groupList: 'Gruppenliste',
      selectPT9800: 'PT9800-Benutzer auswählen',
      enterCredentials: 'Anmeldeinformationen eingeben',
    },
    loadModal: {
      startingLoad: 'Job wird gestartet...',
      confirmPartHint: 'Bitte bestätigen Sie, dass alle Teile geladen sind',
      confirmStart: 'Bestätigen Sie den Start des Jobs.',
      workplaceWoNumber: generalTerm.loadIdentifier,
      createOrUpdate: 'Job plannen',
      description: 'Planen Sie Ihre nächste Ladung, indem Sie Ihre',
      addTraveller: 'Neuen Reisenden hinzufügen',
      emptyPartsTable: 'Keine Teile hinzugefügt',
      partNotFound: 'Teil mit der ID "{id}" wurde nicht gefunden',
      partDoNotMatch: 'Nicht alle Teile entsprechen dem Rezept',
      readyToScan: 'Bereit zum Scannen',
    },
    rootCauseModal: {
      create: 'Ursache erstellen',
      update: 'Ursache aktualisieren',
    },
    rootCauseGroupModal: {
      create: 'Ursachengruppe erstellen',
      update: 'Ursachengruppe aktualisieren',
    },
    counterActionModal: {
      create: 'Gegenmaßnahme erstellen',
      update: 'Gegenmaßnahme aktualisieren',
    },
    counterActionTable: {
      create: 'Gegenmaßnahme erstellen',
      confirmDelete: 'Gegenmaßnahme "{counterActionName}" löschen?',
      confirmUnlink: 'Ursache "{rootCauseName}" von Gegenmaßnahme "{counterActionName}" trennen?',
    },
    rootCauseTable: {
      create: 'Ursache erstellen',
      confirmDelete: 'Ursache "{rootCauseName}" löschen?',
      confirmUnlink: 'Gegenmaßnahme "{counterActionName}" von Ursache "{rootCauseName}" trennen?',
    },
    rootCauseGroupTable: {
      create: 'Ursachengruppe erstellen',
      confirmDelete: 'Ursachengruppe "{rootCauseGroupName}" löschen?',
      confirmUnlink: 'Ursache "{rootCauseName}" von Ursachengruppe "{rootCauseGroupName}" trennen?',
    },
    observationTypeTable: {
      unlinkConfirm: 'Ursache "{rootCauseName}" von Beobachtungstyp "{observationTypeName}" trennen?',
    },
    productionLogsTable: {
      heatTreatmentProcess: 'Wärmebehandlungsprozess',
      hasAlarms: 'Hat Alarme',
      tab: {
        table: 'Tabelle',
        chart: 'Diagramm',
      },
    },
    dateRangePicker: {
      currentWeek: 'Aktuelle Woche',
      custom: 'Benutzerdefiniert',
      absolute: 'Absolut',
      customRange: 'Zeitraum',
      relative: 'Relativ',
      timeUnit: 'Zeiteinheit',
      relativeMinDateError: 'Das Datum darf nicht vor dem {0} liegen',
      operatingAbdoluteErrorMessage: 'Bitte wählen Sie ein Datum innerhalb des angegebenen Bereichs: {min} - {max}',
    },
    specificationModal: {
      header: 'Specification',
      specificationSubmitSuccess: 'Specification saved',
      error: {
        envelopeInvalid: 'Envelope is invalid',
        emptyZones: 'Zones cannot be empty',
        nameMissing: 'Specification must have name',
      },
    },
    treeSidebarTemplate: {
      defaultTitle: 'Asset Navigation',
    },
    TableJobStages: {
      header: 'Rezeptstufen',
    },
    observationView: {
      header: 'Beobachtungen',
    },
    furnacePanel: {
      status: 'Status',
      process: 'Load No',
      recipeName: 'Rezeptname',
      stageNumber: 'Stufennummer',
      stageName: 'Stufenname',
      activeAlarms: 'Aktive Alarme',
      furnaceStatus: {
        IDLE: 'Leerlauf',
        MAINTENANCE: 'Wartung',
        OFFLINE: 'Aus',
        RUNNING: 'In Betrieb',
        RUNNING_NO_ALARMS: 'In Betrieb',
        RUNNING_ALARM: 'Alarm',
        RUNNING_EMERGENCY: 'Notfall',
        RUNNING_NORMAL: 'Info',
        RUNNING_WARNING: 'Warnung',
        LOADING: 'Wird geladen...',
        LOADING_ERROR: 'Fehler beim Laden',
      } as Record<TFurnacePanelStatus, string>,
    },
    alarmView: {
      header: 'Alarme',
      empty: 'Keine Alarme gefunden',
    },
    accordionAudit: {
      envelopeNamePlaceholder: 'Grenzenname',
    },
    page: {
      performanceDashboard: {
        stackedPolarChart: {
          title: 'Productivity (Polar)',
        },
        clusteredStackedBarChart: {
          title: 'Productivity (Clustered Stacked bar)',
        },
        table: {
          title: 'Productivity (Table)',
        },
        stackedBarChart: {
          title: 'Productivity (Stacked bar)',
        },
        paretoChart: {
          title: 'Productivity (Pareto)',
        },
        visualisationModal: {
          title: 'Anzeigeeinstellungen',
          subtitle: 'Wählen Sie die Diagramme aus, die Sie auf dem Dashboard anzeigen möchten, aus den unten stehenden Listen aus.',
          aggregationTitle: 'Aggregation',
          aggregationDescription: 'Diese Diagramme zeigen die Effizienz für den gesamten ausgewählten Zeitraum an.',
        },
      },
      productionLog: {
        presetEditForm: {
          saveSuccessMessage: 'Ihr Filter wurde gespeichert.',
          deleteConfirmation: 'Möchten Sie den Filter löschen?',
          deleteSuccessMessage: 'Filter erfolgreich gelöscht',
        },
      },
      maintenanceCalendar: {
        maintenanceCalendarTemplate: {
          routeName: {
            [ERouteName.MAINTENANCE_CALENDAR]: 'Kalender',
            [ERouteName.MAINTENANCE_CALENDAR_REPORT_LIST]: 'Berichte',
            [ERouteName.MAINTENANCE_CALENDAR_JOB_LIST]: 'Jobs',
            [ERouteName.MAINTENANCE_CALENDAR_TASK_LIST]: 'Tasks',
          },
        },
      },
      quality: {
        draft: {
          steps: {
            loadSelection: 'Job auswählen',
            partSelection: 'Teil auswählen',
            sampling: 'Probenahme',
          },
        },
      },
    },
    processChartComponent: {
      treeView: {
        deselectAll: 'Alles abwählen',
      },
      defaultCustomConfigName: 'New unsaved custom config',
      deselect: 'Deselect All',
      presetConfigModal: {
        configMapPlaceholder: 'Select signal to edit',
        presetConfigSaveSuccess: 'Preset config saved',
        presetNameLabel: 'Preset name',
        presetSaveSuccess: 'Preset saved',
      },
      presetEdit: 'Select preset to edit',
      signalHintList: 'Sensor-Datenvergleich',
    },
    maintenanceCalendarJobModal: {
      titleCreate: 'Wartungsjob erstellen',
      titleEdit: 'Wartungsjob bearbeiten',
      createTask: 'Aufgabe erstellen',
    },
    maintenanceCalendarTaskModal: {
      titleCreate: 'Arbeitsschritt erstellen',
      titleEdit: 'Arbeitsschritt bearbeiten',
    },
    maintenanceOccurrenceTable: {
      occurrenceListTitle: 'Wartungsaufträge',
      hideCompletedEvents: 'Abgeschlossene Aufträge ausblenden',
    },
    maintenanceServiceReportModal: {
      finish: 'Wartungsauftrag beenden',
      reject: 'Wartungsauftrag ablehnen',
      rejectLabel: 'Bitte geben Sie einen Grund für die Ablehnung an.',
    },
    equipmentDocumentUploadModal: {
      headline: 'Dokumente hochladen',
      categoryTitle: 'Kategorie',
      descriptionTitle: 'Beschreibung',
      uploadFilesTitle: 'Dateien hochladen',
      errorMessage: 'Datei existiert bereits',
    },
    PartAttribute: {
      headline: 'Part settings',
      subheadline: 'Die in dieser Tabelle definierten Attribute werden bei der Erstellung/Änderung von Teilen abgefragt.',
    },
    materialFilter: {
      multiselectPlaceholder: 'Filtern nach Material',
      unit: 'Materials',
    },
    partForm: {
      partSpecification: 'Teilespezifikation',
      treatmentInformation: 'Information zur Behandlung',
      treatmentRemark: 'Anmerkung zur Behandlung',
      assetsAndRecipes: 'Qualifiziertes Assete & Rezepte',
    },
    expressionMeaningSelection: {
      onlyMapped: 'Show mapped meanings only',
    },
    expressionPreviewModal: {
      title: 'Neues virtuelles Signal',
    },
    specificationSaveModal: {
      nameLabel: 'Name',
      nameDescription: 'Beschreibung',
    },
  },
  confirmationMessages: {
    componentAdded: 'Die Komponente wurde hinzugefügt',
    componentUpdated: 'Komponente {component} wurde erfolgreich aktualisiert',
    confirmDelete: 'Möchten sie {name} löschen?',
    deleteComponent: 'Möchten Sie löschen {part}?',
    deleteConnection: 'Möchten sie diese Verbindung Löschen?',
    deleteFile: 'Möchten Sie diese Datei löschen?',
    deleteMaterial: 'Möchten Sie {material} löschen?',
    deletePart: 'Möchten Sie das Teil {name} löschen?',
    deleteRecipe: 'Möchten Sie das Rezept {name} löschen?',
    deleteRootCauseLink: 'Möchten Sie die Verknüpfung von Grundursache {rootCause} wirklich aufheben?',
    deleteRootCauses: 'Möchten Sie die {rootCauses} löschen?',
    deleteStage: 'Möchten Sie {name} löschen?',
    deleteUser: 'Möchten Sie den Benutzer {Benutzername} löschen?',
    deletedAlready: 'Benutzer {username} wurde bereits gelöscht',
    deletedComponent: 'Die Komponente wurde gelöscht',
    deletedPart: 'Das Teil wurde gelöscht',
    deletedRecipe: 'Das Rezept wurde gelöscht',
    deletedSparePart: 'Ersatzteil wurde gelöscht',
    deletedUser: 'Benutzer {Benutzername} wurde gelöscht',
    equipmentAdded: 'Asset erfolgreich hinzugefügt',
    equipmentEdited: 'Ausrüstung erfolgreich bearbeitet',
    equipmentUpdated: 'Asset {equipment} wurde erfolgreich aktualisiert',
    materialAdded: 'Material erfolgreich hinzugefügt',
    materialUpdated: 'Material erfolgreich aktualisiert',
    materialsGroupAdded: 'Materialgruppe erfolgreich hinzugefügt',
    materialsGroupUpdated: 'Materialgruppe erfolgreich aktualisiert',
    partAdded: 'Teil erfolgreich hinzugefügt',
    recipeAdded: 'Rezept erfolgreich hinzugefügt',
    registrationAlreadyConfirmed: 'Benutzer {Benutzername} hat die Registrierung bereits bestätigt',
    registrationEmailResend: 'Die Registrierungs-E-Mail wurde erneut an {Benutzername} gesendet.',
    resendUserEmail: 'Möchten Sie die E-Mail zur Benutzerregistrierung erneut an {Benutzername} senden?',
    restoreRecipe: 'Bitte bestätigen Sie die Wiederherstellung von {recipe} Rezept?',
    saveMaterials: 'Möchten Sie Material hinzufügen?',
    savePart: 'Möchten Sie einen Teil speichern?',
    saveRecipe: 'Bitte bestätigen Sie die Speicherung des Rezepts {recipe}?',
    sensorCopied: 'Sensorzuordnung wurde erfolgreich kopiert',
    sensorNotCopied: 'Sensorzuordnung konnte nicht kopiert werden',
    sparePartAdded: 'Ersatzteile erfolgreich hinzugefügt',
    sparePartUpdated: 'Ersatzteil {sparePart} erfolgreich aktualisiert',
    userAdded: 'Benutzer {user} hinzugefügt',
    userTilesUpdated: 'Apps für {user} erfolgreich aktualisiert',
  },
  consumables: {
    currMonth: 'Aktueller Monat',
    forecast: 'Prognose',
    prevMonth: 'Vorheriger Monat',
  },
  customerSettings: {
    actions: 'Aktionen',
    errorMsg: 'Falscher Wert!',
    currencyError: 'Die Währung sollte aus 3 Buchstaben der Währung bestehen, z.B.: EUR, USD',
    hourlyBillingRate: 'Abrechnungssatz pro Stunde',
    hourlyDepreciationRate: 'Abschreibungssatz pro Stunde',
    hoursPerShift: 'Stunden pro Schicht',
    plannedOutageDaysPerYear: 'Geplante Stillstände pro Jahr (in Tagen)',
    rateCurrency: 'Währung',
    workingShiftsPerWeek: 'Schichten pro Woche',
  },
  dateRangePicker: {
    currentWeek: 'Aktuelle Woche',
    custom: 'Benutzerdefinierte',
    days: 'Tage',
    months: 'Monate',
    weeks: 'Wochen',
    years: 'Jahre',
  },
  details: {
    chart: 'Grafik',
    images: 'Bilder',
  },
  dialog: {
    saveChangesConfirm: 'Wollen Sie die Aenderungen speichern ?',
    saveChangesFailed: 'Die Änderungen konnten nicht gespeichert werden.',
  },
  durationFormat: {
    withValue: {
      minute: '{0} @:durationFormat.minute',
      hour: '{0} @:durationFormat.hour',
      day: '{0} @:durationFormat.day',
      week: '{0} @:durationFormat.week',
      month: '{0} @:durationFormat.month',
      year: '{0} @:durationFormat.year',
    },
    minute: 'Minute | Minuten',
    hour: 'Stunde | Stunden',
    day: 'Tag | Tage',
    week: 'Woche | Wochen',
    month: 'Monat | Monate',
    year: 'Jahr | Jahre',
  },
  errorMessages: {
    componentAdded: 'Die Komponente ist nicht hinzugefügt worden',
    componentUpdated: 'Die Komponente wurde nicht aktualisiert',
    credentials: 'Falscher Benutzername oder Passwort',
    deletedUser: 'Benutzer {Benutzername} wurde seit der letzten Aktualisierung der Seite gelöscht',
    equipmentAdded: 'Das Asset ist nicht hinzugefügt worden',
    equipmentEdited: 'Das Gerät wurde nicht bearbeitet',
    equipmentUpdated: 'Das Asset wurde aktualisiert',
    equipmentViewError: 'Keine Ansicht für das angegebene Asset gefunden',
    error: 'Fehler',
    furnaceNotAvailable: 'Ansicht nicht verfügbar für diesen Ofentyp',
    generalMessage: 'Etwas ist schief gelaufen. Versuchen Sie es später nochmal',
    materialNotAdded: 'Das Material wurde nicht hinzugefügt',
    materialNotUpdated: 'Das Material wurde nicht aktualisiert',
    materialsGroupAdded: 'Die Materialgruppe wurde nicht hinzugefügt',
    materialsGroupUpdated: 'Die Materialgruppe wurde nicht aktualisiert',
    noEquipmentSelected: 'Bitte wählen Sie in Asset aus der Asset Navigation auf der linken Seite aus um Details zu sehen',
    oauth2Fail: 'Oauth2 fehlgeschlagen',
    outOfRangeTemperature: 'Die Temperatur liegt außerhalb des zulässigen Bereichs: -200°C - 850°C (-328°F - 1562°F). Bitte passen Sie den RG oder RL Wert an',
    partNotAdded: 'Das Teil wurde nicht hinzugefügt',
    recipeNotAdded: 'Das Rezept wurde nicht hinzugefügt',
    serverMessage: 'Server Probleme, versuchen Sie es später nochmal',
    unauthorized: 'Unberechtigt',
  },
  filters: {
    dateFrom: 'Datum von',
    dateTo: 'Datum bis',
    filter: 'Filter',
    filterSensors: 'Sensoren filtern',
    savedFilters: 'Gespeicherte Filter',
  },
  footer: {
    privacyPolicy: 'Datenschutzrichtlinie',
  },
  forms: {
    JobValue: 'Chargenwert',
    addNewPlant: 'Standort hinzufügen',
    addNewUser: 'Neuen Benutzer hinzufügen',
    additionalInformation: 'Weitere Informationen',
    assetId: 'Asset nummer',
    configureValue: 'Wert konfigurieren',
    createRootCause: 'Ursachen erstellen',
    credentials: 'Zugangsdaten',
    credentialsValid: 'Zugangsdaten gültig',
    customerName: 'Customer Name',
    customerRequestAdded: 'Die File wurde hochgeladen',
    delete: 'Datei gelöscht',
    department: 'Abteilung',
    depth: 'Tiefe',
    derivedValues: 'Abgeleitete Werte',
    description: 'Beschreibung',
    diameter: 'Durchmesser',
    dimensionsOfLoad: 'Abmessungen der Ladung',
    documentsCategoryCreated: 'Kategorie hinzugefügt',
    editMaterial: 'Material bearbeiten',
    editMaterialGroup: 'Materialgruppe bearbeiten',
    editRootCause: 'Grundursachen bearbeiten',
    editUser: 'Aktualisier Nutzer {user}',
    email: 'E-Mail',
    fileUploaded: 'Die Datei wurde hochgeladen',
    filterCriteria: '4 Filterkriterien',
    fullName: 'Vollständiger Name',
    furnaceClass: 'Klasse der Öfen',
    furnaceName: 'Name des Ofens',
    heatTreatment: 'AMS2759: Wärmebehandlung von Stahlteilen',
    height: 'Höhe',
    host: 'Host',
    information: 'Informationen',
    instrumentationType: 'Art der Instrumentierung',
    jobFeatures: 'Chargeneigenschaften',
    jobValue: 'Load Value',
    jobValues: 'Chargenwert',
    locationName: 'Standort Name',
    logValues: 'Log-Werte',
    malfunctionStartDate: 'Beginn der Störung',
    manufacturer: 'Hersteller',
    manufacturerPartNumber: 'Original-Teilenummer',
    mapTiles: 'Benutzerzuordnung: Controller',
    maxFlow: 'Max Flow',
    maxLoad: 'Max Load',
    maxTemp: 'Max Temp',
    minTemp: 'Min Temp',
    name: 'Name',
    newMaterial: 'Neues Material',
    newMaterialGroup: 'Neue Materialgruppe',
    otherGasses: 'Andere Fähigkeiten',
    password: 'Passwort',
    persistCredentials: 'Zugangsdaten speichern',
    plantName: 'Name',
    port: 'Port',
    predictions: 'Vorhersagen',
    pyrometry: 'AMS2750: Pyrometry',
    requestCategory: 'Kategorie',
    requiredEndDate: 'Benötigter Endtermin',
    selectBatchesOrTimePeriod: '3 Wählen Sie Chargen oder Zeitraum aus',
    selectFurnace: 'Kopieren der Sensorzuordnung vom aktuellen Ofen für den Ofen:',
    selectedBatchesOrTimePeriod: '3 ausgewählte Chargen oder Zeiträume',
    selectedLocationsAndAssets: '1 Ausgewählte Standorte und Assets',
    selectedValue: '2 Ausgewählter Wert',
    spareParts: 'Ersatzteile',
    stateChanged: 'Der Status wurde geändert',
    ticketStatus: 'Ticket-Status',
    type: 'Typ',
    uploadCategory: 'Kategorie auswählen oder erstellen',
    uploadEquipment: 'Asset aktualisieren',
    uploadFile: 'Dateien hochladen',
    uploadFiles: 'Dateien hochladen',
    uploadedFiles: 'Hochgeladene Dateien',
    username: 'Benutzername',
    width: 'Breite',
  },
  furnaceStatus: {
    alarm: 'Alarm',
    disconnected: 'Nicht verbunden',
    emergencyProcedure: 'Verfahren für Notfälle',
    idle: 'Leerlauf',
    noRemoteConnection: 'Keine Remote-Verbindung',
    running: 'Laufendes Rezept',
    switchedOff: 'Ausgeschaltet',
  },
  global: {
    all: 'Alle',
    comment: 'Bemerkung',
    order: 'Auftrag',
    part: 'Teil',
    workplace: 'Arbeitsplatz',
  },
  hubspotTicketStatus: {
    1: 'Neu',
    2: 'Warten auf Kontakt',
    3: 'Warten auf uns',
    4: 'Geschlossen',
    5: 'Entfernt',
    34633361: 'Neu',
    34633362: 'In Bearbeitung',
    34633364: 'Geschlossen',
  },
  images: {
    addImage: 'Bild hinzufügen',
    delete: 'Löschen',
    download: 'Herunterladen',
    images: 'Bilder',
    maximumNumberOfImages: 'Sie können bis zu 4 Bilder hinzufügen',
    noImages: 'Keine Bilder',
    print: 'Drucken',
    save: 'Speichern',
    upload: 'Hochladen',
    uploadImageDescription: 'Laden Sie ein Bild hoch',
    zoom: 'Zoom',
  },
  inputLabels: {
    from: 'von',
    groupname: 'Name der Gruppe',
    searchRecipe: 'Rezept suchen',
    to: 'bis',
  },
  kpis: {
    cost_avg_ammonia: 'Cost Avg Ammonia',
    cost_avg_carbon_dioxide: 'Cost Avg Carbon Dioxide',
    cost_avg_co_gas: 'Cost Avg CO gas',
    cost_avg_dissociated_ammonia: 'Cost Avg Dissociated Ammonia',
    cost_avg_electricity: 'Cost Avg Electricity',
    cost_avg_endo_gas: 'Cost Avg Endo Gas',
    cost_avg_h2: 'Cost Avg H2',
    cost_avg_nitrogen: 'Cost Avg Nitrogen',
    cost_stddev_ammonia: 'Kosten-Standardabweichung Ammoniak',
    cost_stddev_carbon_dioxide: 'Kosten-Standardabweichung Kohlendioxid',
    cost_stddev_co_gas: 'Kosten-Standardabweichung CO Gas',
    cost_stddev_dissociated_ammonia: 'Kosten-Standardabweichung dissoziiertes Ammoniak',
    cost_stddev_endo_gas: 'Kosten-Standardabweichung Endo Gas',
    cost_stddev_h2: 'Kosten-Standardabweichung H2',
    cost_stddev_nitrogen: 'Kosten-Standardabweichung Nitrogen',
    cost_sum_ammonia: 'Cost Sum Ammonia',
    cost_sum_carbon_dioxide: 'Cost Sum Carbon Dioxide',
    cost_sum_co_gas: 'Cost Sum CO gas',
    cost_sum_dissociated_ammonia: 'Cost Sum Dissociated Ammonia',
    cost_sum_electricity: 'Cost Sum Electricity',
    cost_sum_endo_gas: 'Cost Sum Endo Gas',
    cost_sum_h2: 'Cost Sum H2',
    cost_sum_nitrogen: 'Cost Sum Nitrogen',
    time_avg_load_duration: 'Avg Duration',
    time_max_start_date: 'Start date latest',
    time_min_start_date: 'Start date oldest',
    time_stddev_load_duration: 'Standardabweichung Zeit',
    time_sum_load_duration: 'Sum Duration',
    value_availability: 'Ofen Verfügbarkeit',
    value_avg_ammonia: 'Avg Ammonia',
    value_avg_carbon_dioxide: 'Avg Carbon Dioxide',
    value_avg_co_gas: 'Avg CO gas',
    value_avg_dissociated_ammonia: 'Avg Dissociated Ammonia',
    value_avg_electricity: 'Avg Electricity',
    value_avg_endo_gas: 'Avg End Endo Gas',
    value_avg_h2: 'Avg H2',
    value_avg_nitrogen: 'Avg Nitrogen',
    value_cnt_batch: 'Chargenanzahl',
    value_furnace_brutto_productivity: 'Ofen Produktivität (brutto)',
    value_furnace_netto_productivity: 'Ofen Produktivität (Netto)',
    value_stddev_ammonia: 'Standardabweichung Ammoniak',
    value_stddev_carbon_dioxide: 'Standardabweichung Kohlendioxid',
    value_stddev_co_gas: 'Standardabweichung CO Gas',
    value_stddev_dissociated_ammonia: 'Standardabweichung dissoziiertes Ammoniak',
    value_stddev_endo_gas: 'Standardabweichung Endo Gas',
    value_stddev_h2: 'Standardabweichung H2',
    value_stddev_nitrogen: 'Standardabweichung Nitrogen',
    value_sum_ammonia: 'Sum Ammonia',
    value_sum_carbon_dioxide: 'Sum Carbon Dioxide',
    value_sum_co_gas: 'Sum CO gas',
    value_sum_dissociated_ammonia: 'Sum Dissociated Ammonia',
    value_sum_electricity: 'Sum Electricity',
    value_sum_endo_gas: 'Sum End Endo Gas',
    value_sum_h2: 'Sum H2',
    value_sum_nitrogen: 'Sum Nitrogen',
  },
  levels: {
    critical: 'Kritisch',
    high: 'Hoch',
    low: 'Niedrig',
    medium: 'Mittel',
  },
  loadList: {
    chargeId: 'Ch-Kennung',
    sortingTime: 'Anzeige-Reihenfolge',
    statusSequence: 'Status der Chargenfolge',
  },
  localizationDialogs: {
    '12hourTime': '12 Stunden Zeit',
    '24hourTime': '24 Stunden Zeit',
    decimalComma: 'Komma',
    decimalPoint: 'Punkt',
  },
  maintenanceDashboard: {
    activeAlarms: 'Aktive Alarme',
    alarmsFrequency: 'Häufigkeit der Alarme',
    plannedTasks: 'Geplante Wartungsaufgaben',
    rollingAverageSize: 'Größe der Teilmenge des gleitenden Durchschnitts',
    showAlarms: 'Alarm-Statistik',
    showRollingAverage: 'Gleitenden Durchschnitt anzeigen',
    showShewhartGraph: 'Shewhart-Graph anzeigen',
  },
  maintenanceDetails: {
    noDatabaseForFurnace: 'Asset hat keine Datenbank',
  },
  maintenanceRecurrencePeriod: {
    [EMaintenanceRecurrencePeriod.NEVER]: 'Niemals',
    [EMaintenanceRecurrencePeriod.WEEKLY]: 'Wöchentlich',
    [EMaintenanceRecurrencePeriod.MONTHLY]: 'Monatlich',
    [EMaintenanceRecurrencePeriod.YEARLY]: 'Jährlich',
  },
  maintenanceServiceTab: {
    [EMaintenanceServiceTab.ATTACHMENTS]: 'Anhänge',
    [EMaintenanceServiceTab.DETAILS]: 'Details',
    [EMaintenanceServiceTab.REPORT]: 'Bericht',
  },
  materials: {
    elements: 'Elemente',
    filterGroups: 'Materialgruppe filtern',
    materialElements: 'Material Elemente',
    materialGroup: 'Materialgruppe',
    materialGroups: 'Materialgruppen',
    searchMaterialGroup: 'Materialgruppe suchen',
    searchMaterials: 'Materialien suchen',
  },
  materialComposition: {
    min: {
      label: 'Min',
      description: 'Analyse mit den kleinsten Materialeigenschaften.',
    },
    max: {
      label: 'Max',
      description: 'Analyse mit den größten Materialeigenschaften.',
    },
    avg: {
      label: 'Mittelwert',
      description: 'Analyse mit mittleren Materialeigenschaften.',
    },
    comp: {
      label: 'Mittlere Analyse',
      description: 'Durschnittliche Analyse entsprechend der Gewichtsprozente.',
    },
  },
  modals: {
    imagesOf: 'Bilder zu {entity}',
  },
  modelProcess: {
    STAGE_WISE_TUNNEL: 'Phasenbezogener Tunnel',
    STATIC_TUNNEL: 'Statischer Tunnel',
    createModel: 'Modellprozess erstellen',
    createNewModel: 'Neuen Modellprozess erstellen',
    creationPage: 'Erstellungsseite anzeigen',
    deleteModelProcess: 'Wollen Sie Model Process wirklich löschen {model}?',
    modelDescription: 'Beschreibung des Modellprozesses',
    modelProcessName: 'Modellprozessname',
    notAllGood: 'Nicht alle für den Modellprozess ausgewählten Chargen wurden als gut eingestuft',
    removeProcess: 'Modellprozess entfernen',
    tunnelType: 'Tunnel Typ',
    viewProcess: 'Modellprozess anzeigen',
  },
  modifyMenu: {
    addNew: 'Neuer {0}',
    createNew: 'Neuen Hintergrundprozess erstellen',
    delete: 'Löschen',
    edit: 'Ändern',
    print: 'Drucken',
    saveAs: 'Speichern unter',
  },
  monitoringDashboard: {
    consumables: 'Verbräuche',
    furnaceState: 'Status',
    productivity: 'Produktivität',
    sankeyChartLoadingError: 'Fehler beim Laden der Daten. Überprüfen Sie die Asset Einstellungen',
  },
  nitrexConnect: {
    dashboard: 'Dashboard',
    description: 'Beschreibung',
    endCondition: 'Abschlussbedingung',
    furnPress: 'Ofendruck',
    host: 'Host',
    kn: 'Kɴ',
    mainTCSetValue: 'Main TC SP',
    mainTc: 'Main TC',
    port: 'Port',
    process: 'Prozess',
    processTime: 'Prozessdauer',
    stageName: 'Name des Behandlungsschrittes',
    stageNumber: 'Nummer des Behandlungsschrittes',
    stageTime: 'Dauer des Behandlungsschrittes',
    status: 'Status',
    time: 'Zeit',
    totalFlow: 'Durchfluss (gesamt)',
  },
  notes: {
    add: 'Notiz hinzufügen',
    author: 'Autor:',
    edit: 'Notiz bearbeiten',
    noAuthor: 'Schreiben Sie hier Ihre Notiz',
    timestamp: 'Zeitstempel: ',
  },
  notifications: {
    noPending: 'Keine Benachrichtigungen',
  },
  operationDashboard: {
    AMMONIA_FLOW: 'Ammoniak Durchfluss',
    C2H2_FLOW: 'Acetylen Durchfluss',
    C2H4_FLOW: 'Ethylen Durchfluss',
    C2H6_FLOW: 'Ethan Durchfluss',
    C3H8_FLOW: 'Propan Durchfluss',
    CH4_FLOW: 'Methan Durchfluss',
    CO2_FLOW: 'Kohlendioxid Durchfluss',
    CO_FLOW: 'Kohlenmonoxid Durchfluss',
    CP: 'C Potenzial',
    C_POTENTIAL: 'C-Potenzial',
    FURNACE_PRESSURE: 'Ofendruck',
    FURNACE_TEMPERATURE: 'Ofen Temperatur',
    H2_FLOW: 'Wasserstoff Durchfluss',
    KN_POTENTIAL: 'Kn-Potenzial',
    TEMPERATURE: 'Temperatur',
    expCompletionTime: 'Voraussichtliche Fertigstellungszeit',
    finishedJobs: 'Chargen',
    ganttView: 'Gantt Diagramm',
    hoursUntilCompletion: 'Stunden bis zur Fertigstellung',
    jobs: 'Chargen',
    listView: 'Listenansicht',
    missingSensorsMapping: 'Nicht zugeordnete Sensoren',
    mustSelectLocation: 'Wählen Sie einen Standort oder eine Asset, um fortzufahren',
    noKpiData: 'Keine KPI-Daten',
    recipeName: 'Rezeptname',
    recipeStatus: 'Rezeptstatus',
    status: 'Status',
    tableView: 'Tabellenansicht',
    tileView: 'Kachelansicht',
    workOrderNumber: 'Auftragsnummer',
  },
  page: {
    notification: {
      title: 'Meldungen',
    },
    customerDetails: {
      title: 'Customer details',
    },
    customerEdit: {
      title: 'Edit customer',
    },
    customerCreate: {
      title: 'Create customer',
    },
    userManagement: {
      manageRole: 'Rollen verwalten',
      headline: 'Benutzer ({0})',
      addUser: 'Benutzer hinzufügen',
      customPermissions: 'Benutzerdefinierte Zugriffsrechte',
      searchPlaceholder: {
        name: 'Suche nach Name',
        email: 'Suche nach E-Mail',
        role: 'Suche nach Rolle',
      },
    },
    roleManagement: {
      roles: 'Rollen',
      createRole: 'Rolle erstellen',
      appPermission: 'App-Berechtigungen',
      assetPermission: 'Asset-Berechtigungen',
    },
    pt9800RecipeDetails: {
      general: 'Allgemein',
      steps: 'Schritte',
    },
    home: {
      title: 'Startseite',
      description: 'Willkommen bei einer neuen cloudbasierten Lösung, die darauf ausgelegt ist, Ihnen zu helfen, jeden Ihrer Standorte effizient aus der Ferne zu verwalten.',
    },
    releaseNotes: {
      title: 'Versionshinweise',
    },
    privacyPolicy: {
      title: 'Datenschutzrichtlinie',
    },
    userManual: {
      title: 'Benutzerhandbuch',
    },
    appOverview: {
      title: 'App-Übersicht',
      showOnlyAvailableApp: 'Nur verfügbare Apps anzeigen',
      search: 'App suchen',
    },
    partCreate: {
      title: 'Teil erstellen',
    },
    partEdit: {
      title: 'Teil bearbeiten',
    },
    partDetails: {
      title: 'Teilinformationen',
    },
    performanceDashboardDetails: {
      datePickerRange: {
        currentWeek: 'Aktuelle Woche',
        lastMonth: 'Vorheriger Monat',
        currentMonth: 'Aktueller Monat',
        lastQuarter: 'Vorheriges Quartal',
        currentQuarter: 'Aktuelles Quartal',
        currentYear: 'Aktuelles Jahr',
      },
    },
    productionStatisticsDetails: {
      panelSettings: {
        header: 'Panel Einstellungen',
        helpText: 'Wähle einen Titel für das Panel und die KPIs, die angezeigt werden sollen. Die Art des Diagramms kann im Panel ausgewählt werden.',
        labelTitle: 'Titel',
        labelSize: 'Größe',
        labelType: 'Typ',
        labelKpiSelection: 'KPI Auswahl',
      },
      size: {
        small: 'Klein',
        medium: 'Mittel',
        large: 'Groß',
      },
      type: {
        table: 'Tabelle',
        totals: 'Gesamtsummen',
        totalDistribution: 'Gesamtverteilung',
        childrenTotals: 'Kind-Gesamtsummen',
        childrenDistribution: 'Kind-Verteilung',
        childrenComparison: 'Kind-Vergleich',
      },
    },
    productionStatistics: {
      table: {
        header: {
          displayName: 'Name',
        },
      },
    },
    assetManagement: {
      title: 'Asset overview',
      overview: {
        title: 'Asset overview',
        tableHeader: 'Asset tree',
        filter: {
          name: 'Nach Name filtern',
        },
      },
      sensorSettings: {
        placeholder: {
          meaningId: 'Bedeutung auswählen',
          meaningInstanceNumber: 'Instanznummer',
        },
      },
      equipmentTab: {
        [ERouteName.ASSET_MANAGEMENT_MAIN]: 'Übersicht',
        [ERouteName.ASSET_MANAGEMENT_MAIN_EQUIPMENT]: 'Übersicht',
        [ERouteName.ASSET_MANAGEMENT_DOCUMENT]: 'Dokumente',
        [ERouteName.ASSET_MANAGEMENT_SENSOR_MAPPING]: 'Sensor-mapping',
        [ERouteName.ASSET_MANAGEMENT_SENSOR_SETTINGS]: 'Sensor-Einstellungen',
        [ERouteName.ASSET_MANAGEMENT_DATA_SOURCES]: 'Data sources',
      },
    },
    equipmentSettings: {
      headline: 'Profil-Einstellungen',
      subheadline: 'Aktualisieren Sie Ihre Informationen',
      currencyRate: 'Währungskurs',
      dropdownLabel: {
        usd: '$ USD',
        eur: '€ EUR',
      },
    },
    equipmentDocument: {
      deleteMessage: 'Datei "{name}" löschen?',
      documentList: {
        headline: 'Dokumente',
        button: 'Dokument hochladen',
        empty: 'Keine hochgeladenen Dokumente',
        column: {
          fileName: 'Dateiname',
          description: 'Beschreibung',
          category: 'Kategorie',
          uploadedBy: 'Hochgeladen von',
        },
      },
      instructionList: {
        headline: 'Anweisungen',
        button: 'Anleitung hochladen',
        empty: 'Keine hochgeladenen Anleitungen',
        column: {
          fileName: 'Dateiname',
          description: 'Beschreibung',
          uploadedBy: 'Hochgeladen von',
        },
      },
    },
    liveApp: {
      equipment: {
        running: 'Läuft',
        autoUpdate: 'Automatisch aktualisieren (jede {0})',
        noActiveJob: 'Kein aktiver Job gefunden',
        noJobs: 'Keine Jobs gefunden',
        chart: 'Chart',
        pinnedSensorModalHeader: 'Wähle angepinnte Sensoren aus',
      },
    },
    assetManagementEquipmentSensorMapping: {
      showHiddenSignals: 'Versteckte Signale anzeigen',
      sensorTableTitle: 'Sensor-Kartierung',
      signalTableTitle: 'Asset-Signale',
      mappedSignalTitle: 'Abgebildetes Signal',
    },
    maintenanceJobList: {
      jobDeleteConfirm: 'Sind Sie sicher, dass Sie den Wartungsjob {0} löschen möchten?',
      createJob: 'Wartungsjob erstellen',
    },
    maintenanceCalendar: {
      scheduleMaintenance: 'Wartungsauftrag hinzufügen',
    },
    maintenanceDashboardDetail: {
      createOccurrence: 'Wartungsauftrag hinzufügen',
      alarmStatisticReference: 'Alarm-Statistik',
      alarmTableTitle: 'Alarme',
    },
    pt9800: {
      common: {
        id: 'ID',
        name: 'Name',
        description: 'Beschreibung',
        modifiedBy: 'Geändert von',
        modifiedOn: 'Letze Änderung',
        number: 'Nummer',
        comment: 'Bemerkung',
      },
      controllerStatus: {
        title: 'Controller status:',
        template: "connected={connected} ({timeSinceUpdateTime} since update time) {'|'} actualStep={actualStep} {'|'} remote={remote} {'|'} hold={hold}",
      },
      deleteConfirm: 'Are you sure to delete entry {0}? This action cannot be undone.',
      error: {
        create: '{0} konnte nicht erstellt werden',
        update: '{0} konnte nicht bearbeitet werden',
        delete: '{0} konnte nicht gelöscht werden',
        clone: '{0} konnte nicht kopiert werden',
        fetch: '{0} konnte nicht abgerufen werden',
      },
      form: {
        header: {
          create: '{0} erstellen',
          edit: '{0} bearbeiten',
          delete: '{0} löschen',
        },
      },
      entryType: {
        alarm: 'Alarm',
        application: 'Programm',
        applicationGroup: 'Programmgruppe',
        box: 'Box',
        clientAccess: 'Rechte-Client',
        color: 'Color',
        sms: 'SMS',
        terminal: 'Client',
        terminalType: 'Clienttyp',
        user: 'Benutzer',
        userAccess: 'Rechte_pro_Benutzer',
        userGroup: 'Benutzergruppe',
        backgroundProcess: 'Hintergrund Prozess',
        tasks: 'Aufgaben',
        workplace: 'Arbeitsplatz',
        workplaceType: 'Arbeitsplatz-Typ',
        operation: 'Operation',
      },
      backgroundProcess: {
        order: 'Auftrag',
        parameter: 'Parameter',
        active: 'Aktiv',
        activeTime: 'Aktiv Zeit',
        pid: 'PID',
        header: 'Hintergrund Prozess',
      },
      loadList: {
        selectedLoad: 'Selected load',
        forWorkplace: 'For workplace',
        recipe: 'Recipe',
        loadIdentifier: 'WP - Load-identifier',
        dateSorting: 'Date-Time for sorting',
        pos: 'Pos',
        amount: 'Amount',
        intern: 'Intern',
        customer: 'Customer',
        orderName: 'Order Description',
        orderRemark: 'Order remark',
        weightInKg: 'Weight [kg]',
        totalWeightInKg: 'Tot. weight [kg]',
      },
      operation: {
        header: 'Operation',
        property: 'Eigenschaft',
        operationType: 'Vorgangsart',
        type: 'Typ',
        mode: 'Modus',
        order: 'Auftrag',
        operationName: 'Vorgangsname',
        peProcessType: 'PE-Prozesstyp',
        sortingNo: 'Sortierung Nr.',
        automatingPlanningMode: 'Automatischer Planungsmodus',
        relevantForProductionStatistics: 'Relevant für die Produktionsstatistik',
        relevantForStat: 'Relevant für Statistik',
        selectProcess: 'Prozess auswählen',
        selectPart: 'Teil auswählen',
        sapNo: 'Sap-Nr.',
        workplaceName: 'Arbeitsplatz/ WP-Gruppen',
        placeDescription: 'Ortsbeschreibung',
      },
      tasks: {
        header: 'Verwaltung der Aufgaben',
        active: 'Aktiv',
        type: 'Typ',
        command1: 'Befehl 1',
        command2: 'Befehl 2',
        command3: 'Befehl 3',
        command4: 'Befehl 4',
        command5: 'Befehl 5',
        command6: 'Befehl 6',
        command7: 'Befehl 7',
        command8: 'Befehl 8',
        command9: 'Befehl 9',
        command10: 'Befehl 10',
        startHour: 'Start Stunde',
        dateLastActive: 'Datum zuletzt aktiv',
        intervalInMin: 'Intervall in min [min]',
      },
      workplace: {
        logicTypes: 'Logiktypen',
        header: 'Arbeitsort',
        inventoryNumber: 'Ausrüstungsnummer',
        belongsTo: 'Gehört zu Arbeitsplatz-ID',
        ip: 'IP-Adresse',
        mGuardIp: 'MGuard IP',
        device: 'Gerät',
        telegramSplit: 'RFC-Telegramm-Split',
        deactivateOperations: 'Operationen deaktivieren',
        aliveBitErrorNum: 'Alive-Bit-Fehlernummer',
        logicType: 'Logiktyp',
        recipePrefix: 'Rezept-Präfix',
        chamberId: 'Kammer-Id',
        sharesController: 'Teilt Controller/Kopie von chamber-id',
        mainUseMode: 'Hauptverwendungsmodus',
        workplaceType: 'Arbeitsplatztyp',
        isActive: 'Ist aktiv',
        importantSettings: 'Wichtige Einstellungen',
      },
      alarm: {
        alarmAdministration: 'Stoerung',
        alarm: 'Stoerung',
        alarmText: 'Stoertext',
        causesTestingLoad: 'Wird zu Pruef-Charge',
        onlyForChamber: 'Nur fuer Kammer',
        security: 'Pfoertner',
        color: 'Farbe',
        errorType: 'Stoer-Typ',
        outputType: 'Ausgabe-Typ',
        alarmGroup: 'Stoerungs-Gruppe',
      },
      application: {
        header: 'Programm',
        command: 'Command',
        description: 'Description',
        groupId: 'Group',
        iconGifName: 'Icon-Gif-Name',
        name: 'Name',
        furnacePicture: 'Furnace picture',
        order: 'Order',
        parameter: 'Parameter',
        tooltip: 'Tooltip',
        typeId: 'Type',
        uid: 'UID',
      },
      applicationGroup: {
        header: 'Programmgruppe',
      },
      recipe: {
        modifyRecipe: 'Rezept bearbeiten',
        recipeName: 'Rezeptname',
      },
      systemData: {
        active: 'Active',
        addresseeMobilePhoneNo: 'Addressee mobile phone no',
        boxHeader: 'Box Administration',
        colorHeader: 'Color Administration',
        colorValue: 'Color value',
        pin: 'PIN',
        serialInterface: 'Serial interface',
        smsHeader: 'SMS',
        testMessage: 'Test message (1=YES)',
        used: 'Used',
      },
      terminal: {
        header: 'Client',
        id: 'Id',
        clientType: 'Client Typ',
        clientAccess: 'Rechte-Client',
        name: 'Name',
        osType: 'Betriebssystem:',
        remark: 'Bemerkung',
        address: 'Adresse',
        description: 'Beschreibung',
        location: 'Aufstellungs-Ort:',
        valueLong2: '',
        valueLong3: 'Integer Wert 3:',
        valueLong4: 'Integer Wert 4:',
        valueLong5: 'Integer Wert 5:',
        valueChar1: 'Character Wert 1:',
        valueChar2: 'Character Wert 2:',
        actUserName: 'Aktueller Benutzer:',
        actLoginTime: 'Aktuelle Login Zeit',
        terminalNo: 'Client-No:',
        lastLoginTime: 'Letzte Login Zeit:',
        lastUserName: 'Letzter Benutzer',
        resolution: 'Bildschirmauflösung',
        users: 'Users',
        permission: 'Erlaubnis',
        rightsToTerminals: 'Rechte auf Clients',
      },
      terminalType: {
        header: 'Client Typ',
      },
      user: {
        header: 'Benutzer',
        accessLevel: 'Benutzerrecht',
        changePicture: 'Change Picture',
        comment: 'Bemerkung',
        costCenter: 'Kostenstelle',
        employeeNumber: 'Personalnummer',
        shortName: 'Kurzname',
        firstName: 'Vorname',
        function: 'Funktion',
        lastName: 'Nachname',
        language: 'Sprache',
        loginTimeInMin: 'Login-Zeit in min."',
        maintenancePopups: 'Wartungsplan Popups',
        password: 'Passwort',
        plant: 'Werk',
        playAlarmSound: 'AlarmTon wenn Stoerung',
        userGroup: 'Benutzergruppe',
        applicationGroup: 'Application Group',
        alarmSound: 'Alarmton abspielen',
        access: 'Zugriffsstufe',
      },
      userAccess: {
        header: 'Rechte_pro_Benutzer',
        delete: 'Loeschen',
        newEntry: 'Eintrag Neu',
        print: 'Drucken',
        run: 'Ausfuehren',
        save: 'Speichern',
        special1: 'Special-1',
        startRecipeProcessFlow: 'Start Beh/Ablauf',
        modifyRecipeProcessFlow: 'Eingriff Beh/Ablauf',
        startCorrectionRecipe: 'Recht Korrekturprogr. Starten',
        stopRecipeProcessFlow: 'Stop Beh/Ablauf',
      },
      userGroup: {
        header: 'Benutzergruppe',
      },
      workplaceType: {
        header: 'Arbeitsplatz-Typ',
        prio: 'Prio',
      },
    },
    login: {
      title: 'Login',
    },
    anomalyDetectionJobFeatureDetail: {
      title: 'Job-Funktion',
      tab: {
        observationTable: 'Beobachtungen',
        rootCauseTable: 'Ursachen',
      },
    },
    anomalyDetectionEquipmentSettings: {
      title: 'Einstellungen',
      tab: {
        counterActionTable: 'Gegenmaßnahmen',
        rootCauseGroupTable: 'Ursachen-Gruppen',
        rootCauseTable: 'Ursachen',
        jobFeatureTable: 'Job-Funktionen',
      },
    },
    anomalyDetectionEquipment: {
      title: 'Anomaly detection',
      confirmUnlink: 'Gegenmaßnahme "{counterActionName}" von der Ursache "{rootCauseName}" trennen?',
      tab: {
        rootCauseTable: 'Möglichen Ursachen',
        jobFeatureTable: 'Beobachtungen pro Job Feature',
      },
      jobFeatureTable: {
        header: {
          jobFeatureName: 'Job Feature',
          observationCount: 'Anzahl der Beobachtungen',
          rootCauseCount: 'Anzahl der Ursachen',
        },
      },
      observationTypeByJobFeatureTable: {
        header: {
          observationTypeName: 'Name des Beobachtungstyps',
          observationCount: 'Anzahl der Beobachtungen',
          rootCauseIdList: 'Anzahl der Ursachen',
        },
      },
    },
    anomalyDetection: {
      noObservations: 'Keine Beobachtungen gefunden',
      jobFeatures: {
        title: 'Chargeneigenschaften',
        tab: {
          rootCauseTable: 'Grundlegende Kausalitäten',
          jobFeatureTable: 'Beobachtungen',
        },
      },
      title: 'Anomalie-Erkennung',
    },
    audit: {
      navigation: {
        configure: 'Configure audit',
        load: 'Select load',
        verify: 'Rate audit',
      },
      selectLoad: {
        selectTime: 'Select time range',
        tableHeader: {
          durationInSec: 'Duration',
          endingTime: 'End time',
          header: {
            recipeName: 'Recipe',
          },
          loadName: 'Load No',
          locationName: 'Location',
          startingTime: 'Start time',
          workplace: {
            fullName: 'Asset',
          },
        },
        title: 'Audit / Select load',
      },
      title: 'Audit',
    },
    quality: {
      title: 'Audit & Qualität',
      header: {
        title: 'Qualität',
        createReport: 'Bericht erstellen',
      },
    },
    auditResult: {
      passed: 'Audit bestanden',
      notPassed: 'Audit nicht bestanden',
      emptySecondarySensor: 'Kein ausgewählter sekundärer Sensor',
      asset: 'Asset',
      comment: 'Kommentar',
      duration: 'Dauer',
      endTime: 'Endzeitpunkt',
      headerTitle: 'Audit Report',
      loadNo: 'Load No',
      location: 'Standort',
      rating: 'Bewertung',
      recipe: 'Rezept',
      reportNo: 'Report No.',
      reporter: 'Auditor',
      signature: {
        customer: 'Kunde',
        engineering: 'Engineering',
        manufacturing: 'Fertigung',
        material: 'Material',
        program: 'Programm',
        quality: 'Qualitätssicherung',
      },
      startTime: 'Start time',
      title: 'Audit result',
    },
    certification: {
      audit: {
        title: 'Audit & Quality / Audit',
      },
      auditConfiguration: {
        title: 'Audit & Quality / Audit Einstellungen',
        deleteConfirm: 'Sind Sie sicher das Sie die Spezification {0} löschen wollen?',
        deleteSuccess: 'Spezifikation wurde erfolgreich gelöscht.',
      },
      quality: {
        title: 'Audit & Quality / Qualität',
      },
      qualityConfiguration: {
        title: 'Audit & Quality / Qualitätseinstellungen',
      },
      qualityStatistics: {
        title: 'Audit & Quality / Qualitätsstatistik',
      },
      title: 'Audit & Quality',
    },
    chartRecorder: {
      defaultGroup: 'Other',
    },
    createRootCause: {
      linkRootCause: {
        title: 'Verknüpfen Sie eine Grundursache',
      },
      linkSuggestedAction: {
        title: 'Link Vorgeschlagene Aktionen',
      },
      selectJobFeature: {
        title: 'Auftragsmerkmal auswählen',
      },
      selectObservationType: {
        title: 'Beobachtungsart auswählen',
      },
      title: 'Ursachen erstellen',
    },
    productionLogList: {
      compare: 'Vergleiche',
      export: 'Export',
      filterPreset: 'Gespeicherte Filter',
      furnaceType: 'Ofentyp',
      presetEditTitle: 'Voreinstellung bearbeiten',
      includeRunningJobs: 'Laufende Jobs inkludieren',
    },
  },
  part: {
    noRecipes: 'Keine Rezepte...',
  },
  parts: {
    amount: 'Menge',
    defineParameterForPartStatistics: 'Teilestatistik-Parameter definieren',
    description: 'Beschreibung',
    forWorkplace: 'für Asset',
    name: 'Name',
    order: 'Auftrag',
    orderNo: generalTerm.loadIdentifier,
    part: 'Teil',
    partDesc: 'Teilebezeichnung',
    partNo: 'Teil-Nr:',
    parts: 'Teile',
    recipe: 'Rezept',
    recipeNo: 'Behandlung-Nr.',
    statistics: 'Teile-Statistik',
    statisticsFor: 'Statistik für:',
    weight: 'Gewicht',
  },
  passwordDialog: {
    confirm: 'Password ändern',
    important: 'Wichtig',
    newPassword: 'Neues Passwort',
    newUserPassword: 'Geben Sie dieses Passwort dem neu angelegten Benutzer: {password}',
    oldPassword: 'Altes Passwort',
    passwordChanged: 'Passwort wurde geändert!',
    retypePassword: 'Neues Passwort erneut eingeben',
    title: 'Passwort ändern',
  },
  productionLogs: {
    default: 'Standard',
    partDataToLoad: 'Teile-Daten für Charge',
    profileForLoad: 'Profil für Charge',
    showInGraph: 'Im Graph anzeigen',
  },
  productionStatistics: {
    assets: 'Assets',
    compareHierarchyTooltip: 'Es können nur Elemente der gleichen Ebene verglichen werden',
    comparing: 'Vergleichen',
    customers: 'Kunden',
    loads: 'Chargen',
    locations: 'Standorte',
    recipes: 'Rezepte',
    selectedItems: 'Ausgewählte Gegenstände',
  },
  recipeBuilder: {
    alarmDeactivated: 'Alarm ausgeschaltet',
    alarmDelay: 'Alarmverzögerung',
    description: 'Beschreibung',
    equi: 'Equi',
    highAlarmOffsets: 'HIGH Alarm Offsets',
    highWarningOffsets: 'HIGH Warnung Offsets',
    lowAlarmOffsets: 'LOW Alarm Offsets',
    lowWarningOffsets: 'LOW Warnung Offsets',
    nitrexProcess: 'Geschützter Nitrex Process',
    recipeName: 'Rezeptname',
    referenceValue: 'Referenzwert',
    setAlarmLimit: 'Alarmgrenze einstellen',
    setAlarmLimits: 'Alarmgrenzen festlegen',
    setPoints: {
      'Cooling Ramp': 'Abkühlkurve',
      Dissociation: 'Dissoziation',
      'Dissociation H2Smart': 'Dissoziation H2Smart',
      Furn: {
        Press: 'Ofendruck',
      },
      'Heating Ramp': 'Aufheizkurve',
      Kn: 'Kn',
      Kc: 'Kc',
      Turbo: 'Turbo',
      'Main TC': 'Main TC',
      Time: 'Dauer',
      'Total Flow': 'Gesamt Durchfluss',
    },
    setValue: 'Wert einstellen',
    totalDuration: 'Gesamtdauer',
  },
  recipeOnDemand: {
    ammonia: 'Ammoniak',
    chart: {
      gasCompositionLabel: {
        ammonia: 'Ammoniak: {value} %',
        dissociatedAmmonia: 'Dissoziiertes Ammoniak: {value} %',
        nitrogen: 'Stickstoff: {value} %',
        carbonOxide: 'Carbon Oxide: {value} %',
        carbonDioxide: 'Carbon Dioxide: {value} %',
      },
      processLabel: {
        knAct: 'Kn Act: {value}',
      },
    },
    compoundLayerThickness: 'Dicke der Verbundschicht',
    coreHardnessHV: 'Kernhärte (HV)',
    diffusionLayerThickness: 'Dicke der Diffusionsschicht',
    dissAmmonia: 'Dissoziiertes Ammoniak',
    distance: 'Distanz',
    effective: 'Wirksam',
    gasFlowChart: 'Gas-flow chart',
    hardness: 'Härte',
    material: 'Material',
    nitridetHardness: 'Tiefe der Nitrierhärte',
    nitriding: 'Nitrieren',
    nitrocarburizing: 'Nitrocarburieren',
    nitrogen: 'Stickstoff',
    oxideLayerThickness: 'Dicke der Oxidschicht',
    porosity: 'Porosität %',
    postoxidation: 'Postoxidation',
    process: 'Prozess',
    processChart: 'Process chart',
    profileChart: 'Profile chart',
    stepNumber: 'Schritt {number}',
    surfaceHardness: 'Härte der Oberfläche',
    unit: 'Einheit',
    unitSystems: {
      imperial: 'Imperial',
      metric: 'Metrisch',
    },
    vickersHardness: 'Vickers-Härte',
  },
  recipeStep: {
    actualConditions: 'Istwert Zustaende',
    actualValue: 'Istwert',
    addSetpoint: 'Sollwert hinzufügen',
    addStep: 'Rezeptschritt hinzufügen',
    chamber: 'Kammer',
    chamberOperation: 'Betrieb der Kammer',
    ctrl: 'Strg.',
    description: 'Beschreibung',
    digitalOutput: 'Digitaler Ausgang',
    digitalOutputs: 'Spuren',
    endAfter: 'Ende nach',
    endCondition: 'Endebedingung',
    endStates: 'Ende bei Zustaenden',
    estimatedDuration: 'gesch. Dauer:',
    modifyStep: 'Behandlungsschritt bearbeiten',
    next: 'Naechster',
    operation: 'Verfahren',
    previous: 'Voriger',
    runtime: 'Laufzeit',
    setpoint: 'Sollwert',
    setpoint1: 'Sollwert 1',
    setpoint2: 'Sollwert 2',
    step: 'Schritt',
    time1: 'AZt1',
    time2: 'AZt2',
    tolMinus: 'Tol-',
    tolPlus: 'Tol+',
    unit: 'Einheit',
    value: 'Wert',
    viewStep: 'Rezeptschritt anzeigen',
  },
  recipes: {
    alloyFactor: 'Legierungsfaktor',
    analysis: 'Analyse ...',
    calculateParameters: 'Analyse Parameter Berechnen',
    carbideLimit: 'Carbidgrenze',
    carbonContent: 'C-Gehalt',
    carbonContentPc: 'C-Gehalt [%C]',
    carburizingDepth: 'Aufkohlungstiefe',
    comment: 'Bemerkung',
    correction: 'Korrektur',
    correctionProfile: 'Korrektur Profil eingeben',
    deleteConfirm: 'Wollen Sie das Rezept wirklich löschen?',
    deleteVersionConfirm: 'Willst du die Rezeptversion wirklich löschen?',
    depth: 'Tiefe',
    desc: 'Bez.',
    desc2: 'Bez. 2:',
    description: 'Beschreibung',
    diff: 'Diff',
    endTemperature: 'Endtemperatur',
    general: 'General',
    grainSize: 'Korngroesse:',
    incorrectAmountOfElementsInCorrectionProfile: 'Die Anzahl der Elemente im Korrekturprofil ist nicht korrekt.',
    legFactorN: 'Bein. Factor N',
    material: 'Werkstoff',
    name: 'Name',
    nameAlreadyInUse: 'Name {name} existiert bereits.',
    nameChangeNotAllowedInNewRecipeVersionMode: 'Der Name des Rezepts kann nicht geändert werden, da die Länge der Versionen von eins abweicht',
    nitridingDepth: 'Nitriertiefe',
    operation: 'Verfahren',
    process: 'P-Typ:',
    profile: 'Profil',
    quenchingIntensity: 'Abschreckintensitaet',
    recipe: 'Rezept',
    representativeDiameter: 'Maßgeblicher Wärmebehandlungsdurchmesser (DIN 17211)',
    requiredHardnessHV: 'Grenzhaerte [HV]:',
    saveNewVersion: 'Als neue Version speichern',
    selectRecipe: 'Behandlung auswaehlen',
    steps: 'Positionen',
    thsd: 'thsd',
    versionNo: 'Version Nb:',
    noAccess: 'Benutzer hat keinen Zugriff auf {0}',
  },
  recipesPt9800: {
    unknownRecipeMenu: 'Unbekannte Rezeptgruppe',
  },
  report: {
    actions: 'Aktionen',
    approvals: 'Zulassungen',
    batchNumber: 'Chargennummer',
    companyName: 'Firma',
    customer: 'Kunde',
    date: generalTerm.date,
    dateTime: 'Datum/Uhrzeit',
    engineering: 'Technik',
    in: 'IN',
    manufacturing: 'Herstellung',
    materiel: 'Materie',
    maxBand: 'oberes Limit',
    minBand: 'unteres Limit',
    out: 'AUS',
    program: 'Programm',
    quality: 'Qualität',
    recipeCode: 'Rezeptname',
    reporter: 'Author',
    request: 'ANTRAG NR.',
    systemId: 'Assetname',
    timeInBand: 'Zeit in der Band',
    title: 'ERSUCHEN UM MASSNAHMEN DES MRB',
    totalTimeInBand: 'Gesamtzeit innerhalb der Grenzen',
  },
  requestCategory: {
    bom: 'BOM',
    configuration: 'Konfiguration',
    drawings: 'Zeichnungen',
    emergency: 'Notfall',
    sales: 'Vertrieb',
    service: 'Service',
    software: 'Software Service',
  },
  settings: {
    commonSensors: {
      customSensorName: 'Benutzerdefinierter Sensorname',
      introduction: 'Klicke auf einen der unten aufgelisteten Sensoren um den Name oder dessen Farbe zu ändern.',
      sensorName: 'Sensorname',
      title: 'Generische Sensoreinstellung',
    },
    equipmentGroups: {
      deleteEquipmentGroup: 'Wollen Sie die Ausrüstungsgruppe {group} wirklich löschen?',
      deleteSensorGroup: 'Möchten Sie die Sensorgruppe {group} wirklich löschen?',
      dragEquipment: 'Ziehen Sie das Equipment nach unten',
      equipmentSelect: 'Asset',
      filterEquipments: 'Assets filtern',
      introduction: 'Wähle einen Standort aus der Liste auf der linken Seite um eigene Asset Gruppen fuer den Standort zu konfigurieren.',
      noLocationSelected: 'Kein Standort ausgewählt',
      title: 'Asset Gruppen',
    },
    operationDashboard: {
      introduction: 'Wähle die Sensor für den primären und sekundären Graph im Operation Dashboard.',
      mainChart: 'Primäres Diagramm',
      secondaryChart: 'Sekundäres Diagramm',
      title: 'Operation Dashboard Sensoren',
    },
    sensorGroups: {
      createSensorGroup: 'Sensorgruppe erstellen',
      dragSensor: 'Ziehen Sie den Sensor unter',
      introduction: 'Erstelle eine neue Gruppe und verknüpfe diese per drag & drop mit den gewünschten Sensoren.',
      title: 'Sensor Gruppen',
    },
    statistics: {
      introduction: 'Choose the sensors to be displayed in Production Statistics. If no sensor is selected all sensors will be selected.',
      title: 'Einstellungen für Statistiken',
    },
  },
  simulator: {
    addNewStage: 'Neuer Behandlungsschritt',
    alloyFactor: 'Legierungsfaktor C',
    alloyFactorCarbon: 'Legierungsfaktor [Carbon]',
    alloyFactorNitride: 'Legierungsfaktor [Nitrogen]',
    auto: 'Auto',
    awt: 'Awt',
    buffering: 'Buffering...',
    carbDepth: 'Aufkohlungstiefe (CHD)',
    carbideLimit: 'Carbidgrenze [%aC=1]',
    carbonAtDepth: 'Grenzkohlenstoffgehalt',
    carbonitriding: 'Carbonitrieren',
    carburizing: 'Aufkohlen',
    'co+co2': 'CO + CO2',
    composition: 'Material Zusammensetzung',
    cool: 'Kühlen',
    coolDown: 'Abkühlen',
    coreCarbon: 'Kernkohlenstoffgehalt',
    coreHardnessUntreatedHv: 'Kernhärte (unbehandelt) [HV]',
    diameter: 'Durchmesser',
    effectiveCaseDepth: 'Aufkohlungstiefe (CHD)',
    effectiveCaseHardness: 'Grenzhärte',
    endTemperature: 'End Temperatur',
    estTemperingConditions: 'Geschätzte Anlassbedingungen',
    fixed: 'Fest',
    generalShapeOfPart: 'Teilegeometrie (DIN 17211)',
    grabke: 'Grabke',
    grainSize: 'Korngröße',
    gunnarson: 'Gunnarson',
    hardnessNormalized: 'Normalisierte Härte',
    hardnessQuenched: 'Abgeschreckte Härte',
    heat: 'Heizen',
    heatUp: 'Aufheizen',
    hold: 'Halten',
    logReplay: 'Log-Wiedergabe',
    lowPressureCarbonizing: 'Niederdruck-Aufkohlung',
    material: 'Material',
    materialModel: 'Material Model',
    materialSimulationData: 'Daten zur Materialsimulation',
    minSurfaceHardness: 'Min. Oberflächenhärte',
    minus: 'Minus {type}',
    models: 'Material Modelle',
    neumann: 'Neumann',
    nitride: 'Nitrid',
    nitrideLimit: 'Nitridgrenze [%FexNy]',
    normalized: 'Normalisiert',
    nothingSelected: 'Nichts ausgewählt',
    nxc: 'NX C - Diff',
    'nxc+n': 'NX C[+N] - Diff',
    off: 'Aus',
    on: 'Ein',
    overwrite: 'Überschreiben',
    part: 'Teil',
    plus: 'Plus {type}',
    preHeatTreatment: 'Zustand vor der Wärmbehandlung',
    processChart: 'Prozesswert Chart',
    profileChart: 'Profil Chart',
    profileTable: 'Profil Table',
    properties: 'Eigenschaften',
    quenched: 'Abgeschreckt',
    quenchingIntensity: 'Abschreckintensität',
    recipe: 'Rezept',
    recipeStages: 'Behandlungsschritte',
    representativeCoupon: 'Coupon Durchmesser',
    restartToApplyParameters: 'Restart to apply new parameters',
    retainedAustenite: 'Restaustenit',
    runtime: 'Laufzeit',
    selectMaterial: 'Material auswählen',
    selectPart: 'Teil auswählen',
    selectRecipe: 'Rezept auswählen',
    selectRecipeFirst: 'Sie müssen zuerst ein Rezept auswählen',
    setPoints: 'Sollwert',
    shapes: {
      CYL_HOLLOW_OVER_200MM: 'Hohlzylinder mit Innendurchmesser > 200 mm',
      CYL_HOLLOW_UPTO_200MM: 'Hohlzylinder mit Innendurchmesser < 200 mm',
      CYL_HOLLOW_UPTO_80MM: 'Hohlzylinder mit Innendurchmesser < 80 mm',
      HEXAGON_OR_OCTAGON_STEEL: 'Hexagon oder Oktagon',
      HOLLOW_BODY_CLOSED: 'Hohlkörper geschlossen von einer oder zwei Seiten',
      RING_OVER_200MM: 'Ring (Innendurchmesser > 200 mm)',
      ROUND_STEEL: 'Rundstahl',
      SOLID_STEEL_UPTO_200MM: 'Stahlblock (Durchmesser < 200 mm)',
      SQUARE_OR_FLAT_STEEL: 'Flachstahl',
    },
    simulation: 'Simulation',
    sootLimit: 'Rußgrenze',
    steelGradeEstimation: 'Geschätze Stahlqualität',
    surf: 'Rand {type}',
    tempered: 'gehärtet',
    temperingConditions: 'Geschätzte Temperierungsbedingungen',
    variable: 'Variabel',
    visualizations: 'Visualisierungen',
  },
  tabHeaders: {
    readings: 'Messwerte',
    setPoints: 'Sollwerte',
  },
  table: {
    getQuote: 'Nehmen Sie Kontakt auf und fordern Sie ein Angebot an',
  },
  tableHeaders: {
    NoAnalogOutputsAvailable: 'Keine analogen Ausgänge verfügbar',
    RowsPerPage: 'Zeilen pro Seite',
    actions: 'Aktionen',
    activity: 'Aktivität',
    advisedStockQuantity: 'Empfohlene Lagermenge',
    aggregatedProbability: 'Aggregierte Wahrscheinlichkeit [%]',
    alarm: 'Alarm',
    alarmNumber: 'Alarmnummer',
    alarmText: 'Alarmtext',
    alarmType: 'Alarmtyp',
    amount: 'Betrag',
    analogOutputs: 'Analoge Ausgänge',
    announcementId: 'Benachrichtigungs ID',
    announcementType: 'Benachrichtigungs Typ',
    apps: 'Apps',
    assetSensors: 'Anlagensensoren',
    assetType: 'Asset-Typ',
    assignee: 'Rechtsnachfolger',
    atmosphere: 'Atmosphäre',
    auditEnvelope: {
      duration: 'Duration (min)',
      durationLimitMinus: 'Duration limi(-)',
      durationLimitPlus: 'Duration limit(+)',
      name: 'Envelope label',
      selectedMainSensor: 'Main sensor',
      selectedSecondarySensorList: 'Secondary sensors',
      selectedStartTime: 'Start timer at',
      setpoint: 'Setpoint',
      setpointLimit: 'Setpoint Limit(±)',
    },
    austenite: 'Austenit',
    avg: 'Avg {unit}',
    batch: 'Charge',
    batchCount: 'Chargenanzahl',
    batchDuration: 'Dauer',
    batchNumber: 'Chargennummer',
    batchRating: 'Chargenbewertung',
    bomQuantity: 'Stücklistenmenge',
    c2h2: 'C2H2',
    c8H8: 'C8H8',
    carbonPotential: 'C-Pegel Status',
    carburizingPotential: 'Aufkohlungspotenzial',
    category: 'Kategorie',
    cause: 'Ursache',
    chartToolsData: 'Legende und Diagrammwerkzeuge',
    co: 'CO',
    commonSensor: 'Generischer Sensor',
    commonSensors: 'Generische Sensoren',
    comp: 'Comp',
    contentName: 'Inhaltsname',
    coolingRamp: 'Kühlrampe',
    cost: 'Kosten',
    count: 'Anzahl',
    created: 'Erstellt',
    createdAt: 'Erstellt am',
    createdBy: 'Erstellt von',
    customAnalogControllers: 'Benutzerdefinierte analoge Controller',
    customDigitalControllers: 'Benutzerdefinierte digitale Controller',
    customerCode: 'Unternehmen',
    customerRequestId: 'Serviceanfrage ID',
    date: generalTerm.date,
    defaultDeliveredQuantity: 'Standard Liefermenge',
    delete: 'Löschen',
    description: 'Beschreibung',
    digitalOutputs: 'Digitalausgang - Verzögerung',
    digits: 'Ziffern',
    dinNumber: 'DIN Nummer',
    dissociation: 'Dissoziation',
    documentId: 'Dokumenten ID',
    duration: 'Dauer',
    durationAvg: 'Dauer Durchschnitt',
    durationSum: 'Gesamtdauer',
    email: 'E-Mail',
    enabled: 'Aktiviert',
    endCondition: 'Endzustand',
    endDate: 'Enddatum',
    endTemp: 'Endtemp',
    equipmentId: 'Asset Name',
    expectedDuration: 'Erwartete Dauer',
    explanation: 'Erklärung',
    featureObservationMapping: 'Kartierung der Beobachtung von Merkmalen',
    fileName: 'Dateiname',
    filterName: 'Filtername',
    finishedAt: 'Beendet am',
    firstOccurrence: 'Erster Wartungsauftrag',
    fullName: 'Vollständiger Name',
    furnace: 'Ofen',
    furnaceName: 'Name des Ofens',
    furnaceSensor: 'Ofensensor',
    furnaceType: 'Ofentyp',
    group: 'Nutzer Gruppe',
    groupSize: 'Größe der Gruppe',
    h2: 'H2',
    heatingRamp: 'Heizrampe',
    highlighted: 'Hervorgehoben',
    hvResult: 'HV Ergebnis',
    imperialUnit: 'Imperiale Einheit',
    instructionId: 'Anweisungs ID',
    instructionType: 'Anweisungs Typ',
    intern: 'Intern',
    jobCost: 'Chargenkosten',
    jobFeaturesName: 'Name des Jobmerkmals',
    jobValue: 'Chargenwert',
    jobsLocationDetail: {
      asset_name: 'Asset',
      batch_duration_in_sec: 'Duration',
      end_date_utc: 'End time',
      job_name: 'Load No',
      location_name: 'Location',
      recipe: 'Recipe',
      start_date_utc: 'Start time',
    },
    latestOccurrence: 'Letztes Vorkommen',
    linkedObservations: 'Verknüpfte Beobachtungen',
    linkedRootCauses: 'Verknüpfte Grundlegende Kausalitäten',
    load: 'Charge',
    loadIdentifier: 'Kennung der Charge',
    loadNo: 'Charge N*',
    location: 'Standort',
    logInterval: 'Protokollintervall',
    manufacturer: 'Hersteller',
    manufacturerPartNumber: 'Original-Teilenummer',
    materialGroup: 'Material Gruppe(n)',
    max: 'Max {unit}',
    maxFlow: 'Maximaler Durchfluss',
    maxProbability: 'Maximale Wahrscheinlichkeit [%]',
    measurement: 'Messung',
    message: 'Nachricht',
    metricUnit: 'Metrische Einheit',
    min: 'Min {unit}',
    minFlow: 'minimaler Durchfluss',
    modifiedAt: 'Modifiziert am',
    n2: 'N2',
    name: 'Name',
    nitridingPotential: 'Nitrierpotenzial',
    nitrogenPotential: 'Stickstoffpotenzial',
    noCustomAnalogControllersAvailable: 'Keine benutzerdefinierten analogen Controller verfügbar',
    noCustomDigitalControllersAvailable: 'Keine benutzerdefinierten digitalen Controller verfügbar',
    notificationId: 'Meldungs ID',
    numberOfObservation: 'Anzahl der Beobachtungen',
    numberOfOccurrences: 'Anzahl der Vorkommnisse',
    numberOfRootCauses: 'Anzahl der Grundursachen',
    observation: 'Beobachtung',
    observationType: 'Typ der Beobachtung',
    occurrences: 'Vorkommnisse',
    ofTotalFlow: '{type} des Gesamtflusses',
    orderName: 'Auftragsname',
    orderRemark: 'Auftragsanmerkung',
    outputs: 'Ausgänge',
    partDescription: 'Teilebeschreibung',
    partName: 'Teilname',
    plantName: 'Standort',
    pos: 'Position',
    possibleRootCauses: 'Mögliche Grundursachen',
    probability: 'Wahrscheinlichkeit',
    probabilityPercentages: 'Wahrscheinlichkeit [%]',
    process: 'Prozess',
    processMode: 'Prozess-Modus',
    protected: 'geschützt',
    publishedOn: 'Veröffentlicht am',
    recent: 'letzte Änderung',
    recipe: 'Rezept',
    recipeCode: 'Rezeptcode',
    recipeDescription: 'Rezept Beschreibung',
    recipeHash: 'Rezepthashwert',
    recipeName: 'Rezeptname',
    requestId: 'Anfrage-ID',
    requestStatus: 'Anfragestatus',
    rootCause: 'Grundlegende Kausalitäten',
    rootCauses: 'Grundlegende Kausalitäten',
    sensorName: 'Sensorname',
    sensorSelection: 'Sensor Auswahl',
    set: 'Set',
    setPoint: 'Sollwert',
    setPoints: 'Sollwerte',
    siUnit: 'SI-Einheit',
    sparePartsId: 'Ersatzteile ID',
    stage: 'Behandlungsschritt',
    stageDuration: 'Dauer des Behandlungsschrittes',
    stageName: 'Name des Behandlungsschrittes',
    startDate: 'Startdatum',
    startDateLatest: 'Startdatum (ältestes)',
    startDateOldest: 'Startdatum (ältestes)',
    status: 'Status',
    step: 'Schritt',
    suggestedAction: 'Vorgeschlagene Maßnahmen',
    systemId: 'Asset Name',
    task: 'Aufgabe',
    temperature: 'Temperatur',
    time: 'Zeit',
    timeOfDeclaration: 'Zeitpunkt der Erklärung',
    timeStamp: 'Zeitstempel',
    toolsAndSpareParts: 'Hilfsmittel und Ersatzteile',
    totalDuration: 'Gesamtdauer',
    totalFlow: 'Gesamtfluss',
    totalWeightInKg: 'Gesamtgewicht [kg]',
    tunnelFunction: 'Tunnel-Funktion',
    type: 'Typ',
    updated: 'Aktualisiert',
    upload: 'Hochgeladen von',
    username: 'Nutzername',
    value: 'Wert',
    valueWhenDeclared: 'Wert bei Deklaration',
    volumeFlow: 'Volumenstrom',
    weightInKg: 'Gewicht [kg]',
    workOrderNo: 'Arbeitsauftrag Nr.',
  },
  tabs: {
    job_list: 'Jobs',
    task_list: 'Tasks',
    absolute: 'Absolut',
    alarms: 'Alarme',
    attachments: 'Anhänge',
    audit: 'Audit',
    calendar: 'Kalender',
    chartView: 'Diagrammwerkzeuge',
    charts: 'Diagramme',
    communication: 'Korrespondenzen',
    controller: 'Controller',
    customerSettings: 'Einstellungen',
    data: 'Daten',
    details: 'Details',
    documents: 'Dokumente',
    equipments: 'Assets',
    filters: 'Filter',
    graph: 'Graph',
    legend: 'Legende',
    main: 'Haupt',
    'material-groups': 'Materialgruppen',
    materials: 'Materialien',
    materialsGroups: 'Materialiengruppen',
    modify: 'Ändern Sie',
    notifications: 'Meldungen',
    relative: 'Relativ',
    report: 'Bericht',
    reports: 'Berichte',
    save: 'Speichern',
    sensorGroups: 'Sensorgruppen',
    sensorMapping: 'Sensor-mapping',
    singleSensor: 'Einzelner Sensor',
    stages: 'Behandlungsschritte',
    status: 'Status',
  },
  tiles: {
    userManagement: 'Benutzerverwaltung',
    agentChat: 'Agenten-Chat',
    alarmStatistics: 'Alarmstatistik',
    anomalyDetection: 'Anomalie-Erkennung',
    appOverview: 'Applikationsübersicht',
    assetManagement: 'Asset Management',
    certification: 'Audit & Quality',
    customerSettings: 'Asseteinstellungen',
    drSommerMaterialDatabase: 'Dr. Sommer Materialdatenbank',
    grafana: 'Grafana',
    historyAndTrends: 'History and Trends',
    home: 'Startseite',
    liveApp: 'Live app',
    maintenanceCalendar: 'Wartungskalender',
    materials: 'Materialdatenbank',
    mlPrototyping: 'ML Prototyping',
    modelProcess: 'Modelprozesse',
    nitrexLive: 'Nitrex Connect',
    part: 'Teile',
    performanceDashboard: 'Auslastungsübersicht',
    productionLogs: 'Produktionshistorie',
    productionStatistics: 'Produktionsstatistik',
    pt9800Cloud: 'PT9800 Webclient',
    pt9800Configuration: 'PT9800 Konfiguration',
    recipeOnDemand: 'Rezeptsuche',
    recipes: 'Rezepte',
    recipesNitride: 'Rezepte (Nitride)',
    recipesPt9800: 'Rezepte (PT9800)',
    remoteControl: 'Fernsteuerung',
    shopLayout: 'Hallen-Layout',
    simulator: 'Simulatoren',
    universalChart: 'Universelles Diagramm',
    upcPro: 'UPC Werkzeugkasten',
    userManual: 'Benutzer Handbuch',
    userSettings: 'Einstellungen',
    customer: 'Customer',
  },
  titles: {
    alarms: 'Alarme',
    ams: 'AMS',
    announcements: 'QMULUS Benachrichtigungen',
    capabilities: 'Fähigkeiten',
    certificates: 'Zertifikate',
    components: 'Komponenten',
    composition: 'Zusammensetzung',
    copySensors: 'Zuordnung kopieren',
    customerRequests: 'Serviceanfrage',
    dimensions: 'Abmessungen',
    documents: 'Dokumente',
    drawings: 'Zeichnungen',
    equipment: 'Asset',
    filters: 'Filter',
    gasses: 'Gase',
    general: 'General',
    heatTreatmentType: 'Art der Wärmebehandlung',
    instructions: 'Anweisungen',
    jobUsage: 'Chargenverbrauch',
    locations: 'Standorte',
    materialsGroups: 'Materialgruppen',
    modelProcess: 'Modell Prozess',
    modelProcesses: 'Modellprozesse',
    nitridingTemplates: 'Nitriervorlagen',
    no: 'Nein',
    noData: 'Keine Daten vorhanden',
    notifications: 'Meldungen',
    npmController: 'NPM Controller',
    others: 'Andere',
    overview: 'Übersicht',
    partsSimulationData: 'Daten zur Teilesimulation',
    plants: 'Standorte',
    pt9800Controller: 'PT9800 Controller',
    recipe: 'Rezept',
    recipeStages: 'Behandlungsschritte',
    selectAnalogInputForEndCondition: 'Analogen Eingang für Endbedingung auswählen',
    selectDigitalInputForEndCondition: 'Digitaleingang für Endbedingung auswählen',
    selectedBatch: 'Ausgewählte Chargen',
    settings: 'Einstellungen',
    showAlarms: 'Alarme zeigen',
    spareParts: 'Ersatzteile',
    stageChange: 'Stage Change',
    stageSetup: 'Stage Setup',
    step: 'Schritt',
    steps: 'Schritte',
    temperature: 'Temperatur',
    threeDots: '...',
    users: 'Nutzer',
    valuesAndCalculations: 'Werte und Berechnungen',
    yes: 'Ja',
  },
  type: {
    Visualisation,
    auditEnvelope: {
      duration: 'Dauer',
      durationLimitMinus: 'Untergrenze',
      durationLimitPlus: 'Obergrenze',
      name: 'Name',
      selectedMainSensor: 'Primärer Sensor',
      selectedSecondarySensorList: 'Sekundäre Sensoren',
      selectedStartTime: 'Beginn',
      selectedStartTimeLimit: 'Grenze',
      setpoint: 'Sollwert',
      setpointLimit: 'Sollwert Grenze (±)',
    },
  },
  units: {
    days: 'Tage',
    hours: 'h',
    minutes: 'min',
    months: 'm',
    weeks: 'Woche(n)',
    years: 'a',
  },
  upcPro: {
    analyzerReading: 'Analyzer Reading',
    convertsTo: 'ergibt',
    endoGeneratorDataError: 'DATEN EINGABEFEHLER:\nCO[%] + CO₂[%] + CH₄[%] + H₂[%] > 100 \nBITTE DIE WERTE KORRIGIEREN.',
    floMeterGlossary: 'Glossar:\n\nSG1 = Spezifische Dichte des Gases, für das das Durchflussmessgerät kalibriert ist\n\nSG2 = Spezifisches Gewicht des Gases, für das Flo-Meter verwendet wird\n\nTemp.1 = Temperatur, für die der Durchflussmesser kalibriert ist\n\nTemp.2 = Temperatur, für die der Durchflussmesser verwendet wird\n\nP1 = Druck, für den der Durchflussmesser kalibriert ist\n\nP2 = Druck, für den der Durchflussmesser verwendet werden soll\n\nÜbersetzt mit www.DeepL.com/Translator (kostenlose Version)',
    flows: 'Eingangsströme',
    furnaceAtmosphere: 'Ofenatmosphäre',
    imperialUnits: 'imperial',
    inputGroup: 'Input',
    inputs: {
      CELCIUS_TO_FAHRENHEIT: 'Celsius to Fahrenheit',
      FAHRENHEIT_TO_CELCIUS: 'Fahrenheit to Celsius',
      FT3PH_TO_M3PH: 'cfh to m³/h',
      GPM_TO_LMIN: 'US gpm to l/min',
      HARDNESS_TABLE1: 'Rockwell C Range',
      HARDNESS_TABLE2: 'Rockwell B Range',
      HBS: {
        name: 'HBS',
        subtitle: 'Brinell Scale',
      },
      HBS2: {
        name: 'HBS',
        subtitle: 'Brinell Scale',
      },
      HBW: {
        name: 'HBW',
        subtitle: 'Brinell Scale',
      },
      HK: {
        name: 'HK',
        subtitle: 'Knoop Hardness',
      },
      HK2: {
        name: 'HK',
        subtitle: 'Knoop Hardness',
      },
      HR15N: {
        name: 'HR15N',
        subtitle: 'Rockwell 15-N Scale',
      },
      HR15T: {
        name: 'HR15T',
        subtitle: 'Rockwell 15-T Scale',
      },
      HR30N: {
        name: 'HR30N',
        subtitle: 'Rockwell 30-N Scale',
      },
      HR30T: {
        name: 'HR30T',
        subtitle: 'Rockwell 30-T Scale',
      },
      HR45N: {
        name: 'HR45N',
        subtitle: 'Rockwell 45-N Scale',
      },
      HR45T: {
        name: 'HR45T',
        subtitle: 'Rockwell 45-T Scale',
      },
      HRA: {
        name: 'HRA',
        subtitle: 'Rockwell A Scale',
      },
      HRA2: {
        name: 'HRA',
        subtitle: 'Rockwell A Scale',
      },
      HRB: {
        name: 'HRB',
        subtitle: 'Rockwell B Hardness',
      },
      HRC: {
        name: 'HRC',
        subtitle: 'Rockwell C Hardness',
      },
      HRD: {
        name: 'HRD',
        subtitle: 'Rockwell D Scale',
      },
      HRF: {
        name: 'HRF',
        subtitle: 'Rockwell F Hardness',
      },
      HSC: {
        name: 'HSc',
        subtitle: 'HSc Scleroscope Hardness',
      },
      HV: {
        name: 'HV',
        subtitle: 'Vickers Hardness',
      },
      HV2: {
        name: 'HV',
        subtitle: 'Vickers Hardness',
      },
      LMIN_TO_GPM: 'l/min to US gpm',
      M3PH_TO_FT3PH: 'm³/h to cfh',
      ac: 'ac',
      actualScale: 'Actual Scale',
      aisi: 'AISI',
      al: 'Al',
      alloyFactor: 'Legierungsfaktor',
      baseC: 'Base C',
      c: 'C',
      carbonPotential: 'Carbon Potenzial',
      cch4: 'C + CH₄',
      ccontent: 'C-Content',
      ch3oh: 'CH₃OH',
      ch4: 'CH₄',
      ch4Factor: 'CH₄ Factor',
      co: 'CO',
      co2: 'CO₂',
      coTotalFlow: 'CO/Total Flow',
      coco2: 'CO/CO₂',
      coldJunctionTemp: 'Cold-Junction Temp.',
      cpatm: 'CPatm',
      cr: 'Cr',
      cu: 'Cu',
      dewPoint: 'Dew Point',
      din: 'DIN',
      dissBurette: 'Diss. Burette',
      dissDegree: 'Diss. Degree',
      dnh3: 'D-NH₃',
      dpt: 'DPT',
      emf: 'EMF',
      floMeterScale: 'Flo-Meter Scale',
      flow: 'Flow',
      gas: 'Gas',
      h2: 'H₂',
      h2n2: 'H₂:N₂',
      h2o: 'H₂O',
      hardness: 'Hardness',
      kcb: 'Kᴄʙ',
      kh: 'Kʜ',
      kn: 'Kɴ',
      ko: 'Kᴏ',
      mn: 'Mn',
      mo: 'Mo',
      mv: 'mV',
      n2: 'N₂',
      n2Percentage: 'N₂',
      n2ch3oh: 'N₂/CH₃OH',
      nc2: 'NC₂',
      nh3: 'NH₃',
      ni: 'Ni',
      o2: 'O₂',
      o2Percentage: 'O₂',
      o2Probe: 'O₂ Probe',
      others: 'Others',
      p1: 'P1',
      p2: 'P2',
      po2: 'pO₂',
      preload: 'Preload',
      pt10: {
        description: '(Pt10) Platinum RTD with Nominal Resistance of 10 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt10) Platinum RTD',
      },
      pt100: {
        description: '(Pt100) Platinum RTD with Nominal Resistance of 100 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt100) Platinum RTD',
      },
      pt1000: {
        description: '(Pt1000) Platinum RTD with Nominal Resistance of 1000 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt1000) Platinum RTD',
      },
      pt500: {
        description: '(Pt500) Platinum RTD with Nominal Resistance of 500 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt500) Platinum RTD',
      },
      resistance: 'Resistance',
      rg: 'RG',
      rl: 'RL',
      scaleFactor: 'Scale Factor',
      sg1: 'SG1',
      sg2: 'SG2',
      si: 'Si',
      sootLimit: 'Soot Limit',
      tc_b: {
        description: 'Plus:Platinum 30% Rhodium(Pt30Rh)\nMinus:Platinum 6% Rhodium (Pt6Rh)\nRange: -50°C-1820°C (-58°F-3308°F)\nColors according to ANSI 96.6.\nAccording to NFC 42-324, colors are yellow and gray',
        name: 'Type B = Pt30Rh-Pt6Rh',
      },
      tc_e: {
        description: 'Plus:Nickel-Chromium (NiCr), also\nknown as Chromel\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -270°C-1000°C (-454°F-1832°F)\nColors according to DIN EN 60584-1',
        name: 'Type E = NiCr-CuNi',
      },
      tc_j: {
        description: 'Plus:Copper(Cu), Copper Color\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -270°C-400°C (-454°F-752°F)\nColors according to DIN EN 60584-1',
        name: 'Type J = Fe-CuNi',
      },
      tc_k: {
        description: 'Plus:Nickel-Chromium (NiCr), also\nknown as Chromel\nMinus:Nickel Aluminum (NiAl) also\nknown as Alumel, magnetic wire\nRange: -270°C-1372°C (-454°F-2502°F)\nColors according to DIN EN 60584-1',
        name: 'Type K = NiCr-Ni',
      },
      tc_l: {
        description: 'Plus:Iron (Fe), magnetic wire\nMinus:Copper-Nicker(CuNi), also\nknown as Constantan\nRange: 0°C-900°C (32°F-1652°F)\nColors according to DIN EN 43714',
        name: 'Type L = Fe-CuNi',
      },
      tc_n: {
        description: 'Plus:Nickel-Chromium-Silicon (NiCrSi),\nalso known as Nicrosil\nMinus:Nickel-Silicon (NiSi), also\nknown as Nisil\nRange: -270°C-1300°C (-454°F-2372°F)\nColors according to DIN EN 60584-1',
        name: 'Type N = NiCrSi-NiSi',
      },
      tc_r: {
        description: 'Plus:Platinum 13% Rhodium (Pt13Rh)\nMinus:Platinum(Pt), minus wire softer\nthan plus wire\nRange: -50°C-1768°C (-58°F-3214°F)\nColors according to DIN EN 60584-1',
        name: 'Type R = Pt13Rh-Pt',
      },
      tc_s: {
        description: 'Plus:Platinum 10% Rhodium (Pt10Rh)\nMinus:Platinum(Pt), minus wire softer\nthan plus wire\nRange: -50°C-1768°C (-58°F-3214°F)\nColors according to DIN EN 60584-1',
        name: 'Type S = Pt10Rh-Pt',
      },
      tc_t: {
        description: 'Plus:Iron (Fe), magnetic wire\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -210°C-1200°C (-346°F-2192°F)\nColors according to DIN EN 60584-1',
        name: 'Type T = Cu-CuNi',
      },
      tc_u: {
        description: 'Plus:Copper(Cu), Copper Color\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: 0°C-600°C (32°F-1112°F)\nColors according to DIN EN 43714',
        name: 'Type U = Cu-CuNi',
      },
      temp1: 'Temp.1',
      temp2: 'Temp.2',
      temperature: 'Temperatur',
      total: 'Total',
      totalFlow: 'Total Flow',
      v: 'V',
      voltage: 'Voltage',
      weight1: 'Weight₁',
      weight2: 'Weight₂',
    },
    metricUnits: 'metrisch',
    mustBeInteger: 'Muss ganze Zahl sein',
    norm: 'Norm',
    outOfRange: 'Außerhalb des zulässigen Wertebereichs',
    potentials: 'Potenziale',
    range: 'Bereich',
    units: 'Einheiten',
  },
  validation: {
    ProcessCodeRequired: 'Vorgangscode und Beschreibung erforderliches Feld',
    decimalValue: 'Max {decimalValue} Nachkommastellen',
    file: 'Datei ist erforderlich',
    filesTooBig: 'Die Gesamtgröße der Dateien sollte weniger als 5 MB betragen.',
    greaterBandValue: 'Umschlag Plus muss größer sein als Minus',
    greaterOrEqual: 'Wert sollte gleich oder höher sein {minValue}',
    idIsUsed: 'Id wird verwendet',
    integerValue: 'Der Wert sollte eine ganze Zahl sein',
    invalidEmailFormat: 'Ungültiges E-Mail-Format',
    isInteger: 'Der Wert muss eine ganze Zahl sein',
    lessOrEqual: 'Wert sollte gleich oder niedriger sein {maxValue}',
    lowerBandValue: 'Hüllkurvenminus muss niedriger sein als Plus',
    maxValue: 'Der Wert sollte darunter liegen {maxValue}',
    minValue: 'Wert sollte oben sein {minValue}',
    nameAlreadyExists: 'Name existiert bereits',
    notEmptySpace: 'Text darf keine Leerzeichen beinhalten',
    numberMustBeGreaterThan: 'Zahl muss größer als {number} sein',
    oneValue: 'Muss mindestens einen Wert enthalten',
    required: 'Pflichtfeld',
    requiredFormat: 'Erforderliches Format {format}',
    tooBigFile: 'Die Dateigröße sollte weniger als 50 MB betragen',
    tooManyFiles: 'Sie können nicht mehr als 3 Dateien hochladen',
    userAlreadyExists: 'Benutzer mit angegebener E-Mail existiert bereits',
    valueBelowMin: 'Wert ist kleiner als Min',
    // new
    fileTooBig: 'Die Dateigröße sollte weniger als {size} betragen.',
  },
  util: {
    schaefflerChart: {
      title: 'Schäffler Diagramm',
      CrEquivalent: 'Cr-Äquivalent',
      NiEquivalent: 'Ni-Äquivalent',
    },
  },
  useQueryDateRange: {
    parseFailureHeadline: 'Zeitfilter konnte nicht angewendet werden.',
    parseFailureDetails: 'Der Zeitfilter der URL ist ungültig und konnte daher nicht angewendet werden. Der standard filter wurde angewandt. Eventuell sollte das Bookmark aktualsiert werden.',
  },
  ...getComponentScopedTranslationMap('page', 'de'),
  ...getComponentScopedTranslationMap('components', 'de'),
  ...getComponentScopedTranslationMap('pt9800', 'de'),
};
