export default {
  jobNumber: 'Job Number',
  loaded: 'Geladen: {recipeCode}',
  process: 'Rezept',
  processStartTime: 'Rezept Laufzeit',
  stageName: 'Rezeptschritt',
  stageNumber: 'Rezeptschritt',
  stageTime: 'Schrittlaufzeit',
  status: 'Status',
  systemName: 'System',
};
