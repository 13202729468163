// @ts-ignore
import {
  AxiosResponse,
} from 'axios';
// @ts-ignore
import {
  paths,
} from '@/types/openapi';
// @ts-ignore
import {
  TApiPayload,
} from '@/api/type/export';
// @ts-ignore
import {
  axiosClientV3,
} from '@/api/client';
// @ts-ignore
import {
  interpolateUrl,
} from '@/utils/url.ts';
// @ts-ignore
import {
  QueryKey,
} from '@tanstack/vue-query';

export const TGetAllAppsWithPermissionsPath = '/core/apps';
export type TGetAllAppsWithPermissionsResponse200 = paths['/core/apps']['get']['responses']['200']['content']['application/json'];
export type TGetAllAppsWithPermissionsResponse = TGetAllAppsWithPermissionsResponse200;
export const getAllAppsWithPermissionsQueryKey = (): QueryKey => ([...["core","apps"]]);
export function getAllAppsWithPermissions(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAllAppsWithPermissionsResponse>> {
  return axiosClientV3.get(TGetAllAppsWithPermissionsPath, { signal: abortSignal });
}

export const TFindAssetsPath = '/core/assets';
export type TFindAssetsQuery = paths['/core/assets']['get']['parameters']['query'];
export type TFindAssetsPayload = TApiPayload<undefined, TFindAssetsQuery, undefined>;
export type TFindAssetsResponse200 = paths['/core/assets']['get']['responses']['200']['content']['application/json'];
export type TFindAssetsResponse = TFindAssetsResponse200;
export const findAssetsQueryKey = (query?: TFindAssetsQuery): QueryKey => ([...["core","assets"], ...(query ? [query] : [])]);
export function findAssets(payload: TFindAssetsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetsResponse>> {
  return axiosClientV3.get(TFindAssetsPath, { signal: abortSignal, params: payload.query });
}

export const TGetAssetDetailsPath = '/core/assets/{assetId}';
export type TGetAssetDetailsPath = paths['/core/assets/{assetId}']['get']['parameters']['path'];
export type TGetAssetDetailsPayload = TApiPayload<TGetAssetDetailsPath, undefined, undefined>;
export type TGetAssetDetailsResponse200 = paths['/core/assets/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetAssetDetailsResponse = TGetAssetDetailsResponse200;
export const getAssetDetailsQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets"], assetId]);
export function getAssetDetails(payload: TGetAssetDetailsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetDetailsResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetAssetDetailsPath, payload.path), { signal: abortSignal });
}

export const TUpdateAssetDetailsPath = '/core/assets/{assetId}';
export type TUpdateAssetDetailsPath = paths['/core/assets/{assetId}']['put']['parameters']['path'];
export type TUpdateAssetDetailsBodyParams = paths['/core/assets/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateAssetDetailsPayload = TApiPayload<TUpdateAssetDetailsPath, undefined, TUpdateAssetDetailsBodyParams>;
export type TUpdateAssetDetailsResponse200 = paths['/core/assets/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateAssetDetailsResponse = TUpdateAssetDetailsResponse200;
export const updateAssetDetailsQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets"], assetId]);
export function updateAssetDetails(payload: TUpdateAssetDetailsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAssetDetailsResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateAssetDetailsPath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteAssetRoutePath = '/core/assets/{assetId}';
export type TDeleteAssetRoutePath = paths['/core/assets/{assetId}']['delete']['parameters']['path'];
export type TDeleteAssetRoutePayload = TApiPayload<TDeleteAssetRoutePath, undefined, undefined>;
export type TDeleteAssetRouteResponse200 = paths['/core/assets/{assetId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteAssetRouteResponse = TDeleteAssetRouteResponse200;
export const deleteAssetRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets"], assetId]);
export function deleteAssetRoute(payload: TDeleteAssetRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteAssetRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteAssetRoutePath, payload.path), { signal: abortSignal });
}

export const TFindAssetBlobsRoutePath = '/core/assets/{assetId}/blobs';
export type TFindAssetBlobsRoutePath = paths['/core/assets/{assetId}/blobs']['get']['parameters']['path'];
export type TFindAssetBlobsRoutePayload = TApiPayload<TFindAssetBlobsRoutePath, undefined, undefined>;
export type TFindAssetBlobsRouteResponse200 = paths['/core/assets/{assetId}/blobs']['get']['responses']['200']['content']['application/json'];
export type TFindAssetBlobsRouteResponse = TFindAssetBlobsRouteResponse200;
export const findAssetBlobsRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId]);
export function findAssetBlobsRoute(payload: TFindAssetBlobsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetBlobsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TFindAssetBlobsRoutePath, payload.path), { signal: abortSignal });
}

export const TCreateAssetBlobRoutePath = '/core/assets/{assetId}/blobs';
export type TCreateAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs']['post']['parameters']['path'];
export type TCreateAssetBlobRouteBodyParams = paths['/core/assets/{assetId}/blobs']['post']['requestBody']['content']['application/json'];
export type TCreateAssetBlobRoutePayload = TApiPayload<TCreateAssetBlobRoutePath, undefined, TCreateAssetBlobRouteBodyParams>;
export type TCreateAssetBlobRouteResponse200 = paths['/core/assets/{assetId}/blobs']['post']['responses']['200']['content']['application/json'];
export type TCreateAssetBlobRouteResponse = TCreateAssetBlobRouteResponse200;
export const createAssetBlobRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId]);
export function createAssetBlobRoute(payload: TCreateAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateAssetBlobRouteResponse>> {
  return axiosClientV3.post(interpolateUrl(TCreateAssetBlobRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetAssetBlobRoutePath = '/core/assets/{assetId}/blobs/{blobId}';
export type TGetAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs/{blobId}']['get']['parameters']['path'];
export type TGetAssetBlobRoutePayload = TApiPayload<TGetAssetBlobRoutePath, undefined, undefined>;
export type TGetAssetBlobRouteResponse200 = paths['/core/assets/{assetId}/blobs/{blobId}']['get']['responses']['200']['content']['application/json'];
export type TGetAssetBlobRouteResponse = TGetAssetBlobRouteResponse200;
export const getAssetBlobRouteQueryKey = (assetId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId, blobId]);
export function getAssetBlobRoute(payload: TGetAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetBlobRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetAssetBlobRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateAssetBlobRoutePath = '/core/assets/{assetId}/blobs/{blobId}';
export type TUpdateAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs/{blobId}']['put']['parameters']['path'];
export type TUpdateAssetBlobRouteBodyParams = paths['/core/assets/{assetId}/blobs/{blobId}']['put']['requestBody']['content']['application/json'];
export type TUpdateAssetBlobRoutePayload = TApiPayload<TUpdateAssetBlobRoutePath, undefined, TUpdateAssetBlobRouteBodyParams>;
export type TUpdateAssetBlobRouteResponse200 = paths['/core/assets/{assetId}/blobs/{blobId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateAssetBlobRouteResponse = TUpdateAssetBlobRouteResponse200;
export const updateAssetBlobRouteQueryKey = (assetId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId, blobId]);
export function updateAssetBlobRoute(payload: TUpdateAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAssetBlobRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateAssetBlobRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteAssetBlobRoutePath = '/core/assets/{assetId}/blobs/{blobId}';
export type TDeleteAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs/{blobId}']['delete']['parameters']['path'];
export type TDeleteAssetBlobRoutePayload = TApiPayload<TDeleteAssetBlobRoutePath, undefined, undefined>;
export type TDeleteAssetBlobRouteResponse200 = paths['/core/assets/{assetId}/blobs/{blobId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteAssetBlobRouteResponse = TDeleteAssetBlobRouteResponse200;
export const deleteAssetBlobRouteQueryKey = (assetId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId, blobId]);
export function deleteAssetBlobRoute(payload: TDeleteAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteAssetBlobRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteAssetBlobRoutePath, payload.path), { signal: abortSignal });
}

export const TGetDataSourcesForAssetPath = '/core/assets/{assetId}/data-sources';
export type TGetDataSourcesForAssetPath = paths['/core/assets/{assetId}/data-sources']['get']['parameters']['path'];
export type TGetDataSourcesForAssetPayload = TApiPayload<TGetDataSourcesForAssetPath, undefined, undefined>;
export type TGetDataSourcesForAssetResponse200 = paths['/core/assets/{assetId}/data-sources']['get']['responses']['200']['content']['application/json'];
export type TGetDataSourcesForAssetResponse = TGetDataSourcesForAssetResponse200;
export const getDataSourcesForAssetQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","data-sources"], assetId]);
export function getDataSourcesForAsset(payload: TGetDataSourcesForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDataSourcesForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetDataSourcesForAssetPath, payload.path), { signal: abortSignal });
}

export const TGetAssetStagesRoutePath = '/core/assets/{assetId}/data/stages';
export type TGetAssetStagesRouteQuery = paths['/core/assets/{assetId}/data/stages']['get']['parameters']['query'];
export type TGetAssetStagesRoutePath = paths['/core/assets/{assetId}/data/stages']['get']['parameters']['path'];
export type TGetAssetStagesRoutePayload = TApiPayload<TGetAssetStagesRoutePath, TGetAssetStagesRouteQuery, undefined>;
export type TGetAssetStagesRouteResponse200 = paths['/core/assets/{assetId}/data/stages']['get']['responses']['200']['content']['application/json'];
export type TGetAssetStagesRouteResponse = TGetAssetStagesRouteResponse200;
export const getAssetStagesRouteQueryKey = (assetId: QueryKey[number], query?: TGetAssetStagesRouteQuery): QueryKey => ([...["core","assets","data","stages"], assetId, ...(query ? [query] : [])]);
export function getAssetStagesRoute(payload: TGetAssetStagesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetStagesRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetAssetStagesRoutePath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TFindAlarmEventMetadataForAssetPath = '/core/assets/{assetId}/metadata/alarm-event';
export type TFindAlarmEventMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/alarm-event']['get']['parameters']['query'];
export type TFindAlarmEventMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/alarm-event']['get']['parameters']['path'];
export type TFindAlarmEventMetadataForAssetPayload = TApiPayload<TFindAlarmEventMetadataForAssetPath, TFindAlarmEventMetadataForAssetQuery, undefined>;
export type TFindAlarmEventMetadataForAssetResponse200 = paths['/core/assets/{assetId}/metadata/alarm-event']['get']['responses']['200']['content']['application/json'];
export type TFindAlarmEventMetadataForAssetResponse = TFindAlarmEventMetadataForAssetResponse200;
export const findAlarmEventMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindAlarmEventMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","alarm-event"], assetId, ...(query ? [query] : [])]);
export function findAlarmEventMetadataForAsset(payload: TFindAlarmEventMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAlarmEventMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl(TFindAlarmEventMetadataForAssetPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TFindBlobMetadataForAssetPath = '/core/assets/{assetId}/metadata/blob';
export type TFindBlobMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/blob']['get']['parameters']['query'];
export type TFindBlobMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/blob']['get']['parameters']['path'];
export type TFindBlobMetadataForAssetPayload = TApiPayload<TFindBlobMetadataForAssetPath, TFindBlobMetadataForAssetQuery, undefined>;
export type TFindBlobMetadataForAssetResponse200 = paths['/core/assets/{assetId}/metadata/blob']['get']['responses']['200']['content']['application/json'];
export type TFindBlobMetadataForAssetResponse = TFindBlobMetadataForAssetResponse200;
export const findBlobMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindBlobMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","blob"], assetId, ...(query ? [query] : [])]);
export function findBlobMetadataForAsset(payload: TFindBlobMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindBlobMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl(TFindBlobMetadataForAssetPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TFindEventMetadataForAssetPath = '/core/assets/{assetId}/metadata/event';
export type TFindEventMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/event']['get']['parameters']['query'];
export type TFindEventMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/event']['get']['parameters']['path'];
export type TFindEventMetadataForAssetPayload = TApiPayload<TFindEventMetadataForAssetPath, TFindEventMetadataForAssetQuery, undefined>;
export type TFindEventMetadataForAssetResponse200 = paths['/core/assets/{assetId}/metadata/event']['get']['responses']['200']['content']['application/json'];
export type TFindEventMetadataForAssetResponse = TFindEventMetadataForAssetResponse200;
export const findEventMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindEventMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","event"], assetId, ...(query ? [query] : [])]);
export function findEventMetadataForAsset(payload: TFindEventMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindEventMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl(TFindEventMetadataForAssetPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TFindJobAggregateMetadataForAssetPath = '/core/assets/{assetId}/metadata/job-aggregate';
export type TFindJobAggregateMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/job-aggregate']['get']['parameters']['query'];
export type TFindJobAggregateMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/job-aggregate']['get']['parameters']['path'];
export type TFindJobAggregateMetadataForAssetPayload = TApiPayload<TFindJobAggregateMetadataForAssetPath, TFindJobAggregateMetadataForAssetQuery, undefined>;
export type TFindJobAggregateMetadataForAssetResponse200 = paths['/core/assets/{assetId}/metadata/job-aggregate']['get']['responses']['200']['content']['application/json'];
export type TFindJobAggregateMetadataForAssetResponse = TFindJobAggregateMetadataForAssetResponse200;
export const findJobAggregateMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindJobAggregateMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","job-aggregate"], assetId, ...(query ? [query] : [])]);
export function findJobAggregateMetadataForAsset(payload: TFindJobAggregateMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindJobAggregateMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl(TFindJobAggregateMetadataForAssetPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TFindTimeSeriesMetadataForAssetPath = '/core/assets/{assetId}/metadata/time-series';
export type TFindTimeSeriesMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/time-series']['get']['parameters']['query'];
export type TFindTimeSeriesMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/time-series']['get']['parameters']['path'];
export type TFindTimeSeriesMetadataForAssetPayload = TApiPayload<TFindTimeSeriesMetadataForAssetPath, TFindTimeSeriesMetadataForAssetQuery, undefined>;
export type TFindTimeSeriesMetadataForAssetResponse200 = paths['/core/assets/{assetId}/metadata/time-series']['get']['responses']['200']['content']['application/json'];
export type TFindTimeSeriesMetadataForAssetResponse = TFindTimeSeriesMetadataForAssetResponse200;
export const findTimeSeriesMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindTimeSeriesMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","time-series"], assetId, ...(query ? [query] : [])]);
export function findTimeSeriesMetadataForAsset(payload: TFindTimeSeriesMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindTimeSeriesMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl(TFindTimeSeriesMetadataForAssetPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TGetAssetProductivityPath = '/core/assets/{assetId}/productivity';
export type TGetAssetProductivityQuery = paths['/core/assets/{assetId}/productivity']['get']['parameters']['query'];
export type TGetAssetProductivityPath = paths['/core/assets/{assetId}/productivity']['get']['parameters']['path'];
export type TGetAssetProductivityPayload = TApiPayload<TGetAssetProductivityPath, TGetAssetProductivityQuery, undefined>;
export type TGetAssetProductivityResponse200 = paths['/core/assets/{assetId}/productivity']['get']['responses']['200']['content']['application/json'];
export type TGetAssetProductivityResponse = TGetAssetProductivityResponse200;
export const getAssetProductivityQueryKey = (assetId: QueryKey[number], query?: TGetAssetProductivityQuery): QueryKey => ([...["core","assets","productivity"], assetId, ...(query ? [query] : [])]);
export function getAssetProductivity(payload: TGetAssetProductivityPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetProductivityResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetAssetProductivityPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TGetEffectiveAssetProfilePath = '/core/assets/{assetId}/profiles';
export type TGetEffectiveAssetProfileQuery = paths['/core/assets/{assetId}/profiles']['get']['parameters']['query'];
export type TGetEffectiveAssetProfilePath = paths['/core/assets/{assetId}/profiles']['get']['parameters']['path'];
export type TGetEffectiveAssetProfilePayload = TApiPayload<TGetEffectiveAssetProfilePath, TGetEffectiveAssetProfileQuery, undefined>;
export type TGetEffectiveAssetProfileResponse200 = paths['/core/assets/{assetId}/profiles']['get']['responses']['200']['content']['application/json'];
export type TGetEffectiveAssetProfileResponse = TGetEffectiveAssetProfileResponse200;
export const getEffectiveAssetProfileQueryKey = (assetId: QueryKey[number], query?: TGetEffectiveAssetProfileQuery): QueryKey => ([...["core","assets","profiles"], assetId, ...(query ? [query] : [])]);
export function getEffectiveAssetProfile(payload: TGetEffectiveAssetProfilePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEffectiveAssetProfileResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetEffectiveAssetProfilePath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TGetProfileMappingStatusPath = '/core/assets/{assetId}/profile-mapping-status';
export type TGetProfileMappingStatusPath = paths['/core/assets/{assetId}/profile-mapping-status']['get']['parameters']['path'];
export type TGetProfileMappingStatusPayload = TApiPayload<TGetProfileMappingStatusPath, undefined, undefined>;
export type TGetProfileMappingStatusResponse200 = paths['/core/assets/{assetId}/profile-mapping-status']['get']['responses']['200']['content']['application/json'];
export type TGetProfileMappingStatusResponse = TGetProfileMappingStatusResponse200;
export const getProfileMappingStatusQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","profile-mapping-status"], assetId]);
export function getProfileMappingStatus(payload: TGetProfileMappingStatusPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetProfileMappingStatusResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetProfileMappingStatusPath, payload.path), { signal: abortSignal });
}

export const TFindAssetSettingsRouteByAssetAndCategoryPath = '/core/assets/{assetId}/settings/{category}';
export type TFindAssetSettingsRouteByAssetAndCategoryQuery = paths['/core/assets/{assetId}/settings/{category}']['get']['parameters']['query'];
export type TFindAssetSettingsRouteByAssetAndCategoryPath = paths['/core/assets/{assetId}/settings/{category}']['get']['parameters']['path'];
export type TFindAssetSettingsRouteByAssetAndCategoryPayload = TApiPayload<TFindAssetSettingsRouteByAssetAndCategoryPath, TFindAssetSettingsRouteByAssetAndCategoryQuery, undefined>;
export type TFindAssetSettingsRouteByAssetAndCategoryResponse200 = paths['/core/assets/{assetId}/settings/{category}']['get']['responses']['200']['content']['application/json'];
export type TFindAssetSettingsRouteByAssetAndCategoryResponse = TFindAssetSettingsRouteByAssetAndCategoryResponse200;
export const findAssetSettingsRouteByAssetAndCategoryQueryKey = (assetId: QueryKey[number], category: QueryKey[number], query?: TFindAssetSettingsRouteByAssetAndCategoryQuery): QueryKey => ([...["core","assets","settings"], assetId, category, ...(query ? [query] : [])]);
export function findAssetSettingsRouteByAssetAndCategory(payload: TFindAssetSettingsRouteByAssetAndCategoryPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetSettingsRouteByAssetAndCategoryResponse>> {
  return axiosClientV3.get(interpolateUrl(TFindAssetSettingsRouteByAssetAndCategoryPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TGetAssetSparePartsPath = '/core/assets/{assetId}/spare-parts';
export type TGetAssetSparePartsPath = paths['/core/assets/{assetId}/spare-parts']['get']['parameters']['path'];
export type TGetAssetSparePartsPayload = TApiPayload<TGetAssetSparePartsPath, undefined, undefined>;
export type TGetAssetSparePartsResponse200 = paths['/core/assets/{assetId}/spare-parts']['get']['responses']['200']['content']['application/json'];
export type TGetAssetSparePartsResponse = TGetAssetSparePartsResponse200;
export const getAssetSparePartsQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId]);
export function getAssetSpareParts(payload: TGetAssetSparePartsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetSparePartsResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetAssetSparePartsPath, payload.path), { signal: abortSignal });
}

export const TAssociateSparePartsPath = '/core/assets/{assetId}/spare-parts';
export type TAssociateSparePartsPath = paths['/core/assets/{assetId}/spare-parts']['put']['parameters']['path'];
export type TAssociateSparePartsBodyParams = paths['/core/assets/{assetId}/spare-parts']['put']['requestBody']['content']['application/json'];
export type TAssociateSparePartsPayload = TApiPayload<TAssociateSparePartsPath, undefined, TAssociateSparePartsBodyParams>;
export type TAssociateSparePartsResponse200 = paths['/core/assets/{assetId}/spare-parts']['put']['responses']['200']['content']['application/json'];
export type TAssociateSparePartsResponse = TAssociateSparePartsResponse200;
export const associateSparePartsQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId]);
export function associateSpareParts(payload: TAssociateSparePartsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TAssociateSparePartsResponse>> {
  return axiosClientV3.put(interpolateUrl(TAssociateSparePartsPath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TAddSparePartToAssetRoutePath = '/core/assets/{assetId}/spare-parts/{sparePartId}';
export type TAddSparePartToAssetRoutePath = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['put']['parameters']['path'];
export type TAddSparePartToAssetRoutePayload = TApiPayload<TAddSparePartToAssetRoutePath, undefined, undefined>;
export type TAddSparePartToAssetRouteResponse200 = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['put']['responses']['200']['content']['application/json'];
export type TAddSparePartToAssetRouteResponse = TAddSparePartToAssetRouteResponse200;
export const addSparePartToAssetRouteQueryKey = (assetId: QueryKey[number], sparePartId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId, sparePartId]);
export function addSparePartToAssetRoute(payload: TAddSparePartToAssetRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TAddSparePartToAssetRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TAddSparePartToAssetRoutePath, payload.path), undefined, { signal: abortSignal });
}

export const TRemoveSparePartsFromAssetRoutePath = '/core/assets/{assetId}/spare-parts/{sparePartId}';
export type TRemoveSparePartsFromAssetRoutePath = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['delete']['parameters']['path'];
export type TRemoveSparePartsFromAssetRoutePayload = TApiPayload<TRemoveSparePartsFromAssetRoutePath, undefined, undefined>;
export type TRemoveSparePartsFromAssetRouteResponse200 = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['delete']['responses']['200']['content']['application/json'];
export type TRemoveSparePartsFromAssetRouteResponse = TRemoveSparePartsFromAssetRouteResponse200;
export const removeSparePartsFromAssetRouteQueryKey = (assetId: QueryKey[number], sparePartId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId, sparePartId]);
export function removeSparePartsFromAssetRoute(payload: TRemoveSparePartsFromAssetRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TRemoveSparePartsFromAssetRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TRemoveSparePartsFromAssetRoutePath, payload.path), { signal: abortSignal });
}

export const TFindAutomatsRoutePath = '/core/asset/automats';
export type TFindAutomatsRouteQuery = paths['/core/asset/automats']['get']['parameters']['query'];
export type TFindAutomatsRoutePayload = TApiPayload<undefined, TFindAutomatsRouteQuery, undefined>;
export type TFindAutomatsRouteResponse200 = paths['/core/asset/automats']['get']['responses']['200']['content']['application/json'];
export type TFindAutomatsRouteResponse = TFindAutomatsRouteResponse200;
export const findAutomatsRouteQueryKey = (query?: TFindAutomatsRouteQuery): QueryKey => ([...["core","asset","automats"], ...(query ? [query] : [])]);
export function findAutomatsRoute(payload: TFindAutomatsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAutomatsRouteResponse>> {
  return axiosClientV3.get(TFindAutomatsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateAutomatRoutePath = '/core/asset/automats';
export type TCreateAutomatRouteBodyParams = paths['/core/asset/automats']['post']['requestBody']['content']['application/json'];
export type TCreateAutomatRoutePayload = TApiPayload<undefined, undefined, TCreateAutomatRouteBodyParams>;
export type TCreateAutomatRouteResponse200 = paths['/core/asset/automats']['post']['responses']['200']['content']['application/json'];
export type TCreateAutomatRouteResponse = TCreateAutomatRouteResponse200;
export const createAutomatRouteQueryKey = (): QueryKey => ([...["core","asset","automats"]]);
export function createAutomatRoute(payload: TCreateAutomatRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateAutomatRouteResponse>> {
  return axiosClientV3.post(TCreateAutomatRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetAutomatRoutePath = '/core/asset/automats/{assetId}';
export type TGetAutomatRoutePath = paths['/core/asset/automats/{assetId}']['get']['parameters']['path'];
export type TGetAutomatRoutePayload = TApiPayload<TGetAutomatRoutePath, undefined, undefined>;
export type TGetAutomatRouteResponse200 = paths['/core/asset/automats/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetAutomatRouteResponse = TGetAutomatRouteResponse200;
export const getAutomatRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","automats"], assetId]);
export function getAutomatRoute(payload: TGetAutomatRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAutomatRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetAutomatRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateAutomatRoutePath = '/core/asset/automats/{assetId}';
export type TUpdateAutomatRoutePath = paths['/core/asset/automats/{assetId}']['put']['parameters']['path'];
export type TUpdateAutomatRouteBodyParams = paths['/core/asset/automats/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateAutomatRoutePayload = TApiPayload<TUpdateAutomatRoutePath, undefined, TUpdateAutomatRouteBodyParams>;
export type TUpdateAutomatRouteResponse200 = paths['/core/asset/automats/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateAutomatRouteResponse = TUpdateAutomatRouteResponse200;
export const updateAutomatRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","automats"], assetId]);
export function updateAutomatRoute(payload: TUpdateAutomatRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAutomatRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateAutomatRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteAutomatRoutePath = '/core/asset/automats/{assetId}';
export type TDeleteAutomatRoutePath = paths['/core/asset/automats/{assetId}']['delete']['parameters']['path'];
export type TDeleteAutomatRoutePayload = TApiPayload<TDeleteAutomatRoutePath, undefined, undefined>;
export type TDeleteAutomatRouteResponse200 = paths['/core/asset/automats/{assetId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteAutomatRouteResponse = TDeleteAutomatRouteResponse200;
export const deleteAutomatRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","automats"], assetId]);
export function deleteAutomatRoute(payload: TDeleteAutomatRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteAutomatRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteAutomatRoutePath, payload.path), { signal: abortSignal });
}

export const TFindChambersRoutePath = '/core/asset/chambers';
export type TFindChambersRouteQuery = paths['/core/asset/chambers']['get']['parameters']['query'];
export type TFindChambersRoutePayload = TApiPayload<undefined, TFindChambersRouteQuery, undefined>;
export type TFindChambersRouteResponse200 = paths['/core/asset/chambers']['get']['responses']['200']['content']['application/json'];
export type TFindChambersRouteResponse = TFindChambersRouteResponse200;
export const findChambersRouteQueryKey = (query?: TFindChambersRouteQuery): QueryKey => ([...["core","asset","chambers"], ...(query ? [query] : [])]);
export function findChambersRoute(payload: TFindChambersRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindChambersRouteResponse>> {
  return axiosClientV3.get(TFindChambersRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateChamberRoutePath = '/core/asset/chambers';
export type TCreateChamberRouteBodyParams = paths['/core/asset/chambers']['post']['requestBody']['content']['application/json'];
export type TCreateChamberRoutePayload = TApiPayload<undefined, undefined, TCreateChamberRouteBodyParams>;
export type TCreateChamberRouteResponse200 = paths['/core/asset/chambers']['post']['responses']['200']['content']['application/json'];
export type TCreateChamberRouteResponse = TCreateChamberRouteResponse200;
export const createChamberRouteQueryKey = (): QueryKey => ([...["core","asset","chambers"]]);
export function createChamberRoute(payload: TCreateChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateChamberRouteResponse>> {
  return axiosClientV3.post(TCreateChamberRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetChamberRoutePath = '/core/asset/chambers/{assetId}';
export type TGetChamberRoutePath = paths['/core/asset/chambers/{assetId}']['get']['parameters']['path'];
export type TGetChamberRoutePayload = TApiPayload<TGetChamberRoutePath, undefined, undefined>;
export type TGetChamberRouteResponse200 = paths['/core/asset/chambers/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetChamberRouteResponse = TGetChamberRouteResponse200;
export const getChamberRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","chambers"], assetId]);
export function getChamberRoute(payload: TGetChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetChamberRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetChamberRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateChamberRoutePath = '/core/asset/chambers/{assetId}';
export type TUpdateChamberRoutePath = paths['/core/asset/chambers/{assetId}']['put']['parameters']['path'];
export type TUpdateChamberRouteBodyParams = paths['/core/asset/chambers/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateChamberRoutePayload = TApiPayload<TUpdateChamberRoutePath, undefined, TUpdateChamberRouteBodyParams>;
export type TUpdateChamberRouteResponse200 = paths['/core/asset/chambers/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateChamberRouteResponse = TUpdateChamberRouteResponse200;
export const updateChamberRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","chambers"], assetId]);
export function updateChamberRoute(payload: TUpdateChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateChamberRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateChamberRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteChamberRoutePath = '/core/asset/chambers/{assetId}';
export type TDeleteChamberRoutePath = paths['/core/asset/chambers/{assetId}']['delete']['parameters']['path'];
export type TDeleteChamberRoutePayload = TApiPayload<TDeleteChamberRoutePath, undefined, undefined>;
export type TDeleteChamberRouteResponse200 = paths['/core/asset/chambers/{assetId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteChamberRouteResponse = TDeleteChamberRouteResponse200;
export const deleteChamberRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","chambers"], assetId]);
export function deleteChamberRoute(payload: TDeleteChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteChamberRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteChamberRoutePath, payload.path), { signal: abortSignal });
}

export const TFindCompanyRoutePath = '/core/asset/companies';
export type TFindCompanyRouteQuery = paths['/core/asset/companies']['get']['parameters']['query'];
export type TFindCompanyRoutePayload = TApiPayload<undefined, TFindCompanyRouteQuery, undefined>;
export type TFindCompanyRouteResponse200 = paths['/core/asset/companies']['get']['responses']['200']['content']['application/json'];
export type TFindCompanyRouteResponse = TFindCompanyRouteResponse200;
export const findCompanyRouteQueryKey = (query?: TFindCompanyRouteQuery): QueryKey => ([...["core","asset","companies"], ...(query ? [query] : [])]);
export function findCompanyRoute(payload: TFindCompanyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindCompanyRouteResponse>> {
  return axiosClientV3.get(TFindCompanyRoutePath, { signal: abortSignal, params: payload.query });
}

export const TGetCompanyRoutePath = '/core/asset/companies/{assetId}';
export type TGetCompanyRoutePath = paths['/core/asset/companies/{assetId}']['get']['parameters']['path'];
export type TGetCompanyRoutePayload = TApiPayload<TGetCompanyRoutePath, undefined, undefined>;
export type TGetCompanyRouteResponse200 = paths['/core/asset/companies/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetCompanyRouteResponse = TGetCompanyRouteResponse200;
export const getCompanyRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","companies"], assetId]);
export function getCompanyRoute(payload: TGetCompanyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCompanyRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetCompanyRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateCompanyRoutePath = '/core/asset/companies/{assetId}';
export type TUpdateCompanyRoutePath = paths['/core/asset/companies/{assetId}']['put']['parameters']['path'];
export type TUpdateCompanyRouteBodyParams = paths['/core/asset/companies/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateCompanyRoutePayload = TApiPayload<TUpdateCompanyRoutePath, undefined, TUpdateCompanyRouteBodyParams>;
export type TUpdateCompanyRouteResponse200 = paths['/core/asset/companies/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateCompanyRouteResponse = TUpdateCompanyRouteResponse200;
export const updateCompanyRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","companies"], assetId]);
export function updateCompanyRoute(payload: TUpdateCompanyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateCompanyRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateCompanyRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TFindComponentsRoutePath = '/core/asset/components';
export type TFindComponentsRouteQuery = paths['/core/asset/components']['get']['parameters']['query'];
export type TFindComponentsRoutePayload = TApiPayload<undefined, TFindComponentsRouteQuery, undefined>;
export type TFindComponentsRouteResponse200 = paths['/core/asset/components']['get']['responses']['200']['content']['application/json'];
export type TFindComponentsRouteResponse = TFindComponentsRouteResponse200;
export const findComponentsRouteQueryKey = (query?: TFindComponentsRouteQuery): QueryKey => ([...["core","asset","components"], ...(query ? [query] : [])]);
export function findComponentsRoute(payload: TFindComponentsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindComponentsRouteResponse>> {
  return axiosClientV3.get(TFindComponentsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateComponentRoutePath = '/core/asset/components';
export type TCreateComponentRouteBodyParams = paths['/core/asset/components']['post']['requestBody']['content']['application/json'];
export type TCreateComponentRoutePayload = TApiPayload<undefined, undefined, TCreateComponentRouteBodyParams>;
export type TCreateComponentRouteResponse200 = paths['/core/asset/components']['post']['responses']['200']['content']['application/json'];
export type TCreateComponentRouteResponse = TCreateComponentRouteResponse200;
export const createComponentRouteQueryKey = (): QueryKey => ([...["core","asset","components"]]);
export function createComponentRoute(payload: TCreateComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateComponentRouteResponse>> {
  return axiosClientV3.post(TCreateComponentRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetComponentRoutePath = '/core/asset/components/{assetId}';
export type TGetComponentRoutePath = paths['/core/asset/components/{assetId}']['get']['parameters']['path'];
export type TGetComponentRoutePayload = TApiPayload<TGetComponentRoutePath, undefined, undefined>;
export type TGetComponentRouteResponse200 = paths['/core/asset/components/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetComponentRouteResponse = TGetComponentRouteResponse200;
export const getComponentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","components"], assetId]);
export function getComponentRoute(payload: TGetComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetComponentRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetComponentRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateComponentRoutePath = '/core/asset/components/{assetId}';
export type TUpdateComponentRoutePath = paths['/core/asset/components/{assetId}']['put']['parameters']['path'];
export type TUpdateComponentRouteBodyParams = paths['/core/asset/components/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateComponentRoutePayload = TApiPayload<TUpdateComponentRoutePath, undefined, TUpdateComponentRouteBodyParams>;
export type TUpdateComponentRouteResponse200 = paths['/core/asset/components/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateComponentRouteResponse = TUpdateComponentRouteResponse200;
export const updateComponentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","components"], assetId]);
export function updateComponentRoute(payload: TUpdateComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateComponentRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateComponentRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteComponentRoutePath = '/core/asset/components/{assetId}';
export type TDeleteComponentRoutePath = paths['/core/asset/components/{assetId}']['delete']['parameters']['path'];
export type TDeleteComponentRoutePayload = TApiPayload<TDeleteComponentRoutePath, undefined, undefined>;
export type TDeleteComponentRouteResponse200 = paths['/core/asset/components/{assetId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteComponentRouteResponse = TDeleteComponentRouteResponse200;
export const deleteComponentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","components"], assetId]);
export function deleteComponentRoute(payload: TDeleteComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteComponentRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteComponentRoutePath, payload.path), { signal: abortSignal });
}

export const TGetEquipmentPath = '/core/asset/equipments/{assetId}';
export type TGetEquipmentPath = paths['/core/asset/equipments/{assetId}']['get']['parameters']['path'];
export type TGetEquipmentPayload = TApiPayload<TGetEquipmentPath, undefined, undefined>;
export type TGetEquipmentResponse200 = paths['/core/asset/equipments/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetEquipmentResponse = TGetEquipmentResponse200;
export const getEquipmentQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments"], assetId]);
export function getEquipment(payload: TGetEquipmentPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetEquipmentPath, payload.path), { signal: abortSignal });
}

export const TUpdateEquipmentRoutePath = '/core/asset/equipments/{assetId}';
export type TUpdateEquipmentRoutePath = paths['/core/asset/equipments/{assetId}']['put']['parameters']['path'];
export type TUpdateEquipmentRouteBodyParams = paths['/core/asset/equipments/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateEquipmentRoutePayload = TApiPayload<TUpdateEquipmentRoutePath, undefined, TUpdateEquipmentRouteBodyParams>;
export type TUpdateEquipmentRouteResponse200 = paths['/core/asset/equipments/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateEquipmentRouteResponse = TUpdateEquipmentRouteResponse200;
export const updateEquipmentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments"], assetId]);
export function updateEquipmentRoute(payload: TUpdateEquipmentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateEquipmentRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateEquipmentRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetEquipmentStatusPath = '/core/asset/equipments/{assetId}/status';
export type TGetEquipmentStatusPath = paths['/core/asset/equipments/{assetId}/status']['get']['parameters']['path'];
export type TGetEquipmentStatusPayload = TApiPayload<TGetEquipmentStatusPath, undefined, undefined>;
export type TGetEquipmentStatusResponse200 = paths['/core/asset/equipments/{assetId}/status']['get']['responses']['200']['content']['application/json'];
export type TGetEquipmentStatusResponse = TGetEquipmentStatusResponse200;
export const getEquipmentStatusQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","status"], assetId]);
export function getEquipmentStatus(payload: TGetEquipmentStatusPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentStatusResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetEquipmentStatusPath, payload.path), { signal: abortSignal });
}

export const TGetEquipmentCertificatesRoutePath = '/core/asset/equipments/{assetId}/certificates';
export type TGetEquipmentCertificatesRoutePath = paths['/core/asset/equipments/{assetId}/certificates']['get']['parameters']['path'];
export type TGetEquipmentCertificatesRoutePayload = TApiPayload<TGetEquipmentCertificatesRoutePath, undefined, undefined>;
export type TGetEquipmentCertificatesRouteResponse200 = paths['/core/asset/equipments/{assetId}/certificates']['get']['responses']['200']['content']['application/json'];
export type TGetEquipmentCertificatesRouteResponse = TGetEquipmentCertificatesRouteResponse200;
export const getEquipmentCertificatesRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","certificates"], assetId]);
export function getEquipmentCertificatesRoute(payload: TGetEquipmentCertificatesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentCertificatesRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetEquipmentCertificatesRoutePath, payload.path), { signal: abortSignal });
}

export const TSetEquipmentCertificatesRoutePath = '/core/asset/equipments/{assetId}/certificates';
export type TSetEquipmentCertificatesRoutePath = paths['/core/asset/equipments/{assetId}/certificates']['put']['parameters']['path'];
export type TSetEquipmentCertificatesRouteBodyParams = paths['/core/asset/equipments/{assetId}/certificates']['put']['requestBody']['content']['application/json'];
export type TSetEquipmentCertificatesRoutePayload = TApiPayload<TSetEquipmentCertificatesRoutePath, undefined, TSetEquipmentCertificatesRouteBodyParams>;
export type TSetEquipmentCertificatesRouteResponse200 = paths['/core/asset/equipments/{assetId}/certificates']['put']['responses']['200']['content']['application/json'];
export type TSetEquipmentCertificatesRouteResponse = TSetEquipmentCertificatesRouteResponse200;
export const setEquipmentCertificatesRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","certificates"], assetId]);
export function setEquipmentCertificatesRoute(payload: TSetEquipmentCertificatesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetEquipmentCertificatesRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TSetEquipmentCertificatesRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetEquipmentLimitsRoutePath = '/core/asset/equipments/{assetId}/meaning-limits';
export type TGetEquipmentLimitsRoutePath = paths['/core/asset/equipments/{assetId}/meaning-limits']['get']['parameters']['path'];
export type TGetEquipmentLimitsRoutePayload = TApiPayload<TGetEquipmentLimitsRoutePath, undefined, undefined>;
export type TGetEquipmentLimitsRouteResponse200 = paths['/core/asset/equipments/{assetId}/meaning-limits']['get']['responses']['200']['content']['application/json'];
export type TGetEquipmentLimitsRouteResponse = TGetEquipmentLimitsRouteResponse200;
export const getEquipmentLimitsRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","meaning-limits"], assetId]);
export function getEquipmentLimitsRoute(payload: TGetEquipmentLimitsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentLimitsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetEquipmentLimitsRoutePath, payload.path), { signal: abortSignal });
}

export const TSetEquipmentLimitsRoutePath = '/core/asset/equipments/{assetId}/meaning-limits';
export type TSetEquipmentLimitsRoutePath = paths['/core/asset/equipments/{assetId}/meaning-limits']['put']['parameters']['path'];
export type TSetEquipmentLimitsRouteBodyParams = paths['/core/asset/equipments/{assetId}/meaning-limits']['put']['requestBody']['content']['application/json'];
export type TSetEquipmentLimitsRoutePayload = TApiPayload<TSetEquipmentLimitsRoutePath, undefined, TSetEquipmentLimitsRouteBodyParams>;
export type TSetEquipmentLimitsRouteResponse200 = paths['/core/asset/equipments/{assetId}/meaning-limits']['put']['responses']['200']['content']['application/json'];
export type TSetEquipmentLimitsRouteResponse = TSetEquipmentLimitsRouteResponse200;
export const setEquipmentLimitsRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","meaning-limits"], assetId]);
export function setEquipmentLimitsRoute(payload: TSetEquipmentLimitsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetEquipmentLimitsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TSetEquipmentLimitsRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TFindEquipmentGroupsRoutePath = '/core/asset/equipment-groups';
export type TFindEquipmentGroupsRouteQuery = paths['/core/asset/equipment-groups']['get']['parameters']['query'];
export type TFindEquipmentGroupsRoutePayload = TApiPayload<undefined, TFindEquipmentGroupsRouteQuery, undefined>;
export type TFindEquipmentGroupsRouteResponse200 = paths['/core/asset/equipment-groups']['get']['responses']['200']['content']['application/json'];
export type TFindEquipmentGroupsRouteResponse = TFindEquipmentGroupsRouteResponse200;
export const findEquipmentGroupsRouteQueryKey = (query?: TFindEquipmentGroupsRouteQuery): QueryKey => ([...["core","asset","equipment-groups"], ...(query ? [query] : [])]);
export function findEquipmentGroupsRoute(payload: TFindEquipmentGroupsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindEquipmentGroupsRouteResponse>> {
  return axiosClientV3.get(TFindEquipmentGroupsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateEquipmentGroupRoutePath = '/core/asset/equipment-groups';
export type TCreateEquipmentGroupRouteBodyParams = paths['/core/asset/equipment-groups']['post']['requestBody']['content']['application/json'];
export type TCreateEquipmentGroupRoutePayload = TApiPayload<undefined, undefined, TCreateEquipmentGroupRouteBodyParams>;
export type TCreateEquipmentGroupRouteResponse200 = paths['/core/asset/equipment-groups']['post']['responses']['200']['content']['application/json'];
export type TCreateEquipmentGroupRouteResponse = TCreateEquipmentGroupRouteResponse200;
export const createEquipmentGroupRouteQueryKey = (): QueryKey => ([...["core","asset","equipment-groups"]]);
export function createEquipmentGroupRoute(payload: TCreateEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateEquipmentGroupRouteResponse>> {
  return axiosClientV3.post(TCreateEquipmentGroupRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetEquipmentGroupRoutePath = '/core/asset/equipment-groups/{assetId}';
export type TGetEquipmentGroupRoutePath = paths['/core/asset/equipment-groups/{assetId}']['get']['parameters']['path'];
export type TGetEquipmentGroupRoutePayload = TApiPayload<TGetEquipmentGroupRoutePath, undefined, undefined>;
export type TGetEquipmentGroupRouteResponse200 = paths['/core/asset/equipment-groups/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetEquipmentGroupRouteResponse = TGetEquipmentGroupRouteResponse200;
export const getEquipmentGroupRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipment-groups"], assetId]);
export function getEquipmentGroupRoute(payload: TGetEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentGroupRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetEquipmentGroupRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateEquipmentGroupRoutePath = '/core/asset/equipment-groups/{assetId}';
export type TUpdateEquipmentGroupRoutePath = paths['/core/asset/equipment-groups/{assetId}']['put']['parameters']['path'];
export type TUpdateEquipmentGroupRouteBodyParams = paths['/core/asset/equipment-groups/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateEquipmentGroupRoutePayload = TApiPayload<TUpdateEquipmentGroupRoutePath, undefined, TUpdateEquipmentGroupRouteBodyParams>;
export type TUpdateEquipmentGroupRouteResponse200 = paths['/core/asset/equipment-groups/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateEquipmentGroupRouteResponse = TUpdateEquipmentGroupRouteResponse200;
export const updateEquipmentGroupRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipment-groups"], assetId]);
export function updateEquipmentGroupRoute(payload: TUpdateEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateEquipmentGroupRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateEquipmentGroupRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteEquipmentGroupRoutePath = '/core/asset/equipment-groups/{assetId}';
export type TDeleteEquipmentGroupRoutePath = paths['/core/asset/equipment-groups/{assetId}']['delete']['parameters']['path'];
export type TDeleteEquipmentGroupRoutePayload = TApiPayload<TDeleteEquipmentGroupRoutePath, undefined, undefined>;
export type TDeleteEquipmentGroupRouteResponse200 = paths['/core/asset/equipment-groups/{assetId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteEquipmentGroupRouteResponse = TDeleteEquipmentGroupRouteResponse200;
export const deleteEquipmentGroupRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipment-groups"], assetId]);
export function deleteEquipmentGroupRoute(payload: TDeleteEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteEquipmentGroupRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteEquipmentGroupRoutePath, payload.path), { signal: abortSignal });
}

export const TFindLocationsRoutePath = '/core/asset/locations';
export type TFindLocationsRouteQuery = paths['/core/asset/locations']['get']['parameters']['query'];
export type TFindLocationsRoutePayload = TApiPayload<undefined, TFindLocationsRouteQuery, undefined>;
export type TFindLocationsRouteResponse200 = paths['/core/asset/locations']['get']['responses']['200']['content']['application/json'];
export type TFindLocationsRouteResponse = TFindLocationsRouteResponse200;
export const findLocationsRouteQueryKey = (query?: TFindLocationsRouteQuery): QueryKey => ([...["core","asset","locations"], ...(query ? [query] : [])]);
export function findLocationsRoute(payload: TFindLocationsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindLocationsRouteResponse>> {
  return axiosClientV3.get(TFindLocationsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TGetLocationRoutePath = '/core/asset/locations/{assetId}';
export type TGetLocationRoutePath = paths['/core/asset/locations/{assetId}']['get']['parameters']['path'];
export type TGetLocationRoutePayload = TApiPayload<TGetLocationRoutePath, undefined, undefined>;
export type TGetLocationRouteResponse200 = paths['/core/asset/locations/{assetId}']['get']['responses']['200']['content']['application/json'];
export type TGetLocationRouteResponse = TGetLocationRouteResponse200;
export const getLocationRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","locations"], assetId]);
export function getLocationRoute(payload: TGetLocationRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetLocationRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetLocationRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateLocationRoutePath = '/core/asset/locations/{assetId}';
export type TUpdateLocationRoutePath = paths['/core/asset/locations/{assetId}']['put']['parameters']['path'];
export type TUpdateLocationRouteBodyParams = paths['/core/asset/locations/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateLocationRoutePayload = TApiPayload<TUpdateLocationRoutePath, undefined, TUpdateLocationRouteBodyParams>;
export type TUpdateLocationRouteResponse200 = paths['/core/asset/locations/{assetId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateLocationRouteResponse = TUpdateLocationRouteResponse200;
export const updateLocationRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","locations"], assetId]);
export function updateLocationRoute(payload: TUpdateLocationRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateLocationRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateLocationRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TFindAssetSettingsRoutePath = '/core/asset-settings';
export type TFindAssetSettingsRouteQuery = paths['/core/asset-settings']['get']['parameters']['query'];
export type TFindAssetSettingsRoutePayload = TApiPayload<undefined, TFindAssetSettingsRouteQuery, undefined>;
export type TFindAssetSettingsRouteResponse200 = paths['/core/asset-settings']['get']['responses']['200']['content']['application/json'];
export type TFindAssetSettingsRouteResponse = TFindAssetSettingsRouteResponse200;
export const findAssetSettingsRouteQueryKey = (query?: TFindAssetSettingsRouteQuery): QueryKey => ([...["core","asset-settings"], ...(query ? [query] : [])]);
export function findAssetSettingsRoute(payload: TFindAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetSettingsRouteResponse>> {
  return axiosClientV3.get(TFindAssetSettingsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateAssetSettingsRoutePath = '/core/asset-settings';
export type TCreateAssetSettingsRouteBodyParams = paths['/core/asset-settings']['post']['requestBody']['content']['application/json'];
export type TCreateAssetSettingsRoutePayload = TApiPayload<undefined, undefined, TCreateAssetSettingsRouteBodyParams>;
export type TCreateAssetSettingsRouteResponse200 = paths['/core/asset-settings']['post']['responses']['200']['content']['application/json'];
export type TCreateAssetSettingsRouteResponse = TCreateAssetSettingsRouteResponse200;
export const createAssetSettingsRouteQueryKey = (): QueryKey => ([...["core","asset-settings"]]);
export function createAssetSettingsRoute(payload: TCreateAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateAssetSettingsRouteResponse>> {
  return axiosClientV3.post(TCreateAssetSettingsRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetAssetSettingsRoutePath = '/core/asset-settings/{assetSettingsId}';
export type TGetAssetSettingsRoutePath = paths['/core/asset-settings/{assetSettingsId}']['get']['parameters']['path'];
export type TGetAssetSettingsRoutePayload = TApiPayload<TGetAssetSettingsRoutePath, undefined, undefined>;
export type TGetAssetSettingsRouteResponse200 = paths['/core/asset-settings/{assetSettingsId}']['get']['responses']['200']['content']['application/json'];
export type TGetAssetSettingsRouteResponse = TGetAssetSettingsRouteResponse200;
export const getAssetSettingsRouteQueryKey = (assetSettingsId: QueryKey[number]): QueryKey => ([...["core","asset-settings"], assetSettingsId]);
export function getAssetSettingsRoute(payload: TGetAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetSettingsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetAssetSettingsRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateAssetSettingsRoutePath = '/core/asset-settings/{assetSettingsId}';
export type TUpdateAssetSettingsRoutePath = paths['/core/asset-settings/{assetSettingsId}']['put']['parameters']['path'];
export type TUpdateAssetSettingsRouteBodyParams = paths['/core/asset-settings/{assetSettingsId}']['put']['requestBody']['content']['application/json'];
export type TUpdateAssetSettingsRoutePayload = TApiPayload<TUpdateAssetSettingsRoutePath, undefined, TUpdateAssetSettingsRouteBodyParams>;
export type TUpdateAssetSettingsRouteResponse200 = paths['/core/asset-settings/{assetSettingsId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateAssetSettingsRouteResponse = TUpdateAssetSettingsRouteResponse200;
export const updateAssetSettingsRouteQueryKey = (assetSettingsId: QueryKey[number]): QueryKey => ([...["core","asset-settings"], assetSettingsId]);
export function updateAssetSettingsRoute(payload: TUpdateAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAssetSettingsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateAssetSettingsRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteAssetSettingsRoutePath = '/core/asset-settings/{assetSettingsId}';
export type TDeleteAssetSettingsRoutePath = paths['/core/asset-settings/{assetSettingsId}']['delete']['parameters']['path'];
export type TDeleteAssetSettingsRoutePayload = TApiPayload<TDeleteAssetSettingsRoutePath, undefined, undefined>;
export type TDeleteAssetSettingsRouteResponse200 = paths['/core/asset-settings/{assetSettingsId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteAssetSettingsRouteResponse = TDeleteAssetSettingsRouteResponse200;
export const deleteAssetSettingsRouteQueryKey = (assetSettingsId: QueryKey[number]): QueryKey => ([...["core","asset-settings"], assetSettingsId]);
export function deleteAssetSettingsRoute(payload: TDeleteAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteAssetSettingsRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteAssetSettingsRoutePath, payload.path), { signal: abortSignal });
}

export const TFindSparePartsRoutePath = '/core/asset-spare-parts/parts';
export type TFindSparePartsRouteQuery = paths['/core/asset-spare-parts/parts']['get']['parameters']['query'];
export type TFindSparePartsRoutePayload = TApiPayload<undefined, TFindSparePartsRouteQuery, undefined>;
export type TFindSparePartsRouteResponse200 = paths['/core/asset-spare-parts/parts']['get']['responses']['200']['content']['application/json'];
export type TFindSparePartsRouteResponse = TFindSparePartsRouteResponse200;
export const findSparePartsRouteQueryKey = (query?: TFindSparePartsRouteQuery): QueryKey => ([...["core","asset-spare-parts","parts"], ...(query ? [query] : [])]);
export function findSparePartsRoute(payload: TFindSparePartsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindSparePartsRouteResponse>> {
  return axiosClientV3.get(TFindSparePartsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateSparePartRoutePath = '/core/asset-spare-parts/parts';
export type TCreateSparePartRouteBodyParams = paths['/core/asset-spare-parts/parts']['post']['requestBody']['content']['application/json'];
export type TCreateSparePartRoutePayload = TApiPayload<undefined, undefined, TCreateSparePartRouteBodyParams>;
export type TCreateSparePartRouteResponse200 = paths['/core/asset-spare-parts/parts']['post']['responses']['200']['content']['application/json'];
export type TCreateSparePartRouteResponse = TCreateSparePartRouteResponse200;
export const createSparePartRouteQueryKey = (): QueryKey => ([...["core","asset-spare-parts","parts"]]);
export function createSparePartRoute(payload: TCreateSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateSparePartRouteResponse>> {
  return axiosClientV3.post(TCreateSparePartRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetSparePartRoutePath = '/core/asset-spare-parts/parts/{sparePartId}';
export type TGetSparePartRoutePath = paths['/core/asset-spare-parts/parts/{sparePartId}']['get']['parameters']['path'];
export type TGetSparePartRoutePayload = TApiPayload<TGetSparePartRoutePath, undefined, undefined>;
export type TGetSparePartRouteResponse200 = paths['/core/asset-spare-parts/parts/{sparePartId}']['get']['responses']['200']['content']['application/json'];
export type TGetSparePartRouteResponse = TGetSparePartRouteResponse200;
export const getSparePartRouteQueryKey = (sparePartId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","parts"], sparePartId]);
export function getSparePartRoute(payload: TGetSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetSparePartRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetSparePartRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateSparePartRoutePath = '/core/asset-spare-parts/parts/{sparePartId}';
export type TUpdateSparePartRoutePath = paths['/core/asset-spare-parts/parts/{sparePartId}']['put']['parameters']['path'];
export type TUpdateSparePartRouteBodyParams = paths['/core/asset-spare-parts/parts/{sparePartId}']['put']['requestBody']['content']['application/json'];
export type TUpdateSparePartRoutePayload = TApiPayload<TUpdateSparePartRoutePath, undefined, TUpdateSparePartRouteBodyParams>;
export type TUpdateSparePartRouteResponse200 = paths['/core/asset-spare-parts/parts/{sparePartId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateSparePartRouteResponse = TUpdateSparePartRouteResponse200;
export const updateSparePartRouteQueryKey = (sparePartId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","parts"], sparePartId]);
export function updateSparePartRoute(payload: TUpdateSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateSparePartRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateSparePartRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteSparePartRoutePath = '/core/asset-spare-parts/parts/{sparePartId}';
export type TDeleteSparePartRoutePath = paths['/core/asset-spare-parts/parts/{sparePartId}']['delete']['parameters']['path'];
export type TDeleteSparePartRoutePayload = TApiPayload<TDeleteSparePartRoutePath, undefined, undefined>;
export type TDeleteSparePartRouteResponse200 = paths['/core/asset-spare-parts/parts/{sparePartId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteSparePartRouteResponse = TDeleteSparePartRouteResponse200;
export const deleteSparePartRouteQueryKey = (sparePartId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","parts"], sparePartId]);
export function deleteSparePartRoute(payload: TDeleteSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteSparePartRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteSparePartRoutePath, payload.path), { signal: abortSignal });
}

export const TFindSparePartManufacturesRoutePath = '/core/asset-spare-parts/manufacturers';
export type TFindSparePartManufacturesRouteQuery = paths['/core/asset-spare-parts/manufacturers']['get']['parameters']['query'];
export type TFindSparePartManufacturesRoutePayload = TApiPayload<undefined, TFindSparePartManufacturesRouteQuery, undefined>;
export type TFindSparePartManufacturesRouteResponse200 = paths['/core/asset-spare-parts/manufacturers']['get']['responses']['200']['content']['application/json'];
export type TFindSparePartManufacturesRouteResponse = TFindSparePartManufacturesRouteResponse200;
export const findSparePartManufacturesRouteQueryKey = (query?: TFindSparePartManufacturesRouteQuery): QueryKey => ([...["core","asset-spare-parts","manufacturers"], ...(query ? [query] : [])]);
export function findSparePartManufacturesRoute(payload: TFindSparePartManufacturesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindSparePartManufacturesRouteResponse>> {
  return axiosClientV3.get(TFindSparePartManufacturesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateSparePartManufacturerRoutePath = '/core/asset-spare-parts/manufacturers';
export type TCreateSparePartManufacturerRouteBodyParams = paths['/core/asset-spare-parts/manufacturers']['post']['requestBody']['content']['application/json'];
export type TCreateSparePartManufacturerRoutePayload = TApiPayload<undefined, undefined, TCreateSparePartManufacturerRouteBodyParams>;
export type TCreateSparePartManufacturerRouteResponse200 = paths['/core/asset-spare-parts/manufacturers']['post']['responses']['200']['content']['application/json'];
export type TCreateSparePartManufacturerRouteResponse = TCreateSparePartManufacturerRouteResponse200;
export const createSparePartManufacturerRouteQueryKey = (): QueryKey => ([...["core","asset-spare-parts","manufacturers"]]);
export function createSparePartManufacturerRoute(payload: TCreateSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateSparePartManufacturerRouteResponse>> {
  return axiosClientV3.post(TCreateSparePartManufacturerRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetSparePartManufacturerRoutePath = '/core/asset-spare-parts/manufacturers/{manufacturerId}';
export type TGetSparePartManufacturerRoutePath = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['get']['parameters']['path'];
export type TGetSparePartManufacturerRoutePayload = TApiPayload<TGetSparePartManufacturerRoutePath, undefined, undefined>;
export type TGetSparePartManufacturerRouteResponse200 = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['get']['responses']['200']['content']['application/json'];
export type TGetSparePartManufacturerRouteResponse = TGetSparePartManufacturerRouteResponse200;
export const getSparePartManufacturerRouteQueryKey = (manufacturerId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","manufacturers"], manufacturerId]);
export function getSparePartManufacturerRoute(payload: TGetSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetSparePartManufacturerRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetSparePartManufacturerRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateSparePartManufacturerRoutePath = '/core/asset-spare-parts/manufacturers/{manufacturerId}';
export type TUpdateSparePartManufacturerRoutePath = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['put']['parameters']['path'];
export type TUpdateSparePartManufacturerRouteBodyParams = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['put']['requestBody']['content']['application/json'];
export type TUpdateSparePartManufacturerRoutePayload = TApiPayload<TUpdateSparePartManufacturerRoutePath, undefined, TUpdateSparePartManufacturerRouteBodyParams>;
export type TUpdateSparePartManufacturerRouteResponse200 = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateSparePartManufacturerRouteResponse = TUpdateSparePartManufacturerRouteResponse200;
export const updateSparePartManufacturerRouteQueryKey = (manufacturerId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","manufacturers"], manufacturerId]);
export function updateSparePartManufacturerRoute(payload: TUpdateSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateSparePartManufacturerRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateSparePartManufacturerRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteSparePartManufacturerRoutePath = '/core/asset-spare-parts/manufacturers/{manufacturerId}';
export type TDeleteSparePartManufacturerRoutePath = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['delete']['parameters']['path'];
export type TDeleteSparePartManufacturerRoutePayload = TApiPayload<TDeleteSparePartManufacturerRoutePath, undefined, undefined>;
export type TDeleteSparePartManufacturerRouteResponse200 = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteSparePartManufacturerRouteResponse = TDeleteSparePartManufacturerRouteResponse200;
export const deleteSparePartManufacturerRouteQueryKey = (manufacturerId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","manufacturers"], manufacturerId]);
export function deleteSparePartManufacturerRoute(payload: TDeleteSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteSparePartManufacturerRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteSparePartManufacturerRoutePath, payload.path), { signal: abortSignal });
}

export const TGetAssetTreePath = '/core/asset-tree';
export type TGetAssetTreeQuery = paths['/core/asset-tree']['get']['parameters']['query'];
export type TGetAssetTreePayload = TApiPayload<undefined, TGetAssetTreeQuery, undefined>;
export type TGetAssetTreeResponse200 = paths['/core/asset-tree']['get']['responses']['200']['content']['application/json'];
export type TGetAssetTreeResponse = TGetAssetTreeResponse200;
export const getAssetTreeQueryKey = (query?: TGetAssetTreeQuery): QueryKey => ([...["core","asset-tree"], ...(query ? [query] : [])]);
export function getAssetTree(payload: TGetAssetTreePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetTreeResponse>> {
  return axiosClientV3.get(TGetAssetTreePath, { signal: abortSignal, params: payload.query });
}

export const TUpdateAssetTreeRelativePositionsPath = '/core/asset-tree';
export type TUpdateAssetTreeRelativePositionsBodyParams = paths['/core/asset-tree']['put']['requestBody']['content']['application/json'];
export type TUpdateAssetTreeRelativePositionsPayload = TApiPayload<undefined, undefined, TUpdateAssetTreeRelativePositionsBodyParams>;
export type TUpdateAssetTreeRelativePositionsResponse200 = paths['/core/asset-tree']['put']['responses']['200']['content']['application/json'];
export type TUpdateAssetTreeRelativePositionsResponse = TUpdateAssetTreeRelativePositionsResponse200;
export const updateAssetTreeRelativePositionsQueryKey = (): QueryKey => ([...["core","asset-tree"]]);
export function updateAssetTreeRelativePositions(payload: TUpdateAssetTreeRelativePositionsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAssetTreeRelativePositionsResponse>> {
  return axiosClientV3.put(TUpdateAssetTreeRelativePositionsPath, payload.bodyParams, { signal: abortSignal });
}

export const TCreateTimeseriesNoteRoutePath = '/core/data/timeseries/notes';
export type TCreateTimeseriesNoteRouteBodyParams = paths['/core/data/timeseries/notes']['post']['requestBody']['content']['application/json'];
export type TCreateTimeseriesNoteRoutePayload = TApiPayload<undefined, undefined, TCreateTimeseriesNoteRouteBodyParams>;
export type TCreateTimeseriesNoteRouteResponse200 = paths['/core/data/timeseries/notes']['post']['responses']['200']['content']['application/json'];
export type TCreateTimeseriesNoteRouteResponse = TCreateTimeseriesNoteRouteResponse200;
export const createTimeseriesNoteRouteQueryKey = (): QueryKey => ([...["core","data","timeseries","notes"]]);
export function createTimeseriesNoteRoute(payload: TCreateTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateTimeseriesNoteRouteResponse>> {
  return axiosClientV3.post(TCreateTimeseriesNoteRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TFindTimeseriesNotesRoutePath = '/core/data/timeseries/notes';
export type TFindTimeseriesNotesRouteQuery = paths['/core/data/timeseries/notes']['get']['parameters']['query'];
export type TFindTimeseriesNotesRoutePayload = TApiPayload<undefined, TFindTimeseriesNotesRouteQuery, undefined>;
export type TFindTimeseriesNotesRouteResponse200 = paths['/core/data/timeseries/notes']['get']['responses']['200']['content']['application/json'];
export type TFindTimeseriesNotesRouteResponse = TFindTimeseriesNotesRouteResponse200;
export const findTimeseriesNotesRouteQueryKey = (query?: TFindTimeseriesNotesRouteQuery): QueryKey => ([...["core","data","timeseries","notes"], ...(query ? [query] : [])]);
export function findTimeseriesNotesRoute(payload: TFindTimeseriesNotesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindTimeseriesNotesRouteResponse>> {
  return axiosClientV3.get(TFindTimeseriesNotesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TGetTimeseriesNoteRoutePath = '/core/data/timeseries/notes/{timeseriesNoteId}';
export type TGetTimeseriesNoteRoutePath = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['get']['parameters']['path'];
export type TGetTimeseriesNoteRoutePayload = TApiPayload<TGetTimeseriesNoteRoutePath, undefined, undefined>;
export type TGetTimeseriesNoteRouteResponse200 = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['get']['responses']['200']['content']['application/json'];
export type TGetTimeseriesNoteRouteResponse = TGetTimeseriesNoteRouteResponse200;
export const getTimeseriesNoteRouteQueryKey = (timeseriesNoteId: QueryKey[number]): QueryKey => ([...["core","data","timeseries","notes"], timeseriesNoteId]);
export function getTimeseriesNoteRoute(payload: TGetTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetTimeseriesNoteRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetTimeseriesNoteRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateTimeseriesNoteRoutePath = '/core/data/timeseries/notes/{timeseriesNoteId}';
export type TUpdateTimeseriesNoteRoutePath = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['put']['parameters']['path'];
export type TUpdateTimeseriesNoteRouteBodyParams = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['put']['requestBody']['content']['application/json'];
export type TUpdateTimeseriesNoteRoutePayload = TApiPayload<TUpdateTimeseriesNoteRoutePath, undefined, TUpdateTimeseriesNoteRouteBodyParams>;
export type TUpdateTimeseriesNoteRouteResponse200 = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateTimeseriesNoteRouteResponse = TUpdateTimeseriesNoteRouteResponse200;
export const updateTimeseriesNoteRouteQueryKey = (timeseriesNoteId: QueryKey[number]): QueryKey => ([...["core","data","timeseries","notes"], timeseriesNoteId]);
export function updateTimeseriesNoteRoute(payload: TUpdateTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateTimeseriesNoteRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateTimeseriesNoteRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteTimeseriesNoteRoutePath = '/core/data/timeseries/notes/{timeseriesNoteId}';
export type TDeleteTimeseriesNoteRoutePath = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['delete']['parameters']['path'];
export type TDeleteTimeseriesNoteRoutePayload = TApiPayload<TDeleteTimeseriesNoteRoutePath, undefined, undefined>;
export type TDeleteTimeseriesNoteRouteResponse200 = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteTimeseriesNoteRouteResponse = TDeleteTimeseriesNoteRouteResponse200;
export const deleteTimeseriesNoteRouteQueryKey = (timeseriesNoteId: QueryKey[number]): QueryKey => ([...["core","data","timeseries","notes"], timeseriesNoteId]);
export function deleteTimeseriesNoteRoute(payload: TDeleteTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteTimeseriesNoteRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteTimeseriesNoteRoutePath, payload.path), { signal: abortSignal });
}

export const TGetTimeseriesPath = '/core/data/timeseries/{dataId}';
export type TGetTimeseriesQuery = paths['/core/data/timeseries/{dataId}']['get']['parameters']['query'];
export type TGetTimeseriesPath = paths['/core/data/timeseries/{dataId}']['get']['parameters']['path'];
export type TGetTimeseriesPayload = TApiPayload<TGetTimeseriesPath, TGetTimeseriesQuery, undefined>;
export type TGetTimeseriesResponse200 = paths['/core/data/timeseries/{dataId}']['get']['responses']['200']['content']['application/json'];
export type TGetTimeseriesResponse = TGetTimeseriesResponse200;
export const getTimeseriesQueryKey = (dataId: QueryKey[number], query?: TGetTimeseriesQuery): QueryKey => ([...["core","data","timeseries"], dataId, ...(query ? [query] : [])]);
export function getTimeseries(payload: TGetTimeseriesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetTimeseriesResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetTimeseriesPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TGetJobValuesPath = '/core/data/job-values/{dataId}';
export type TGetJobValuesQuery = paths['/core/data/job-values/{dataId}']['get']['parameters']['query'];
export type TGetJobValuesPath = paths['/core/data/job-values/{dataId}']['get']['parameters']['path'];
export type TGetJobValuesPayload = TApiPayload<TGetJobValuesPath, TGetJobValuesQuery, undefined>;
export type TGetJobValuesResponse200 = paths['/core/data/job-values/{dataId}']['get']['responses']['200']['content']['application/json'];
export type TGetJobValuesResponse = TGetJobValuesResponse200;
export const getJobValuesQueryKey = (dataId: QueryKey[number], query?: TGetJobValuesQuery): QueryKey => ([...["core","data","job-values"], dataId, ...(query ? [query] : [])]);
export function getJobValues(payload: TGetJobValuesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobValuesResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetJobValuesPath, payload.path), { signal: abortSignal, params: payload.query });
}

export const TGetAlarmsPath = '/core/data/alarms';
export type TGetAlarmsQuery = paths['/core/data/alarms']['get']['parameters']['query'];
export type TGetAlarmsPayload = TApiPayload<undefined, TGetAlarmsQuery, undefined>;
export type TGetAlarmsResponse200 = paths['/core/data/alarms']['get']['responses']['200']['content']['application/json'];
export type TGetAlarmsResponse = TGetAlarmsResponse200;
export const getAlarmsQueryKey = (query?: TGetAlarmsQuery): QueryKey => ([...["core","data","alarms"], ...(query ? [query] : [])]);
export function getAlarms(payload: TGetAlarmsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAlarmsResponse>> {
  return axiosClientV3.get(TGetAlarmsPath, { signal: abortSignal, params: payload.query });
}

export const TFindDataSourcesRoutePath = '/core/data-sources/data-sources';
export type TFindDataSourcesRouteQuery = paths['/core/data-sources/data-sources']['get']['parameters']['query'];
export type TFindDataSourcesRoutePayload = TApiPayload<undefined, TFindDataSourcesRouteQuery, undefined>;
export type TFindDataSourcesRouteResponse200 = paths['/core/data-sources/data-sources']['get']['responses']['200']['content']['application/json'];
export type TFindDataSourcesRouteResponse = TFindDataSourcesRouteResponse200;
export const findDataSourcesRouteQueryKey = (query?: TFindDataSourcesRouteQuery): QueryKey => ([...["core","data-sources","data-sources"], ...(query ? [query] : [])]);
export function findDataSourcesRoute(payload: TFindDataSourcesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindDataSourcesRouteResponse>> {
  return axiosClientV3.get(TFindDataSourcesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateDataSourceRoutePath = '/core/data-sources/data-sources';
export type TCreateDataSourceRouteBodyParams = paths['/core/data-sources/data-sources']['post']['requestBody']['content']['application/json'];
export type TCreateDataSourceRoutePayload = TApiPayload<undefined, undefined, TCreateDataSourceRouteBodyParams>;
export type TCreateDataSourceRouteResponse200 = paths['/core/data-sources/data-sources']['post']['responses']['200']['content']['application/json'];
export type TCreateDataSourceRouteResponse = TCreateDataSourceRouteResponse200;
export const createDataSourceRouteQueryKey = (): QueryKey => ([...["core","data-sources","data-sources"]]);
export function createDataSourceRoute(payload: TCreateDataSourceRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateDataSourceRouteResponse>> {
  return axiosClientV3.post(TCreateDataSourceRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetDataSourceRoutePath = '/core/data-sources/data-sources/{dataSourceId}';
export type TGetDataSourceRoutePath = paths['/core/data-sources/data-sources/{dataSourceId}']['get']['parameters']['path'];
export type TGetDataSourceRoutePayload = TApiPayload<TGetDataSourceRoutePath, undefined, undefined>;
export type TGetDataSourceRouteResponse200 = paths['/core/data-sources/data-sources/{dataSourceId}']['get']['responses']['200']['content']['application/json'];
export type TGetDataSourceRouteResponse = TGetDataSourceRouteResponse200;
export const getDataSourceRouteQueryKey = (dataSourceId: QueryKey[number]): QueryKey => ([...["core","data-sources","data-sources"], dataSourceId]);
export function getDataSourceRoute(payload: TGetDataSourceRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDataSourceRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetDataSourceRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateDataSourceRoutePath = '/core/data-sources/data-sources/{dataSourceId}';
export type TUpdateDataSourceRoutePath = paths['/core/data-sources/data-sources/{dataSourceId}']['put']['parameters']['path'];
export type TUpdateDataSourceRouteBodyParams = paths['/core/data-sources/data-sources/{dataSourceId}']['put']['requestBody']['content']['application/json'];
export type TUpdateDataSourceRoutePayload = TApiPayload<TUpdateDataSourceRoutePath, undefined, TUpdateDataSourceRouteBodyParams>;
export type TUpdateDataSourceRouteResponse200 = paths['/core/data-sources/data-sources/{dataSourceId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateDataSourceRouteResponse = TUpdateDataSourceRouteResponse200;
export const updateDataSourceRouteQueryKey = (dataSourceId: QueryKey[number]): QueryKey => ([...["core","data-sources","data-sources"], dataSourceId]);
export function updateDataSourceRoute(payload: TUpdateDataSourceRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateDataSourceRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateDataSourceRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetDataSourceTreeRoutePath = '/core/data-sources/tree';
export type TGetDataSourceTreeRouteQuery = paths['/core/data-sources/tree']['get']['parameters']['query'];
export type TGetDataSourceTreeRoutePayload = TApiPayload<undefined, TGetDataSourceTreeRouteQuery, undefined>;
export type TGetDataSourceTreeRouteResponse200 = paths['/core/data-sources/tree']['get']['responses']['200']['content']['application/json'];
export type TGetDataSourceTreeRouteResponse = TGetDataSourceTreeRouteResponse200;
export const getDataSourceTreeRouteQueryKey = (query?: TGetDataSourceTreeRouteQuery): QueryKey => ([...["core","data-sources","tree"], ...(query ? [query] : [])]);
export function getDataSourceTreeRoute(payload: TGetDataSourceTreeRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDataSourceTreeRouteResponse>> {
  return axiosClientV3.get(TGetDataSourceTreeRoutePath, { signal: abortSignal, params: payload.query });
}

export const TFindMaterialRoutePath = '/core/material/materials';
export type TFindMaterialRouteQuery = paths['/core/material/materials']['get']['parameters']['query'];
export type TFindMaterialRoutePayload = TApiPayload<undefined, TFindMaterialRouteQuery, undefined>;
export type TFindMaterialRouteResponse200 = paths['/core/material/materials']['get']['responses']['200']['content']['application/json'];
export type TFindMaterialRouteResponse = TFindMaterialRouteResponse200;
export const findMaterialRouteQueryKey = (query?: TFindMaterialRouteQuery): QueryKey => ([...["core","material","materials"], ...(query ? [query] : [])]);
export function findMaterialRoute(payload: TFindMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindMaterialRouteResponse>> {
  return axiosClientV3.get(TFindMaterialRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateMaterialRoutePath = '/core/material/materials';
export type TCreateMaterialRouteBodyParams = paths['/core/material/materials']['post']['requestBody']['content']['application/json'];
export type TCreateMaterialRoutePayload = TApiPayload<undefined, undefined, TCreateMaterialRouteBodyParams>;
export type TCreateMaterialRouteResponse200 = paths['/core/material/materials']['post']['responses']['200']['content']['application/json'];
export type TCreateMaterialRouteResponse = TCreateMaterialRouteResponse200;
export const createMaterialRouteQueryKey = (): QueryKey => ([...["core","material","materials"]]);
export function createMaterialRoute(payload: TCreateMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMaterialRouteResponse>> {
  return axiosClientV3.post(TCreateMaterialRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TDraftMaterialRoutePath = '/core/material/materials/draft';
export type TDraftMaterialRouteBodyParams = paths['/core/material/materials/draft']['put']['requestBody']['content']['application/json'];
export type TDraftMaterialRoutePayload = TApiPayload<undefined, undefined, TDraftMaterialRouteBodyParams>;
export type TDraftMaterialRouteResponse200 = paths['/core/material/materials/draft']['put']['responses']['200']['content']['application/json'];
export type TDraftMaterialRouteResponse = TDraftMaterialRouteResponse200;
export const draftMaterialRouteQueryKey = (): QueryKey => ([...["core","material","materials","draft"]]);
export function draftMaterialRoute(payload: TDraftMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDraftMaterialRouteResponse>> {
  return axiosClientV3.put(TDraftMaterialRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetMaterialRoutePath = '/core/material/materials/{materialId}';
export type TGetMaterialRoutePath = paths['/core/material/materials/{materialId}']['get']['parameters']['path'];
export type TGetMaterialRoutePayload = TApiPayload<TGetMaterialRoutePath, undefined, undefined>;
export type TGetMaterialRouteResponse200 = paths['/core/material/materials/{materialId}']['get']['responses']['200']['content']['application/json'];
export type TGetMaterialRouteResponse = TGetMaterialRouteResponse200;
export const getMaterialRouteQueryKey = (materialId: QueryKey[number]): QueryKey => ([...["core","material","materials"], materialId]);
export function getMaterialRoute(payload: TGetMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMaterialRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetMaterialRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateMaterialRoutePath = '/core/material/materials/{materialId}';
export type TUpdateMaterialRoutePath = paths['/core/material/materials/{materialId}']['put']['parameters']['path'];
export type TUpdateMaterialRouteBodyParams = paths['/core/material/materials/{materialId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMaterialRoutePayload = TApiPayload<TUpdateMaterialRoutePath, undefined, TUpdateMaterialRouteBodyParams>;
export type TUpdateMaterialRouteResponse200 = paths['/core/material/materials/{materialId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateMaterialRouteResponse = TUpdateMaterialRouteResponse200;
export const updateMaterialRouteQueryKey = (materialId: QueryKey[number]): QueryKey => ([...["core","material","materials"], materialId]);
export function updateMaterialRoute(payload: TUpdateMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMaterialRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateMaterialRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteMaterialRoutePath = '/core/material/materials/{materialId}';
export type TDeleteMaterialRoutePath = paths['/core/material/materials/{materialId}']['delete']['parameters']['path'];
export type TDeleteMaterialRoutePayload = TApiPayload<TDeleteMaterialRoutePath, undefined, undefined>;
export type TDeleteMaterialRouteResponse200 = paths['/core/material/materials/{materialId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteMaterialRouteResponse = TDeleteMaterialRouteResponse200;
export const deleteMaterialRouteQueryKey = (materialId: QueryKey[number]): QueryKey => ([...["core","material","materials"], materialId]);
export function deleteMaterialRoute(payload: TDeleteMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteMaterialRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteMaterialRoutePath, payload.path), { signal: abortSignal });
}

export const TFindMaterialGroupsRoutePath = '/core/material/groups';
export type TFindMaterialGroupsRouteQuery = paths['/core/material/groups']['get']['parameters']['query'];
export type TFindMaterialGroupsRoutePayload = TApiPayload<undefined, TFindMaterialGroupsRouteQuery, undefined>;
export type TFindMaterialGroupsRouteResponse200 = paths['/core/material/groups']['get']['responses']['200']['content']['application/json'];
export type TFindMaterialGroupsRouteResponse = TFindMaterialGroupsRouteResponse200;
export const findMaterialGroupsRouteQueryKey = (query?: TFindMaterialGroupsRouteQuery): QueryKey => ([...["core","material","groups"], ...(query ? [query] : [])]);
export function findMaterialGroupsRoute(payload: TFindMaterialGroupsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindMaterialGroupsRouteResponse>> {
  return axiosClientV3.get(TFindMaterialGroupsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateMaterialGroupRoutePath = '/core/material/groups';
export type TCreateMaterialGroupRouteBodyParams = paths['/core/material/groups']['post']['requestBody']['content']['application/json'];
export type TCreateMaterialGroupRoutePayload = TApiPayload<undefined, undefined, TCreateMaterialGroupRouteBodyParams>;
export type TCreateMaterialGroupRouteResponse200 = paths['/core/material/groups']['post']['responses']['200']['content']['application/json'];
export type TCreateMaterialGroupRouteResponse = TCreateMaterialGroupRouteResponse200;
export const createMaterialGroupRouteQueryKey = (): QueryKey => ([...["core","material","groups"]]);
export function createMaterialGroupRoute(payload: TCreateMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMaterialGroupRouteResponse>> {
  return axiosClientV3.post(TCreateMaterialGroupRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetMaterialGroupRoutePath = '/core/material/groups/{materialGroupId}';
export type TGetMaterialGroupRoutePath = paths['/core/material/groups/{materialGroupId}']['get']['parameters']['path'];
export type TGetMaterialGroupRoutePayload = TApiPayload<TGetMaterialGroupRoutePath, undefined, undefined>;
export type TGetMaterialGroupRouteResponse200 = paths['/core/material/groups/{materialGroupId}']['get']['responses']['200']['content']['application/json'];
export type TGetMaterialGroupRouteResponse = TGetMaterialGroupRouteResponse200;
export const getMaterialGroupRouteQueryKey = (materialGroupId: QueryKey[number]): QueryKey => ([...["core","material","groups"], materialGroupId]);
export function getMaterialGroupRoute(payload: TGetMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMaterialGroupRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetMaterialGroupRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateMaterialGroupRoutePath = '/core/material/groups/{materialGroupId}';
export type TUpdateMaterialGroupRoutePath = paths['/core/material/groups/{materialGroupId}']['put']['parameters']['path'];
export type TUpdateMaterialGroupRouteBodyParams = paths['/core/material/groups/{materialGroupId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMaterialGroupRoutePayload = TApiPayload<TUpdateMaterialGroupRoutePath, undefined, TUpdateMaterialGroupRouteBodyParams>;
export type TUpdateMaterialGroupRouteResponse200 = paths['/core/material/groups/{materialGroupId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateMaterialGroupRouteResponse = TUpdateMaterialGroupRouteResponse200;
export const updateMaterialGroupRouteQueryKey = (materialGroupId: QueryKey[number]): QueryKey => ([...["core","material","groups"], materialGroupId]);
export function updateMaterialGroupRoute(payload: TUpdateMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMaterialGroupRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateMaterialGroupRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteMaterialGroupRoutePath = '/core/material/groups/{materialGroupId}';
export type TDeleteMaterialGroupRoutePath = paths['/core/material/groups/{materialGroupId}']['delete']['parameters']['path'];
export type TDeleteMaterialGroupRoutePayload = TApiPayload<TDeleteMaterialGroupRoutePath, undefined, undefined>;
export type TDeleteMaterialGroupRouteResponse200 = paths['/core/material/groups/{materialGroupId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteMaterialGroupRouteResponse = TDeleteMaterialGroupRouteResponse200;
export const deleteMaterialGroupRouteQueryKey = (materialGroupId: QueryKey[number]): QueryKey => ([...["core","material","groups"], materialGroupId]);
export function deleteMaterialGroupRoute(payload: TDeleteMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteMaterialGroupRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteMaterialGroupRoutePath, payload.path), { signal: abortSignal });
}

export const TGetMaterialSystemsRoutePath = '/core/material/naming-systems';
export type TGetMaterialSystemsRouteResponse200 = paths['/core/material/naming-systems']['get']['responses']['200']['content']['application/json'];
export type TGetMaterialSystemsRouteResponse = TGetMaterialSystemsRouteResponse200;
export const getMaterialSystemsRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems"]]);
export function getMaterialSystemsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMaterialSystemsRouteResponse>> {
  return axiosClientV3.get(TGetMaterialSystemsRoutePath, { signal: abortSignal });
}

export const TCreateMaterialSystemRoutePath = '/core/material/naming-systems';
export type TCreateMaterialSystemRouteBodyParams = paths['/core/material/naming-systems']['post']['requestBody']['content']['application/json'];
export type TCreateMaterialSystemRoutePayload = TApiPayload<undefined, undefined, TCreateMaterialSystemRouteBodyParams>;
export type TCreateMaterialSystemRouteResponse200 = paths['/core/material/naming-systems']['post']['responses']['200']['content']['application/json'];
export type TCreateMaterialSystemRouteResponse = TCreateMaterialSystemRouteResponse200;
export const createMaterialSystemRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems"]]);
export function createMaterialSystemRoute(payload: TCreateMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMaterialSystemRouteResponse>> {
  return axiosClientV3.post(TCreateMaterialSystemRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetDefaultMaterialSystemRoutePath = '/core/material/naming-systems/default';
export type TGetDefaultMaterialSystemRouteResponse200 = paths['/core/material/naming-systems/default']['get']['responses']['200']['content']['application/json'];
export type TGetDefaultMaterialSystemRouteResponse = TGetDefaultMaterialSystemRouteResponse200;
export const getDefaultMaterialSystemRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems","default"]]);
export function getDefaultMaterialSystemRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDefaultMaterialSystemRouteResponse>> {
  return axiosClientV3.get(TGetDefaultMaterialSystemRoutePath, { signal: abortSignal });
}

export const TSetDefaultMaterialSystemRoutePath = '/core/material/naming-systems/default';
export type TSetDefaultMaterialSystemRouteBodyParams = paths['/core/material/naming-systems/default']['put']['requestBody']['content']['application/json'];
export type TSetDefaultMaterialSystemRoutePayload = TApiPayload<undefined, undefined, TSetDefaultMaterialSystemRouteBodyParams>;
export type TSetDefaultMaterialSystemRouteResponse200 = paths['/core/material/naming-systems/default']['put']['responses']['200']['content']['application/json'];
export type TSetDefaultMaterialSystemRouteResponse = TSetDefaultMaterialSystemRouteResponse200;
export const setDefaultMaterialSystemRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems","default"]]);
export function setDefaultMaterialSystemRoute(payload: TSetDefaultMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetDefaultMaterialSystemRouteResponse>> {
  return axiosClientV3.put(TSetDefaultMaterialSystemRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TUpdateMaterialSystemRoutePath = '/core/material/naming-systems/{materialNamingSystemId}';
export type TUpdateMaterialSystemRoutePath = paths['/core/material/naming-systems/{materialNamingSystemId}']['put']['parameters']['path'];
export type TUpdateMaterialSystemRouteBodyParams = paths['/core/material/naming-systems/{materialNamingSystemId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMaterialSystemRoutePayload = TApiPayload<TUpdateMaterialSystemRoutePath, undefined, TUpdateMaterialSystemRouteBodyParams>;
export type TUpdateMaterialSystemRouteResponse200 = paths['/core/material/naming-systems/{materialNamingSystemId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateMaterialSystemRouteResponse = TUpdateMaterialSystemRouteResponse200;
export const updateMaterialSystemRouteQueryKey = (materialNamingSystemId: QueryKey[number]): QueryKey => ([...["core","material","naming-systems"], materialNamingSystemId]);
export function updateMaterialSystemRoute(payload: TUpdateMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMaterialSystemRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateMaterialSystemRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteMaterialSystemRoutePath = '/core/material/naming-systems/{materialNamingSystemId}';
export type TDeleteMaterialSystemRoutePath = paths['/core/material/naming-systems/{materialNamingSystemId}']['delete']['parameters']['path'];
export type TDeleteMaterialSystemRoutePayload = TApiPayload<TDeleteMaterialSystemRoutePath, undefined, undefined>;
export type TDeleteMaterialSystemRouteResponse200 = paths['/core/material/naming-systems/{materialNamingSystemId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteMaterialSystemRouteResponse = TDeleteMaterialSystemRouteResponse200;
export const deleteMaterialSystemRouteQueryKey = (materialNamingSystemId: QueryKey[number]): QueryKey => ([...["core","material","naming-systems"], materialNamingSystemId]);
export function deleteMaterialSystemRoute(payload: TDeleteMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteMaterialSystemRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteMaterialSystemRoutePath, payload.path), { signal: abortSignal });
}

export const TFindCalendarEventsRoutePath = '/core/operation/calendar-events';
export type TFindCalendarEventsRouteQuery = paths['/core/operation/calendar-events']['get']['parameters']['query'];
export type TFindCalendarEventsRoutePayload = TApiPayload<undefined, TFindCalendarEventsRouteQuery, undefined>;
export type TFindCalendarEventsRouteResponse200 = paths['/core/operation/calendar-events']['get']['responses']['200']['content']['application/json'];
export type TFindCalendarEventsRouteResponse = TFindCalendarEventsRouteResponse200;
export const findCalendarEventsRouteQueryKey = (query?: TFindCalendarEventsRouteQuery): QueryKey => ([...["core","operation","calendar-events"], ...(query ? [query] : [])]);
export function findCalendarEventsRoute(payload: TFindCalendarEventsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindCalendarEventsRouteResponse>> {
  return axiosClientV3.get(TFindCalendarEventsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateCalendarEventRoutePath = '/core/operation/calendar-events';
export type TCreateCalendarEventRouteBodyParams = paths['/core/operation/calendar-events']['post']['requestBody']['content']['application/json'];
export type TCreateCalendarEventRoutePayload = TApiPayload<undefined, undefined, TCreateCalendarEventRouteBodyParams>;
export type TCreateCalendarEventRouteResponse201 = paths['/core/operation/calendar-events']['post']['responses']['201']['content']['application/json'];
export type TCreateCalendarEventRouteResponse = TCreateCalendarEventRouteResponse201;
export const createCalendarEventRouteQueryKey = (): QueryKey => ([...["core","operation","calendar-events"]]);
export function createCalendarEventRoute(payload: TCreateCalendarEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateCalendarEventRouteResponse>> {
  return axiosClientV3.post(TCreateCalendarEventRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetOperationEventRoutePath = '/core/operation/calendar-events/{operationEventId}';
export type TGetOperationEventRoutePath = paths['/core/operation/calendar-events/{operationEventId}']['get']['parameters']['path'];
export type TGetOperationEventRoutePayload = TApiPayload<TGetOperationEventRoutePath, undefined, undefined>;
export type TGetOperationEventRouteResponse200 = paths['/core/operation/calendar-events/{operationEventId}']['get']['responses']['200']['content']['application/json'];
export type TGetOperationEventRouteResponse = TGetOperationEventRouteResponse200;
export const getOperationEventRouteQueryKey = (operationEventId: QueryKey[number]): QueryKey => ([...["core","operation","calendar-events"], operationEventId]);
export function getOperationEventRoute(payload: TGetOperationEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetOperationEventRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetOperationEventRoutePath, payload.path), { signal: abortSignal });
}

export const TDeleteOperationEventRoutePath = '/core/operation/calendar-events/{operationEventId}';
export type TDeleteOperationEventRoutePath = paths['/core/operation/calendar-events/{operationEventId}']['delete']['parameters']['path'];
export type TDeleteOperationEventRoutePayload = TApiPayload<TDeleteOperationEventRoutePath, undefined, undefined>;
export type TDeleteOperationEventRouteResponse200 = paths['/core/operation/calendar-events/{operationEventId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteOperationEventRouteResponse = TDeleteOperationEventRouteResponse200;
export const deleteOperationEventRouteQueryKey = (operationEventId: QueryKey[number]): QueryKey => ([...["core","operation","calendar-events"], operationEventId]);
export function deleteOperationEventRoute(payload: TDeleteOperationEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteOperationEventRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteOperationEventRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateOperationEventRoutePath = '/core/operation/calendar-events/{operationEventId}';
export type TUpdateOperationEventRoutePath = paths['/core/operation/calendar-events/{operationEventId}']['put']['parameters']['path'];
export type TUpdateOperationEventRouteBodyParams = paths['/core/operation/calendar-events/{operationEventId}']['put']['requestBody']['content']['application/json'];
export type TUpdateOperationEventRoutePayload = TApiPayload<TUpdateOperationEventRoutePath, undefined, TUpdateOperationEventRouteBodyParams>;
export type TUpdateOperationEventRouteResponse200 = paths['/core/operation/calendar-events/{operationEventId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateOperationEventRouteResponse = TUpdateOperationEventRouteResponse200;
export const updateOperationEventRouteQueryKey = (operationEventId: QueryKey[number]): QueryKey => ([...["core","operation","calendar-events"], operationEventId]);
export function updateOperationEventRoute(payload: TUpdateOperationEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateOperationEventRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateOperationEventRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TFindCustomersRoutePath = '/core/operation/customers';
export type TFindCustomersRouteQuery = paths['/core/operation/customers']['get']['parameters']['query'];
export type TFindCustomersRoutePayload = TApiPayload<undefined, TFindCustomersRouteQuery, undefined>;
export type TFindCustomersRouteResponse200 = paths['/core/operation/customers']['get']['responses']['200']['content']['application/json'];
export type TFindCustomersRouteResponse = TFindCustomersRouteResponse200;
export const findCustomersRouteQueryKey = (query?: TFindCustomersRouteQuery): QueryKey => ([...["core","operation","customers"], ...(query ? [query] : [])]);
export function findCustomersRoute(payload: TFindCustomersRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindCustomersRouteResponse>> {
  return axiosClientV3.get(TFindCustomersRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateCustomerRoutePath = '/core/operation/customers';
export type TCreateCustomerRouteBodyParams = paths['/core/operation/customers']['post']['requestBody']['content']['application/json'];
export type TCreateCustomerRoutePayload = TApiPayload<undefined, undefined, TCreateCustomerRouteBodyParams>;
export type TCreateCustomerRouteResponse200 = paths['/core/operation/customers']['post']['responses']['200']['content']['application/json'];
export type TCreateCustomerRouteResponse = TCreateCustomerRouteResponse200;
export const createCustomerRouteQueryKey = (): QueryKey => ([...["core","operation","customers"]]);
export function createCustomerRoute(payload: TCreateCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateCustomerRouteResponse>> {
  return axiosClientV3.post(TCreateCustomerRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetCustomerRoutePath = '/core/operation/customers/{customerId}';
export type TGetCustomerRoutePath = paths['/core/operation/customers/{customerId}']['get']['parameters']['path'];
export type TGetCustomerRoutePayload = TApiPayload<TGetCustomerRoutePath, undefined, undefined>;
export type TGetCustomerRouteResponse200 = paths['/core/operation/customers/{customerId}']['get']['responses']['200']['content']['application/json'];
export type TGetCustomerRouteResponse = TGetCustomerRouteResponse200;
export const getCustomerRouteQueryKey = (customerId: QueryKey[number]): QueryKey => ([...["core","operation","customers"], customerId]);
export function getCustomerRoute(payload: TGetCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCustomerRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetCustomerRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateCustomerRoutePath = '/core/operation/customers/{customerId}';
export type TUpdateCustomerRoutePath = paths['/core/operation/customers/{customerId}']['put']['parameters']['path'];
export type TUpdateCustomerRouteBodyParams = paths['/core/operation/customers/{customerId}']['put']['requestBody']['content']['application/json'];
export type TUpdateCustomerRoutePayload = TApiPayload<TUpdateCustomerRoutePath, undefined, TUpdateCustomerRouteBodyParams>;
export type TUpdateCustomerRouteResponse200 = paths['/core/operation/customers/{customerId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateCustomerRouteResponse = TUpdateCustomerRouteResponse200;
export const updateCustomerRouteQueryKey = (customerId: QueryKey[number]): QueryKey => ([...["core","operation","customers"], customerId]);
export function updateCustomerRoute(payload: TUpdateCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateCustomerRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateCustomerRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteCustomerRoutePath = '/core/operation/customers/{customerId}';
export type TDeleteCustomerRoutePath = paths['/core/operation/customers/{customerId}']['delete']['parameters']['path'];
export type TDeleteCustomerRoutePayload = TApiPayload<TDeleteCustomerRoutePath, undefined, undefined>;
export type TDeleteCustomerRouteResponse200 = paths['/core/operation/customers/{customerId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteCustomerRouteResponse = TDeleteCustomerRouteResponse200;
export const deleteCustomerRouteQueryKey = (customerId: QueryKey[number]): QueryKey => ([...["core","operation","customers"], customerId]);
export function deleteCustomerRoute(payload: TDeleteCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteCustomerRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteCustomerRoutePath, payload.path), { signal: abortSignal });
}

export const TFindJobsPath = '/core/operation/jobs';
export type TFindJobsQuery = paths['/core/operation/jobs']['get']['parameters']['query'];
export type TFindJobsPayload = TApiPayload<undefined, TFindJobsQuery, undefined>;
export type TFindJobsResponse200 = paths['/core/operation/jobs']['get']['responses']['200']['content']['application/json'];
export type TFindJobsResponse = TFindJobsResponse200;
export const findJobsQueryKey = (query?: TFindJobsQuery): QueryKey => ([...["core","operation","jobs"], ...(query ? [query] : [])]);
export function findJobs(payload: TFindJobsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindJobsResponse>> {
  return axiosClientV3.get(TFindJobsPath, { signal: abortSignal, params: payload.query });
}

export const TGetJobPath = '/core/operation/jobs/{jobId}';
export type TGetJobPath = paths['/core/operation/jobs/{jobId}']['get']['parameters']['path'];
export type TGetJobPayload = TApiPayload<TGetJobPath, undefined, undefined>;
export type TGetJobResponse200 = paths['/core/operation/jobs/{jobId}']['get']['responses']['200']['content']['application/json'];
export type TGetJobResponse = TGetJobResponse200;
export const getJobQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs"], jobId]);
export function getJob(payload: TGetJobPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetJobPath, payload.path), { signal: abortSignal });
}

export const TGetJobImagesRoutePath = '/core/operation/jobs/{jobId}/images';
export type TGetJobImagesRoutePath = paths['/core/operation/jobs/{jobId}/images']['get']['parameters']['path'];
export type TGetJobImagesRoutePayload = TApiPayload<TGetJobImagesRoutePath, undefined, undefined>;
export type TGetJobImagesRouteResponse200 = paths['/core/operation/jobs/{jobId}/images']['get']['responses']['200']['content']['application/json'];
export type TGetJobImagesRouteResponse = TGetJobImagesRouteResponse200;
export const getJobImagesRouteQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId]);
export function getJobImagesRoute(payload: TGetJobImagesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobImagesRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetJobImagesRoutePath, payload.path), { signal: abortSignal });
}

export const TCreateJobImageRoutePath = '/core/operation/jobs/{jobId}/images';
export type TCreateJobImageRoutePath = paths['/core/operation/jobs/{jobId}/images']['post']['parameters']['path'];
export type TCreateJobImageRouteBodyParams = paths['/core/operation/jobs/{jobId}/images']['post']['requestBody']['content']['application/json'];
export type TCreateJobImageRoutePayload = TApiPayload<TCreateJobImageRoutePath, undefined, TCreateJobImageRouteBodyParams>;
export type TCreateJobImageRouteResponse200 = paths['/core/operation/jobs/{jobId}/images']['post']['responses']['200']['content']['application/json'];
export type TCreateJobImageRouteResponse = TCreateJobImageRouteResponse200;
export const createJobImageRouteQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId]);
export function createJobImageRoute(payload: TCreateJobImageRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateJobImageRouteResponse>> {
  return axiosClientV3.post(interpolateUrl(TCreateJobImageRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TUpdateJobImageNameRoutePath = '/core/operation/jobs/{jobId}/images/{blobId}';
export type TUpdateJobImageNameRoutePath = paths['/core/operation/jobs/{jobId}/images/{blobId}']['put']['parameters']['path'];
export type TUpdateJobImageNameRouteBodyParams = paths['/core/operation/jobs/{jobId}/images/{blobId}']['put']['requestBody']['content']['application/json'];
export type TUpdateJobImageNameRoutePayload = TApiPayload<TUpdateJobImageNameRoutePath, undefined, TUpdateJobImageNameRouteBodyParams>;
export type TUpdateJobImageNameRouteResponse200 = paths['/core/operation/jobs/{jobId}/images/{blobId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateJobImageNameRouteResponse = TUpdateJobImageNameRouteResponse200;
export const updateJobImageNameRouteQueryKey = (jobId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId, blobId]);
export function updateJobImageNameRoute(payload: TUpdateJobImageNameRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateJobImageNameRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateJobImageNameRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteJobImageRoutePath = '/core/operation/jobs/{jobId}/images/{blobId}';
export type TDeleteJobImageRoutePath = paths['/core/operation/jobs/{jobId}/images/{blobId}']['delete']['parameters']['path'];
export type TDeleteJobImageRoutePayload = TApiPayload<TDeleteJobImageRoutePath, undefined, undefined>;
export type TDeleteJobImageRouteResponse200 = paths['/core/operation/jobs/{jobId}/images/{blobId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteJobImageRouteResponse204 = paths['/core/operation/jobs/{jobId}/images/{blobId}']['delete']['responses']['204']['content'];
export type TDeleteJobImageRouteResponse = TDeleteJobImageRouteResponse200 | TDeleteJobImageRouteResponse204;
export const deleteJobImageRouteQueryKey = (jobId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId, blobId]);
export function deleteJobImageRoute(payload: TDeleteJobImageRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteJobImageRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteJobImageRoutePath, payload.path), { signal: abortSignal });
}

export const TGetJobStagesPath = '/core/operation/jobs/{jobId}/stages';
export type TGetJobStagesPath = paths['/core/operation/jobs/{jobId}/stages']['get']['parameters']['path'];
export type TGetJobStagesPayload = TApiPayload<TGetJobStagesPath, undefined, undefined>;
export type TGetJobStagesResponse200 = paths['/core/operation/jobs/{jobId}/stages']['get']['responses']['200']['content']['application/json'];
export type TGetJobStagesResponse = TGetJobStagesResponse200;
export const getJobStagesQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","stages"], jobId]);
export function getJobStages(payload: TGetJobStagesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobStagesResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetJobStagesPath, payload.path), { signal: abortSignal });
}

export const TFindPartAttributesRoutePath = '/core/operation/parts/attributes';
export type TFindPartAttributesRouteQuery = paths['/core/operation/parts/attributes']['get']['parameters']['query'];
export type TFindPartAttributesRoutePayload = TApiPayload<undefined, TFindPartAttributesRouteQuery, undefined>;
export type TFindPartAttributesRouteResponse200 = paths['/core/operation/parts/attributes']['get']['responses']['200']['content']['application/json'];
export type TFindPartAttributesRouteResponse = TFindPartAttributesRouteResponse200;
export const findPartAttributesRouteQueryKey = (query?: TFindPartAttributesRouteQuery): QueryKey => ([...["core","operation","parts","attributes"], ...(query ? [query] : [])]);
export function findPartAttributesRoute(payload: TFindPartAttributesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindPartAttributesRouteResponse>> {
  return axiosClientV3.get(TFindPartAttributesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TUpdatePartAttributeRoutePath = '/core/operation/parts/attributes';
export type TUpdatePartAttributeRouteBodyParams = paths['/core/operation/parts/attributes']['post']['requestBody']['content']['application/json'];
export type TUpdatePartAttributeRoutePayload = TApiPayload<undefined, undefined, TUpdatePartAttributeRouteBodyParams>;
export type TUpdatePartAttributeRouteResponse200 = paths['/core/operation/parts/attributes']['post']['responses']['200']['content']['application/json'];
export type TUpdatePartAttributeRouteResponse = TUpdatePartAttributeRouteResponse200;
export const updatePartAttributeRouteQueryKey = (): QueryKey => ([...["core","operation","parts","attributes"]]);
export function updatePartAttributeRoute(payload: TUpdatePartAttributeRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdatePartAttributeRouteResponse>> {
  return axiosClientV3.post(TUpdatePartAttributeRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TDeletePartAttributesRoutePath = '/core/operation/parts/attributes/{partAttributeId}';
export type TDeletePartAttributesRoutePath = paths['/core/operation/parts/attributes/{partAttributeId}']['delete']['parameters']['path'];
export type TDeletePartAttributesRoutePayload = TApiPayload<TDeletePartAttributesRoutePath, undefined, undefined>;
export type TDeletePartAttributesRouteResponse200 = paths['/core/operation/parts/attributes/{partAttributeId}']['delete']['responses']['200']['content']['application/json'];
export type TDeletePartAttributesRouteResponse = TDeletePartAttributesRouteResponse200;
export const deletePartAttributesRouteQueryKey = (partAttributeId: QueryKey[number]): QueryKey => ([...["core","operation","parts","attributes"], partAttributeId]);
export function deletePartAttributesRoute(payload: TDeletePartAttributesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeletePartAttributesRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeletePartAttributesRoutePath, payload.path), { signal: abortSignal });
}

export const TFindPartInstancesRoutePath = '/core/operation/parts/instances';
export type TFindPartInstancesRouteQuery = paths['/core/operation/parts/instances']['get']['parameters']['query'];
export type TFindPartInstancesRoutePayload = TApiPayload<undefined, TFindPartInstancesRouteQuery, undefined>;
export type TFindPartInstancesRouteResponse200 = paths['/core/operation/parts/instances']['get']['responses']['200']['content']['application/json'];
export type TFindPartInstancesRouteResponse = TFindPartInstancesRouteResponse200;
export const findPartInstancesRouteQueryKey = (query?: TFindPartInstancesRouteQuery): QueryKey => ([...["core","operation","parts","instances"], ...(query ? [query] : [])]);
export function findPartInstancesRoute(payload: TFindPartInstancesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindPartInstancesRouteResponse>> {
  return axiosClientV3.get(TFindPartInstancesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TFindPartsRoutePath = '/core/operation/parts';
export type TFindPartsRouteQuery = paths['/core/operation/parts']['get']['parameters']['query'];
export type TFindPartsRoutePayload = TApiPayload<undefined, TFindPartsRouteQuery, undefined>;
export type TFindPartsRouteResponse200 = paths['/core/operation/parts']['get']['responses']['200']['content']['application/json'];
export type TFindPartsRouteResponse = TFindPartsRouteResponse200;
export const findPartsRouteQueryKey = (query?: TFindPartsRouteQuery): QueryKey => ([...["core","operation","parts"], ...(query ? [query] : [])]);
export function findPartsRoute(payload: TFindPartsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindPartsRouteResponse>> {
  return axiosClientV3.get(TFindPartsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreatePartRoutePath = '/core/operation/parts';
export type TCreatePartRouteBodyParams = paths['/core/operation/parts']['post']['requestBody']['content']['application/json'];
export type TCreatePartRoutePayload = TApiPayload<undefined, undefined, TCreatePartRouteBodyParams>;
export type TCreatePartRouteResponse200 = paths['/core/operation/parts']['post']['responses']['200']['content']['application/json'];
export type TCreatePartRouteResponse = TCreatePartRouteResponse200;
export const createPartRouteQueryKey = (): QueryKey => ([...["core","operation","parts"]]);
export function createPartRoute(payload: TCreatePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreatePartRouteResponse>> {
  return axiosClientV3.post(TCreatePartRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TCreateOnetimePartRoutePath = '/core/operation/parts/onetime';
export type TCreateOnetimePartRouteBodyParams = paths['/core/operation/parts/onetime']['post']['requestBody']['content']['application/json'];
export type TCreateOnetimePartRoutePayload = TApiPayload<undefined, undefined, TCreateOnetimePartRouteBodyParams>;
export type TCreateOnetimePartRouteResponse200 = paths['/core/operation/parts/onetime']['post']['responses']['200']['content']['application/json'];
export type TCreateOnetimePartRouteResponse = TCreateOnetimePartRouteResponse200;
export const createOnetimePartRouteQueryKey = (): QueryKey => ([...["core","operation","parts","onetime"]]);
export function createOnetimePartRoute(payload: TCreateOnetimePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateOnetimePartRouteResponse>> {
  return axiosClientV3.post(TCreateOnetimePartRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetPartRoutePath = '/core/operation/parts/{partId}';
export type TGetPartRoutePath = paths['/core/operation/parts/{partId}']['get']['parameters']['path'];
export type TGetPartRoutePayload = TApiPayload<TGetPartRoutePath, undefined, undefined>;
export type TGetPartRouteResponse200 = paths['/core/operation/parts/{partId}']['get']['responses']['200']['content']['application/json'];
export type TGetPartRouteResponse = TGetPartRouteResponse200;
export const getPartRouteQueryKey = (partId: QueryKey[number]): QueryKey => ([...["core","operation","parts"], partId]);
export function getPartRoute(payload: TGetPartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetPartRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetPartRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdatePartRoutePath = '/core/operation/parts/{partId}';
export type TUpdatePartRoutePath = paths['/core/operation/parts/{partId}']['put']['parameters']['path'];
export type TUpdatePartRouteBodyParams = paths['/core/operation/parts/{partId}']['put']['requestBody']['content']['application/json'];
export type TUpdatePartRoutePayload = TApiPayload<TUpdatePartRoutePath, undefined, TUpdatePartRouteBodyParams>;
export type TUpdatePartRouteResponse200 = paths['/core/operation/parts/{partId}']['put']['responses']['200']['content']['application/json'];
export type TUpdatePartRouteResponse = TUpdatePartRouteResponse200;
export const updatePartRouteQueryKey = (partId: QueryKey[number]): QueryKey => ([...["core","operation","parts"], partId]);
export function updatePartRoute(payload: TUpdatePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdatePartRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdatePartRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeletePartRoutePath = '/core/operation/parts/{partId}';
export type TDeletePartRoutePath = paths['/core/operation/parts/{partId}']['delete']['parameters']['path'];
export type TDeletePartRoutePayload = TApiPayload<TDeletePartRoutePath, undefined, undefined>;
export type TDeletePartRouteResponse200 = paths['/core/operation/parts/{partId}']['delete']['responses']['200']['content']['application/json'];
export type TDeletePartRouteResponse = TDeletePartRouteResponse200;
export const deletePartRouteQueryKey = (partId: QueryKey[number]): QueryKey => ([...["core","operation","parts"], partId]);
export function deletePartRoute(payload: TDeletePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeletePartRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeletePartRoutePath, payload.path), { signal: abortSignal });
}

export const TGetJobsStagesRoutePath = '/core/operation/job-stages';
export type TGetJobsStagesRouteQuery = paths['/core/operation/job-stages']['get']['parameters']['query'];
export type TGetJobsStagesRoutePayload = TApiPayload<undefined, TGetJobsStagesRouteQuery, undefined>;
export type TGetJobsStagesRouteResponse200 = paths['/core/operation/job-stages']['get']['responses']['200']['content']['application/json'];
export type TGetJobsStagesRouteResponse = TGetJobsStagesRouteResponse200;
export const getJobsStagesRouteQueryKey = (query?: TGetJobsStagesRouteQuery): QueryKey => ([...["core","operation","job-stages"], ...(query ? [query] : [])]);
export function getJobsStagesRoute(payload: TGetJobsStagesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobsStagesRouteResponse>> {
  return axiosClientV3.get(TGetJobsStagesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TGetPermissionsRoutePath = '/core/permissions';
export type TGetPermissionsRouteResponse200 = paths['/core/permissions']['get']['responses']['200']['content']['application/json'];
export type TGetPermissionsRouteResponse = TGetPermissionsRouteResponse200;
export const getPermissionsRouteQueryKey = (): QueryKey => ([...["core","permissions"]]);
export function getPermissionsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetPermissionsRouteResponse>> {
  return axiosClientV3.get(TGetPermissionsRoutePath, { signal: abortSignal });
}

export const TFindRecipesRoutePath = '/core/recipe';
export type TFindRecipesRouteQuery = paths['/core/recipe']['get']['parameters']['query'];
export type TFindRecipesRoutePayload = TApiPayload<undefined, TFindRecipesRouteQuery, undefined>;
export type TFindRecipesRouteResponse200 = paths['/core/recipe']['get']['responses']['200']['content']['application/json'];
export type TFindRecipesRouteResponse = TFindRecipesRouteResponse200;
export const findRecipesRouteQueryKey = (query?: TFindRecipesRouteQuery): QueryKey => ([...["core","recipe"], ...(query ? [query] : [])]);
export function findRecipesRoute(payload: TFindRecipesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindRecipesRouteResponse>> {
  return axiosClientV3.get(TFindRecipesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TFindRolesRoutePath = '/core/roles';
export type TFindRolesRouteQuery = paths['/core/roles']['get']['parameters']['query'];
export type TFindRolesRoutePayload = TApiPayload<undefined, TFindRolesRouteQuery, undefined>;
export type TFindRolesRouteResponse200 = paths['/core/roles']['get']['responses']['200']['content']['application/json'];
export type TFindRolesRouteResponse = TFindRolesRouteResponse200;
export const findRolesRouteQueryKey = (query?: TFindRolesRouteQuery): QueryKey => ([...["core","roles"], ...(query ? [query] : [])]);
export function findRolesRoute(payload: TFindRolesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindRolesRouteResponse>> {
  return axiosClientV3.get(TFindRolesRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateRoleRoutePath = '/core/roles';
export type TCreateRoleRouteBodyParams = paths['/core/roles']['post']['requestBody']['content']['application/json'];
export type TCreateRoleRoutePayload = TApiPayload<undefined, undefined, TCreateRoleRouteBodyParams>;
export type TCreateRoleRouteResponse200 = paths['/core/roles']['post']['responses']['200']['content']['application/json'];
export type TCreateRoleRouteResponse201 = paths['/core/roles']['post']['responses']['201']['content'];
export type TCreateRoleRouteResponse = TCreateRoleRouteResponse200 | TCreateRoleRouteResponse201;
export const createRoleRouteQueryKey = (): QueryKey => ([...["core","roles"]]);
export function createRoleRoute(payload: TCreateRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateRoleRouteResponse>> {
  return axiosClientV3.post(TCreateRoleRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetRoleRoutePath = '/core/roles/{roleId}';
export type TGetRoleRoutePath = paths['/core/roles/{roleId}']['get']['parameters']['path'];
export type TGetRoleRoutePayload = TApiPayload<TGetRoleRoutePath, undefined, undefined>;
export type TGetRoleRouteResponse200 = paths['/core/roles/{roleId}']['get']['responses']['200']['content']['application/json'];
export type TGetRoleRouteResponse = TGetRoleRouteResponse200;
export const getRoleRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles"], roleId]);
export function getRoleRoute(payload: TGetRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetRoleRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetRoleRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateRoleRoutePath = '/core/roles/{roleId}';
export type TUpdateRoleRoutePath = paths['/core/roles/{roleId}']['put']['parameters']['path'];
export type TUpdateRoleRouteBodyParams = paths['/core/roles/{roleId}']['put']['requestBody']['content']['application/json'];
export type TUpdateRoleRoutePayload = TApiPayload<TUpdateRoleRoutePath, undefined, TUpdateRoleRouteBodyParams>;
export type TUpdateRoleRouteResponse200 = paths['/core/roles/{roleId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateRoleRouteResponse = TUpdateRoleRouteResponse200;
export const updateRoleRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles"], roleId]);
export function updateRoleRoute(payload: TUpdateRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateRoleRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateRoleRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteRoleRoutePath = '/core/roles/{roleId}';
export type TDeleteRoleRoutePath = paths['/core/roles/{roleId}']['delete']['parameters']['path'];
export type TDeleteRoleRoutePayload = TApiPayload<TDeleteRoleRoutePath, undefined, undefined>;
export type TDeleteRoleRouteResponse200 = paths['/core/roles/{roleId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteRoleRouteResponse = TDeleteRoleRouteResponse200;
export const deleteRoleRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles"], roleId]);
export function deleteRoleRoute(payload: TDeleteRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteRoleRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteRoleRoutePath, payload.path), { signal: abortSignal });
}

export const TGetRolePermissionsRoutePath = '/core/roles/{roleId}/permissions';
export type TGetRolePermissionsRoutePath = paths['/core/roles/{roleId}/permissions']['get']['parameters']['path'];
export type TGetRolePermissionsRoutePayload = TApiPayload<TGetRolePermissionsRoutePath, undefined, undefined>;
export type TGetRolePermissionsRouteResponse200 = paths['/core/roles/{roleId}/permissions']['get']['responses']['200']['content']['application/json'];
export type TGetRolePermissionsRouteResponse = TGetRolePermissionsRouteResponse200;
export const getRolePermissionsRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles","permissions"], roleId]);
export function getRolePermissionsRoute(payload: TGetRolePermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetRolePermissionsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetRolePermissionsRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateRolePermissionsRoutePath = '/core/roles/{roleId}/permissions';
export type TUpdateRolePermissionsRoutePath = paths['/core/roles/{roleId}/permissions']['put']['parameters']['path'];
export type TUpdateRolePermissionsRouteBodyParams = paths['/core/roles/{roleId}/permissions']['put']['requestBody']['content']['application/json'];
export type TUpdateRolePermissionsRoutePayload = TApiPayload<TUpdateRolePermissionsRoutePath, undefined, TUpdateRolePermissionsRouteBodyParams>;
export type TUpdateRolePermissionsRouteResponse200 = paths['/core/roles/{roleId}/permissions']['put']['responses']['200']['content']['application/json'];
export type TUpdateRolePermissionsRouteResponse = TUpdateRolePermissionsRouteResponse200;
export const updateRolePermissionsRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles","permissions"], roleId]);
export function updateRolePermissionsRoute(payload: TUpdateRolePermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateRolePermissionsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateRolePermissionsRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetMeaningsRoutePath = '/core/semantics/meanings';
export type TGetMeaningsRouteQuery = paths['/core/semantics/meanings']['get']['parameters']['query'];
export type TGetMeaningsRoutePayload = TApiPayload<undefined, TGetMeaningsRouteQuery, undefined>;
export type TGetMeaningsRouteResponse200 = paths['/core/semantics/meanings']['get']['responses']['200']['content']['application/json'];
export type TGetMeaningsRouteResponse = TGetMeaningsRouteResponse200;
export const getMeaningsRouteQueryKey = (query?: TGetMeaningsRouteQuery): QueryKey => ([...["core","semantics","meanings"], ...(query ? [query] : [])]);
export function getMeaningsRoute(payload: TGetMeaningsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMeaningsRouteResponse>> {
  return axiosClientV3.get(TGetMeaningsRoutePath, { signal: abortSignal, params: payload.query });
}

export const TUpdateMeaningsBulkRoutePath = '/core/semantics/meanings';
export type TUpdateMeaningsBulkRouteBodyParams = paths['/core/semantics/meanings']['put']['requestBody']['content']['application/json'];
export type TUpdateMeaningsBulkRoutePayload = TApiPayload<undefined, undefined, TUpdateMeaningsBulkRouteBodyParams>;
export type TUpdateMeaningsBulkRouteResponse200 = paths['/core/semantics/meanings']['put']['responses']['200']['content']['application/json'];
export type TUpdateMeaningsBulkRouteResponse = TUpdateMeaningsBulkRouteResponse200;
export const updateMeaningsBulkRouteQueryKey = (): QueryKey => ([...["core","semantics","meanings"]]);
export function updateMeaningsBulkRoute(payload: TUpdateMeaningsBulkRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMeaningsBulkRouteResponse>> {
  return axiosClientV3.put(TUpdateMeaningsBulkRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetMeaningRoutePath = '/core/semantics/meanings/{meaningId}';
export type TGetMeaningRoutePath = paths['/core/semantics/meanings/{meaningId}']['get']['parameters']['path'];
export type TGetMeaningRoutePayload = TApiPayload<TGetMeaningRoutePath, undefined, undefined>;
export type TGetMeaningRouteResponse200 = paths['/core/semantics/meanings/{meaningId}']['get']['responses']['200']['content']['application/json'];
export type TGetMeaningRouteResponse = TGetMeaningRouteResponse200;
export const getMeaningRouteQueryKey = (meaningId: QueryKey[number]): QueryKey => ([...["core","semantics","meanings"], meaningId]);
export function getMeaningRoute(payload: TGetMeaningRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMeaningRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetMeaningRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateMeaningRoutePath = '/core/semantics/meanings/{meaningId}';
export type TUpdateMeaningRoutePath = paths['/core/semantics/meanings/{meaningId}']['put']['parameters']['path'];
export type TUpdateMeaningRouteBodyParams = paths['/core/semantics/meanings/{meaningId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMeaningRoutePayload = TApiPayload<TUpdateMeaningRoutePath, undefined, TUpdateMeaningRouteBodyParams>;
export type TUpdateMeaningRouteResponse200 = paths['/core/semantics/meanings/{meaningId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateMeaningRouteResponse = TUpdateMeaningRouteResponse200;
export const updateMeaningRouteQueryKey = (meaningId: QueryKey[number]): QueryKey => ([...["core","semantics","meanings"], meaningId]);
export function updateMeaningRoute(payload: TUpdateMeaningRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMeaningRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateMeaningRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TFindMetadataPath = '/core/semantics/metadata';
export type TFindMetadataQuery = paths['/core/semantics/metadata']['get']['parameters']['query'];
export type TFindMetadataPayload = TApiPayload<undefined, TFindMetadataQuery, undefined>;
export type TFindMetadataResponse200 = paths['/core/semantics/metadata']['get']['responses']['200']['content']['application/json'];
export type TFindMetadataResponse = TFindMetadataResponse200;
export const findMetadataQueryKey = (query?: TFindMetadataQuery): QueryKey => ([...["core","semantics","metadata"], ...(query ? [query] : [])]);
export function findMetadata(payload: TFindMetadataPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindMetadataResponse>> {
  return axiosClientV3.get(TFindMetadataPath, { signal: abortSignal, params: payload.query });
}

export const TCreateMetadataRoutePath = '/core/semantics/metadata';
export type TCreateMetadataRouteBodyParams = paths['/core/semantics/metadata']['post']['requestBody']['content']['application/json'];
export type TCreateMetadataRoutePayload = TApiPayload<undefined, undefined, TCreateMetadataRouteBodyParams>;
export type TCreateMetadataRouteResponse200 = paths['/core/semantics/metadata']['post']['responses']['200']['content']['application/json'];
export type TCreateMetadataRouteResponse = TCreateMetadataRouteResponse200;
export const createMetadataRouteQueryKey = (): QueryKey => ([...["core","semantics","metadata"]]);
export function createMetadataRoute(payload: TCreateMetadataRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMetadataRouteResponse>> {
  return axiosClientV3.post(TCreateMetadataRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetMetadataPath = '/core/semantics/metadata/{dataId}';
export type TGetMetadataPath = paths['/core/semantics/metadata/{dataId}']['get']['parameters']['path'];
export type TGetMetadataPayload = TApiPayload<TGetMetadataPath, undefined, undefined>;
export type TGetMetadataResponse200 = paths['/core/semantics/metadata/{dataId}']['get']['responses']['200']['content']['application/json'];
export type TGetMetadataResponse = TGetMetadataResponse200;
export const getMetadataQueryKey = (dataId: QueryKey[number]): QueryKey => ([...["core","semantics","metadata"], dataId]);
export function getMetadata(payload: TGetMetadataPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMetadataResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetMetadataPath, payload.path), { signal: abortSignal });
}

export const TUpdateMetadataPath = '/core/semantics/metadata/{dataId}';
export type TUpdateMetadataPath = paths['/core/semantics/metadata/{dataId}']['put']['parameters']['path'];
export type TUpdateMetadataBodyParams = paths['/core/semantics/metadata/{dataId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMetadataPayload = TApiPayload<TUpdateMetadataPath, undefined, TUpdateMetadataBodyParams>;
export type TUpdateMetadataResponse200 = paths['/core/semantics/metadata/{dataId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateMetadataResponse = TUpdateMetadataResponse200;
export const updateMetadataQueryKey = (dataId: QueryKey[number]): QueryKey => ([...["core","semantics","metadata"], dataId]);
export function updateMetadata(payload: TUpdateMetadataPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMetadataResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateMetadataPath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TSetMetadataMeaningPath = '/core/semantics/metadata/{dataId}/meaning';
export type TSetMetadataMeaningPath = paths['/core/semantics/metadata/{dataId}/meaning']['put']['parameters']['path'];
export type TSetMetadataMeaningBodyParams = paths['/core/semantics/metadata/{dataId}/meaning']['put']['requestBody']['content']['application/json'];
export type TSetMetadataMeaningPayload = TApiPayload<TSetMetadataMeaningPath, undefined, TSetMetadataMeaningBodyParams>;
export type TSetMetadataMeaningResponse200 = paths['/core/semantics/metadata/{dataId}/meaning']['put']['responses']['200']['content']['application/json'];
export type TSetMetadataMeaningResponse = TSetMetadataMeaningResponse200;
export const setMetadataMeaningQueryKey = (dataId: QueryKey[number]): QueryKey => ([...["core","semantics","metadata","meaning"], dataId]);
export function setMetadataMeaning(payload: TSetMetadataMeaningPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetMetadataMeaningResponse>> {
  return axiosClientV3.put(interpolateUrl(TSetMetadataMeaningPath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetProfileMeaningsPath = '/core/semantics/profiles/{profileId}/meanings';
export type TGetProfileMeaningsPath = paths['/core/semantics/profiles/{profileId}/meanings']['get']['parameters']['path'];
export type TGetProfileMeaningsPayload = TApiPayload<TGetProfileMeaningsPath, undefined, undefined>;
export type TGetProfileMeaningsResponse200 = paths['/core/semantics/profiles/{profileId}/meanings']['get']['responses']['200']['content']['application/json'];
export type TGetProfileMeaningsResponse = TGetProfileMeaningsResponse200;
export const getProfileMeaningsQueryKey = (profileId: QueryKey[number]): QueryKey => ([...["core","semantics","profiles","meanings"], profileId]);
export function getProfileMeanings(payload: TGetProfileMeaningsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetProfileMeaningsResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetProfileMeaningsPath, payload.path), { signal: abortSignal });
}

export const TFindUnitOfMeasurementsRoutePath = '/core/semantics/unit-of-measures';
export type TFindUnitOfMeasurementsRouteResponse200 = paths['/core/semantics/unit-of-measures']['get']['responses']['200']['content']['application/json'];
export type TFindUnitOfMeasurementsRouteResponse = TFindUnitOfMeasurementsRouteResponse200;
export const findUnitOfMeasurementsRouteQueryKey = (): QueryKey => ([...["core","semantics","unit-of-measures"]]);
export function findUnitOfMeasurementsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TFindUnitOfMeasurementsRouteResponse>> {
  return axiosClientV3.get(TFindUnitOfMeasurementsRoutePath, { signal: abortSignal });
}

export const TGetUnitOfMeasurementRoutePath = '/core/semantics/unit-of-measures/{unitOfMeasurementId}';
export type TGetUnitOfMeasurementRoutePath = paths['/core/semantics/unit-of-measures/{unitOfMeasurementId}']['get']['parameters']['path'];
export type TGetUnitOfMeasurementRoutePayload = TApiPayload<TGetUnitOfMeasurementRoutePath, undefined, undefined>;
export type TGetUnitOfMeasurementRouteResponse200 = paths['/core/semantics/unit-of-measures/{unitOfMeasurementId}']['get']['responses']['200']['content']['application/json'];
export type TGetUnitOfMeasurementRouteResponse = TGetUnitOfMeasurementRouteResponse200;
export const getUnitOfMeasurementRouteQueryKey = (unitOfMeasurementId: QueryKey[number]): QueryKey => ([...["core","semantics","unit-of-measures"], unitOfMeasurementId]);
export function getUnitOfMeasurementRoute(payload: TGetUnitOfMeasurementRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUnitOfMeasurementRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetUnitOfMeasurementRoutePath, payload.path), { signal: abortSignal });
}

export const TGetAllSettingsRoutePath = '/core/settings';
export type TGetAllSettingsRouteResponse200 = paths['/core/settings']['get']['responses']['200']['content']['application/json'];
export type TGetAllSettingsRouteResponse = TGetAllSettingsRouteResponse200;
export const getAllSettingsRouteQueryKey = (): QueryKey => ([...["core","settings"]]);
export function getAllSettingsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAllSettingsRouteResponse>> {
  return axiosClientV3.get(TGetAllSettingsRoutePath, { signal: abortSignal });
}

export const TUpdateSettingsRoutePath = '/core/settings';
export type TUpdateSettingsRouteBodyParams = paths['/core/settings']['put']['requestBody']['content']['application/json'];
export type TUpdateSettingsRoutePayload = TApiPayload<undefined, undefined, TUpdateSettingsRouteBodyParams>;
export type TUpdateSettingsRouteResponse200 = paths['/core/settings']['put']['responses']['200']['content']['application/json'];
export type TUpdateSettingsRouteResponse = TUpdateSettingsRouteResponse200;
export const updateSettingsRouteQueryKey = (): QueryKey => ([...["core","settings"]]);
export function updateSettingsRoute(payload: TUpdateSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateSettingsRouteResponse>> {
  return axiosClientV3.put(TUpdateSettingsRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetSettingsStructureRoutePath = '/core/settings/structure';
export type TGetSettingsStructureRouteResponse200 = paths['/core/settings/structure']['get']['responses']['200']['content']['application/json'];
export type TGetSettingsStructureRouteResponse = TGetSettingsStructureRouteResponse200;
export const getSettingsStructureRouteQueryKey = (): QueryKey => ([...["core","settings","structure"]]);
export function getSettingsStructureRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetSettingsStructureRouteResponse>> {
  return axiosClientV3.get(TGetSettingsStructureRoutePath, { signal: abortSignal });
}

export const TGetTenantLogoRoutePath = '/core/settings/tenant-logo';
export type TGetTenantLogoRouteResponse200 = paths['/core/settings/tenant-logo']['get']['responses']['200']['content']['application/json'];
export type TGetTenantLogoRouteResponse = TGetTenantLogoRouteResponse200;
export const getTenantLogoRouteQueryKey = (): QueryKey => ([...["core","settings","tenant-logo"]]);
export function getTenantLogoRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetTenantLogoRouteResponse>> {
  return axiosClientV3.get(TGetTenantLogoRoutePath, { signal: abortSignal });
}

export const TSetTenantLogoRoutePath = '/core/settings/tenant-logo';
export type TSetTenantLogoRouteBodyParams = paths['/core/settings/tenant-logo']['put']['requestBody']['content']['multipart/form-data'];
export type TSetTenantLogoRoutePayload = TApiPayload<undefined, undefined, TSetTenantLogoRouteBodyParams>;
export type TSetTenantLogoRouteResponse200 = paths['/core/settings/tenant-logo']['put']['responses']['200']['content']['application/json'];
export type TSetTenantLogoRouteResponse = TSetTenantLogoRouteResponse200;
export const setTenantLogoRouteQueryKey = (): QueryKey => ([...["core","settings","tenant-logo"]]);
export function setTenantLogoRoute(payload: TSetTenantLogoRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetTenantLogoRouteResponse>> {
  return axiosClientV3.put(TSetTenantLogoRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetCurrentUserRoutePath = '/core/users/me';
export type TGetCurrentUserRouteResponse200 = paths['/core/users/me']['get']['responses']['200']['content']['application/json'];
export type TGetCurrentUserRouteResponse = TGetCurrentUserRouteResponse200;
export const getCurrentUserRouteQueryKey = (): QueryKey => ([...["core","users","me"]]);
export function getCurrentUserRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCurrentUserRouteResponse>> {
  return axiosClientV3.get(TGetCurrentUserRoutePath, { signal: abortSignal });
}

export const TGetCurrentUserPermissionsRoutePath = '/core/users/me/permissions';
export type TGetCurrentUserPermissionsRouteResponse200 = paths['/core/users/me/permissions']['get']['responses']['200']['content']['application/json'];
export type TGetCurrentUserPermissionsRouteResponse = TGetCurrentUserPermissionsRouteResponse200;
export const getCurrentUserPermissionsRouteQueryKey = (): QueryKey => ([...["core","users","me","permissions"]]);
export function getCurrentUserPermissionsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCurrentUserPermissionsRouteResponse>> {
  return axiosClientV3.get(TGetCurrentUserPermissionsRoutePath, { signal: abortSignal });
}

export const TFindApiKeysRoutePath = '/core/users/me/api-keys';
export type TFindApiKeysRouteResponse200 = paths['/core/users/me/api-keys']['get']['responses']['200']['content']['application/json'];
export type TFindApiKeysRouteResponse = TFindApiKeysRouteResponse200;
export const findApiKeysRouteQueryKey = (): QueryKey => ([...["core","users","me","api-keys"]]);
export function findApiKeysRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TFindApiKeysRouteResponse>> {
  return axiosClientV3.get(TFindApiKeysRoutePath, { signal: abortSignal });
}

export const TCreateApiKeyRoutePath = '/core/users/me/api-keys';
export type TCreateApiKeyRouteBodyParams = paths['/core/users/me/api-keys']['post']['requestBody']['content']['application/json'];
export type TCreateApiKeyRoutePayload = TApiPayload<undefined, undefined, TCreateApiKeyRouteBodyParams>;
export type TCreateApiKeyRouteResponse200 = paths['/core/users/me/api-keys']['post']['responses']['200']['content']['application/json'];
export type TCreateApiKeyRouteResponse = TCreateApiKeyRouteResponse200;
export const createApiKeyRouteQueryKey = (): QueryKey => ([...["core","users","me","api-keys"]]);
export function createApiKeyRoute(payload: TCreateApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateApiKeyRouteResponse>> {
  return axiosClientV3.post(TCreateApiKeyRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetApiKeyRoutePath = '/core/users/me/api-keys/{apiKeyId}';
export type TGetApiKeyRoutePath = paths['/core/users/me/api-keys/{apiKeyId}']['get']['parameters']['path'];
export type TGetApiKeyRoutePayload = TApiPayload<TGetApiKeyRoutePath, undefined, undefined>;
export type TGetApiKeyRouteResponse200 = paths['/core/users/me/api-keys/{apiKeyId}']['get']['responses']['200']['content']['application/json'];
export type TGetApiKeyRouteResponse = TGetApiKeyRouteResponse200;
export const getApiKeyRouteQueryKey = (apiKeyId: QueryKey[number]): QueryKey => ([...["core","users","me","api-keys"], apiKeyId]);
export function getApiKeyRoute(payload: TGetApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetApiKeyRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetApiKeyRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateApiKeyRoutePath = '/core/users/me/api-keys/{apiKeyId}';
export type TUpdateApiKeyRoutePath = paths['/core/users/me/api-keys/{apiKeyId}']['put']['parameters']['path'];
export type TUpdateApiKeyRouteBodyParams = paths['/core/users/me/api-keys/{apiKeyId}']['put']['requestBody']['content']['application/json'];
export type TUpdateApiKeyRoutePayload = TApiPayload<TUpdateApiKeyRoutePath, undefined, TUpdateApiKeyRouteBodyParams>;
export type TUpdateApiKeyRouteResponse200 = paths['/core/users/me/api-keys/{apiKeyId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateApiKeyRouteResponse = TUpdateApiKeyRouteResponse200;
export const updateApiKeyRouteQueryKey = (apiKeyId: QueryKey[number]): QueryKey => ([...["core","users","me","api-keys"], apiKeyId]);
export function updateApiKeyRoute(payload: TUpdateApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateApiKeyRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateApiKeyRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteApiKeyRoutePath = '/core/users/me/api-keys/{apiKeyId}';
export type TDeleteApiKeyRoutePath = paths['/core/users/me/api-keys/{apiKeyId}']['delete']['parameters']['path'];
export type TDeleteApiKeyRoutePayload = TApiPayload<TDeleteApiKeyRoutePath, undefined, undefined>;
export type TDeleteApiKeyRouteResponse200 = paths['/core/users/me/api-keys/{apiKeyId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteApiKeyRouteResponse = TDeleteApiKeyRouteResponse200;
export const deleteApiKeyRouteQueryKey = (apiKeyId: QueryKey[number]): QueryKey => ([...["core","users","me","api-keys"], apiKeyId]);
export function deleteApiKeyRoute(payload: TDeleteApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteApiKeyRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteApiKeyRoutePath, payload.path), { signal: abortSignal });
}

export const TGetCurrentUserSecretRoutePath = '/core/users/me/secrets/{lookupKey}';
export type TGetCurrentUserSecretRoutePath = paths['/core/users/me/secrets/{lookupKey}']['get']['parameters']['path'];
export type TGetCurrentUserSecretRoutePayload = TApiPayload<TGetCurrentUserSecretRoutePath, undefined, undefined>;
export type TGetCurrentUserSecretRouteResponse200 = paths['/core/users/me/secrets/{lookupKey}']['get']['responses']['200']['content']['application/json'];
export type TGetCurrentUserSecretRouteResponse = TGetCurrentUserSecretRouteResponse200;
export const getCurrentUserSecretRouteQueryKey = (lookupKey: QueryKey[number]): QueryKey => ([...["core","users","me","secrets"], lookupKey]);
export function getCurrentUserSecretRoute(payload: TGetCurrentUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCurrentUserSecretRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetCurrentUserSecretRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateCurrentUserSecretRoutePath = '/core/users/me/secrets/{lookupKey}';
export type TUpdateCurrentUserSecretRoutePath = paths['/core/users/me/secrets/{lookupKey}']['put']['parameters']['path'];
export type TUpdateCurrentUserSecretRouteBodyParams = paths['/core/users/me/secrets/{lookupKey}']['put']['requestBody']['content']['application/json'];
export type TUpdateCurrentUserSecretRoutePayload = TApiPayload<TUpdateCurrentUserSecretRoutePath, undefined, TUpdateCurrentUserSecretRouteBodyParams>;
export type TUpdateCurrentUserSecretRouteResponse200 = paths['/core/users/me/secrets/{lookupKey}']['put']['responses']['200']['content']['application/json'];
export type TUpdateCurrentUserSecretRouteResponse = TUpdateCurrentUserSecretRouteResponse200;
export const updateCurrentUserSecretRouteQueryKey = (lookupKey: QueryKey[number]): QueryKey => ([...["core","users","me","secrets"], lookupKey]);
export function updateCurrentUserSecretRoute(payload: TUpdateCurrentUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateCurrentUserSecretRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateCurrentUserSecretRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteCurrentUserSecretRoutePath = '/core/users/me/secrets/{lookupKey}';
export type TDeleteCurrentUserSecretRoutePath = paths['/core/users/me/secrets/{lookupKey}']['delete']['parameters']['path'];
export type TDeleteCurrentUserSecretRoutePayload = TApiPayload<TDeleteCurrentUserSecretRoutePath, undefined, undefined>;
export type TDeleteCurrentUserSecretRouteResponse200 = paths['/core/users/me/secrets/{lookupKey}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteCurrentUserSecretRouteResponse = TDeleteCurrentUserSecretRouteResponse200;
export const deleteCurrentUserSecretRouteQueryKey = (lookupKey: QueryKey[number]): QueryKey => ([...["core","users","me","secrets"], lookupKey]);
export function deleteCurrentUserSecretRoute(payload: TDeleteCurrentUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteCurrentUserSecretRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteCurrentUserSecretRoutePath, payload.path), { signal: abortSignal });
}

export const TGetUserSettingsPath = '/core/users/me/settings/{storageKey}';
export type TGetUserSettingsPath = paths['/core/users/me/settings/{storageKey}']['get']['parameters']['path'];
export type TGetUserSettingsPayload = TApiPayload<TGetUserSettingsPath, undefined, undefined>;
export type TGetUserSettingsResponse200 = paths['/core/users/me/settings/{storageKey}']['get']['responses']['200']['content']['application/json'];
export type TGetUserSettingsResponse = TGetUserSettingsResponse200;
export const getUserSettingsQueryKey = (storageKey: QueryKey[number]): QueryKey => ([...["core","users","me","settings"], storageKey]);
export function getUserSettings(payload: TGetUserSettingsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserSettingsResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetUserSettingsPath, payload.path), { signal: abortSignal });
}

export const TSetUserSettingsPath = '/core/users/me/settings/{storageKey}';
export type TSetUserSettingsPath = paths['/core/users/me/settings/{storageKey}']['put']['parameters']['path'];
export type TSetUserSettingsBodyParams = paths['/core/users/me/settings/{storageKey}']['put']['requestBody']['content']['application/json'];
export type TSetUserSettingsPayload = TApiPayload<TSetUserSettingsPath, undefined, TSetUserSettingsBodyParams>;
export type TSetUserSettingsResponse200 = paths['/core/users/me/settings/{storageKey}']['put']['responses']['200']['content']['application/json'];
export type TSetUserSettingsResponse = TSetUserSettingsResponse200;
export const setUserSettingsQueryKey = (storageKey: QueryKey[number]): QueryKey => ([...["core","users","me","settings"], storageKey]);
export function setUserSettings(payload: TSetUserSettingsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetUserSettingsResponse>> {
  return axiosClientV3.put(interpolateUrl(TSetUserSettingsPath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetUsersRoutePath = '/core/users';
export type TGetUsersRouteQuery = paths['/core/users']['get']['parameters']['query'];
export type TGetUsersRoutePayload = TApiPayload<undefined, TGetUsersRouteQuery, undefined>;
export type TGetUsersRouteResponse200 = paths['/core/users']['get']['responses']['200']['content']['application/json'];
export type TGetUsersRouteResponse = TGetUsersRouteResponse200;
export const getUsersRouteQueryKey = (query?: TGetUsersRouteQuery): QueryKey => ([...["core","users"], ...(query ? [query] : [])]);
export function getUsersRoute(payload: TGetUsersRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUsersRouteResponse>> {
  return axiosClientV3.get(TGetUsersRoutePath, { signal: abortSignal, params: payload.query });
}

export const TCreateUserRoutePath = '/core/users';
export type TCreateUserRouteBodyParams = paths['/core/users']['post']['requestBody']['content']['application/json'];
export type TCreateUserRoutePayload = TApiPayload<undefined, undefined, TCreateUserRouteBodyParams>;
export type TCreateUserRouteResponse200 = paths['/core/users']['post']['responses']['200']['content']['application/json'];
export type TCreateUserRouteResponse201 = paths['/core/users']['post']['responses']['201']['content'];
export type TCreateUserRouteResponse = TCreateUserRouteResponse200 | TCreateUserRouteResponse201;
export const createUserRouteQueryKey = (): QueryKey => ([...["core","users"]]);
export function createUserRoute(payload: TCreateUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateUserRouteResponse>> {
  return axiosClientV3.post(TCreateUserRoutePath, payload.bodyParams, { signal: abortSignal });
}

export const TGetUserRoutePath = '/core/users/{userId}';
export type TGetUserRoutePath = paths['/core/users/{userId}']['get']['parameters']['path'];
export type TGetUserRoutePayload = TApiPayload<TGetUserRoutePath, undefined, undefined>;
export type TGetUserRouteResponse200 = paths['/core/users/{userId}']['get']['responses']['200']['content']['application/json'];
export type TGetUserRouteResponse = TGetUserRouteResponse200;
export const getUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users"], userId]);
export function getUserRoute(payload: TGetUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetUserRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateUserRoutePath = '/core/users/{userId}';
export type TUpdateUserRoutePath = paths['/core/users/{userId}']['put']['parameters']['path'];
export type TUpdateUserRouteBodyParams = paths['/core/users/{userId}']['put']['requestBody']['content']['application/json'];
export type TUpdateUserRoutePayload = TApiPayload<TUpdateUserRoutePath, undefined, TUpdateUserRouteBodyParams>;
export type TUpdateUserRouteResponse200 = paths['/core/users/{userId}']['put']['responses']['200']['content']['application/json'];
export type TUpdateUserRouteResponse = TUpdateUserRouteResponse200;
export const updateUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users"], userId]);
export function updateUserRoute(payload: TUpdateUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateUserRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteUserRoutePath = '/core/users/{userId}';
export type TDeleteUserRoutePath = paths['/core/users/{userId}']['delete']['parameters']['path'];
export type TDeleteUserRoutePayload = TApiPayload<TDeleteUserRoutePath, undefined, undefined>;
export type TDeleteUserRouteResponse200 = paths['/core/users/{userId}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteUserRouteResponse = TDeleteUserRouteResponse200;
export const deleteUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users"], userId]);
export function deleteUserRoute(payload: TDeleteUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteUserRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteUserRoutePath, payload.path), { signal: abortSignal });
}

export const TRestoreUserRoutePath = '/core/users/{userId}/restored';
export type TRestoreUserRoutePath = paths['/core/users/{userId}/restored']['put']['parameters']['path'];
export type TRestoreUserRoutePayload = TApiPayload<TRestoreUserRoutePath, undefined, undefined>;
export type TRestoreUserRouteResponse200 = paths['/core/users/{userId}/restored']['put']['responses']['200']['content']['application/json'];
export type TRestoreUserRouteResponse = TRestoreUserRouteResponse200;
export const restoreUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","restored"], userId]);
export function restoreUserRoute(payload: TRestoreUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TRestoreUserRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TRestoreUserRoutePath, payload.path), undefined, { signal: abortSignal });
}

export const TGetUserPermissionsRoutePath = '/core/users/{userId}/permissions';
export type TGetUserPermissionsRoutePath = paths['/core/users/{userId}/permissions']['get']['parameters']['path'];
export type TGetUserPermissionsRoutePayload = TApiPayload<TGetUserPermissionsRoutePath, undefined, undefined>;
export type TGetUserPermissionsRouteResponse200 = paths['/core/users/{userId}/permissions']['get']['responses']['200']['content']['application/json'];
export type TGetUserPermissionsRouteResponse = TGetUserPermissionsRouteResponse200;
export const getUserPermissionsRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","permissions"], userId]);
export function getUserPermissionsRoute(payload: TGetUserPermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserPermissionsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetUserPermissionsRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateUserPermissionsRoutePath = '/core/users/{userId}/permissions';
export type TUpdateUserPermissionsRoutePath = paths['/core/users/{userId}/permissions']['put']['parameters']['path'];
export type TUpdateUserPermissionsRouteBodyParams = paths['/core/users/{userId}/permissions']['put']['requestBody']['content']['application/json'];
export type TUpdateUserPermissionsRoutePayload = TApiPayload<TUpdateUserPermissionsRoutePath, undefined, TUpdateUserPermissionsRouteBodyParams>;
export type TUpdateUserPermissionsRouteResponse200 = paths['/core/users/{userId}/permissions']['put']['responses']['200']['content']['application/json'];
export type TUpdateUserPermissionsRouteResponse = TUpdateUserPermissionsRouteResponse200;
export const updateUserPermissionsRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","permissions"], userId]);
export function updateUserPermissionsRoute(payload: TUpdateUserPermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserPermissionsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateUserPermissionsRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TGetUserSecretRoutePath = '/core/users/{userId}/secrets/{lookupKey}';
export type TGetUserSecretRoutePath = paths['/core/users/{userId}/secrets/{lookupKey}']['get']['parameters']['path'];
export type TGetUserSecretRoutePayload = TApiPayload<TGetUserSecretRoutePath, undefined, undefined>;
export type TGetUserSecretRouteResponse200 = paths['/core/users/{userId}/secrets/{lookupKey}']['get']['responses']['200']['content']['application/json'];
export type TGetUserSecretRouteResponse = TGetUserSecretRouteResponse200;
export const getUserSecretRouteQueryKey = (userId: QueryKey[number], lookupKey: QueryKey[number]): QueryKey => ([...["core","users","secrets"], userId, lookupKey]);
export function getUserSecretRoute(payload: TGetUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserSecretRouteResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetUserSecretRoutePath, payload.path), { signal: abortSignal });
}

export const TUpdateUserSecretRoutePath = '/core/users/{userId}/secrets/{lookupKey}';
export type TUpdateUserSecretRoutePath = paths['/core/users/{userId}/secrets/{lookupKey}']['put']['parameters']['path'];
export type TUpdateUserSecretRouteBodyParams = paths['/core/users/{userId}/secrets/{lookupKey}']['put']['requestBody']['content']['application/json'];
export type TUpdateUserSecretRoutePayload = TApiPayload<TUpdateUserSecretRoutePath, undefined, TUpdateUserSecretRouteBodyParams>;
export type TUpdateUserSecretRouteResponse200 = paths['/core/users/{userId}/secrets/{lookupKey}']['put']['responses']['200']['content']['application/json'];
export type TUpdateUserSecretRouteResponse = TUpdateUserSecretRouteResponse200;
export const updateUserSecretRouteQueryKey = (userId: QueryKey[number], lookupKey: QueryKey[number]): QueryKey => ([...["core","users","secrets"], userId, lookupKey]);
export function updateUserSecretRoute(payload: TUpdateUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserSecretRouteResponse>> {
  return axiosClientV3.put(interpolateUrl(TUpdateUserSecretRoutePath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteUserSecretRoutePath = '/core/users/{userId}/secrets/{lookupKey}';
export type TDeleteUserSecretRoutePath = paths['/core/users/{userId}/secrets/{lookupKey}']['delete']['parameters']['path'];
export type TDeleteUserSecretRoutePayload = TApiPayload<TDeleteUserSecretRoutePath, undefined, undefined>;
export type TDeleteUserSecretRouteResponse200 = paths['/core/users/{userId}/secrets/{lookupKey}']['delete']['responses']['200']['content']['application/json'];
export type TDeleteUserSecretRouteResponse = TDeleteUserSecretRouteResponse200;
export const deleteUserSecretRouteQueryKey = (userId: QueryKey[number], lookupKey: QueryKey[number]): QueryKey => ([...["core","users","secrets"], userId, lookupKey]);
export function deleteUserSecretRoute(payload: TDeleteUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteUserSecretRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteUserSecretRoutePath, payload.path), { signal: abortSignal });
}

export const TGetUserSubscriptionsTreePath = '/core/users/{userId}/subscriptions';
export type TGetUserSubscriptionsTreePath = paths['/core/users/{userId}/subscriptions']['get']['parameters']['path'];
export type TGetUserSubscriptionsTreePayload = TApiPayload<TGetUserSubscriptionsTreePath, undefined, undefined>;
export type TGetUserSubscriptionsTreeResponse200 = paths['/core/users/{userId}/subscriptions']['get']['responses']['200']['content']['application/json'];
export type TGetUserSubscriptionsTreeResponse = TGetUserSubscriptionsTreeResponse200;
export const getUserSubscriptionsTreeQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId]);
export function getUserSubscriptionsTree(payload: TGetUserSubscriptionsTreePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserSubscriptionsTreeResponse>> {
  return axiosClientV3.get(interpolateUrl(TGetUserSubscriptionsTreePath, payload.path), { signal: abortSignal });
}

export const TCreateUserSubscriptionsPath = '/core/users/{userId}/subscriptions';
export type TCreateUserSubscriptionsPath = paths['/core/users/{userId}/subscriptions']['post']['parameters']['path'];
export type TCreateUserSubscriptionsBodyParams = paths['/core/users/{userId}/subscriptions']['post']['requestBody']['content']['application/json'];
export type TCreateUserSubscriptionsPayload = TApiPayload<TCreateUserSubscriptionsPath, undefined, TCreateUserSubscriptionsBodyParams>;
export type TCreateUserSubscriptionsResponse201 = paths['/core/users/{userId}/subscriptions']['post']['responses']['201']['content']['application/json'];
export type TCreateUserSubscriptionsResponse = TCreateUserSubscriptionsResponse201;
export const createUserSubscriptionsQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId]);
export function createUserSubscriptions(payload: TCreateUserSubscriptionsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateUserSubscriptionsResponse>> {
  return axiosClientV3.post(interpolateUrl(TCreateUserSubscriptionsPath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TReplaceUserSubscriptionsPath = '/core/users/{userId}/subscriptions';
export type TReplaceUserSubscriptionsPath = paths['/core/users/{userId}/subscriptions']['put']['parameters']['path'];
export type TReplaceUserSubscriptionsBodyParams = paths['/core/users/{userId}/subscriptions']['put']['requestBody']['content']['application/json'];
export type TReplaceUserSubscriptionsPayload = TApiPayload<TReplaceUserSubscriptionsPath, undefined, TReplaceUserSubscriptionsBodyParams>;
export type TReplaceUserSubscriptionsResponse200 = paths['/core/users/{userId}/subscriptions']['put']['responses']['200']['content']['application/json'];
export type TReplaceUserSubscriptionsResponse201 = paths['/core/users/{userId}/subscriptions']['put']['responses']['201']['content'];
export type TReplaceUserSubscriptionsResponse = TReplaceUserSubscriptionsResponse200 | TReplaceUserSubscriptionsResponse201;
export const replaceUserSubscriptionsQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId]);
export function replaceUserSubscriptions(payload: TReplaceUserSubscriptionsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TReplaceUserSubscriptionsResponse>> {
  return axiosClientV3.put(interpolateUrl(TReplaceUserSubscriptionsPath, payload.path), payload.bodyParams, { signal: abortSignal });
}

export const TDeleteUserSubscriptionPath = '/core/users/{userId}/subscriptions/{subscriptionId}';
export type TDeleteUserSubscriptionPath = paths['/core/users/{userId}/subscriptions/{subscriptionId}']['delete']['parameters']['path'];
export type TDeleteUserSubscriptionPayload = TApiPayload<TDeleteUserSubscriptionPath, undefined, undefined>;
export type TDeleteUserSubscriptionResponse204 = paths['/core/users/{userId}/subscriptions/{subscriptionId}']['delete']['responses']['204']['content'];
export type TDeleteUserSubscriptionResponse = TDeleteUserSubscriptionResponse204;
export const deleteUserSubscriptionQueryKey = (userId: QueryKey[number], subscriptionId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId, subscriptionId]);
export function deleteUserSubscription(payload: TDeleteUserSubscriptionPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteUserSubscriptionResponse>> {
  return axiosClientV3.delete(interpolateUrl(TDeleteUserSubscriptionPath, payload.path), { signal: abortSignal });
}

export const TGetInAppNotificationsPath = '/core/users/me/notifications';
export type TGetInAppNotificationsQuery = paths['/core/users/me/notifications']['get']['parameters']['query'];
export type TGetInAppNotificationsPayload = TApiPayload<undefined, TGetInAppNotificationsQuery, undefined>;
export type TGetInAppNotificationsResponse200 = paths['/core/users/me/notifications']['get']['responses']['200']['content']['application/json'];
export type TGetInAppNotificationsResponse = TGetInAppNotificationsResponse200;
export const getInAppNotificationsQueryKey = (query?: TGetInAppNotificationsQuery): QueryKey => ([...["core","users","me","notifications"], ...(query ? [query] : [])]);
export function getInAppNotifications(payload: TGetInAppNotificationsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetInAppNotificationsResponse>> {
  return axiosClientV3.get(TGetInAppNotificationsPath, { signal: abortSignal, params: payload.query });
}

export const TMarkReadPath = '/core/users/me/notifications/mark-read';
export type TMarkReadBodyParams = paths['/core/users/me/notifications/mark-read']['put']['requestBody']['content']['application/json'];
export type TMarkReadPayload = TApiPayload<undefined, undefined, TMarkReadBodyParams>;
export type TMarkReadResponse204 = paths['/core/users/me/notifications/mark-read']['put']['responses']['204']['content'];
export type TMarkReadResponse = TMarkReadResponse204;
export const markReadQueryKey = (): QueryKey => ([...["core","users","me","notifications","mark-read"]]);
export function markRead(payload: TMarkReadPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TMarkReadResponse>> {
  return axiosClientV3.put(TMarkReadPath, payload.bodyParams, { signal: abortSignal });
}

export const TMarkAllReadPath = '/core/users/me/notifications/mark-read/all';
export type TMarkAllReadResponse204 = paths['/core/users/me/notifications/mark-read/all']['put']['responses']['204']['content'];
export type TMarkAllReadResponse = TMarkAllReadResponse204;
export const markAllReadQueryKey = (): QueryKey => ([...["core","users","me","notifications","mark-read","all"]]);
export function markAllRead(abortSignal?: AbortSignal): Promise<AxiosResponse<TMarkAllReadResponse>> {
  return axiosClientV3.put(TMarkAllReadPath, undefined, { signal: abortSignal });
}

export const TGetUnseenCountPath = '/core/users/me/notifications/unseen-count';
export type TGetUnseenCountResponse200 = paths['/core/users/me/notifications/unseen-count']['get']['responses']['200']['content']['application/json'];
export type TGetUnseenCountResponse = TGetUnseenCountResponse200;
export const getUnseenCountQueryKey = (): QueryKey => ([...["core","users","me","notifications","unseen-count"]]);
export function getUnseenCount(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUnseenCountResponse>> {
  return axiosClientV3.get(TGetUnseenCountPath, { signal: abortSignal });
}

