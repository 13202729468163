/**
 * better to ask Felix if you're not sure if something is general term or not
 * do not mistake with "simple" translations like "submit" or "cancel"
 * never use for composition
 *
 * generalTerm translations are something, which are specific to project "domain" or "project"
 * "common" translations are everything, which can be reused across different projects
 *
 * TODO: extract "common" above this object in order to reuse some translations
 */
export const generalTerm = {
  // common
  loadId: 'Load ID',
  // do not mistake with "Load ID"
  // "Load ID" might or might not be indexing field for database
  // "Load Identifier" is a customer-written "comment" or "description" which they later use for internal usage
  loadIdentifier: 'Load Identifier',
  date: 'Date',
  name: 'Name',
  description: 'Description',
  event: 'Event | Events',
  mappedPT9800user: 'Mapped PT9800 user',
  // equipment || asset
  asset: 'Asset',
  assetId: 'Asset id',
  assetType: 'Asset type',
  customer: 'Customer',
  composition: 'Composition',
  element: 'Element | Elements',
  root: 'Root | Roots',
  location: 'Location | Locations',
  locationId: 'Location id',
  // job || load
  job: 'Job',
  jobDisplayName: 'Load No',
  jobStartedAtUtc: 'Start time',
  jobFinishedAtUtc: 'End time',
  // sensor || meaning || signal
  sensor: 'Sensor',
  signal: 'Signal | Signals',
  unit: 'Unit | Units',
  recipe: 'Recipe | Recipes',
  scadaType: 'Scada type',
  materialSystemList: 'Material Systems',
  measure: 'Measure | Measures',
  setpoint: 'Setpoint | Setpoints',
  reportId: 'Report No.',
  report: 'Report',
  reporter: 'Reporter',
  jobId: 'Job id',
  partId: 'Part id',
  part: 'Part',
  step: 'Step',
  recipeStep: 'Step',
  material: 'Material',
  drawingReference: 'Drawing reference',
  displayName: 'Name',
  preset: 'Preset',
  materialCalculationModel: 'Material calculation model',
  data: 'Data',
  chart: 'Chart | Charts',
  austenite: 'Austenite',
  limitMinus: 'Limit -',
  limitPlus: 'Limit +',
  envelope: 'Envelope',
  email: 'E-Mail',
  sms: 'SMS',
  upload: 'Upload',
  orientation: 'Orientation',
  height: 'Height',
  width: 'Width',
  title: 'Title',
  send: 'Send',
  fullName: 'Full Name',

  // TODO: migrate to "common"
  createdAt: 'Created at',
  showDeleted: 'Show deleted',
  overwrite: 'Overwrite',
  requirement: 'Requirement',
  result: 'Result',

  schaefflerDiagram: 'Schäffler Diagramm',
  CCTDiagram: 'CCT diagram',
  temperingCurveDiagram: 'Tempering curve',
  jominyDiagram: 'Jominy curve',

  carbonPotential: 'Carbon potential',
  nitrogenPotential: 'Nitrogen potential',
  surfaceCarbon: 'Surface carbon',
  surfaceNitrogen: 'Surface nitrogen',
  surfaceCarbide: 'Surface Carbide',

  recipeStage: 'Recipe stage',
  automat: 'Automat | Automats',
  component: 'Component | Components',
  equipment: 'Equipment | Equipments',
  equipmentGroup: 'Equipment group | Equipment groups',
  sparePart: 'Spare part | Spare parts',
  manufacturer: 'Manufacturer',
  partNumber: 'Part number',
  chamber: 'Chamber | Chambers',
  company: 'Company | Companies',
  heatTreatmentCapability: 'Heat treatment capability | Heat treatment capabilities',
  preference: 'Preference | Preferences',
  heatTreatmentProcess: 'Heat Treatment process | Heat treatment processes',
};

// used in tables or other places where you need to filter by general term
export const generalTermFilter = {
  asset: 'Search asset',
};
