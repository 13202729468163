export default {
  jobNumber: 'Job number',
  loaded: 'Loaded: {recipeCode}',
  process: 'Recipe',
  processStartTime: 'Process start time',
  stageName: 'Stage Name',
  stageNumber: 'Stage Number',
  stageTime: 'Stage Time',
  status: 'Status',
  systemName: 'System Name',
};
