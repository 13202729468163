export const ACTION_PATH = 'action';
export const ALARMS_LIST = 'alarms-list';
export const ALARMS_STATISTICS_PATH = 'alarm-statistics';
export const ALARM_FREQ_PARETO_GRAPH_PATH = 'alarms-frequency';
export const ATOMIC_PERCENTAGE = 'atomic-percentage';
export const BLOB_STORE_PATH = 'blob-store';
export const BUSINESS_CONFIGURATION = 'business-configuration';
export const CALENDAR_PATH = 'maintenance-calendar';
export const CHART = 'chart';
export const CHART_CONFIG_OPERATION_DASHBOARD_SIGNALS = 'operation-dashboard-signals';
export const CHART_CONFIG_PATH = 'chart-configuration';
export const CHART_CONFIG_SIGNALS = 'signals';
export const COMMON_SENSORS = 'common';
export const COMPONENTS_PATH = 'components';
export const CUSTOMER_REQUESTS_PATH = 'customer-requests';
export const DASHBOARD_CONSUMABLES_PATH = 'consumables';
export const DASHBOARD_LOCATION_PATH = 'location';
export const DASHBOARD_OEE_PATH = 'oee';
export const DASHBOARD_OHI_PATH = 'ohi';
export const DASHBOARD_OVERVIEW_GRAPH_PATH = 'overview-graph';
export const DASHBOARD_PENDING_EMERGENCY_PATH = 'has-pending-emergency';
export const DOCUMENT_CATEGORIES_PATH = 'categories';
export const DOCUMENT_PATH = 'documents';
export const DOWNLOAD_PATH = 'download';
export const DRAWING_DOCUMENTS_PATH = 'documents/drawings';
export const EQUIPMENTS_AUTHORIZATION_PATH = 'authorization';
export const EQUIPMENTS_PATH = 'equipments';
export const EQUIPMENTS_OVERVIEW_PATH = 'overview';
export const EQUIPMENT_ALARMS_INFO = 'alarms';
export const EQUIPMENT_BATCH_RATING = 'batch-rating';
export const EQUIPMENT_ID = 'id/find';
export const EQUIPMENT_JOB_AUDIT = 'production-logs/audits';
export const EQUIPMENT_JOB_CHART = 'log-values';
export const EQUIPMENT_JOB_INFO = 'jobs';
export const EQUIPMENT_STAGES_PATH = 'stages';
export const EVENTS_PATH = 'events';
export const FURNACES_PATH = 'furnaces';
export const GROUPS_PATH = 'groups';
export const INSTRUCTIONS_PATH = 'instructions';
export const JOB_FEATURE_DESCRIPTIONS_PATH = 'job-feature-descriptions';
export const JOBS_LIST_PATH = 'jobs-list';
export const KPIS_PATH = 'kpis';
export const LIVE_KPIS = 'live-kpis';
export const LOCALIZATION_PATH = 'localization';
export const LOCATIONS_PATH = 'locations';
export const MAINTENANCE_DASHBOARDS_PATH = 'maintenance-dashboard';
export const MATERIAL_PATH = 'material';
export const MATERIALS_PATH = 'materials';
export const MAINTENANCE_CALENDAR_PATH = 'maintenance-calendar';
export const MODEL_PROCESS_PATH = 'model-process';
export const MONITORING_DASHBOARD_PATH = 'monitoring-dashboard';
export const NOTES_PATH = 'notes';
export const NPM_CONTROLLER = 'npm-controller';
export const OBSERVATION_TYPES_PATH = `${EQUIPMENTS_PATH}/observation-types`;
export const OBSERVATION_TYPES_ROOT_CAUSE_PATH = 'observation-type-root-cause';
export const OPERATION_DASHBOARDS_PATH = 'operation-dashboard';
export const PRODUCTION_LOGS_LIST = 'list';
export const PRODUCTION_LOGS_PATH = 'production-logs';
export const PROTOCOLS_USAGE_PATH = 'usage';
export const RECIPES_PATH = 'recipes';
export const RECIPE_MATERIALS_PATH = 'materials';
export const RECIPE_PATH = 'recipe-on-demand';
export const ROOT_CAUSE_PATH = 'root-cause';
export const SEARCH_PATH = 'search';
export const SENSORS_PATH = 'sensors';
export const SIMULATOR_CHART_PATH = `simulator/${CHART}`;
export const SIMULATOR_MATERIAL_PATH = `simulator/${MATERIAL_PATH}`;
export const SIMULATOR_MATERIAL_CHART_PATH = `${SIMULATOR_MATERIAL_PATH}/${CHART}`;
export const SIMULATOR_PARTS_PATH = 'simulator/parts';
export const SIMULATOR_PART_CHART_PATH = `${SIMULATOR_PARTS_PATH}/cct-chart`;
export const SPARE_PARTS_PATH = 'spareparts';
export const STATISTICS_PATH = 'statistics';
export const SYSTEM_DETAILS_PATH = 'system-details';
export const SYSTEM_IDENTIFIER_PATH = 'system-identifier';
export const SYSTEMS_PATH = 'systems';
export const TREE_NAVIGATION_PATH = 'tree-navigation';
export const USERS_PATH = 'users';
export const WORKPLACES_PATH = 'workplaces';
export const SIMULATORS_RECIPE_PATH = 'simulator/recipe';
