import PrmButton from 'primevue/button';
import {
  ButtonHTMLAttributes,
  FunctionalComponent,
  SVGAttributes,
} from 'vue';

type TPrmButtonProps = InstanceType<typeof PrmButton>['$props'];

export const qmulusButtonSeverity = [
  'primary',
  'secondary',
  'tertiary',
  'danger',
] as const;
export type TQmulusButtonSeverity = typeof qmulusButtonSeverity[number];

export interface IQmulusButtonProps {
  label: string,
  severity?: TQmulusButtonSeverity,
  icon?: string | FunctionalComponent<SVGAttributes, {}, any, {}>,
  disabled?: boolean,
  loading?: boolean,
  iconOnly?: boolean,
  isInlineAction?: boolean,
  tooltip?: string,
  /* If set to true, long text will be truncated and suffixed with ... */
  truncateLabel?: boolean,
  onClick?: ButtonHTMLAttributes['onClick'],
}

export const severityMap: Record<TQmulusButtonSeverity, TPrmButtonProps> = {
  primary: {},
  secondary: {
    severity: 'secondary',
  },
  tertiary: {
    severity: 'secondary',
    outlined: true,
  },
  danger: {
    severity: 'danger',
    text: true,
  },
};
