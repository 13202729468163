import npmApiTranslations from './de.api.npm.ts';
import pt9800ApiTranslations from './de.api.pt9800.ts';
import {
  ETimeseriesMeaning,
  TComponentKeys,
  TComponentLegacyKeys,
} from '@/types/openapi-type.ts';
import {
  generalTerm,
} from '@/constants/locales/de.shared.ts';
import {
  EMeasurementUnitType,
} from '@/types/measurement.ts';
import {
  EAuditResultType,
} from '@/types/audit-result.ts';
import {
  EConsumptionMeaningMap, EJobFeatureMeaningMap,
  EPhysicalPropertyMeaningMap,
} from '@/types/meaning-type.ts';
import {
  EPhysicalDimension,
} from '@/types/physical-dimension.ts';
import {
  ECalendarEventType,
  ECalendarPeriod,
  EMaintenancePriority, EMaintenanceRecurrenceEndCondition,
  EMaintenanceRecurrenceMonthDayDimension,
  EMaintenanceRecurrenceMonthDayValue,
  EMaintenanceState,
} from '@/types/calendar.ts';
import {
  EHierarchy,
} from '@/types/asset-tree.ts';
import {
  ENotificationChannelType,
} from '@/types/notification-channel-type.ts';
import {
  EActivityCoefficients,
  ECarburizingCarbonControlType,
  ECarburizingEndControlType, ECarburizingNitrogenControlType,
  ECarburizingRecipeSimulationValuesType,
  ECarburizingSimulationPartShape,
  ECarburizingSimulatorRecipeType,
  ECarburizingTemperatureControlType, ECarburizingVolumeControlType,
  ESimulationStep,
} from '@/types/simulator.ts';
import {
  EAssetProfileStatus,
} from '@/types/asset-profile-status.ts';
import {
  EAssetProfileCombinator,
} from '@/types/asset-profile-combinator.ts';
import {
  EObservationType,
} from '@/types/observation-type.ts';
import {
  EChamberType, EHeatTreatmentProcessType,
} from '@/types/chamber.ts';
import {
  EAppGroupCommercial, EAppGroupSemantic, EAppId,
} from '@/types/app-type.ts';
import {
  components,
} from '@/types/openapi.ts';
import {
  ESubscriptionTreeLabel,
} from '@/types/notification.ts';
import {
  EDataFormat,
} from '@/types/metadata.ts';
import {
  TFunctionCategory,
  TFunctionDefinition,
  TOperatorDefinition,
} from '@/types/function.ts';
import {
  EModelLifecycleState,
} from '@/types/model-builder.ts';

const SparePart: Partial<TComponentKeys<'SparePart'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  manufacturerId: generalTerm.manufacturer,
  manufacturerDisplayName: generalTerm.manufacturer,
};

const HeatTreatmentCapability: Partial<TComponentKeys<'HeatTreatmentCapability'>> = {
  heatTreatmentProcess: generalTerm.heatTreatmentProcess,
  preference: generalTerm.preference,
};

const Chamber: Partial<TComponentKeys<'Chamber'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
};

const MeasurementDisplayName: Record<EMeasurementUnitType, string> = {
  [EMeasurementUnitType.AMPERE]: 'Ampere',
  [EMeasurementUnitType.ATMOSPHERE]: 'Atmosphäre',
  [EMeasurementUnitType.BAR]: 'Bar',
  [EMeasurementUnitType.BAR_PER_MINUTE]: 'Bar pro Minute',
  [EMeasurementUnitType.BRINELL_HARDNESS]: 'Brinellhärte',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_1500_10]: 'Brinellhärte 10/1500/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_1500_15]: 'Brinellhärte 10/1500/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_3000_10]: 'Brinellhärte 10/3000/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_3000_15]: 'Brinellhärte 10/3000/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_500_10]: 'Brinellhärte 10/500/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_500_15]: 'Brinellhärte 10/500/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_1500_10]: 'Brinellhärte 6/1500/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_1500_15]: 'Brinellhärte 6/1500/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_3000_10]: 'Brinellhärte 6/3000/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_3000_15]: 'Brinellhärte 6/3000/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_500_10]: 'Brinellhärte 6/500/10/',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_500_15]: 'Brinellhärte 6/500/15',
  [EMeasurementUnitType.BTU]: 'Britische Wärmeeinheit',
  [EMeasurementUnitType.BTU_PER_HOUR]: 'Britische Wärmeeinheit pro Stunde',
  [EMeasurementUnitType.BTU_PER_MINUTE]: 'Britische Wärmeeinheit pro Minute',
  [EMeasurementUnitType.CUBIC_CENTIMETRE_PER_MINUTE]: 'Kubikzentimeter pro Minute',
  [EMeasurementUnitType.CUBIC_FEET]: 'Kubikfuß',
  [EMeasurementUnitType.CUBIC_FEET_PER_HOUR]: 'Kubikfuß pro Stunde',
  [EMeasurementUnitType.CUBIC_FEET_PER_MINUTE]: 'Kubikfuß pro Minute',
  [EMeasurementUnitType.CUBIC_METRES]: 'Kubikmeter',
  [EMeasurementUnitType.CUBIC_METRE_PER_HOUR]: 'Kubikmeter pro Stunde',
  [EMeasurementUnitType.CUBIC_METRE_PER_MINUTE]: 'Kubikmeter pro Minute',
  [EMeasurementUnitType.CUBIC_METRE_PER_SECOND]: 'Kubikmeter pro Sekunde',
  [EMeasurementUnitType.DAY]: 'Tag',
  [EMeasurementUnitType.DEGREES_CELSIUS]: 'Grad Celsius',
  [EMeasurementUnitType.DEGREES_CELSIUS_PER_MINUTE]: 'Grad Celsius pro Minute',
  [EMeasurementUnitType.DEGREES_FAHRENHEIT]: 'Grad Fahrenheit',
  [EMeasurementUnitType.DEGREES_FAHRENHEIT_PER_MINUTE]: 'Grad Fahrenheit pro Minute',
  [EMeasurementUnitType.DEGREES_KELVIN]: 'Grad Kelvin',
  [EMeasurementUnitType.DELTA_DEGREES_CELSIUS]: 'Delta Grad Celsius',
  [EMeasurementUnitType.DELTA_DEGREES_FAHRENHEIT]: 'Delta Grad Fahrenheit',
  [EMeasurementUnitType.DELTA_DEGREES_KELVIN]: 'Delta Grad Kelvin',
  [EMeasurementUnitType.FEET_OF_WATER_COLUMN]: 'Fuß der Wassersäule',
  [EMeasurementUnitType.GALLONS_PER_HOUR]: 'Gallonen pro Stunde',
  [EMeasurementUnitType.GALLONS_PER_MINUTE]: 'Gallonen pro Minute',
  [EMeasurementUnitType.GRAM]: 'Gramm',
  [EMeasurementUnitType.GRAM_PER_SECOND]: 'Gram pro Sekunde',
  [EMeasurementUnitType.HECTO_PASCAL]: 'Hektopascal',
  [EMeasurementUnitType.HORSEPOWER]: 'Pferdestärken',
  [EMeasurementUnitType.HOUR]: 'Stunde',
  [EMeasurementUnitType.INCH]: 'Zoll',
  [EMeasurementUnitType.INCH_OF_MERCURY]: 'Zoll Quecksilber',
  [EMeasurementUnitType.INCH_OF_WATER_COLUMN]: 'Zoll der Wassersäule',
  [EMeasurementUnitType.KILOGRAM]: 'Kilogramm',
  [EMeasurementUnitType.KILOGRAM_PER_HOUR]: 'Kilogram pro Stunde',
  [EMeasurementUnitType.KILOGRAM_PER_MINUTE]: 'Kilogram pro Minute',
  [EMeasurementUnitType.KILOGRAM_PER_SECOND]: 'Kilogram pro Sekunde',
  [EMeasurementUnitType.KILOMETER]: 'Kilometer',
  [EMeasurementUnitType.KILOVOLT]: 'Kilovolt',
  [EMeasurementUnitType.KILOVOLT_AMPERE]: 'Kilovolt-Ampere',
  [EMeasurementUnitType.KILOWATT]: 'Kilowatt',
  [EMeasurementUnitType.KILOWATT_HOUR]: 'Kilowattstunde',
  [EMeasurementUnitType.KILO_PASCAL]: 'Kilo-Pascal',
  [EMeasurementUnitType.KNOOP_HARDNESS]: 'Knoophärte',
  [EMeasurementUnitType.KNOOP_HARDNESS_100]: 'Knoophärte 100',
  [EMeasurementUnitType.KNOOP_HARDNESS_200]: 'Knoophärte 200',
  [EMeasurementUnitType.KNOOP_HARDNESS_500]: 'Knoophärte 500',
  [EMeasurementUnitType.KNOOP_HARDNESS_50]: 'Knoophärte 05',
  [EMeasurementUnitType.LITRE]: 'Liter',
  [EMeasurementUnitType.LITRE_PER_HOUR]: 'Liter pro Stunde',
  [EMeasurementUnitType.LITRE_PER_MINUTE]: 'Liter pro Mitute',
  [EMeasurementUnitType.MEGAWATT]: 'Megawatt',
  [EMeasurementUnitType.METRES_PER_MINUTE]: 'Meter pro Minute',
  [EMeasurementUnitType.METRES_PER_SECOND]: 'Meter pro Sekunde',
  [EMeasurementUnitType.METRE]: 'Meter',
  [EMeasurementUnitType.MICROBAR]: 'Mikrobar',
  [EMeasurementUnitType.MICROBAR_PER_MINUTE]: 'Mikrobar pro Minute',
  [EMeasurementUnitType.MICRON]: 'Mikron',
  [EMeasurementUnitType.MICRON_HG_PER_HOUR]: 'Mikron HG pro Stunde',
  [EMeasurementUnitType.MICRON_HG_PER_MINUTE]: 'Mikron HG pro Minute',
  [EMeasurementUnitType.MICRON_OF_MERCURY]: 'Mikron Quecksilber',
  [EMeasurementUnitType.MILLIBAR]: 'Millibar',
  [EMeasurementUnitType.MILLIBAR_PER_MINUTE]: 'Millibar pro Minute',
  [EMeasurementUnitType.MILLIGRAM]: 'Milligramm',
  [EMeasurementUnitType.MILLILITRE]: 'Milliliter',
  [EMeasurementUnitType.MILLILITRE_PER_MINUTE]: 'Milliliter pro Minute',
  [EMeasurementUnitType.MILLIMETRE]: 'Millimeter',
  [EMeasurementUnitType.MILLIMETRE_OF_MERCURY]: 'Millimeter Quecksilber',
  [EMeasurementUnitType.MILLIMETRE_OF_WATER_COLUMN]: 'Millimeter der Wassersäule',
  [EMeasurementUnitType.MILLIMETRE_PER_SECOND]: 'Millimeter pro Sekunde',
  [EMeasurementUnitType.MILLITORR_PER_HOUR]: 'Millitorr pro Stunde',
  [EMeasurementUnitType.MILLIVOLT]: 'Millivolt',
  [EMeasurementUnitType.MILLI_AMPERE]: 'Milli Ampere',
  [EMeasurementUnitType.MINUTE]: 'Minute',
  [EMeasurementUnitType.MONTH]: 'Monat',
  [EMeasurementUnitType.NORMAL_LITRE_PER_SQUARE_METERS_PER_HOUR]: 'Normal Liter pro Quadratmeter pro Stunde',
  [EMeasurementUnitType.NUMBER]: 'Nummer',
  [EMeasurementUnitType.PARTS_PER_MILLION]: 'Teile pro Million',
  [EMeasurementUnitType.PASCAL]: 'Pascal',
  [EMeasurementUnitType.PERCENT]: 'Prozentsatz',
  [EMeasurementUnitType.PERCENT_CARBON]: 'Prozentsatz Kohlenstoff',
  [EMeasurementUnitType.PERCENT_HYDROGEN]: 'Wasserstoff in Prozent',
  [EMeasurementUnitType.PERCENT_NITROGEN]: 'Stickstoff in Prozent',
  [EMeasurementUnitType.POUND]: 'Pfund',
  [EMeasurementUnitType.PSI]: 'PSI',
  [EMeasurementUnitType.ROCKWELL_HARDNESS]: 'Rockwellhärte',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_N]: 'Rockwellhärte 15N',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_T]: 'Rockwellhärte 15T',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_W]: 'Rockwellhärte 15W',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_X]: 'Rockwellhärte 15X',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_Y]: 'Rockwellhärte 15Y',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_N]: 'Rockwellhärte 30N',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_T]: 'Rockwellhärte 30T',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_W]: 'Rockwellhärte 30W',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_X]: 'Rockwellhärte 30X',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_Y]: 'Rockwellhärte 30Y',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_N]: 'Rockwellhärte 45N',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_T]: 'Rockwellhärte 45T',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_W]: 'Rockwellhärte 45W',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_X]: 'Rockwellhärte 45X',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_Y]: 'Rockwellhärte 45Y',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_A]: 'Rockwellhärte A',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_B]: 'Rockwellhärte B',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_C]: 'Rockwellhärte C',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_D]: 'Rockwellhärte D',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_E]: 'Rockwellhärte E',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_F]: 'Rockwellhärte F',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_G]: 'Rockwellhärte G',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_H]: 'Rockwellhärte H',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_K]: 'Rockwellhärte K',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_L]: 'Rockwellhärte L',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_M]: 'Rockwellhärte M',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_P]: 'Rockwellhärte P',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_R]: 'Rockwellhärte R',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_S]: 'Rockwellhärte S',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_V]: 'Rockwellhärte V',
  [EMeasurementUnitType.RPM]: 'RPM',
  [EMeasurementUnitType.SECOND]: 'Sekunde',
  [EMeasurementUnitType.TONNE]: 'Tonne',
  [EMeasurementUnitType.TORR]: 'Torr',
  [EMeasurementUnitType.TORR_PER_MINUTE]: 'Torr pro Minute',
  [EMeasurementUnitType.UNIT_LESS]: 'Keine Angabe',
  [EMeasurementUnitType.VICKERS_HARDNESS]: 'Vickershärte',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_1]: 'Vickershärte 0.1',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_2]: 'Vickershärte 0.2',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_3]: 'Vickershärte 0.3',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_4]: 'Vickershärte 0.4',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_5]: 'Vickershärte 0.5',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_6]: 'Vickershärte 0.6',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_7]: 'Vickershärte 0.7',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_8]: 'Vickershärte 0.8',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_9]: 'Vickershärte 0.9',
  [EMeasurementUnitType.VICKERS_HARDNESS_100]: 'Vickershärte 100',
  [EMeasurementUnitType.VICKERS_HARDNESS_10]: 'Vickershärte 10',
  [EMeasurementUnitType.VICKERS_HARDNESS_1]: 'Vickershärte 1',
  [EMeasurementUnitType.VICKERS_HARDNESS_20]: 'Vickershärte 20',
  [EMeasurementUnitType.VICKERS_HARDNESS_30]: 'Vickershärte 30',
  [EMeasurementUnitType.VICKERS_HARDNESS_40]: 'Vickershärte 40',
  [EMeasurementUnitType.VICKERS_HARDNESS_50]: 'Vickershärte 50',
  [EMeasurementUnitType.VICKERS_HARDNESS_60]: 'Vickershärte 60',
  [EMeasurementUnitType.VICKERS_HARDNESS_70]: 'Vickershärte 70',
  [EMeasurementUnitType.VICKERS_HARDNESS_80]: 'Vickershärte 80',
  [EMeasurementUnitType.VICKERS_HARDNESS_90]: 'Vickershärte 90',
  [EMeasurementUnitType.VOLT]: 'Volt',
  [EMeasurementUnitType.VOLT_AMPERE]: 'Volt Ampere',
  [EMeasurementUnitType.WATT]: 'Watt',
  [EMeasurementUnitType.WEEK]: 'Woche',
  [EMeasurementUnitType.WEIGHT_PERCENT]: 'Gewicht in Prozent',
  [EMeasurementUnitType.YEAR]: 'Jahr',
};

const AuditResultType: Record<EAuditResultType, string> = {
  [EAuditResultType.FAILED]: 'Fehlgeschlagen',
  [EAuditResultType.INCONCLUSIVE]: 'Unschlüssig',
  [EAuditResultType.PASSED]: 'Bestanden',
};

const PhysicalPropertyMeaning: Record<EPhysicalPropertyMeaningMap, string> = {
  [EPhysicalPropertyMeaningMap.LOAD_MASS]: 'Ladungsmasse',
};

const ConsumptionMeaning: Record<EConsumptionMeaningMap, string> = {
  [EConsumptionMeaningMap.DEPRECATED_AMMONIA_CONSUMPTION]: 'Ammoniaverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_AMMONIA_COST]: 'Ammoniakosten',
  [EConsumptionMeaningMap.DEPRECATED_CARBON_DIOXIDE_CONSUMPTION]: 'Kohlendioxidverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_CARBON_DIOXIDE_COST]: 'Kohlendioxidkosten',
  [EConsumptionMeaningMap.DEPRECATED_CO_GAS_CONSUMPTION]: 'CO-Gasverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_CO_GAS_COST]: 'CO-Gaskosten',
  [EConsumptionMeaningMap.DEPRECATED_DISSOCIATED_AMMONIA_CONSUMPTION]: 'Verbrauch von dissociated Ammonia',
  [EConsumptionMeaningMap.DEPRECATED_DISSOCIATED_AMMONIA_COST]: 'Kosten für dissociated Ammonia',
  [EConsumptionMeaningMap.DEPRECATED_ELECTRICITY_CONSUMPTION]: 'Stromverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_ELECTRICITY_COST]: 'Stromkosten',
  [EConsumptionMeaningMap.DEPRECATED_ENDO_GAS_CONSUMPTION]: 'Endo-Gasverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_ENDO_GAS_COST]: 'Endo-Gaskosten',
  [EConsumptionMeaningMap.DEPRECATED_HYDROGEN_CONSUMPTION]: 'Wasserstoffverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_HYDROGEN_COST]: 'Wasserstoffkosten',
  [EConsumptionMeaningMap.DEPRECATED_NITROGEN_CONSUMPTION]: 'Stickstoffverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_NITROGEN_COST]: 'Stickstoffkosten',
  [EConsumptionMeaningMap.ACETYLENE_C2H2_CONSUMPTION]: 'Acetylen (C2H2) Verbrauch',
  [EConsumptionMeaningMap.AIR_CONSUMPTION]: 'Luftverbrauch',
  [EConsumptionMeaningMap.AMMONIA_NH3_CONSUMPTION]: 'Ammoniak (NH3) Verbrauch',
  [EConsumptionMeaningMap.ARGON_CONSUMPTION]: 'Argonverbrauch',
  [EConsumptionMeaningMap.CARBON_DIOXIDE_CO2_CONSUMPTION]: 'Kohlendioxid (CO2) Verbrauch',
  [EConsumptionMeaningMap.CARBON_MONOXIDE_CO_CONSUMPTION]: 'Kohlenmonoxid (CO) Verbrauch',
  [EConsumptionMeaningMap.DISSOCIATED_AMMONIA_NH3_CONSUMPTION]: 'Dissoziierter Ammoniak (NH3) Verbrauch',
  [EConsumptionMeaningMap.ENDO_GAS_NATURAL_CONSUMPTION]: 'Endogas (Naturgas) Verbrauch',
  [EConsumptionMeaningMap.ENDO_GAS_PROPANE_CONSUMPTION]: 'Endogas (Propan) Verbrauch',
  [EConsumptionMeaningMap.ETHENE_C2H4_CONSUMPTION]: 'Ethen (C2H4) Verbrauch',
  [EConsumptionMeaningMap.HELIUM_CONSUMPTION]: 'Heliumverbrauch',
  [EConsumptionMeaningMap.HYDROGEN_H2_CONSUMPTION]: 'Wasserstoff (H2) Verbrauch',
  [EConsumptionMeaningMap.METHANOL_CH3OH_CONSUMPTION]: 'Methanol (CH3OH) Verbrauch',
  [EConsumptionMeaningMap.NATURAL_GAS_CONSUMPTION]: 'Erdgasverbrauch',
  [EConsumptionMeaningMap.NITROGEN_CONSUMPTION]: 'Stickstoffverbrauch',
  [EConsumptionMeaningMap.NITROUS_OXIDE_N2O_CONSUMPTION]: 'Distickstoffoxid (N2O) Verbrauch',
  [EConsumptionMeaningMap.PROPANE_C3H8_CONSUMPTION]: 'Propan (C3H8) Verbrauch',
  [EConsumptionMeaningMap.WATER_CONSUMPTION]: 'Wasserverbrauch',
  [EConsumptionMeaningMap.ETHANE_C2H6_CONSUMPTION]: 'Ethan (C2H6) Verbrauch',
  [EConsumptionMeaningMap.METHANE_CH4_CONSUMPTION]: 'Methan (CH4) Verbrauch',
  [EConsumptionMeaningMap.ELECTRICAL_POWER_CONSUMPTION]: 'Stromverbauch',
  [EConsumptionMeaningMap.USER_DEFINED_001]: 'Benutzerdefinierter Verbrauch 001',
  [EConsumptionMeaningMap.USER_DEFINED_002]: 'Benutzerdefinierter Verbrauch 002',
  [EConsumptionMeaningMap.USER_DEFINED_003]: 'Benutzerdefinierter Verbrauch 003',
  [EConsumptionMeaningMap.USER_DEFINED_004]: 'Benutzerdefinierter Verbrauch 004',
  [EConsumptionMeaningMap.USER_DEFINED_005]: 'Benutzerdefinierter Verbrauch 005',
  [EConsumptionMeaningMap.USER_DEFINED_006]: 'Benutzerdefinierter Verbrauch 006',
  [EConsumptionMeaningMap.USER_DEFINED_007]: 'Benutzerdefinierter Verbrauch 007',
  [EConsumptionMeaningMap.USER_DEFINED_008]: 'Benutzerdefinierter Verbrauch 008',
  [EConsumptionMeaningMap.USER_DEFINED_009]: 'Benutzerdefinierter Verbrauch 009',
  [EConsumptionMeaningMap.USER_DEFINED_010]: 'Benutzerdefinierter Verbrauch 010',
  [EConsumptionMeaningMap.USER_DEFINED_011]: 'Benutzerdefinierter Verbrauch 011',
  [EConsumptionMeaningMap.USER_DEFINED_012]: 'Benutzerdefinierter Verbrauch 012',
  [EConsumptionMeaningMap.USER_DEFINED_013]: 'Benutzerdefinierter Verbrauch 013',
  [EConsumptionMeaningMap.USER_DEFINED_014]: 'Benutzerdefinierter Verbrauch 014',
  [EConsumptionMeaningMap.USER_DEFINED_015]: 'Benutzerdefinierter Verbrauch 015',
  [EConsumptionMeaningMap.USER_DEFINED_016]: 'Benutzerdefinierter Verbrauch 016',
  [EConsumptionMeaningMap.USER_DEFINED_017]: 'Benutzerdefinierter Verbrauch 017',
  [EConsumptionMeaningMap.USER_DEFINED_018]: 'Benutzerdefinierter Verbrauch 018',
  [EConsumptionMeaningMap.USER_DEFINED_019]: 'Benutzerdefinierter Verbrauch 019',
  [EConsumptionMeaningMap.USER_DEFINED_020]: 'Benutzerdefinierter Verbrauch 020',
};

const JobFeatureMeaning: Record<EJobFeatureMeaningMap, string> = {
  [EJobFeatureMeaningMap.HEAT_LOSS_MIN_POWER]: 'Wärmeverlust minimale Leistung',
  [EJobFeatureMeaningMap.HEAT_LOSS_MAX_TEMPERATURE]: 'Wärmeverlust maximale Temperatur',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_ACCURACY]: 'Temperatur Regelgenauigkeit',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_PRECISION]: 'Temperatur Regelpräzision',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_ACCURACY]: 'Heizleistung Regelgenauigkeit',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_PRECISION]: 'Heizleistung Regelpräzision',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_ACCURACY]: 'Elektrische Leistungs Regelgenauigkeit',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_PRECISION]: 'Elektrische Leistungs Regelpräzision',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_ACCURACY]: 'Kühlleistung Regelgenauigkeit',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_PRECISION]: 'Kühlleistung Regelpräzision',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_ACCURACY]: 'Relative Druck Regelgenauigkeit',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_PRECISION]: 'Relative Druck Regelpräzision',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_ACCURACY]: 'Kammertemperatur Regelgenauigkeit',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_PRECISION]: 'Kammertemperatur Regelpräzision',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_ACCURACY]: 'Absolute Druck Regelgenauigkeit',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_PRECISION]: 'Absolute Druck Regelpräzision',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Vakuumdruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_PRECISION]: 'Vakuumdruck Regelpräzision',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_ACCURACY]: 'Atmosphärendruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_PRECISION]: 'Atmosphärendruck Regelpräzision',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_ACCURACY]: 'Raumtemperatur Regelgenauigkeit',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_PRECISION]: 'Raumtemperatur Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_ACCURACY]: 'Aktivität aH Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_PRECISION]: 'Aktivität aH Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_ACCURACY]: 'Potential HP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_PRECISION]: 'Potential HP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_ACCURACY]: 'Potential KH- Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_PRECISION]: 'Potential KH- Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_ACCURACY]: 'Aktivität aO Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_PRECISION]: 'Aktivität aO Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_ACCURACY]: 'Potential OP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_PRECISION]: 'Potential OP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_ACCURACY]: 'Potential KO Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_PRECISION]: 'Potential KO Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_ACCURACY]: 'Aktivität aC Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_PRECISION]: 'Aktivität aC Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_ACCURACY]: 'Potential CP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_PRECISION]: 'Potential CP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_ACCURACY]: 'Potential KC Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_PRECISION]: 'Potential KC Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_ACCURACY]: 'Aktivität aN Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_PRECISION]: 'Aktivität aN Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_ACCURACY]: 'Potential NP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_PRECISION]: 'Potential NP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_ACCURACY]: 'Potential KN Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_PRECISION]: 'Potential KN Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_ACCURACY]: 'Aktivität aB Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_PRECISION]: 'Aktivität aB Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_ACCURACY]: 'Potential BP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_PRECISION]: 'Potential BP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_ACCURACY]: 'Potential kB Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_PRECISION]: 'Potential kB Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_ACCURACY]: 'Aktivität aS Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_PRECISION]: 'Aktivität aS Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_ACCURACY]: 'Potential SP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_PRECISION]: 'Potential SP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_ACCURACY]: 'Potential kS Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_PRECISION]: 'Potential kS Regelpräzision',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_ACCURACY]: 'Acetylen C2H2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_PRECISION]: 'Acetylen C2H2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_ACCURACY]: 'Luftstrom Regelgenauigkeit',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_PRECISION]: 'Luftstrom Regelpräzision',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_ACCURACY]: 'Ammoniak NH3 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_PRECISION]: 'Ammoniak NH3 Flow Regelpräzision',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_ACCURACY]: 'Argon Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_PRECISION]: 'Argon Fluss Regelpräzision',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_ACCURACY]: 'Kohlendioxid CO2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_PRECISION]: 'Kohlendioxid CO2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_ACCURACY]: 'Kohlenmonoxid CO Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_PRECISION]: 'Kohlenmonoxid CO Flow Regelpräzision',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_ACCURACY]: 'Dissoziiertes Ammoniak NH3 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_PRECISION]: 'Dissoziiertes Ammoniak NH3 Fluss Regelpräzision',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_ACCURACY]: 'Endogas natürliche Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_PRECISION]: 'Endogas natürliche Fluss Regelpräzision',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_ACCURACY]: 'Endogas Propan Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_PRECISION]: 'Endogas Propan Fluss Regelpräzision',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_ACCURACY]: 'Äthylen C2H4 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_PRECISION]: 'Äthylen C2H4 Flow Regelpräzision',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_ACCURACY]: 'Helium Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_PRECISION]: 'Helium Fluss Regelpräzision',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_ACCURACY]: 'Wasserstoff H2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_PRECISION]: 'Wasserstoff H2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_ACCURACY]: 'Methanol CH3OH Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_PRECISION]: 'Methanol CH3OH Flow Regelpräzision',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_ACCURACY]: 'Erdgas Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_PRECISION]: 'Erdgas Fluss Regelpräzision',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_ACCURACY]: 'Stickstoff Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_PRECISION]: 'Stickstoff Fluss Regelpräzision',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_ACCURACY]: 'Propan C3H8 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_PRECISION]: 'Propan C3H8 Flow Regelpräzision',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_ACCURACY]: 'Wasser Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_PRECISION]: 'Wasser Fluss Regelpräzision',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_ACCURACY]: 'Lachgas N2O Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_PRECISION]: 'Lachgas N2O Fluss Regelpräzision',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_AGILITY]: 'Temperatur Regelagilität',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_AGILITY]: 'Heizleistung Regelagilität',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_AGILITY]: 'Elektrische Leistungs Regelagilität',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_AGILITY]: 'Kühlleistung Regelagilität',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_AGILITY]: 'Relative Druck Regelagilität',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_AGILITY]: 'Kammertemperatur Regelagilität',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_AGILITY]: 'Absolute Druck Regelagilität',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_AGILITY]: 'Vakuumdruck Regelagilität',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_AGILITY]: 'Atmosphärendruck Regelagilität',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_AGILITY]: 'Raumtemperatur Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_AGILITY]: 'Aktivität aH Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_AGILITY]: 'Potential HP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_AGILITY]: 'Potential kH Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_AGILITY]: 'Aktivität aO Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_AGILITY]: 'Potential OP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_AGILITY]: 'Potential kO Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_AGILITY]: 'Aktivität aC Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_AGILITY]: 'Potential CP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_AGILITY]: 'Potential kC Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_AGILITY]: 'Aktivität aN Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_AGILITY]: 'Potential NP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_AGILITY]: 'Potential kN Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_AGILITY]: 'Aktivität aB Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_AGILITY]: 'Potential BP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_AGILITY]: 'Potential kB Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_AGILITY]: 'Aktivität aS Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_AGILITY]: 'Potential SP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_AGILITY]: 'Potential kS Regelagilität',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_AGILITY]: 'Acetylen C2H2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_AGILITY]: 'Luftstrom Regelagilität',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_AGILITY]: 'Ammoniak NH3 Fluss Regelagilität',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_AGILITY]: 'Argon Fluss Regelagilität',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_AGILITY]: 'Kohlendioxid CO2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_AGILITY]: 'Kohlenmonoxid CO Fluss Regelagilität',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_AGILITY]: 'Dissoziiertes Ammoniak NH3 Fluss Regelagilität',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_AGILITY]: 'Endogas natürliche Fluss Regelagilität',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_AGILITY]: 'Endogas Propan Fluss Regelagilität',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_AGILITY]: 'Äthylen C2H4 Fluss Regelagilität',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_AGILITY]: 'Helium Fluss Regelagilität',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_AGILITY]: 'Wasserstoff H2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_AGILITY]: 'Methanol CH3OH Fluss Regelagilität',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_AGILITY]: 'Erdgas Fluss Regelagilität',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_AGILITY]: 'Stickstoff Fluss Regelagilität',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_AGILITY]: 'Propan- C3H8 Fluss Regelagilität',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_AGILITY]: 'Wasser Fluss Regelagilität',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_AGILITY]: 'Lachgas N2O Fluss Regelagilität',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_ACCURACY]: 'Sauerstoff O2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_PRECISION]: 'Sauerstoff O2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_AGILITY]: 'Sauerstoff O2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_ACCURACY]: 'Methan CH4 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_PRECISION]: 'Methan CH4 Flow Regelpräzision',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_AGILITY]: 'Methan CH4 Fluss Regelagilität',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_ACCURACY]: 'Dissoziations Regelgenauigkeit',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_PRECISION]: 'Dissoziations Regelpräzision',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_AGILITY]: 'Dissoziations Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_ACCURACY]: 'Partialdruck Acetylen C2H2 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_PRECISION]: 'Partialdruck Acetylen C2H2 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_AGILITY]: 'Partialdruck Acetylen C2H2 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_ACCURACY]: 'Partialdruck Luft Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_PRECISION]: 'Partialdruck Luft Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_AGILITY]: 'Partialdruck Luft Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_ACCURACY]: 'Partialdruck Ammoniak NH3 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_PRECISION]: 'Partialdruck Ammoniak NH3 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_AGILITY]: 'Partialdruck Ammoniak NH3 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_ACCURACY]: 'Partialdruck Argon Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_PRECISION]: 'Partialdruck Argon Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_AGILITY]: 'Partialdruck Argon Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_ACCURACY]: 'Partialdruck Kohlendioxid CO2 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_PRECISION]: 'Partialdruck Kohlendioxid CO2 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_AGILITY]: 'Partialdruck Kohlendioxid CO2 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_ACCURACY]: 'Partialdruck Kohlenmonoxid CO Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_PRECISION]: 'Partialdruck Kohlenmonoxid CO Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_AGILITY]: 'Partialdruck Kohlenmonoxid CO Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_ACCURACY]: 'Partialdruck dissoziiertes Ammoniak NH3 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_PRECISION]: 'Partialdruck dissoziiertes Ammoniak NH3 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_AGILITY]: 'Partialdruck dissoziiertes Ammoniak NH3 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_ACCURACY]: 'Partialdruck Endogas natürlich Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_PRECISION]: 'Partialdruck Endogas natürlich Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_AGILITY]: 'Partialdruck Endogas natürlich Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_ACCURACY]: 'Partialdruck Endogas Propan Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_PRECISION]: 'Partialdruck Endogas Propan Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_AGILITY]: 'Partialdruck Endogas Propan Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_ACCURACY]: 'Partialdruck Ethylen C2H4 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_PRECISION]: 'Partialdruck Ethylen C2H4 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_AGILITY]: 'Partialdruck Ethylen C2H4 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_ACCURACY]: 'Partialdruck Helium Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_PRECISION]: 'Partialdruck Helium Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_AGILITY]: 'Partialdruck Helium Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_ACCURACY]: 'Partialdruck Wasserstoff H2 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_PRECISION]: 'Partialdruck Wasserstoff H2 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_AGILITY]: 'Partialdruck Wasserstoff H2 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_ACCURACY]: 'Partialdruck Methanol CH3OH Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_PRECISION]: 'Partialdruck Methanol CH3OH Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_AGILITY]: 'Partialdruck Methanol CH3OH Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_ACCURACY]: 'Partialdruck Erdgas Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_PRECISION]: 'Partialdruck Erdgas Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_AGILITY]: 'Partialdruck Erdgas Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_ACCURACY]: 'Partialdruck Stickstoff Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_PRECISION]: 'Partialdruck Stickstoff Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_AGILITY]: 'Partialdruck Stickstoff Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_ACCURACY]: 'Partialdruck Lachgas N2O Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_PRECISION]: 'Partialdruck Lachgas N2O Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_AGILITY]: 'Partialdruck Lachgas N2O Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_ACCURACY]: 'Partialdruck Propan C3H8 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_PRECISION]: 'Partialdruck Propan C3H8 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_AGILITY]: 'Partialdruck Propan C3H8 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_ACCURACY]: 'Partialdruck Wasser Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_PRECISION]: 'Partialdruck Wasser Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_AGILITY]: 'Partialdruck Wasser Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Acetylen C2H2 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_PRECISION]: 'Partialdruck Acetylen C2H2 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_AGILITY]: 'Partialdruck Acetylen C2H2 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Luft Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_PRECISION]: 'Partialdruck Luft Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_AGILITY]: 'Partialdruck Luft Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Ammoniak NH3 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_PRECISION]: 'Partialdruck Ammoniak NH3 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_AGILITY]: 'Partialdruck Ammoniak NH3 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Argon Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_PRECISION]: 'Partialdruck Argon Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_AGILITY]: 'Partialdruck Argon Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Kohlendioxid CO2 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_PRECISION]: 'Partialdruck Kohlendioxid CO2 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_AGILITY]: 'Partialdruck Kohlendioxid CO2 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Kohlenmonoxid CO Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_PRECISION]: 'Partialdruck Kohlenmonoxid CO Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_AGILITY]: 'Partialdruck Kohlenmonoxid CO Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY]: 'Partialdruck dissoziiertes Ammoniak NH3 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_PRECISION]: 'Partialdruck dissoziiertes Ammoniak NH3 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_AGILITY]: 'Partialdruck dissoziiertes Ammoniak NH3 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Endogas natürlich Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_PRECISION]: 'Partialdruck Endogas natürlich Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_AGILITY]: 'Partialdruck Endogas natürlich Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Endogas Propan Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_PRECISION]: 'Partialdruck Endogas Propan Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_AGILITY]: 'Partialdruck Endogas Propan Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Ethylen C2H4 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_PRECISION]: 'Partialdruck Ethylen C2H4 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_AGILITY]: 'Partialdruck Ethylen C2H4 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Helium Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_PRECISION]: 'Partialdruck Helium Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_AGILITY]: 'Partialdruck Helium Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Wasserstoff H2 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_PRECISION]: 'Partialdruck Wasserstoff H2 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_AGILITY]: 'Partialdruck Wasserstoff H2 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Methanol CH3OH Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_PRECISION]: 'Partialdruck Methanol CH3OH Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_AGILITY]: 'Partialdruck Methanol CH3OH Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Erdgas Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_PRECISION]: 'Partialdruck Erdgas Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_AGILITY]: 'Partialdruck Erdgas Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Stickstoff Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_PRECISION]: 'Partialdruck Stickstoff Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_AGILITY]: 'Partialdruck Stickstoff Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Lachgas N2O Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_PRECISION]: 'Partialdruck Lachgas N2O Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_AGILITY]: 'Partialdruck Lachgas N2O Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Propan C3H8 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_PRECISION]: 'Partialdruck Propan C3H8 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_AGILITY]: 'Partialdruck Propan C3H8 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Wasser Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_PRECISION]: 'Partialdruck Wasser Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_AGILITY]: 'Partialdruck Wasser Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_ACCURACY]: 'Partialdruck Sauerstoff Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_PRECISION]: 'Partialdruck Sauerstoff Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_AGILITY]: 'Partialdruck Sauerstoff Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_ACCURACY]: 'Partialdruck Kohlenstoff Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_PRECISION]: 'Partialdruck Kohlenstoff Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_AGILITY]: 'Partialdruck Kohlenstoff Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_ACCURACY]: 'Partialdruck Bor Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_PRECISION]: 'Partialdruck Bor Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_AGILITY]: 'Partialdruck Bor Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_ACCURACY]: 'Partialdruck Schwefel Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_PRECISION]: 'Partialdruck Schwefel Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_AGILITY]: 'Partialdruck Schwefel Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Sauerstoff Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_PRECISION]: 'Partialdruck Sauerstoff Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_AGILITY]: 'Partialdruck Sauerstoff Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Kohlenstoff Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_PRECISION]: 'Partialdruck Kohlenstoff Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_AGILITY]: 'Partialdruck Kohlenstoff Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Bor Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_PRECISION]: 'Partialdruck Bor Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_AGILITY]: 'Partialdruck Bor Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Schwefel Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_PRECISION]: 'Partialdruck Schwefel Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_AGILITY]: 'Partialdruck Schwefel Prozent Regelagilität',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_ACCURACY]: 'Ethan C2H6 Durchfluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_AGILITY]: 'Ethan C2H6 Durchfluss Regelagilität',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_PRECISION]: 'Ethan C2H6 Durchfluss Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_ACCURACY]: 'Partialdruck Ethan C2H6 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_AGILITY]: 'Partialdruck Ethan C2H6 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_PRECISION]: 'Partialdruck Ethan C2H6 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Ethan C2H6 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_AGILITY]: 'Partialdruck Ethan C2H6 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_PRECISION]: 'Partialdruck Ethan C2H6 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_ACCURACY]: 'Partialdruck Methan CH4 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_AGILITY]: 'Partialdruck Methan CH4 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_PRECISION]: 'Partialdruck Methan CH4 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Methan CH4 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_AGILITY]: 'Partialdruck Methan CH4 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_PRECISION]: 'Partialdruck Methan CH4 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.HEAT_LOSS_AT_500C]: 'Heat loss (v2)',
  [EJobFeatureMeaningMap.ASSET_DWELLING_TIME]: 'Asset Verweildauer pro Job',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Hochvakuumdruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_AGILITY]: 'Hochvakuumdruck Regelagilität',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_PRECISION]: 'Hochvakuumdruck Regelpräzision',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Niderigvakuumdruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_AGILITY]: 'Niderigvakuumdruck Regelagilität',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_PRECISION]: 'Niderigvakuumdruck Regelpräzision',
  [EJobFeatureMeaningMap.VACUUM_TIME_TO_CROSS_OVER]: 'Zeit bis Vakuum cross over',
  [EJobFeatureMeaningMap.VACUUM_MAX_LEAK_UP_RATE]: 'Maximale leak up Zeit',
  [EJobFeatureMeaningMap.LEAD_TIME_CONTROL_ACCURACY]: 'Durchlaufzeit Regelgenauigkeit',
  [EJobFeatureMeaningMap.LEAD_TIME_CONTROL_AGILITY]: 'Durchlaufzeit Regelagilität',
  [EJobFeatureMeaningMap.LEAD_TIME_CONTROL_PRECISION]: 'Durchlaufzeit Regelpräzision',
  [EJobFeatureMeaningMap.BELT_SPEED_CONTROL_ACCURACY]: 'Bandgeschwindigkeit Regelgenauigkeit',
  [EJobFeatureMeaningMap.BELT_SPEED_CONTROL_AGILITY]: 'Bandgeschwindigkeit Regelagilität',
  [EJobFeatureMeaningMap.BELT_SPEED_CONTROL_PRECISION]: 'Bandgeschwindigkeit Regelpräzision',
  [EJobFeatureMeaningMap.PEARSON_CORRELATION]: 'Pearson Korrelation',
  [EJobFeatureMeaningMap.SPEARMAN_CORRELATION]: 'Spearman Korrelation',
  [EJobFeatureMeaningMap.USER_DEFINED_001]: 'Benutzerdefinierter Jobwert 001',
  [EJobFeatureMeaningMap.USER_DEFINED_002]: 'Benutzerdefinierter Jobwert 002',
  [EJobFeatureMeaningMap.USER_DEFINED_003]: 'Benutzerdefinierter Jobwert 003',
  [EJobFeatureMeaningMap.USER_DEFINED_004]: 'Benutzerdefinierter Jobwert 004',
  [EJobFeatureMeaningMap.USER_DEFINED_005]: 'Benutzerdefinierter Jobwert 005',
  [EJobFeatureMeaningMap.USER_DEFINED_006]: 'Benutzerdefinierter Jobwert 006',
  [EJobFeatureMeaningMap.USER_DEFINED_007]: 'Benutzerdefinierter Jobwert 007',
  [EJobFeatureMeaningMap.USER_DEFINED_008]: 'Benutzerdefinierter Jobwert 008',
  [EJobFeatureMeaningMap.USER_DEFINED_009]: 'Benutzerdefinierter Jobwert 009',
  [EJobFeatureMeaningMap.USER_DEFINED_010]: 'Benutzerdefinierter Jobwert 010',
  [EJobFeatureMeaningMap.USER_DEFINED_011]: 'Benutzerdefinierter Jobwert 011',
  [EJobFeatureMeaningMap.USER_DEFINED_012]: 'Benutzerdefinierter Jobwert 012',
  [EJobFeatureMeaningMap.USER_DEFINED_013]: 'Benutzerdefinierter Jobwert 013',
  [EJobFeatureMeaningMap.USER_DEFINED_014]: 'Benutzerdefinierter Jobwert 014',
  [EJobFeatureMeaningMap.USER_DEFINED_015]: 'Benutzerdefinierter Jobwert 015',
  [EJobFeatureMeaningMap.USER_DEFINED_016]: 'Benutzerdefinierter Jobwert 016',
  [EJobFeatureMeaningMap.USER_DEFINED_017]: 'Benutzerdefinierter Jobwert 017',
  [EJobFeatureMeaningMap.USER_DEFINED_018]: 'Benutzerdefinierter Jobwert 018',
  [EJobFeatureMeaningMap.USER_DEFINED_019]: 'Benutzerdefinierter Jobwert 019',
  [EJobFeatureMeaningMap.USER_DEFINED_020]: 'Benutzerdefinierter Jobwert 020',
  [EJobFeatureMeaningMap.USER_DEFINED_021]: 'Benutzerdefinierter Jobwert 021',
  [EJobFeatureMeaningMap.USER_DEFINED_022]: 'Benutzerdefinierter Jobwert 022',
  [EJobFeatureMeaningMap.USER_DEFINED_023]: 'Benutzerdefinierter Jobwert 023',
  [EJobFeatureMeaningMap.USER_DEFINED_024]: 'Benutzerdefinierter Jobwert 024',
  [EJobFeatureMeaningMap.USER_DEFINED_025]: 'Benutzerdefinierter Jobwert 025',
  [EJobFeatureMeaningMap.USER_DEFINED_026]: 'Benutzerdefinierter Jobwert 026',
  [EJobFeatureMeaningMap.USER_DEFINED_027]: 'Benutzerdefinierter Jobwert 027',
  [EJobFeatureMeaningMap.USER_DEFINED_028]: 'Benutzerdefinierter Jobwert 028',
  [EJobFeatureMeaningMap.USER_DEFINED_029]: 'Benutzerdefinierter Jobwert 029',
  [EJobFeatureMeaningMap.USER_DEFINED_030]: 'Benutzerdefinierter Jobwert 030',
  [EJobFeatureMeaningMap.USER_DEFINED_031]: 'Benutzerdefinierter Jobwert 031',
  [EJobFeatureMeaningMap.USER_DEFINED_032]: 'Benutzerdefinierter Jobwert 032',
  [EJobFeatureMeaningMap.USER_DEFINED_033]: 'Benutzerdefinierter Jobwert 033',
  [EJobFeatureMeaningMap.USER_DEFINED_034]: 'Benutzerdefinierter Jobwert 034',
  [EJobFeatureMeaningMap.USER_DEFINED_035]: 'Benutzerdefinierter Jobwert 035',
  [EJobFeatureMeaningMap.USER_DEFINED_036]: 'Benutzerdefinierter Jobwert 036',
  [EJobFeatureMeaningMap.USER_DEFINED_037]: 'Benutzerdefinierter Jobwert 037',
  [EJobFeatureMeaningMap.USER_DEFINED_038]: 'Benutzerdefinierter Jobwert 038',
  [EJobFeatureMeaningMap.USER_DEFINED_039]: 'Benutzerdefinierter Jobwert 039',
  [EJobFeatureMeaningMap.USER_DEFINED_040]: 'Benutzerdefinierter Jobwert 040',
  [EJobFeatureMeaningMap.USER_DEFINED_041]: 'Benutzerdefinierter Jobwert 041',
  [EJobFeatureMeaningMap.USER_DEFINED_042]: 'Benutzerdefinierter Jobwert 042',
  [EJobFeatureMeaningMap.USER_DEFINED_043]: 'Benutzerdefinierter Jobwert 043',
  [EJobFeatureMeaningMap.USER_DEFINED_044]: 'Benutzerdefinierter Jobwert 044',
  [EJobFeatureMeaningMap.USER_DEFINED_045]: 'Benutzerdefinierter Jobwert 045',
  [EJobFeatureMeaningMap.USER_DEFINED_046]: 'Benutzerdefinierter Jobwert 046',
  [EJobFeatureMeaningMap.USER_DEFINED_047]: 'Benutzerdefinierter Jobwert 047',
  [EJobFeatureMeaningMap.USER_DEFINED_048]: 'Benutzerdefinierter Jobwert 048',
  [EJobFeatureMeaningMap.USER_DEFINED_049]: 'Benutzerdefinierter Jobwert 049',
  [EJobFeatureMeaningMap.USER_DEFINED_050]: 'Benutzerdefinierter Jobwert 050',
  [EJobFeatureMeaningMap.USER_DEFINED_051]: 'Benutzerdefinierter Jobwert 051',
  [EJobFeatureMeaningMap.USER_DEFINED_052]: 'Benutzerdefinierter Jobwert 052',
  [EJobFeatureMeaningMap.USER_DEFINED_053]: 'Benutzerdefinierter Jobwert 053',
  [EJobFeatureMeaningMap.USER_DEFINED_054]: 'Benutzerdefinierter Jobwert 054',
  [EJobFeatureMeaningMap.USER_DEFINED_055]: 'Benutzerdefinierter Jobwert 055',
  [EJobFeatureMeaningMap.USER_DEFINED_056]: 'Benutzerdefinierter Jobwert 056',
  [EJobFeatureMeaningMap.USER_DEFINED_057]: 'Benutzerdefinierter Jobwert 057',
  [EJobFeatureMeaningMap.USER_DEFINED_058]: 'Benutzerdefinierter Jobwert 058',
  [EJobFeatureMeaningMap.USER_DEFINED_059]: 'Benutzerdefinierter Jobwert 059',
  [EJobFeatureMeaningMap.USER_DEFINED_060]: 'Benutzerdefinierter Jobwert 060',
  [EJobFeatureMeaningMap.USER_DEFINED_061]: 'Benutzerdefinierter Jobwert 061',
  [EJobFeatureMeaningMap.USER_DEFINED_062]: 'Benutzerdefinierter Jobwert 062',
  [EJobFeatureMeaningMap.USER_DEFINED_063]: 'Benutzerdefinierter Jobwert 063',
  [EJobFeatureMeaningMap.USER_DEFINED_064]: 'Benutzerdefinierter Jobwert 064',
  [EJobFeatureMeaningMap.USER_DEFINED_065]: 'Benutzerdefinierter Jobwert 065',
  [EJobFeatureMeaningMap.USER_DEFINED_066]: 'Benutzerdefinierter Jobwert 066',
  [EJobFeatureMeaningMap.USER_DEFINED_067]: 'Benutzerdefinierter Jobwert 067',
  [EJobFeatureMeaningMap.USER_DEFINED_068]: 'Benutzerdefinierter Jobwert 068',
  [EJobFeatureMeaningMap.USER_DEFINED_069]: 'Benutzerdefinierter Jobwert 069',
  [EJobFeatureMeaningMap.USER_DEFINED_070]: 'Benutzerdefinierter Jobwert 070',
  [EJobFeatureMeaningMap.USER_DEFINED_071]: 'Benutzerdefinierter Jobwert 071',
  [EJobFeatureMeaningMap.USER_DEFINED_072]: 'Benutzerdefinierter Jobwert 072',
  [EJobFeatureMeaningMap.USER_DEFINED_073]: 'Benutzerdefinierter Jobwert 073',
  [EJobFeatureMeaningMap.USER_DEFINED_074]: 'Benutzerdefinierter Jobwert 074',
  [EJobFeatureMeaningMap.USER_DEFINED_075]: 'Benutzerdefinierter Jobwert 075',
  [EJobFeatureMeaningMap.USER_DEFINED_076]: 'Benutzerdefinierter Jobwert 076',
  [EJobFeatureMeaningMap.USER_DEFINED_077]: 'Benutzerdefinierter Jobwert 077',
  [EJobFeatureMeaningMap.USER_DEFINED_078]: 'Benutzerdefinierter Jobwert 078',
  [EJobFeatureMeaningMap.USER_DEFINED_079]: 'Benutzerdefinierter Jobwert 079',
  [EJobFeatureMeaningMap.USER_DEFINED_080]: 'Benutzerdefinierter Jobwert 080',
};

const TimeseriesMeaning: Record<ETimeseriesMeaning, string> = {
  [ETimeseriesMeaning.TEMPERATURE_SETPOINT]: 'Temperatur Sollwert',
  [ETimeseriesMeaning.TEMPERATURE_READING]: 'Temperatur Istwert',
  [ETimeseriesMeaning.HEATING_POWER_OUTPUT_SETPOINT]: 'Heating power output Sollwert',
  [ETimeseriesMeaning.HEATING_POWER_OUTPUT_READING]: 'Heating power output Istwert',
  [ETimeseriesMeaning.HEATING_POWER_ENABLED]: 'Heating power enabled',
  [ETimeseriesMeaning.ELECTRICAL_POWER_SETPOINT]: 'Electrical power Sollwert',
  [ETimeseriesMeaning.ELECTRICAL_POWER_READING]: 'Electrical power Istwert',
  [ETimeseriesMeaning.TOTAL_HEATING_GAS_SUPPLY_FLOW_READING]: 'Total heating Gas supply Durchfluss Istwert',
  [ETimeseriesMeaning.COOLING_POWER_OUTPUT_SETPOINT]: 'Cooling power output Sollwert',
  [ETimeseriesMeaning.COOLING_POWER_OUTPUT_READING]: 'Cooling power output Istwert',
  [ETimeseriesMeaning.COOLING_POWER_ENABLED]: 'Cooling power enabled',
  [ETimeseriesMeaning.TOTAL_COOLING_GAS_SUPPLY_FLOW_READING]: 'Total cooling Gas supply Durchfluss Istwert',
  [ETimeseriesMeaning.RELATIVE_PRESSURE_SETPOINT]: 'Relative pressure Sollwert',
  [ETimeseriesMeaning.RELATIVE_PRESSURE_READING]: 'Relative pressure Istwert',
  [ETimeseriesMeaning.CHAMBER_TEMPERATURE_SETPOINT]: 'Kammertemperatur Sollwert',
  [ETimeseriesMeaning.CHAMBER_TEMPERATURE_READING]: 'Kammertemperatur Istwert',
  [ETimeseriesMeaning.ABSOLUTE_PRESSURE_SETPOINT]: 'Absolute pressure Sollwert',
  [ETimeseriesMeaning.ABSOLUTE_PRESSURE_READING]: 'Absolute pressure Istwert',
  [ETimeseriesMeaning.VACUUM_PRESSURE_SETPOINT]: 'Vacuum pressure Sollwert',
  [ETimeseriesMeaning.VACUUM_PRESSURE_READING]: 'Vacuum pressure Istwert',
  [ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_SETPOINT]: 'Atmospheric pressure Sollwert',
  [ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_READING]: 'Atmospheric pressure Istwert',
  [ETimeseriesMeaning.ROOM_TEMPERATURE_SETPOINT]: 'Room temperature Sollwert',
  [ETimeseriesMeaning.ROOM_TEMPERATURE_READING]: 'Room temperature Istwert',
  [ETimeseriesMeaning.ACTIVITY_AH_SETPOINT]: 'Aktivität aH Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AH_CALCULATION]: 'Aktivität aH Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_HP_SETPOINT]: 'Potential HP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_HP_CALCULATION]: 'Potential HP Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_KH_SETPOINT]: 'Potential kH Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KH_CALCULATION]: 'Potential kH Berechnnung',
  [ETimeseriesMeaning.ACTIVITY_AO_SETPOINT]: 'Aktivität aO Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AO_CALCULATION]: 'Aktivität aO Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_OP_SETPOINT]: 'Potential OP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_OP_CALCULATION]: 'Potential OP Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_KO_SETPOINT]: 'Potential kO Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KO_CALCULATION]: 'Potential kO Berechnnung',
  [ETimeseriesMeaning.ACTIVITY_AC_SETPOINT]: 'Aktivität aC Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AC_CALCULATION]: 'Aktivität aC Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_CP_SETPOINT]: 'Potential CP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_CP_CALCULATION]: 'Potential CP Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_KC_SETPOINT]: 'Potential kC Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KC_CALCULATION]: 'Potential kC Berechnnung',
  [ETimeseriesMeaning.ACTIVITY_AN_SETPOINT]: 'Aktivität aN Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AN_CALCULATION]: 'Aktivität aN Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_NP_SETPOINT]: 'Potential NP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_NP_CALCULATION]: 'Potential NP Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_KN_SETPOINT]: 'Potential kN Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KN_CALCULATION]: 'Potential kN Berechnnung',
  [ETimeseriesMeaning.ACTIVITY_AB_SETPOINT]: 'Aktivität aB Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AB_CALCULATION]: 'Aktivität aB Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_BP_SETPOINT]: 'Potential BP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_BP_CALCULATION]: 'Potential BP Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_KB_SETPOINT]: 'Potential kB Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KB_CALCULATION]: 'Potential kB Berechnnung',
  [ETimeseriesMeaning.ACTIVITY_AS_SETPOINT]: 'Aktivität aS Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AS_CALCULATION]: 'Aktivität aS Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_SP_SETPOINT]: 'Potential SP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_SP_CALCULATION]: 'Potential SP Berechnnung',
  [ETimeseriesMeaning.POTENTIAL_KS_SETPOINT]: 'Potential kS Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KS_CALCULATION]: 'Potential kS Berechnnung',
  [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT]: 'Acetylene C2H2 Durchfluss Sollwert',
  [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_READING]: 'Acetylene C2H2 Durchfluss Istwert',
  [ETimeseriesMeaning.AIR_FLOW_SETPOINT]: 'Air Durchfluss Sollwert',
  [ETimeseriesMeaning.AIR_FLOW_READING]: 'Air Durchfluss Istwert',
  [ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT]: 'Ammonia NH3 Durchfluss Sollwert',
  [ETimeseriesMeaning.AMMONIA_NH3_FLOW_READING]: 'Ammonia NH3 Durchfluss Istwert',
  [ETimeseriesMeaning.ARGON_FLOW_SETPOINT]: 'Argon Durchfluss Sollwert',
  [ETimeseriesMeaning.ARGON_FLOW_READING]: 'Argon Durchfluss Istwert',
  [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT]: 'Carbon dioxide CO2 Durchfluss Sollwert',
  [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_READING]: 'Carbon dioxide CO2 Durchfluss Istwert',
  [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT]: 'Carbon monoxide CO Durchfluss Sollwert',
  [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_READING]: 'Carbon monoxide CO Durchfluss Istwert',
  [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT]: 'Dissociated Ammonia NH3 Durchfluss Sollwert',
  [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_READING]: 'Dissociated Ammonia NH3 Durchfluss Istwert',
  [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT]: 'Endo Gas natural Durchfluss Sollwert',
  [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_READING]: 'Endo Gas natural Durchfluss Istwert',
  [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT]: 'Endo Gas Propan Durchfluss Sollwert',
  [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_READING]: 'Endo Gas Propan Durchfluss Istwert',
  [ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT]: 'Ethene C2H4 Durchfluss Sollwert',
  [ETimeseriesMeaning.ETHENE_C2H4_FLOW_READING]: 'Ethene C2H4 Durchfluss Istwert',
  [ETimeseriesMeaning.HELIUM_FLOW_SETPOINT]: 'Helium Durchfluss Sollwert',
  [ETimeseriesMeaning.HELIUM_FLOW_READING]: 'Helium Durchfluss Istwert',
  [ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT]: 'Hydrogen H2 Durchfluss Sollwert',
  [ETimeseriesMeaning.HYDROGEN_H2_FLOW_READING]: 'Hydrogen H2 Durchfluss Istwert',
  [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT]: 'Methanol CH3OH Durchfluss Sollwert',
  [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_READING]: 'Methanol CH3OH Durchfluss Istwert',
  [ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT]: 'Natural Gas Durchfluss Sollwert',
  [ETimeseriesMeaning.NATURAL_GAS_FLOW_READING]: 'Natural Gas Durchfluss Istwert',
  [ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT]: 'Nitrogen Durchfluss Sollwert',
  [ETimeseriesMeaning.NITROGEN_FLOW_READING]: 'Nitrogen Durchfluss Istwert',
  [ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT]: 'Propane C3H8 Durchfluss Sollwert',
  [ETimeseriesMeaning.PROPANE_C3H8_FLOW_READING]: 'Propane C3H8 Durchfluss Istwert',
  [ETimeseriesMeaning.WATER_FLOW_SETPOINT]: 'Wasser Durchfluss Sollwert',
  [ETimeseriesMeaning.WATER_FLOW_READING]: 'Wasser Durchfluss Istwert',
  [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT]: 'Nitrous oxide N2O Durchfluss Sollwert',
  [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_READING]: 'Nitrous oxide N2O Durchfluss Istwert',
  [ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT]: 'Methan CH4 Durchfluss Sollwert',
  [ETimeseriesMeaning.METHANE_CH4_FLOW_READING]: 'Methan CH4 Durchfluss Istwert',
  [ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT]: 'Sauerstoff O2 Durchfluss Sollwert',
  [ETimeseriesMeaning.OXYGEN_O2_FLOW_READING]: 'Sauerstoff O2 Durchfluss Istwert',
  [ETimeseriesMeaning.DISSOCIATION_SETPOINT]: 'Dissociation Sollwert',
  [ETimeseriesMeaning.DISSOCIATION_READING]: 'Dissociation Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_SETPOINT]: 'Partialdruck Acetylene C2H2 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_READING]: 'Partialdruck Acetylene C2H2 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_SETPOINT]: 'Partialdruck Luft Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_READING]: 'Partialdruck Luft Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_SETPOINT]: 'Partialdruck Ammonia NH3 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_READING]: 'Partialdruck Ammonia NH3 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_SETPOINT]: 'Partialdruck Argon Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_READING]: 'Partialdruck Argon Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_SETPOINT]: 'Partialdruck Kohlenstoffdioxid CO2 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_READING]: 'Partialdruck Kohlenstoffdioxid CO2 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_SETPOINT]: 'Partialdruck Kohlenstoffmonoxid CO Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_READING]: 'Partialdruck Kohlenstoffmonoxid CO Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_SETPOINT]: 'Partialdruck dissociated Ammonia NH3 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_READING]: 'Partialdruck dissociated Ammonia NH3 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_SETPOINT]: 'Partialdruck Endo Gas natural Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_READING]: 'Partialdruck Endo Gas natural Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_SETPOINT]: 'Partialdruck Endo Gas Propan Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_READING]: 'Partialdruck Endo Gas Propan Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_SETPOINT]: 'Partialdruck Ethene C2H4 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_READING]: 'Partialdruck Ethene C2H4 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_SETPOINT]: 'Partialdruck Helium Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_READING]: 'Partialdruck Helium Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_SETPOINT]: 'Partialdruck Wasserstoff H2 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_READING]: 'Partialdruck Wasserstoff H2 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_SETPOINT]: 'Partialdruck Methanol CH3OH Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_READING]: 'Partialdruck Methanol CH3OH Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_SETPOINT]: 'Partialdruck Erdgas Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_READING]: 'Partialdruck Erdgas Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_SETPOINT]: 'Partialdruck Stickstoff Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_READING]: 'Partialdruck Stickstoff Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_SETPOINT]: 'Partialdruck nitrous oxide N2O Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_READING]: 'Partialdruck nitrous oxide N2O Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_SETPOINT]: 'Partialdruck Propan C3H8 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_READING]: 'Partialdruck Propan C3H8 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_SETPOINT]: 'Partialdruck Wasser Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_READING]: 'Partialdruck Wasser Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_SETPOINT]: 'Partialdruck Acetylene C2H2 Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_READING]: 'Partialdruck Acetylene C2H2 Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_SETPOINT]: 'Partialdruck Luft Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_READING]: 'Partialdruck Luft Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_SETPOINT]: 'Partialdruck Ammonia NH3 Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_READING]: 'Partialdruck Ammonia NH3 Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_SETPOINT]: 'Partialdruck Argon Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_READING]: 'Partialdruck Argon Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_SETPOINT]: 'Partialdruck Kohlenstoffdioxid CO2 Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_READING]: 'Partialdruck Kohlenstoffdioxid CO2 Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_SETPOINT]: 'Partialdruck Kohlenstoffmonoxid CO Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_READING]: 'Partialdruck Kohlenstoffmonoxid CO Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_SETPOINT]: 'Partialdruck dissociated Ammonia NH3 Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_READING]: 'Partialdruck dissociated Ammonia NH3 Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_SETPOINT]: 'Partialdruck Endo Gas natural Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_READING]: 'Partialdruck Endo Gas natural Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_SETPOINT]: 'Partialdruck Endo Gas Propan Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_READING]: 'Partialdruck Endo Gas Propan Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_SETPOINT]: 'Partialdruck Ethene C2H4 Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_READING]: 'Partialdruck Ethene C2H4 Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_SETPOINT]: 'Partialdruck helium Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_READING]: 'Partialdruck helium Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_SETPOINT]: 'Partialdruck Wasserstoff H2 Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_READING]: 'Partialdruck Wasserstoff H2 Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_SETPOINT]: 'Partialdruck Methanol CH3OH Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_READING]: 'Partialdruck Methanol CH3OH Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_SETPOINT]: 'Partialdruck Erdgas Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_READING]: 'Partialdruck Erdgas Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_SETPOINT]: 'Partialdruck Stickstoff Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_READING]: 'Partialdruck Stickstoff Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_SETPOINT]: 'Partialdruck nitrous oxide N2O Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_READING]: 'Partialdruck nitrous oxide N2O Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_SETPOINT]: 'Partialdruck Propan C3H8 Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_READING]: 'Partialdruck Propan C3H8 Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_SETPOINT]: 'Partialdruck Wasser Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_READING]: 'Partialdruck Wasser Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_SETPOINT]: 'Partialdruck oxygen Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_READING]: 'Partialdruck oxygen Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_SETPOINT]: 'Partialdruck carbon Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_READING]: 'Partialdruck carbon Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_SETPOINT]: 'Partialdruck boron Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_READING]: 'Partialdruck boron Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_SETPOINT]: 'Partialdruck sulfur Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_READING]: 'Partialdruck sulfur Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_SETPOINT]: 'Partialdruck oxygen Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_READING]: 'Partialdruck oxygen Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_SETPOINT]: 'Partialdruck carbon Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_READING]: 'Partialdruck carbon Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_SETPOINT]: 'Partialdruck boron Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_READING]: 'Partialdruck boron Prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_SETPOINT]: 'Partialdruck sulfur Prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_READING]: 'Partialdruck sulfur Prozent Istwert',
  [ETimeseriesMeaning.ETHANE_C2H6_FLOW_SETPOINT]: 'Ethan C2H6 Durchfluss Sollwert',
  [ETimeseriesMeaning.ETHANE_C2H6_FLOW_READING]: 'Ethan C2H6 Durchfluss Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_SETPOINT]: 'Partialdruck Ethan C2H6 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_READING]: 'Partialdruck Ethan C2H6 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_SETPOINT]: 'Partialdruck Ethan C2H6 percent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_READING]: 'Partialdruck Ethan C2H6 percent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_SETPOINT]: 'Partialdruck mEthan CH4 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_READING]: 'Partialdruck mEthan CH4 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_SETPOINT]: 'Partialdruck mEthan CH4 percent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_READING]: 'Partialdruck mEthan CH4 percent Istwert',
  [ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_SETPOINT]: 'Hochvakuumdruck Sollwert',
  [ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_READING]: 'Hochvakuumdruck Istwert',
  [ETimeseriesMeaning.LOW_VACUUM_PRESSURE_SETPOINT]: 'Niedrigvakuumdruck Sollwert',
  [ETimeseriesMeaning.LOW_VACUUM_PRESSURE_READING]: 'Niedrigvakuumdruck Istwert',
  [ETimeseriesMeaning.LEAD_TIME_SETPOINT]: 'Durchlaufzeit Sollwert',
  [ETimeseriesMeaning.LEAD_TIME_READING]: 'Durchlaufzeit Istwert',
  [ETimeseriesMeaning.BELT_SPEED_SETPOINT]: 'Bandgeschwindigkeit Sollwert',
  [ETimeseriesMeaning.BELT_SPEED_READING]: 'Bandgeschwindigkeit Istwert',
  [ETimeseriesMeaning.USER_DEFINED_001]: 'Benutzerdefinierte Zeitreihe 001',
  [ETimeseriesMeaning.USER_DEFINED_002]: 'Benutzerdefinierte Zeitreihe 002',
  [ETimeseriesMeaning.USER_DEFINED_003]: 'Benutzerdefinierte Zeitreihe 003',
  [ETimeseriesMeaning.USER_DEFINED_004]: 'Benutzerdefinierte Zeitreihe 004',
  [ETimeseriesMeaning.USER_DEFINED_005]: 'Benutzerdefinierte Zeitreihe 005',
  [ETimeseriesMeaning.USER_DEFINED_006]: 'Benutzerdefinierte Zeitreihe 006',
  [ETimeseriesMeaning.USER_DEFINED_007]: 'Benutzerdefinierte Zeitreihe 007',
  [ETimeseriesMeaning.USER_DEFINED_008]: 'Benutzerdefinierte Zeitreihe 008',
  [ETimeseriesMeaning.USER_DEFINED_009]: 'Benutzerdefinierte Zeitreihe 009',
  [ETimeseriesMeaning.USER_DEFINED_010]: 'Benutzerdefinierte Zeitreihe 010',
  [ETimeseriesMeaning.USER_DEFINED_011]: 'Benutzerdefinierte Zeitreihe 011',
  [ETimeseriesMeaning.USER_DEFINED_012]: 'Benutzerdefinierte Zeitreihe 012',
  [ETimeseriesMeaning.USER_DEFINED_013]: 'Benutzerdefinierte Zeitreihe 013',
  [ETimeseriesMeaning.USER_DEFINED_014]: 'Benutzerdefinierte Zeitreihe 014',
  [ETimeseriesMeaning.USER_DEFINED_015]: 'Benutzerdefinierte Zeitreihe 015',
  [ETimeseriesMeaning.USER_DEFINED_016]: 'Benutzerdefinierte Zeitreihe 016',
  [ETimeseriesMeaning.USER_DEFINED_017]: 'Benutzerdefinierte Zeitreihe 017',
  [ETimeseriesMeaning.USER_DEFINED_018]: 'Benutzerdefinierte Zeitreihe 018',
  [ETimeseriesMeaning.USER_DEFINED_019]: 'Benutzerdefinierte Zeitreihe 019',
  [ETimeseriesMeaning.USER_DEFINED_020]: 'Benutzerdefinierte Zeitreihe 020',
  [ETimeseriesMeaning.USER_DEFINED_021]: 'Benutzerdefinierte Zeitreihe 021',
  [ETimeseriesMeaning.USER_DEFINED_022]: 'Benutzerdefinierte Zeitreihe 022',
  [ETimeseriesMeaning.USER_DEFINED_023]: 'Benutzerdefinierte Zeitreihe 023',
  [ETimeseriesMeaning.USER_DEFINED_024]: 'Benutzerdefinierte Zeitreihe 024',
  [ETimeseriesMeaning.USER_DEFINED_025]: 'Benutzerdefinierte Zeitreihe 025',
  [ETimeseriesMeaning.USER_DEFINED_026]: 'Benutzerdefinierte Zeitreihe 026',
  [ETimeseriesMeaning.USER_DEFINED_027]: 'Benutzerdefinierte Zeitreihe 027',
  [ETimeseriesMeaning.USER_DEFINED_028]: 'Benutzerdefinierte Zeitreihe 028',
  [ETimeseriesMeaning.USER_DEFINED_029]: 'Benutzerdefinierte Zeitreihe 029',
  [ETimeseriesMeaning.USER_DEFINED_030]: 'Benutzerdefinierte Zeitreihe 030',
  [ETimeseriesMeaning.USER_DEFINED_031]: 'Benutzerdefinierte Zeitreihe 031',
  [ETimeseriesMeaning.USER_DEFINED_032]: 'Benutzerdefinierte Zeitreihe 032',
  [ETimeseriesMeaning.USER_DEFINED_033]: 'Benutzerdefinierte Zeitreihe 033',
  [ETimeseriesMeaning.USER_DEFINED_034]: 'Benutzerdefinierte Zeitreihe 034',
  [ETimeseriesMeaning.USER_DEFINED_035]: 'Benutzerdefinierte Zeitreihe 035',
  [ETimeseriesMeaning.USER_DEFINED_036]: 'Benutzerdefinierte Zeitreihe 036',
  [ETimeseriesMeaning.USER_DEFINED_037]: 'Benutzerdefinierte Zeitreihe 037',
  [ETimeseriesMeaning.USER_DEFINED_038]: 'Benutzerdefinierte Zeitreihe 038',
  [ETimeseriesMeaning.USER_DEFINED_039]: 'Benutzerdefinierte Zeitreihe 039',
  [ETimeseriesMeaning.USER_DEFINED_040]: 'Benutzerdefinierte Zeitreihe 040',
  [ETimeseriesMeaning.USER_DEFINED_041]: 'Benutzerdefinierte Zeitreihe 041',
  [ETimeseriesMeaning.USER_DEFINED_042]: 'Benutzerdefinierte Zeitreihe 042',
  [ETimeseriesMeaning.USER_DEFINED_043]: 'Benutzerdefinierte Zeitreihe 043',
  [ETimeseriesMeaning.USER_DEFINED_044]: 'Benutzerdefinierte Zeitreihe 044',
  [ETimeseriesMeaning.USER_DEFINED_045]: 'Benutzerdefinierte Zeitreihe 045',
  [ETimeseriesMeaning.USER_DEFINED_046]: 'Benutzerdefinierte Zeitreihe 046',
  [ETimeseriesMeaning.USER_DEFINED_047]: 'Benutzerdefinierte Zeitreihe 047',
  [ETimeseriesMeaning.USER_DEFINED_048]: 'Benutzerdefinierte Zeitreihe 048',
  [ETimeseriesMeaning.USER_DEFINED_049]: 'Benutzerdefinierte Zeitreihe 049',
  [ETimeseriesMeaning.USER_DEFINED_050]: 'Benutzerdefinierte Zeitreihe 050',
  [ETimeseriesMeaning.USER_DEFINED_051]: 'Benutzerdefinierte Zeitreihe 051',
  [ETimeseriesMeaning.USER_DEFINED_052]: 'Benutzerdefinierte Zeitreihe 052',
  [ETimeseriesMeaning.USER_DEFINED_053]: 'Benutzerdefinierte Zeitreihe 053',
  [ETimeseriesMeaning.USER_DEFINED_054]: 'Benutzerdefinierte Zeitreihe 054',
  [ETimeseriesMeaning.USER_DEFINED_055]: 'Benutzerdefinierte Zeitreihe 055',
  [ETimeseriesMeaning.USER_DEFINED_056]: 'Benutzerdefinierte Zeitreihe 056',
  [ETimeseriesMeaning.USER_DEFINED_057]: 'Benutzerdefinierte Zeitreihe 057',
  [ETimeseriesMeaning.USER_DEFINED_058]: 'Benutzerdefinierte Zeitreihe 058',
  [ETimeseriesMeaning.USER_DEFINED_059]: 'Benutzerdefinierte Zeitreihe 059',
  [ETimeseriesMeaning.USER_DEFINED_060]: 'Benutzerdefinierte Zeitreihe 060',
  [ETimeseriesMeaning.USER_DEFINED_061]: 'Benutzerdefinierte Zeitreihe 061',
  [ETimeseriesMeaning.USER_DEFINED_062]: 'Benutzerdefinierte Zeitreihe 062',
  [ETimeseriesMeaning.USER_DEFINED_063]: 'Benutzerdefinierte Zeitreihe 063',
  [ETimeseriesMeaning.USER_DEFINED_064]: 'Benutzerdefinierte Zeitreihe 064',
  [ETimeseriesMeaning.USER_DEFINED_065]: 'Benutzerdefinierte Zeitreihe 065',
  [ETimeseriesMeaning.USER_DEFINED_066]: 'Benutzerdefinierte Zeitreihe 066',
  [ETimeseriesMeaning.USER_DEFINED_067]: 'Benutzerdefinierte Zeitreihe 067',
  [ETimeseriesMeaning.USER_DEFINED_068]: 'Benutzerdefinierte Zeitreihe 068',
  [ETimeseriesMeaning.USER_DEFINED_069]: 'Benutzerdefinierte Zeitreihe 069',
  [ETimeseriesMeaning.USER_DEFINED_070]: 'Benutzerdefinierte Zeitreihe 070',
  [ETimeseriesMeaning.USER_DEFINED_071]: 'Benutzerdefinierte Zeitreihe 071',
  [ETimeseriesMeaning.USER_DEFINED_072]: 'Benutzerdefinierte Zeitreihe 072',
  [ETimeseriesMeaning.USER_DEFINED_073]: 'Benutzerdefinierte Zeitreihe 073',
  [ETimeseriesMeaning.USER_DEFINED_074]: 'Benutzerdefinierte Zeitreihe 074',
  [ETimeseriesMeaning.USER_DEFINED_075]: 'Benutzerdefinierte Zeitreihe 075',
  [ETimeseriesMeaning.USER_DEFINED_076]: 'Benutzerdefinierte Zeitreihe 076',
  [ETimeseriesMeaning.USER_DEFINED_077]: 'Benutzerdefinierte Zeitreihe 077',
  [ETimeseriesMeaning.USER_DEFINED_078]: 'Benutzerdefinierte Zeitreihe 078',
  [ETimeseriesMeaning.USER_DEFINED_079]: 'Benutzerdefinierte Zeitreihe 079',
  [ETimeseriesMeaning.USER_DEFINED_080]: 'Benutzerdefinierte Zeitreihe 080',
  [ETimeseriesMeaning.USER_DEFINED_081]: 'Benutzerdefinierte Zeitreihe 081',
  [ETimeseriesMeaning.USER_DEFINED_082]: 'Benutzerdefinierte Zeitreihe 082',
  [ETimeseriesMeaning.USER_DEFINED_083]: 'Benutzerdefinierte Zeitreihe 083',
  [ETimeseriesMeaning.USER_DEFINED_084]: 'Benutzerdefinierte Zeitreihe 084',
  [ETimeseriesMeaning.USER_DEFINED_085]: 'Benutzerdefinierte Zeitreihe 085',
  [ETimeseriesMeaning.USER_DEFINED_086]: 'Benutzerdefinierte Zeitreihe 086',
  [ETimeseriesMeaning.USER_DEFINED_087]: 'Benutzerdefinierte Zeitreihe 087',
  [ETimeseriesMeaning.USER_DEFINED_088]: 'Benutzerdefinierte Zeitreihe 088',
  [ETimeseriesMeaning.USER_DEFINED_089]: 'Benutzerdefinierte Zeitreihe 089',
  [ETimeseriesMeaning.USER_DEFINED_090]: 'Benutzerdefinierte Zeitreihe 090',
  [ETimeseriesMeaning.USER_DEFINED_091]: 'Benutzerdefinierte Zeitreihe 091',
  [ETimeseriesMeaning.USER_DEFINED_092]: 'Benutzerdefinierte Zeitreihe 092',
  [ETimeseriesMeaning.USER_DEFINED_093]: 'Benutzerdefinierte Zeitreihe 093',
  [ETimeseriesMeaning.USER_DEFINED_094]: 'Benutzerdefinierte Zeitreihe 094',
  [ETimeseriesMeaning.USER_DEFINED_095]: 'Benutzerdefinierte Zeitreihe 095',
  [ETimeseriesMeaning.USER_DEFINED_096]: 'Benutzerdefinierte Zeitreihe 096',
  [ETimeseriesMeaning.USER_DEFINED_097]: 'Benutzerdefinierte Zeitreihe 097',
  [ETimeseriesMeaning.USER_DEFINED_098]: 'Benutzerdefinierte Zeitreihe 098',
  [ETimeseriesMeaning.USER_DEFINED_099]: 'Benutzerdefinierte Zeitreihe 099',
  [ETimeseriesMeaning.USER_DEFINED_100]: 'Benutzerdefinierte Zeitreihe 100',
};

const PhysicalDimension: Record<EPhysicalDimension, string> = {
  [EPhysicalDimension.ACCELERATION]: 'Beschleunigung',
  [EPhysicalDimension.ADMITTANCE]: 'Admittanz',
  [EPhysicalDimension.AMOUNT]: 'Betrag',
  [EPhysicalDimension.ANGLE]: 'Winkel',
  [EPhysicalDimension.ANGULAR_ACCELERATION]: 'Winkelbeschleunigung',
  [EPhysicalDimension.ANGULAR_MOMENTUM]: 'Drehimpuls',
  [EPhysicalDimension.ANGULAR_VELOCITY]: 'Winkelgeschwindigkeit',
  [EPhysicalDimension.APPARENT_ELECTRICAL_POWER]: 'Elektrische Leistung',
  [EPhysicalDimension.AREA]: 'Fläche',
  [EPhysicalDimension.CAPACITANCE]: 'Kapazität',
  [EPhysicalDimension.COOLING_RATE]: 'Abkühlungsrate',
  [EPhysicalDimension.CURRENCY]: 'Währung',
  [EPhysicalDimension.DATETIME]: 'Zeitpunkt',
  [EPhysicalDimension.DATE]: 'Datum',
  [EPhysicalDimension.DENSITY]: 'Dichte',
  [EPhysicalDimension.DURATION]: 'Dauer',
  [EPhysicalDimension.ELECTRICAL_REACTIVE_POWER]: 'Elektrische Blindleistung',
  [EPhysicalDimension.ELECTRICAL_RESISTANCE]: 'Elektrischer Widerstand',
  [EPhysicalDimension.ELECTRIC_CHARGE]: 'Elektrische Ladung',
  [EPhysicalDimension.ELECTRIC_CURRENT]: 'Elektrischer Strom',
  [EPhysicalDimension.ENERGY]: 'Energie',
  [EPhysicalDimension.FLOW]: 'Durchfluss',
  [EPhysicalDimension.FLOW_PER_AREA]: 'Durchfluss pro Fläche',
  [EPhysicalDimension.FORCE]: 'Kraft',
  [EPhysicalDimension.FREQUENCY]: 'Frequenz',
  [EPhysicalDimension.HARDNESS]: 'Härte',
  [EPhysicalDimension.HEAT_FLUX]: 'Wärmestrom',
  [EPhysicalDimension.IDENTITY]: 'Identität',
  [EPhysicalDimension.INDUCTIVITY]: 'Induktivität',
  [EPhysicalDimension.INTERVAL]: 'Intervall',
  [EPhysicalDimension.LENGTH]: 'Länge',
  [EPhysicalDimension.MAGNETIC_FLUX]: 'Magnetischer Fluss',
  [EPhysicalDimension.MASS]: 'Masse',
  [EPhysicalDimension.MASS_FLOW_RATE]: 'Massendurchsatz',
  [EPhysicalDimension.MASS_FRACTION]: 'Massenanteil',
  [EPhysicalDimension.MODULUS_OF_ELASTICITY]: 'Elastizitätsmodul',
  [EPhysicalDimension.MOMENTUM]: 'Momentum',
  [EPhysicalDimension.MOMENT_OF_INERTIA]: 'Trägheitsmoment',
  [EPhysicalDimension.POWER]: 'Leistung',
  [EPhysicalDimension.POWER_DENSITY]: 'Leistungsdichte',
  [EPhysicalDimension.PRESSURE]: 'Druck',
  [EPhysicalDimension.PRESSURE_RATE]: 'Druckrate',
  [EPhysicalDimension.QUANTITY]: 'Menge',
  [EPhysicalDimension.RATIO]: 'Verhältnis',
  [EPhysicalDimension.ROTATIONAL_VELOCITY]: 'Rotationsgeschwindigkeit',
  [EPhysicalDimension.SOUND_PRESSURE]: 'Schalldruck',
  [EPhysicalDimension.STRESS]: 'Spannung',
  [EPhysicalDimension.SURFACE_TENSION]: 'Oberflächenspannung',
  [EPhysicalDimension.TEMPERATURE]: 'Temperatur',
  [EPhysicalDimension.THERMAL_EXPANSION]: 'Thermische Ausdehnung',
  [EPhysicalDimension.THERMAL_RESISTANCE]: 'Thermischer Widerstand',
  [EPhysicalDimension.TIME]: 'Zeit',
  [EPhysicalDimension.TORQUE]: 'Drehmoment',
  [EPhysicalDimension.VELOCITY]: 'Geschwindigkeit',
  [EPhysicalDimension.VISCOSITY]: 'Viskosität',
  [EPhysicalDimension.VOLTAGE]: 'Spannung',
  [EPhysicalDimension.VOLUME]: 'Volumen',
  [EPhysicalDimension.WORK]: 'Arbeit',
};

const CalendarEventType: Record<ECalendarEventType, string> = {
  [ECalendarEventType.ACTUAL_UNIT_DOWNTIME]: 'Tatsächliche Ausfallzeit',
  [ECalendarEventType.PLANNED_UNIT_DOWNTIME]: 'Geplante Ausfallzeit',
};

const HierarchyLabel: Record<EHierarchy, string> = {
  [EHierarchy.CHAMBER]: generalTerm.chamber,
  [EHierarchy.COMPANY]: generalTerm.company,
  [EHierarchy.COMPONENT]: generalTerm.component,
  [EHierarchy.EQUIPMENT]: generalTerm.equipment,
  [EHierarchy.AUTOMAT]: generalTerm.automat,
  [EHierarchy.EQUIPMENT_GROUP]: generalTerm.equipmentGroup,
  [EHierarchy.LOCATION]: generalTerm.location,
  [EHierarchy.ROOT]: generalTerm.root,
};

export const NotificationChannelType: Record<ENotificationChannelType, string> = {
  [ENotificationChannelType.EMAIL]: generalTerm.email,
  [ENotificationChannelType.IN_APP]: 'In-App',
  [ENotificationChannelType.SMS]: generalTerm.sms,
};

const SimulationStep: Record<ESimulationStep, string> = {
  [ESimulationStep.RUNTIME_SECONDS]: 'Laufzeit in Sekunden',
  [ESimulationStep.STAGE_INDEX]: 'Stufenindex',
  [ESimulationStep.STAGE_RUNTIME_SECONDS]: 'Stufenlaufzeit in Sekunden',
  [ESimulationStep.TEMPERATURE]: 'Temperatur',
  [ESimulationStep.CARBON_POTENTIAL]: 'Kohlenstoffpotential',
  [ESimulationStep.NITROGEN_POTENTIAL]: 'Stickstoffpotential',
  [ESimulationStep.CARBURIZING_DEPTH]: 'CHD',
  [ESimulationStep.CARBURIZING_DEPTH_SET_POINT]: 'Festgelegte Eindringtiefe der Aufkohlung',
  [ESimulationStep.CARBURIZING_DEPTH_CARBON_CONTENT]: 'Kohlenstoffgehalt bei CHD',
  [ESimulationStep.SURFACE_CARBON]: 'Randkohlenstoff',
  [ESimulationStep.SURFACE_NITROGEN]: 'Randstickstoff',
  [ESimulationStep.SURFACE_CARBIDE]: 'Oberflächencarbid',
  [ESimulationStep.SURFACE_HARDNESS_SETPOINT]: 'Oberflächenhärte Soll',
  [ESimulationStep.EFFECTIVE_CASE_HARDNESS]: 'Effektive Randschichthärte',
  [ESimulationStep.CARBIDE_LIMIT]: 'Carbidgrenze',
  [ESimulationStep.SOOT_LIMIT]: 'Rußgrenze',
  [ESimulationStep.CASE_DEPTH]: 'Randschichttiefe',
  [ESimulationStep.CARBON_PROFILE]: 'C-Profil',
  [ESimulationStep.CARBON_PROFILE_MIN]: 'C-Profil (min)',
  [ESimulationStep.CARBON_PROFILE_MAX]: 'C-Profil (max)',
  [ESimulationStep.NITROGEN_PROFILE]: 'N-Profil',
  [ESimulationStep.NITROGEN_PROFILE_MIN]: 'N-Profil (min)',
  [ESimulationStep.NITROGEN_PROFILE_MAX]: 'N-Profil (max)',
  [ESimulationStep.CARBIDE_PROFILE]: 'Carbidprofil',
  [ESimulationStep.CARBIDE_PROFILE_MIN]: 'Carbidprofil (min)',
  [ESimulationStep.CARBIDE_PROFILE_MAX]: 'Carbidprofil (max)',
  [ESimulationStep.HARDNESS_PROFILE]: 'Härterverlaufsprofil',
  [ESimulationStep.HARDNESS_PROFILE_MIN]: 'Härterverlaufsprofil (min)',
  [ESimulationStep.HARDNESS_PROFILE_MAX]: 'Härterverlaufsprofil (max)',
  [ESimulationStep.N_SOLUBILITY_PROFILE]: 'Aktuelle Nitridgrenze',
  [ESimulationStep.NITRIDE_PROFILE]: 'Nitridprofil',
  [ESimulationStep.NITRIDE_PROFILE_MIN]: 'Nitridprofil (min)',
  [ESimulationStep.NITRIDE_PROFILE_MAX]: 'Nitridprofil (max)',
  [ESimulationStep.PROFILE_X_VALUES]: 'Werte entlang des Profils von außen nach innen in mm',
};

const Asset: Partial<TComponentKeys<'Asset'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
};

const AssetProfileStatus: Record<EAssetProfileStatus, string> = {
  [EAssetProfileStatus.COMPLETE]: 'Vollständig',
  [EAssetProfileStatus.INCOMPLETE]: 'Unvollständig',
  [EAssetProfileStatus.INVALID]: 'Ungültig',
};

const AssetProfileCombinator: Record<EAssetProfileCombinator, string> = {
  [EAssetProfileCombinator.ANY_OF]: 'Beliebige',
  [EAssetProfileCombinator.ALL_OF]: 'Alle',
  [EAssetProfileCombinator.ONE_OF]: 'Einer',
};

const ObservationType: Record<EObservationType, string> = {
  [EObservationType.LOWER_CONTROL_LIMIT_EXCEEDED]: 'Untere Kontrollgrenze unterschritten',
  [EObservationType.UPPER_CONTROL_LIMIT_EXCEEDED]: 'Obere Kontrollgrenze überschritten',
  [EObservationType.NEGATIVE_TREND_DETECTED]: 'Negativer Trend erkannt',
  [EObservationType.POSITIVE_TREND_DETECTED]: 'Positiver Trend erkannt',
  [EObservationType.LOWER_CUSTOM_LIMIT_EXCEEDED]: 'Untere benutzerdefinierte Grenze überschritten',
  [EObservationType.UPPER_CUSTOM_LIMIT_EXCEEDED]: 'Obere benutzerdefinierte Grenze überschritten',
};

const ChamberType: Record<EChamberType, string> = {
  [EChamberType.HOT]: 'Heiß',
  [EChamberType.ISO_THERMAL]: 'Isothermal',
  [EChamberType.QUENCH]: 'Quenching',
  [EChamberType.VACUM]: 'Vacuum',
};

const HeatTreatmentProcessType: Record<EHeatTreatmentProcessType, string> = {
  [EHeatTreatmentProcessType.ANNEALING]: 'Glühen',
  [EHeatTreatmentProcessType.QUENCHING]: 'Abschrecken',
  [EHeatTreatmentProcessType.NEUTRAL_HARDENING]: 'Neutralglühen',
  [EHeatTreatmentProcessType.CRYOGENIC_TREATMENT]: 'Kryogene Behandlung',
  [EHeatTreatmentProcessType.TEMPERING]: 'Anlassen',
  [EHeatTreatmentProcessType.AGING]: 'Altern',
  [EHeatTreatmentProcessType.CARBURIZING]: 'Vergüten',
  [EHeatTreatmentProcessType.CARBONITRIDING]: 'Carbonitrieren',
  [EHeatTreatmentProcessType.NITRIDING]: 'Nitrieren',
  [EHeatTreatmentProcessType.NITROCARBURIZING]: 'Nitrocarburieren',
  [EHeatTreatmentProcessType.PRE_OXIDATION]: 'Voroxidation',
  [EHeatTreatmentProcessType.POST_OXIDATION]: 'Nachoxidation',
  [EHeatTreatmentProcessType.ACTIVATION]: 'Aktivierung',
};

const AppGroupSemantic: Record<EAppGroupSemantic, string> = {
  [EAppGroupSemantic.OPERATION]: 'Betrieb',
  [EAppGroupSemantic.DATA]: 'Data/Insight',
  [EAppGroupSemantic.MAINTENANCE]: 'Wartung',
  [EAppGroupSemantic.PROCESS]: 'Prozess',
  [EAppGroupSemantic.CONFIGURATION]: 'Konfiguration',
};

const AppId: Record<EAppId, string> = {
  [EAppId.ALARM_STATISTICS]: 'Alarmstatistik',
  [EAppId.ANOMALY_DETECTION]: 'Anomalie-Erkennung',
  [EAppId.ASSET_MANAGEMENT]: 'Asset Management',
  [EAppId.CERTIFICATION]: 'Audit & Quality',
  [EAppId.CUSTOMER]: 'Kundendatenbank',
  [EAppId.DEPRECATED_MODEL_PROCESS]: 'Modelprozesse',
  // [EAppId.DEPRECATED_NITREX_LIVE]: 'Nitrex Connect',
  // [EAppId.DEPRECATED_PT9800_RECIPES]: 'Rezepte (PT9800)',
  // [EAppId.DEPRECATED_PT_9800_CLOUD]: 'PT9800 Webclient',
  // [EAppId.DEPRECATED_RECIPE_NPM]: 'Rezepte (Nitride)',
  // [EAppId.DEPRECATED_USER_SETTINGS]: 'Einstellungen',
  [EAppId.GRAFANA]: 'Grafana',
  [EAppId.HISTORY_AND_TRENDS]: 'History and Trends',
  [EAppId.LIVE]: 'Live app',
  [EAppId.MAINTENANCE_CALENDAR]: 'Wartungskalender',
  [EAppId.MAINTENANCE_DASHBOARD]: 'Wartungsdashboard',
  [EAppId.MATERIALS]: 'Materialdatenbank',
  [EAppId.MODEL_BUILDER]: 'Model Builder',
  // [EAppId.ML_PROTOTYPING]: 'ML Prototyping',
  [EAppId.OPERATION_CALENDAR]: 'Produktionskalender',
  [EAppId.PARTS]: 'Parts',
  [EAppId.PERFORMANCE_DASHBOARD]: 'Auslastungsübersicht',
  [EAppId.PRODUCTION_LOGS]: 'Produktionshistorie',
  [EAppId.PRODUCTION_STATISTICS]: 'Produktionsstatistik',
  [EAppId.PT9800_CONFIGURATION]: 'PT9800 Konfiguration',
  [EAppId.RECIPE]: 'Rezepte',
  [EAppId.RECIPE_ON_DEMAND]: 'Rezeptsuche',
  [EAppId.REMOTE_CONTROL]: 'Fernsteuerung',
  [EAppId.SHOP_LAYOUT]: 'Hallen-Layout',
  [EAppId.SIMULATOR]: 'Simulatoren',
  [EAppId.TENANT_SETTINGS]: 'Einstellungen',
  [EAppId.UPC_PRO]: 'UPC Werkzeugkasten',
  [EAppId.USER_MANAGEMENT]: 'Benutzerverwaltung',
};

const CarburizingSimulationPartShape: Record<ECarburizingSimulationPartShape, string> = {
  [ECarburizingSimulationPartShape.ROUND_STEEL]: 'Rundstahl',
  [ECarburizingSimulationPartShape.HEXAGON_OR_OCTAGON_STEEL]: 'Sechseck- oder Achteckstahl',
  [ECarburizingSimulationPartShape.SQUARE_OR_FLAT_STEEL]: 'Quadrat- oder Flachstahl',
  [ECarburizingSimulationPartShape.SOLID_STEEL]: 'Vollmaterial oder Ring (Innendurchmesser < 200mm)',
  [ECarburizingSimulationPartShape.RING]: 'Ring (Innendurchmesser > 200 mm)',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_80]: 'Zyl. Hohlkörper Innendurchmesser < 80 mm',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_200]: 'Zyl. Hohlkörper Innendurchmesser < 200 mm',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_GREATER_200]: 'Zyl. Hohlkörper Innendurchmesser > 200 mm',
  [ECarburizingSimulationPartShape.HOLLOW_BODY_CLOSED]: 'Hohlkörper, geschlossen von einer oder zwei Seiten',
};

const CarburizingSimulationPartShapeSize: Record<ECarburizingSimulationPartShape, string> = {
  [ECarburizingSimulationPartShape.ROUND_STEEL]: 'Nenndurchmesser',
  [ECarburizingSimulationPartShape.HEXAGON_OR_OCTAGON_STEEL]: 'Nennabstand zwischen gegenüberl. Seiten',
  [ECarburizingSimulationPartShape.SQUARE_OR_FLAT_STEEL]: 'Kürzeste Kantenlänge',
  [ECarburizingSimulationPartShape.SOLID_STEEL]: 'Kürzeste Kantenlänge',
  [ECarburizingSimulationPartShape.RING]: 'Kürzeste Kantenlänge',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_80]: 'Wandstärke',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_200]: 'Wandstärke',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_GREATER_200]: 'Wandstärke',
  [ECarburizingSimulationPartShape.HOLLOW_BODY_CLOSED]: 'Wandstärke',
};

const CarburizingEndControlType: Record<ECarburizingEndControlType, string> = {
  [ECarburizingEndControlType.END_TEMPERATURE]: 'Endtemperatur',
  [ECarburizingEndControlType.CARBON_DEPTH]: 'Aufkohlungstiefe',
  [ECarburizingEndControlType.RUNTIME]: 'Laufzeit',
  [ECarburizingEndControlType.SURFACE_CARBON]: 'Rand C %',
  [ECarburizingEndControlType.CP_PERCENT]: 'C Potenzial %',
};

const CarburizingSimulatorRecipeType: Record<ECarburizingSimulatorRecipeType, string> = {
  [ECarburizingSimulatorRecipeType.CARBURIZING]: 'Aufkohlen [C]',
  [ECarburizingSimulatorRecipeType.CARBONITRIDING]: 'Carbonitrieren [CN]',
  [ECarburizingSimulatorRecipeType.LOW_PRESSURE_CARBURIZING]: 'Niederdruckaufkohlen [LPC]',
};

const PartMaterialProperties: TComponentKeys<'PartMaterialProperties'> = {
  surfaceCarbon: 'Randkohlenstoffgehalt [%]',
  referenceCarbon: 'Grenzkohlenstoffgehalt [%]',
  alloyFactorCAustenite: 'Legierungsfaktor [Carbon]',
  carbideLimit: 'Carbidgrenze [%aC=1]',
  alloyFactorNAustenite: 'Legierungsfaktor [Nitrogen]',
  nitrideLimit: 'Nitridgrenze [%FexNy]',
  coreHardness: 'Kernhärte (unbehandelt) (HV)',
};

const SimulatorRecipe: Record<
  keyof components['schemas']['CarburizingRecipe']
  | keyof components['schemas']['CarbonitridingRecipe']
  | keyof components['schemas']['LowPressureCarburizingRecipe'],
  string> = {
    c2H2Content: 'C2H2',
    c3H8Content: 'C3H8',
    coContent: 'CO',
    h2Content: 'H2',
    description: generalTerm.description, // Beschreibung
    displayName: generalTerm.name, // Anzeigename
    isGasValuesConstant: '',
    recipeType: 'Wärmebehandlungsart',
    simulationValuesType: 'Art',
    simulatorCarburizingRecipeId: '',
    simulatorPartId: '',
    stages: '',
    userDefinedAlloyFactor: 'Legierungsfaktor C',
    userDefinedCarbideLimit: 'Carbidgrenze',
    userDefinedCarbonContent: 'Kernkohlenstoffgehalt',
    userDefinedCarburizingDepth: 'Aufkohlungstiefe',
    userDefinedReferenceCarbon: 'Grenzkohlenstoffgehalt',
  };

const NotificationTable: Record<ESubscriptionTreeLabel, Record<'event' | 'description', string>> = {
  [ESubscriptionTreeLabel.ALARMS]: {
    event: 'Alarme',
    description: 'Erhalte eine Benachrichtigung, sobald ein Alarmereignis beliebiger Schwere auftritt.',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_ALARM_OCCURRED]: {
    event: 'Alarm',
    description: 'Benachrichtigung bei Alarmereignissen.',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_EMERGENCY_OCCURRED]: {
    event: 'Notfall',
    description: 'Benachrichtigung bei Notfallereignissen. Notfallereignisse werden nicht von allen Controllertypen unterstützt. Notfallereignisse führen dazu, dass der jeweilige Job sofort abgebrochen oder unterbrochen wird und möglicherweise ein menschliches Eingreifen erfordert.',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_WARNING_OCCURRED]: {
    event: 'Warnung',
    description: 'Benachrichtigung bei Warnereignissen. Warnungen werden nicht von allen Controllertypen unterstützt.',
  },
  [ESubscriptionTreeLabel.JOBS]: {
    event: 'Jobs',
    description: 'Erhalte eine Benachrichtigung, wenn sich der Status eines Jobs ändert.',
  },
  [ESubscriptionTreeLabel.JOB_FINISHED]: {
    event: 'Job beendet',
    description: 'Ein Job wurde beendet.',
  },
  [ESubscriptionTreeLabel.JOB_STARTED]: {
    event: 'Job gestartet',
    description: 'Ein Job wurde gestartet.',
  },
  [ESubscriptionTreeLabel.TIMESERIES_LAG_DETECTED]: {
    event: 'Zeitreihen nicht mehr aktuell',
    description: 'Mindestens eine Zeitreihe eines Ofens hängt mehr als 30 Minuten hinterher.',
  },
  [ESubscriptionTreeLabel.NEW_QMULUS_RELEASE]: {
    event: 'Neue Qmulus Version',
    description: 'Eine neue Version von Qmulus wurde veröffentlicht.',
  },
  [ESubscriptionTreeLabel.ANOMALIES_AND_OBSERVATIONS]: {
    event: 'Anomalien & Beobachtungen',
    description: 'Erhalte eine Benachrichtigung, wenn eine Anomalie beobachtet wurde.',
  },
  [ESubscriptionTreeLabel.CONTROL_LEVEL_EXCEEDED]: {
    event: 'Kontrollniveau überschritten',
    description: 'Das Kontrollniveau des Sensors wurde überschritten.',
  },
  [ESubscriptionTreeLabel.TREND_DETECTED]: {
    event: 'Trend erkannt',
    description: 'Ein neuer Trend in den Sensormessungen wurde erkannt.',
  },
  [ESubscriptionTreeLabel.DRIFT_DETECTED]: {
    event: 'Drift erkannt',
    description: 'Die Sensormessungen weichen von den Referenzwerten ab.',
  },
};

const SubscriptionTreeNode: Partial<TComponentKeys<'SubscriptionTreeNode'>> = {
  notificationMetadataId: generalTerm.description,
  label: generalTerm.event,
  children: '',
  subscriptions: '',
  isLeaf: '',
};

const CarburizingRecipeSimulationValuesType: Record<ECarburizingRecipeSimulationValuesType, string> = {
  [ECarburizingRecipeSimulationValuesType.AVG_VAL]: 'Durchschnitt',
  [ECarburizingRecipeSimulationValuesType.COMP_VAL]: 'Mittlere Analyse',
  [ECarburizingRecipeSimulationValuesType.USER_VAL]: 'Benutzerdefiniert',
};

const AppGroupCommercial: Record<EAppGroupCommercial, string> = {
  [EAppGroupCommercial.QMULUS_ESSENTIAL]: 'QMULUS Essential',
  [EAppGroupCommercial.QMULUS_LITE]: 'QMULUS Lite',
  [EAppGroupCommercial.SETTINGS]: 'Settings',
  [EAppGroupCommercial.QMULUS_EXPERT]: 'QMULUS Expert',
  [EAppGroupCommercial.SIMULATORS]: 'Simulators',
  [EAppGroupCommercial.QMULUS_OPERATIONS]: 'QMULUS Operations',
  [EAppGroupCommercial.QMULUS_PERFORMANCE]: 'QMULUS Performance',
  [EAppGroupCommercial.QMULUS_AI]: 'QMULUS AI',
  [EAppGroupCommercial.QMULUS_ANALYTICS]: 'QMULUS Analytics',
  [EAppGroupCommercial.QMULUS_AUDIT]: 'QMULUS Audit',
  [EAppGroupCommercial.QMULUS_QUALITY]: 'QMULUS Quality',
  [EAppGroupCommercial.QMULUS_PLANNER]: 'QMULUS Planner',
  [EAppGroupCommercial.QMULUS_TOOLING_AND_GAUGE_MANAGER]: 'QMULUS Tooling and Gauge Manager',
  [EAppGroupCommercial.QMULUS_AUTOMATION]: 'QMULUS Automation',
};

const DataFormat: Record<EDataFormat, string> = {
  [EDataFormat.TIME_SERIES]: 'Zeitreihe',
  [EDataFormat.JOB_AGGREGATE]: 'Jobwert',
  [EDataFormat.EVENT]: 'Event',
  [EDataFormat.ALARM_EVENT]: 'Alarm',
  [EDataFormat.BLOB]: 'Binary Large Object (BLOB)',
};

const CarburizingTemperatureControlType: Record<ECarburizingTemperatureControlType, string> = {
  [ECarburizingTemperatureControlType.HEAT]: 'Heizen',
  [ECarburizingTemperatureControlType.COOL]: 'Kühlen',
  [ECarburizingTemperatureControlType.HEAT_UP]: 'Aufheizen',
  [ECarburizingTemperatureControlType.HEAT_DOWN]: 'Abkühlen',
  [ECarburizingTemperatureControlType.HOLD]: 'Halten',
};

const CarburizingVolumeControlType: Record<ECarburizingVolumeControlType, string> = {
  [ECarburizingVolumeControlType.OFF]: 'Aus',
  [ECarburizingVolumeControlType.ON]: 'Ein',
  [ECarburizingVolumeControlType.AUTO]: 'Auto',
};

const CarburizingCarbonControlType: Record<ECarburizingCarbonControlType, string> = {
  [ECarburizingCarbonControlType.OFF]: 'Aus',
  [ECarburizingCarbonControlType.ON]: 'Ein',
  [ECarburizingCarbonControlType.SOOT_LIMIT]: 'Rußgrenze %',
  [ECarburizingCarbonControlType.AUTO_100_X]: 'Auto (100-x) %',
  [ECarburizingCarbonControlType.SURFACE_CARBON]: 'Rand C %',
  [ECarburizingCarbonControlType.PLUS_C]: 'Plus C %',
  [ECarburizingCarbonControlType.MINUS_C]: 'Minus C %',
};

const CarburizingEndControlTypeStrings: Record<ECarburizingEndControlType, string> = {
  [ECarburizingEndControlType.END_TEMPERATURE]: 'Endtemperatur',
  [ECarburizingEndControlType.CARBON_DEPTH]: 'Aufkohlungstiefe',
  [ECarburizingEndControlType.RUNTIME]: 'Laufzeit',
  [ECarburizingEndControlType.SURFACE_CARBON]: 'Rand C %',
  [ECarburizingEndControlType.CP_PERCENT]: 'C Potenzial %',
};

const CarburizingNitrogenControlType: Record<ECarburizingNitrogenControlType, string> = {
  [ECarburizingNitrogenControlType.OFF]: 'Aus',
  [ECarburizingNitrogenControlType.ON]: 'Ein',
  [ECarburizingNitrogenControlType.NITRIDE_100_X]: 'Nitride (100-x)%',
  [ECarburizingNitrogenControlType.SURF_N]: 'Rand N %',
};

const ObservationTypeHeader: Record<string, string> = {
  name: 'Name des Beobachtungstyps',
};

const LegacyEquipmentResponse: TComponentLegacyKeys<'EquipmentResponse'> = {
  customerName: generalTerm.customer,
  department: 'Abteilung',
  equipmentId: generalTerm.assetId,
  imageURL: 'Bild',
  location: generalTerm.location,
  locationId: generalTerm.locationId,
  modelName: generalTerm.assetType,
  name: generalTerm.asset,
  scadaType: generalTerm.scadaType,
  shortDescription: 'Beschreibung',
  status: 'Status',
  systemId: 'System id',
};

const MaintenanceJob: TComponentLegacyKeys<'MaintenanceJob'> = {
  description: 'Beschreibung',
  displayName: 'Wartungsjobname',
  durationOverheadMinutes: 'Gesamtdauer',
  id: 'Id',
  tasks: 'Aufgaben',
};

const MaintenanceTask: TComponentLegacyKeys<'MaintenanceTask'> = {
  description: 'Beschreibung',
  displayName: 'Aufgabenname',
  durationMinutes: 'Dauer',
  id: 'Id',
};

const AssetUtilization: Partial<TComponentKeys<'AssetUtilization'>> = {
  assetDisplayName: generalTerm.asset,
  noProductionSeconds: 'Keine Produktion',
  actualProductionTimeSeconds: 'Produktive Zeit',
  actualUnitDowntimeSeconds: 'Tatsächliche Stillstandszeit',
  plannedDowntimeSeconds: 'Geplante Stillstandszeit',
  noProductionPercentage: 'Keine Produktion',
  actualProductionTimePercentage: 'Produktive Zeit',
  actualUnitDowntimePercentage: 'Tatsächliche Stillstandszeit',
  plannedDowntimePercentage: 'Geplante Stillstandszeit',
  effectiveTargetProductivityPercentage: 'Zielproduktivität',
};

const AuditSpecificationResultOverview: TComponentKeys<'AuditSpecificationResultOverview'> = {
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  auditResultId: 'Report No',
  auditedAtUtc: generalTerm.date,
  auditedByUserName: 'Auditor',
  jobName: generalTerm.jobDisplayName,
  recipeDisplayName: generalTerm.recipe,
  jobId: '',
};

const AuditEnvelopeNonPassedReasons: Record<components['schemas']['AuditEnvelopeNonPassedReasons'], string> = {
  no_start_point_found: 'Kein Startpunkt gefunden',
  values_out_of_band: 'Werte außerhalb des Bands',
  max_duration_exceeded: 'Maximale Dauer überschritten',
  min_duration_not_reached: 'Minimale Dauer nicht erreicht',
  job_is_running: 'Job ist nicht beendet',
  meanings_missing: 'Keine Signale mit Bedeutung vorhanden fuer das Asset.',
  no_data: 'Keine Daten',
  data_ended_unexpected: 'Daten endeten unerwartet',
};

const Part: Partial<TComponentKeys<'Part'>> = {
  displayName: 'Teil',
  description: 'Teil Beschreibung',
  materialDisplayName: 'Material',
  treatmentRemarks: 'Hinweis zur Behandlung',
  customerDisplayName: 'Kunde',
  drawingReference: 'Zeichnung',
  lastModifiedAtUtc: 'Geändert am',
  lastModifiedByUserDisplayName: 'Geändert von',
};

const Customer: Partial<TComponentKeys<'Customer'>> = {
  displayName: 'Unternehmen',
  email: 'E-Mail',
  phone: 'Telefon',
  cellphone: 'Mobiltelefon',
  fax: 'Fax',
};

const Material: Partial<TComponentKeys<'Material-Output'>> = {
  displayName: 'Materialname',
  materialComposition: 'Materialzusammensetzung',
  materialGroups: 'Materialgruppen',
  materialNames: 'Materialnamen',
  materialId: 'Material-ID',
};

const MaterialNamingSystem: Partial<TComponentKeys<'MaterialNamingSystem'>> = {
  displayName: 'Name',
};

const ChartAssessmentTestRequirementType: Record<components['schemas']['ChartAssessmentTestRequirementType'], string> = {
  setpoints_greater_than_measures: 'Messwerte < Sollwerte',
  setpoints_greater_than_or_equal_to_measures: 'Messwerte ≤ Sollwerte',
  setpoints_less_than_measures: 'Messwerte > Sollwerte',
  setpoints_less_than_or_equal_to_measures: 'Messwerte ≥ Sollwerte',
};

const User: Partial<TComponentKeys<'User'>> = {
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'Email',
  rolesDisplayName: 'Rollen',
};

const OpenAPIPrimitiveType: Record<components['schemas']['OpenAPIPrimitiveType'], string> = {
  boolean: 'Boolean',
  integer: 'Ganzzahl',
  number: 'Fließkommazahl',
  string: 'Text',
};

const ProductionStatistics: Partial<TComponentKeys<'ProductionStatistics'>> = {
  displayName: generalTerm.name,
  earliestJobStartedAtUtc: 'Frühester Job Startzeitpunkt',
  latestJobFinishedAtUtc: 'Spätester Job Endzeitpunkt',
  jobCnt: 'Anzahl Jobs',
};

const CsvSeperator: Record<components['schemas']['CsvSeperator'], string> = {
  comma: 'Komma',
  semicolon: 'Semikolon',
  tab: 'Tabulator',
  pipe: 'Rohr',
  space: 'Leerzeichen',
};

const CsvDatetimeFormat: Record<components['schemas']['CsvDatetimeFormat'], string> = {
  iso_8601: 'ISO 8601',
  unix_timestamp: 'UNIX',
  us: 'US',
};

const CsvLineTerminator: Record<components['schemas']['CsvLineTerminator'], string> = {
  posix: 'POSIX',
  windows: 'Windows',
};

const CsvQuoteStyle: Record<components['schemas']['CsvQuoteStyle'], string> = {
  necessary: 'Erforderlich',
  always: 'Immer',
  non_numeric: 'Nicht numerisch',
  never: 'Nie',
};

const CsvQuoteStyleHint: Record<components['schemas']['CsvQuoteStyle'], string> = {
  necessary: 'Sie sind notwendig, wenn Felder ein Anführungszeichen, einen Separator oder einen Datensatzabschluss enthalten. Anführungszeichen sind auch notwendig, wenn ein leerer Datensatz geschrieben wird (der sich nicht von einem Datensatz mit einem leeren Feld unterscheidet). Dies ist der Standard.',
  always: 'Dies setzt Anführungszeichen um jedes Feld. Immer.',
  non_numeric: 'Dies setzt Anführungszeichen um alle Felder, die nicht numerisch sind. Nämlich, wenn ein Feld geschrieben wird, das nicht als gültige Fließkommazahl oder Ganzzahl geparst werden kann, dann werden Anführungszeichen verwendet, auch wenn sie nicht unbedingt erforderlich sind.',
  never: 'Dies setzt niemals Anführungszeichen um Felder, selbst wenn dies zu ungültigen CSV-Daten führt (z.B.: indem keine Anführungszeichen bei Zeichenfolgen gesetzt werden, die den Separator enthalten).',
};

const CsvQuoteCharacter: Record<components['schemas']['CsvQuoteCharacter'], string> = {
  single_quote: 'Einzelnes Zitatzeichen',
  double_quote: 'Doppeltes Zitatzeichen',
};

const PartAssetQualification: Partial<TComponentKeys<'PartAssetQualification'>> = {
  assetDisplayName: generalTerm.asset,
  assetId: generalTerm.asset,
  maxNumberOfPartsPerRost: 'Maximale Anzahl der Teile pro Rost',
  recipeDisplayName: generalTerm.recipe,
  recipeId: generalTerm.recipe,
};

const PartAttribute: Partial<TComponentKeys<'PartAttribute'>> & {
  value: string,
} = {
  displayName: 'Attribute',
  unitOfMeasureId: 'Einheit',
  value: 'Wert',
  valueType: 'Datentyp',
};

const AuditSpecification: TComponentKeys<'AuditSpecification'> = {
  auditSpecificationId: 'Audit Spezifikations ID',
  description: 'Beschreibung',
  displayName: 'Spezifikationsname',
  envelopes: 'Anzahl der Umschläge',
  revision: 'Revisions ID',
  isOneTimeSpec: '',
};

const AlarmCollection: TComponentKeys<'AlarmCollection'> = {
  dataId: 'Data id',
  displayName: 'Name',
  eventType: 'Alarm type',
  occurrenceData: generalTerm.date,
  severity: 'Alarm type',
};

const BusinessConfigurationOutput: Partial<TComponentLegacyKeys<'BusinessConfiguration'>> = {
  planned_outage_days_per_year: 'Geplante Ausfalltage pro Jahr',
  working_shifts_per_week: 'Arbeitsschichten pro Woche',
  hours_per_shift: 'Stunden pro Schicht',
  hourly_billing_rate: 'Stündlicher Abrechnungssatz',
  hourly_depreciation_rate: 'Stündliche Abschreibungsrate',
};

const CounterAction: Partial<TComponentKeys<'CounterAction'>> = {
  displayName: 'Gegenmaßnahme',
  description: generalTerm.description,
};

const JobFeature: Partial<TComponentKeys<'Metadata'>> = {
  displayName: 'Name der Job-Funktion',
  description: generalTerm.description,
};

const Sample: Partial<TComponentKeys<'Sample'>> = {
  displayName: 'Stichprobe',
};

const TableAssessmentTest: Partial<TComponentKeys<'TableAssessmentTest'>> = {
  displayName: generalTerm.name,
  isOverwritten: generalTerm.overwrite,
};

const TableAssessmentTestItem: Partial<Record<keyof components['schemas']['TableAssessmentTest']['items'][number], string>> = {
  displayName: generalTerm.name,
  requirement: generalTerm.requirement,
  result: generalTerm.result,
};

const AssessmentTestResultType = 'Test result';

const ChartAssessmentTest: Partial<TComponentKeys<'ChartAssessmentTest'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  xUnitId: 'X-Einheit',
  yUnitId: 'Y-Einheit',
  requirement: 'Überprüfung',
  isOverwritten: generalTerm.overwrite,
};

const MaterialPropertySpectrum: Record<keyof components['schemas']['MaterialPropertySpectrum']['avg'], string> = {
  alloyFactorCAustenite: 'Legierungsfaktor [Carbon]',
  carbideLimit: 'Carbidgrenze [%aC=1]',
  alloyFactorNAustenite: 'Legierungsfaktor [Nitrogen]',
  nitrideLimit: 'Nitridgrenze [%FexNy]',
  coreHardness: 'Kernhärte (unbehandelt) [HV]',
};

const FileAssessmentTest: Partial<TComponentKeys<'FileAssessmentTest'>> = {
  displayName: generalTerm.name,
  result: 'Testergebnis',
  resultComment: 'Kommentar',
  isOverwritten: generalTerm.overwrite,
};

const ReportOverview: Partial<TComponentKeys<'ReportOverview'>> = {
  reportId: generalTerm.reportId,
  reporterDisplayName: generalTerm.reporter,
  jobId: generalTerm.jobId,
  jobDisplayName: generalTerm.job,
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  assetLocation: generalTerm.location,
  recipeDisplayName: generalTerm.recipe,
  partId: generalTerm.partId,
  partDisplayName: generalTerm.part,
  partCustomerDisplayName: generalTerm.customer,
  partMaterialDisplayName: generalTerm.material,
  partDrawingReference: generalTerm.drawingReference,
  orderNumbers: 'Auftrag',
  isAutogenerated: 'Importiert aus Fremdsystem',
  orderCreatedAtUtc: 'Auftragdatum',
  isDeleted: generalTerm.showDeleted,
};

const Job: Partial<TComponentKeys<'Job'>> = {
  assetDisplayName: generalTerm.asset,
  displayName: generalTerm.jobDisplayName,
  locationDisplayName: 'Location',
  durationSeconds: 'Dauer',
  description: 'Beschreibung',
  startedAtUtc: generalTerm.jobStartedAtUtc,
  finishedAtUtc: generalTerm.jobFinishedAtUtc,
  recipeDisplayName: generalTerm.recipe,
  hasAlarms: 'Alarms',
  jobVerdict: 'Bewertung',
};

const MaterialCalculationModelOutput: Partial<TComponentKeys<'MaterialCalculationModel-Output'>> = {
  displayName: 'Name',
  description: 'Beschreibung',
};

const JobStage: Partial<TComponentKeys<'JobStage'>> | Record<'state', string> = {
  displayName: 'Schritt',
  state: 'Status',
  jobDisplayName: generalTerm.jobDisplayName,
  startedAtUtc: generalTerm.jobStartedAtUtc,
  finishedAtUtc: generalTerm.jobFinishedAtUtc,
  durationSeconds: 'Dauer',
  stageNo: 'Nr',
};

const AlarmSeverity: Partial<Record<components['schemas']['AlarmSeverity'], string>> = {
  0: 'INFO',
  1: 'WARNING',
  2: 'ALARM',
  3: 'EMERGENCY',
};

const MaintenanceOccurrenceIdentifier: Partial<TComponentLegacyKeys<'MaintenanceOccurrenceIdentifier'>> = {
  id: 'Anfrage-ID',
  maintenanceSeriesDisplayName: 'Beschreibung',
  state: 'Anfragestatus',
  assignedTo: 'Rechtsnachfolger',
  locationDisplayName: 'Location',
  assetDisplayName: 'Asset',
  scheduledCompletedAtUtc: generalTerm.date,
  scheduledAtUtc: generalTerm.date,
  maintenanceJobDisplayName: 'Load No',
};

const MaintenanceOccurrence: Partial<TComponentLegacyKeys<'MaintenanceOccurrence'>> = {
  assetDisplayName: 'Name des Ofens',
  scheduledAtUtc: 'Wartungsbeginn',
  scheduledCompletedAtUtc: 'Wartungsende',
  maintenanceSeriesDisplayName: 'Wartungsart',
  description: 'Beschreibung',
  report: 'Bericht',
};

const MaintenanceSeries: Partial<TComponentLegacyKeys<'MaintenanceSeries'>> = {
  displayName: 'Name der Aufgabe',
  description: 'Beschreibung',
  maintenanceJobId: 'Wartungsarbeit',
  recurrence: 'Wiederholung',
  priority: 'Priorität',
  color: 'Farbe',
  validFromUtc: 'Geplantes Datum',
  assetId: 'Asset',
  ownerId: 'Zuordnen zu',
};

const Equipment: Partial<TComponentLegacyKeys<'EquipmentResponse'>> = {
  modelName: 'Ofentyp',
};

const RootCauseGroup: Partial<TComponentKeys<'RootCauseGroup'>> = {
  displayName: 'Name der Ursachengruppe',
  description: generalTerm.description,
};

const EquipmentProfile: Partial<TComponentKeys<'ProfileTreeNode'>> = {
  label: 'Profilname',
  combinator: 'Kombination Typ',
};

// eslint-disable-next-line no-underscore-dangle, camelcase
const TimeBucket_AssetUtilization_: Partial<TComponentKeys<'TimeBucket_AssetUtilization_'>> = {
  earliestDateUtc: 'Start date',
  latestDateUtc: 'End date',
};

const EquipmentProfileMeaning: Partial<TComponentKeys<'Meaning'>> = {
  label: 'Bedeutung des Namens',
  description: 'Beschreibung',
  physicalDimensionId: 'Physikalische Größe',
  preferredUnitId: 'Bevorzugte Einheit',
};

const EquipmentTreeNavigation: Partial<TComponentKeys<'AssetTreeNode'>> = {
  displayName: 'Name',
};

const MaterialGroup: Partial<TComponentKeys<'MaterialGroup'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  materialIds: 'Materialien',
};

const SettingsActivityCoefficients: Record<EActivityCoefficients, string> = {
  [EActivityCoefficients.ALLOYING_ELEMENT_ID]: 'Element',
  [EActivityCoefficients.DESCRIPTION]: 'Comment',
  [EActivityCoefficients.E_JC_AUSTENITE]: 'eJC (Austenite)',
  [EActivityCoefficients.E_JC_FERRITE]: 'eJC (Ferrite)',
  [EActivityCoefficients.E_JN_AUSTENITE]: 'eJN (Austenite)',
  [EActivityCoefficients.E_JN_FERRITE]: 'eJN (Ferrite)',
  [EActivityCoefficients.R_JJC_AUSTENITE]: 'rJJC (Austenite)',
  [EActivityCoefficients.R_JJC_FERRITE]: 'rJJC (Ferrite)',
  [EActivityCoefficients.R_JJN_AUSTENITE]: 'rJJN (Austenite)',
  [EActivityCoefficients.R_JJN_FERRITE]: 'rJJN (Ferrite)',
};

const MaintenanceState: Record<EMaintenanceState, string> = {
  [EMaintenanceState.PENDING]: 'Pending',
  [EMaintenanceState.ACCEPTED]: 'Angenommen',
  [EMaintenanceState.IN_PROGRESS]: 'In Bearbeitung',
  [EMaintenanceState.DONE]: 'Erledigt',
  [EMaintenanceState.VALIDATED]: 'Validiert',
};

const MaintenancePriority: Record<EMaintenancePriority, string> = {
  [EMaintenancePriority.LOW]: 'Niedrig',
  [EMaintenancePriority.NORMAL]: 'Normal',
  [EMaintenancePriority.HIGH]: 'Hoch',
};

const MaintenancePeriod: Record<ECalendarPeriod, string> = {
  [ECalendarPeriod.WEEK]: 'Woche',
  [ECalendarPeriod.MONTH]: 'Monat',
  [ECalendarPeriod.YEAR]: 'Jahr',
};

const MaintenanceRecurrenceMonthDayValue: Record<EMaintenanceRecurrenceMonthDayValue, string> = {
  [EMaintenanceRecurrenceMonthDayValue.FIRST]: 'Erste',
  [EMaintenanceRecurrenceMonthDayValue.SECOND]: 'Zweite',
  [EMaintenanceRecurrenceMonthDayValue.THIRD]: 'Dritter',
  [EMaintenanceRecurrenceMonthDayValue.FOURTH]: 'Vierte',
  [EMaintenanceRecurrenceMonthDayValue.LAST]: 'Letzter',
};

const MaintenanceRecurrenceMonthDayDimension: Record<EMaintenanceRecurrenceMonthDayDimension, string> = {
  [EMaintenanceRecurrenceMonthDayDimension.WEEKDAY]: 'Wochentag',
  [EMaintenanceRecurrenceMonthDayDimension.WEEKEND_DAY]: 'Wochenendtag',
  [EMaintenanceRecurrenceMonthDayDimension.DAY]: 'Tag',
};

const MaintenanceRecurrenceEndCondition: Record<EMaintenanceRecurrenceEndCondition, string> = {
  [EMaintenanceRecurrenceEndCondition.AFTER]: 'nach',
  [EMaintenanceRecurrenceEndCondition.ON_THIS_DAY]: 'an diesem Tag',
};

const MetadataWithChartConfigInput: Partial<TComponentKeys<'MetadataWithChartConfig'>> = {
  isHidden: 'Sichtbarkeit',
  displayName: generalTerm.name,
  color: 'Farbe',
  guaranteedColor: 'Farbe',
  description: generalTerm.description,
  unitOfMeasureId: 'Einheit',
  dataSourceReference: 'Referenz der Datenquelle',
  meaningId: 'Meaning',
  significantDigits: 'Signifikante Ziffern',
};

const RootCause: Partial<TComponentKeys<'RootCause'> & {
  count: string,
}> = {
  displayName: 'Ursache',
  description: generalTerm.description,
  count: 'Anzahl',
};

const JobValueData: Partial<TComponentKeys<'JobValueData'>> = {
  jobId: 'JobId',
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartUtc: generalTerm.jobStartedAtUtc,
  jobEndUtc: generalTerm.jobFinishedAtUtc,
  value: 'Wert',
  recipeId: 'RecipeId',
  recipeRevision: 'RecipeRevision',
  recipeDisplayName: generalTerm.recipe,
};

const QualityPreset: Partial<TComponentKeys<'QualityPreset'>> = {
  displayName: generalTerm.name,
};

const Observation: Partial<TComponentKeys<'Observation'>> = {
  displayName: 'Beobachtung',
  observationTimeAtUtc: 'Zeitpunkt der Beobachtung',
  jobFeatureDisplayName: 'Job Feature',
  observationType: 'Beobachtungstyp',
  jobValue: 'Wert',
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartedAtUtc: generalTerm.jobStartedAtUtc,
  jobFinishedAtUtc: generalTerm.jobFinishedAtUtc,
};

const EquipmentDocument: Partial<TComponentLegacyKeys<'EquipmentDocument'>> = {
  name: 'Dateiname',
  description: 'Beschreibung',
  category: 'Kategorie',
  username: 'Hochgeladen von',
};

const InstructionEquipment: Partial<TComponentLegacyKeys<'EquipmentDocument'>> = {
  name: 'Dateiname',
  description: 'Beschreibung',
  username: 'Hochgeladen von',
};

const QuenchingMedia: Partial<TComponentKeys<'QuenchingMedia'>> = {
  displayName: generalTerm.name,
  maxIntensity: 'Maximale Intensität',
  minIntensity: 'Minimale Intensität',
  quenchingMediaId: 'ID',
};

const expressionFunctionCategories: Record<TFunctionCategory, string> & {all: string} = {
  all: 'Alle',
  arithmetic: 'Arithmetische Funktionen',
  cumulative: 'Kumulative Funktionen',
  custom: 'Benutzerdefinierte Funktionen',
  exponential: 'Exponential Funktionen',
  generative: 'Generative Funktionen',
  imputation: 'Imputations Funktionen',
  logical: 'Logische Funktionen',
  numerical: 'Numerische Funktionen',
  operator: 'Operatoren',
  other: 'Andere Funktionen',
  sections: 'Bereichsfunktionen',
  statistical: 'Statistische Funktionen',
  trigonometric: 'Trigonometrische Funktionen',
};

const expressionFunctionsExplanations: {
  functions: Record<TFunctionDefinition['functionType'], string>,
  operators: Record<TOperatorDefinition['operatorType'], string>,
} = {
  functions: {
    abs: 'Diese Funktion berechnet die absoluten Werte eines Signals elementweise.',
    acos: 'Diese Funktion berechnet den Arkuskosinus jedes Elements eines Signals.',
    add: 'Addition. Addiert zwei Operanden zusammen.',
    asin: 'Diese Funktion berechnet den Arkussinus jedes Elements eines Signals.',
    atan2: 'Diese Funktion berechnet den Arkustangens von a/b für jedes entsprechende Elementenpaar.',
    atan: 'Diese Funktion berechnet den Arkustangens jedes Elements eines Signals.',
    avg: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion den Durchschnitt über jedes Signal. Andernfalls berechnet sie den Durchschnitt aller Proben.',
    avg_windowed: 'Diese Funktion berechnet den Mittelwert der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    bfill: 'Füllt NaN-Werte, indem der nächste gültige Wert verwendet wird, um die Lücke zu füllen.',
    ceil: 'Diese Funktion rundet jedes Element eines Signals auf die nächste Ganzzahl auf.',
    clamp: 'Diese Funktion klemmt die Werte eines Signals innerhalb eines angegebenen Bereichs ab.',
    cos: 'Diese Funktion berechnet den Kosinus jedes Elements eines Signals.',
    count_samples: 'Accumulates the number of samples where the input signal evaluates to true.',
    count_seconds: 'Accumulates the seconds where the input signal evaluates to true.',
    counter: 'Diese Funktion erhöht einen Zähler für jeden Zeitschritt um 1.',
    cumulative_avg: 'Gibt den kumulierten Durchschnitt des Signals zurück.',
    cumulative_integrate: 'Gibt das kumulierte Integral (unter Verwendung der Trapezregel über Sekunden) zurück.',
    cumulative_max: 'Gibt das kumulierte Maximum des Signals zurück.',
    cumulative_mean: 'Gibt den kumulierten Mittelwert des Signals zurück.',
    cumulative_median: 'Gibt den kumulierten Median des Signals zurück.',
    cumulative_min: 'Gibt das kumulierte Minimum des Signals zurück.',
    cumulative_range: 'Gibt den kumulierten Bereich des Signals zurück.',
    cumulative_std: 'Gibt die kumulierte Standardabweichung des Signals zurück.',
    cumulative_sum: 'Gibt die kumulierte Summe des Signals zurück, wobei "Not a Number" (NaN) als Null behandelt wird. Die kumulierte Summe ändert sich nicht, wenn NaNs auftreten, und führende NaNs werden durch Nullen ersetzt. Für Signale, die nur NaN oder leer sind, werden Nullen zurückgegeben.',
    cumulative_var: 'Gibt die kumulierte Varianz des Signals zurück.',
    degrees: 'Diese Funktion wandelt jedes Element eines Signals von Radianten in Grad um.',
    denormalize: 'Diese Funktion denormalisiert die Werte eines Signals vom Bereich [0, 1] in den angegebenen Bereich.',
    diff: 'Diese Funktion berechnet die Differenz zwischen aufeinanderfolgenden Elementen eines Signals.',
    divide: 'Division. Teilt den ersten Operanden durch den zweiten.',
    do_nothing: 'Diese Funktion gibt das Eingangssignal zurück.',
    elapsed_seconds: 'Diese Funktion gibt die Anzahl der vergangenen Sekunden seit Beginn des Zeitrahmens zurück.',
    equals: 'Gleichheit. Gibt true zurück, wenn die Operanden gleich sind, ansonsten false.',
    exp: 'Diese Funktion berechnet die Exponentialfunktion jedes Elements eines Signals.',
    fill: 'Füllt NaN-Werte in Signal a mit Werten von Signal b.',
    ffill: 'Füllt NaN-Werte, indem der letzte gültige Wert zum nächsten gültigen propagiert wird.',
    floor: 'Diese Funktion rundet jedes Element eines Signals auf die nächste Ganzzahl ab.',
    floor_divide: 'Ganzzahlige Division. Gibt den Quotienten der Division zurück und verwirft dabei den Rest.',
    gradient: 'Numerische Ableitung des Eingangssignals pro Sekunde (1/s).',
    gradient_windowed: 'Numerische Ableitung über ein gegebenes dx (in Sekunden). Falls dx negativ oder null ist, ist der Rückgabewert NaN. Der parameter dx wird als Konstante interpretiert.',
    greater_than: 'Größer als. Gibt true zurück, wenn der erste Operand größer als der zweite ist.',
    greater_than_or_equal: 'Größer als oder gleich. Gibt true zurück, wenn der erste Operand größer oder gleich dem zweiten ist.',
    if: 'Diese Funktion führt eine bedingte Operation elementweise basierend auf einer Bedingung durch.',
    integrate: 'Diese Funktion integriert das Signal über Sekunden unter Verwendung der Trapezregel.',
    interpfill: 'Füllt NaN-Werte, indem linear zwischen dem letzten und dem nächsten gültigen Wert interpoliert wird.',
    is_finite: 'Diese Funktion überprüft, ob die Elemente eines Signals elementweise endlich sind.',
    less_than: 'Kleiner als. Gibt true zurück, wenn der erste Operand kleiner als der zweite ist.',
    less_than_or_equal: 'Kleiner als oder gleich. Gibt true zurück, wenn der erste Operand kleiner oder gleich dem zweiten ist.',
    ln: 'Diese Funktion berechnet den natürlichen Logarithmus jedes Elements eines Signals.',
    log10: 'Diese Funktion berechnet den Logarithmus zur Basis 10 jedes Elements eines Signals.',
    log2: 'Diese Funktion berechnet den Logarithmus zur Basis 2 jedes Elements eines Signals.',
    log: 'Diese Funktion berechnet den Logarithmus jedes Elements eines Signals zur Basis b.',
    logical_and: 'Logisches UND. Gibt true zurück, wenn beide Operanden true sind, ansonsten false.',
    logical_not: 'Unäres logisches NICHT. Gibt true zurück, wenn das Operand falsch ist, und false, wenn das Operand wahr ist.',
    logical_or: 'Logisches ODER. Gibt true zurück, wenn mindestens einer der Operanden true ist, ansonsten false.',
    logical_xor: 'Logisches XOR. Gibt false zurück, wenn beide Operatoren den gleichen logischen Zustand haben, ansonsten true.',
    max: 'Wenn mehrere Argumente gegeben sind, findet diese Funktion den maximalen Wert unter den Signalen. Andernfalls findet sie den maximalen Wert unter allen Proben.',
    max_windowed: 'Diese Funktion berechnet das Maximum der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    mean: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion den Durchschnitt über jedes Signal. Andernfalls berechnet sie den Durchschnitt aller Proben.',
    mean_windowed: 'Diese Funktion berechnet den Mittelwert der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    median: 'Wenn mehrere Argumente gegeben sind, findet diese Funktion den Medianwert unter den Signalen. Andernfalls findet sie den Medianwert unter allen Proben.',
    median_windowed: 'Diese Funktion berechnet den Median der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    min: 'Wenn mehrere Argumente gegeben sind, findet diese Funktion den maximalen Wert unter den Signalen. Andernfalls findet sie den maximalen Wert unter allen Proben.',
    min_windowed: 'Diese Funktion berechnet das Minimum der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    modulo: 'Modulus. Gibt den Rest der Division des ersten Operanden durch den zweiten zurück.',
    multiply: 'Multiplikation. Multipliziert zwei Operanden miteinander.',
    negate: 'Diese Funktion negiert jedes Element eines Signals.',
    normalize: 'Diese Funktion normalisiert die Werte eines Signals auf den Bereich [0, 1].',
    not_equals: 'Ungleichheit. Gibt true zurück, wenn die Operanden ungleich sind, ansonsten false.',
    percentile: 'Diese Funktion berechnet das Perzentil der Signalwerte.',
    power: 'Exponentialfunktion. Erhöht den ersten Operanden zur Potenz des zweiten Operanden.',
    radians: 'Diese Funktion wandelt jedes Element eines Signals von Grad in Radianten um.',
    ramp: 'Diese Funktion gibt eine lineare Rampe von 0 bis 1 zurück.',
    random: 'Diese Funktion gibt für jeden Zeitschritt eine Zufallszahl zwischen 0 und 1 zurück.',
    range: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion den Bereich (max - min) über jedes Signal. Andernfalls berechnet sie den Bereich aller Proben.',
    range_windowed: 'Diese Funktion berechnet den Bereich der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    relative_error: 'Berechnet den relativen Fehler in % an Stellen wo reference != 0.',
    round: 'Diese Funktion rundet jedes Element eines Signals auf die nächste Ganzzahl.',
    sections_join: 'Kombiniert alle Abschnitte deren Abstand kürzer als die gewählte Grenze ist.',
    sections_first: 'Gibt den ersten verfügbaren Abschnitt zurück. Kann keinen Abschnitt zurückgeben, wenn keiner existiert.',
    sections_last: 'Gibt den letzten verfügbaren Abschnitt zurück. Kann keinen Abschnitt zurückgeben, wenn keiner existiert.',
    sections_nth: 'Gibt den n-ten Abschnitt zurück, falls existent. Um von hinten zu addressieren, kann negative Indizierung verwendet werden. Die Indizierung beginnt bei 0.',
    sections_shortest: 'Gibt die kürzeste(n) Abschnitt(e) zurück. Kann mehrere Abschnitte zurückgeben, falls es mehrere Abschnitte mit minimaler Länge gibt.',
    sections_median_duration: 'Gibt den/die Abschnitt(e) mit der mittleren Dauer zurück. Kann mehrere Abschnitte zurückgeben, wenn es mehrere Abschnitte mit der mittleren Länge gibt.',
    sections_longest: 'Gibt den/die längsten Abschnitt(e) zurück. Kann mehrere Abschnitte zurückgeben, wenn es mehrere Abschnitte mit maximaler Länge gibt.',
    sections_max_value: 'Gibt den/die Abschnitt(e) mit dem größten Wert zurück. Kann mehrere Abschnitte zurückgeben, wenn mehrere Abschnitte mit dem absoluten Maximalwert vorhanden sind.',
    sections_median_value: 'Gibt den Abschnitt zurück, der den Medianwert enthält. Kann mehrere Abschnitte zurückgeben, wenn mehrere Abschnitte mit dem Medianwert vorhanden sind.',
    sections_min_value: 'Gibt den/die Abschnitt(e) mit dem kleinsten Wert zurück. Kann mehrere Abschnitte zurückgeben, wenn mehrere Abschnitte mit dem absoluten Minimalwert vorhanden sind.',
    sign: 'Diese Funktion gibt das Vorzeichen jedes Elements eines Signals zurück.',
    sin: 'Diese Funktion berechnet den Sinus jedes Elements eines Signals.',
    sqrt: 'Diese Funktion berechnet die Quadratwurzel jedes Elements eines Signals.',
    stage_number: 'Liefert die Nummer des aktuellen Rezeptschrittes, sofern ein job aktiv ist. Ansonsten NaN.',
    std: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion die Standardabweichung über jedes Signal. Andernfalls berechnet sie die Standardabweichung aller Proben.',
    std_windowed: 'Diese Funktion berechnet die Standardabweichung der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    steady_state_detection: 'Diese Funktion erlaubt es Bereiche mit nahezu Konstanten werten zu finden. Die Windowlength erlaubt das Glätten des Eingangssignals und der Grenzwert erlaubt es eine maximale Steigung zu definieren bei das Signal noch als Konstant angenommen wird.',
    subtract: 'Subtraktion. Subtrahiert den zweiten Operanden vom ersten.',
    sum: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion die Summe über jedes Signal. Andernfalls berechnet sie die Summe aller Proben.',
    sum_windowed: 'Diese Funktion berechnet die Summe der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    tan: 'Diese Funktion berechnet den Tangens jedes Elements eines Signals.',
    timestamp: 'Diese Funktion gibt die Millisekunden seit der Epoche (1970-01-01 00:00:00 UTC) zurück.',
    total_seconds: 'Gibt die Gesamtzeit des angezeigen Zeitraum in Sekunden als Konstante zurück.',
    trunc: 'Diese Funktion kürzt jedes Element eines Signals in Richtung Null ab.',
    var: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion die Varianz über jedes Signal. Andernfalls berechnet sie die Varianz aller Proben.',
    var_windowed: 'Diese Funktion berechnet die Varianz der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
  },
  operators: {
    '!': 'Logisches NICHT. Gibt true zurück, wenn das Operand falsch ist, und false, wenn das Operand wahr ist.',
    '-': 'Subtraktion. Subtrahiert den zweiten Operanden vom ersten.',
    '+': 'Addition. Addiert zwei Operanden zusammen.',
    '^': 'Bitweise XOR. Gibt an jeder Bitposition eine 1 zurück, an der die entsprechenden Bits beider Operanden unterschiedlich sind.',
    '**': 'Exponentialfunktion. Erhöht den ersten Operanden zur Potenz des zweiten Operanden.',
    '*': 'Multiplikation. Multipliziert zwei Operanden miteinander.',
    '//': 'Ganzzahlige Division. Gibt den Quotienten der Division zurück und verwirft dabei den Rest.',
    '/': 'Division. Teilt den ersten Operanden durch den zweiten.',
    '%': 'Modulo. Gibt den Rest der Division des ersten Operanden durch den zweiten zurück.',
    '==': 'Gleichheit. Gibt true zurück, wenn die Operanden gleich sind, ansonsten false.',
    '!=': 'Ungleichheit. Gibt true zurück, wenn die Operanden ungleich sind, ansonsten false.',
    '<': 'Kleiner als. Gibt true zurück, wenn der erste Operand kleiner als der zweite ist.',
    '<=': 'Kleiner als oder gleich. Gibt true zurück, wenn der erste Operand kleiner oder gleich dem zweiten ist.',
    '>': 'Größer als. Gibt true zurück, wenn der erste Operand größer als der zweite ist.',
    '>=': 'Größer als oder gleich. Gibt true zurück, wenn der erste Operand größer oder gleich dem zweiten ist.',
    '&&': 'Logisches UND. Gibt true zurück, wenn beide Operanden true sind, ansonsten false.',
    '||': 'Logisches ODER. Gibt true zurück, wenn mindestens einer der Operanden true ist, ansonsten false.',
  },
};

const DataMigrationState: TComponentKeys<'DataMigrationState'> = {
  assetId: generalTerm.assetId,
  displayName: generalTerm.displayName,
  assetDisplayName: generalTerm.asset,
  lastTimestampAtUtc: 'Neuste Daten',
  lastEtlRunAtUtc: 'Letzte Migration',
  etlLagSeconds: 'Migration lag',
  dataId: 'Identifizierer',
  dataFormat: 'Typ',
  dataSourceReference: 'Referenz in der Datenquelle',
};

const JobMigrationState: TComponentKeys<'JobMigrationState'> = {
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  lastTimestampAtUtc: 'Neuste Daten',
  lastEtlRunAtUtc: 'Letzte Migration',
  etlLagSeconds: 'Migration lag',
};

const CarburizingSimulationRecipe: Record<keyof components['schemas']['CarbonitridingRecipeStageMutation']
| (keyof components['schemas']['CarbonitridingRecipeStageMutation'])
| (keyof components['schemas']['LowPressureCarburizingRecipeStageMutation'])
| 'duration', string> = {
  stageId: 'Rezeptschritt',
  cControl: 'C-Pegel',
  coContent: 'CO',
  h2Content: 'H2',
  tempControl: 'Temperatur',
  endControl: 'Endzustand',
  duration: 'Dauer',
  nControl: 'N-Pegel',
  volControl: 'Volumenstrom',
  // keys with "Value" will not be used
  volValue: 'Volume',
  endValue: 'Endzustand',
  tempValue: 'Temperatur',
  nValue: 'Nitrogenpotential',
  cValue: 'Kohlenstoffpotenzial',
};

const QmulusDriver = {
  PT9800: {
    DataMigrationState,
    JobMigrationState,
  },
};

const TableTestData: Partial<TComponentKeys<'TableTestData'>> = {
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartUtc: generalTerm.jobStartedAtUtc,
  jobEndUtc: generalTerm.jobFinishedAtUtc,
  value: 'Wert',
  recipeDisplayName: generalTerm.recipe,
  partDisplayName: generalTerm.part,
  sampleDisplayName: 'Stichprobe',
};

const ModelBuilderModelOverview: Partial<TComponentKeys<'ModelBuilderModelOverview'>> = {
  accuracy: 'Genauigkeit',
  assetId: generalTerm.asset,
  createdAtUtc: generalTerm.createdAt,
  createdByDisplayName: 'Autor',
  displayName: 'Model',
  inputDisplayNames: 'Eingabesignale',
  lifecycleState: 'Status',
  modelRevision: 'Version',
  modelType: 'Modeltyp',
  outputDisplayName: 'Ausgabesignal',
  revisionCount: 'Versionen',
};

const ModelLifecycleState: Record<EModelLifecycleState, string> = {
  [EModelLifecycleState.REGISTERED]: 'Registriert',
  [EModelLifecycleState.LOADING_DATA]: 'Daten werden geladen',
  [EModelLifecycleState.PREPARING_DATA]: 'Trainings daten werden vorbereitet',
  [EModelLifecycleState.TRAINING]: 'Training läuft',
  [EModelLifecycleState.SAVING_MODEL]: 'Model wird gespeichert',
  [EModelLifecycleState.CREATING_METADATA]: 'Signal definitionen werden erstellen',
  [EModelLifecycleState.READY]: 'Bereit',
};

const ModelBuilderModelType: Record<components['schemas']['ModelBuilderModelType'], string> = {
  LINEAR_REGRESSION: 'Lineare Regression',
  SMART_REGRESSION: 'Smart Regression',
};

export default {
  Asset,
  CsvQuoteCharacter,
  CsvLineTerminator,
  CsvSeperator,
  CsvDatetimeFormat,
  CsvQuoteStyle,
  CsvQuoteStyleHint,
  AuditResultType,
  AuditEnvelopeNonPassedReasons,
  QualityPreset,
  SparePart,
  HeatTreatmentCapability,
  Chamber,
  QuenchingMedia,
  MaterialCalculationModelOutput,
  CarburizingSimulationRecipe,
  DataFormat,
  QmulusDriver,
  MeasurementDisplayName,
  CounterAction,
  ProductionStatistics,
  PhysicalDimension,
  CalendarEventType,
  HierarchyLabel,
  NotificationChannelType,
  SimulationStep,
  ObservationType,
  AppGroupSemantic,
  ChamberType,
  HeatTreatmentProcessType,
  AppId,
  AppGroupCommercial,
  ObservationTypeHeader,
  AssetProfileStatus,
  AssetProfileCombinator,
  MaintenanceJob,
  MaintenanceTask,
  Material,
  AuditSpecification,
  AssetUtilization,
  AuditSpecificationResultOverview,
  Part,
  SettingsActivityCoefficients,
  Customer,
  User,
  PartAttribute,
  MetadataWithChartConfigInput,
  JobFeature,
  RootCauseGroup,
  // eslint-disable-next-line camelcase
  TimeBucket_AssetUtilization_,
  CarburizingRecipeSimulationValuesType,

  MaintenanceState,
  MaintenancePriority,
  MaintenancePeriod,
  MaintenanceRecurrenceMonthDayValue,
  MaintenanceRecurrenceMonthDayDimension,
  MaintenanceRecurrenceEndCondition,

  BusinessConfigurationOutput,
  AlarmCollection,
  AlarmSeverity,
  LegacyEquipmentResponse,
  MaintenanceOccurrenceIdentifier,
  MaintenanceOccurrence,
  MaintenanceSeries,
  Equipment,
  EquipmentProfile,
  EquipmentProfileMeaning,
  EquipmentTreeNavigation,
  RootCause,
  JobValueData,
  JobStage,
  Job,
  Observation,
  EquipmentDocument,
  InstructionEquipment,
  PartAssetQualification,
  OpenAPIPrimitiveType,
  MaterialGroup,
  MaterialNamingSystem,
  ReportOverview,
  Sample,
  TableAssessmentTest,
  ChartAssessmentTest,
  FileAssessmentTest,
  AssessmentTestResultType,
  TableAssessmentTestItem,
  ChartAssessmentTestRequirementType,
  CarburizingSimulationPartShape,
  CarburizingSimulationPartShapeSize,
  CarburizingSimulatorRecipeType,
  MaterialPropertySpectrum,
  CarburizingTemperatureControlType,
  CarburizingVolumeControlType,
  CarburizingCarbonControlType,
  CarburizingEndControlTypeStrings,
  CarburizingNitrogenControlType,
  CarburizingEndControlType,
  SimulatorRecipe,
  NotificationTable,
  SubscriptionTreeNode,
  PartMaterialProperties,

  expressionFunctionCategories,
  expressionFunctionsExplanations,
  TableTestData,

  ModelBuilderModelOverview,
  ModelLifecycleState,
  ModelBuilderModelType,

  util: {
    timeBucketName: 'Zeitperiode',
  },

  meaning: {
    ...TimeseriesMeaning,
    ...ConsumptionMeaning,
    ...JobFeatureMeaning,
    ...PhysicalPropertyMeaning,
  },
  npm: npmApiTranslations,
  pt9800: pt9800ApiTranslations,
};
