import {
  TTranslationEvent,
} from '@/components/item-notification/item-notification.ts';

const event: TTranslationEvent = {
  job_finished: {
    label: '{assetDisplayName}: Job {displayName} ist fertig',
    description: 'Job {displayName} mit Rezept: {recipeDisplayName} ist fertig am {finishedAtUtc} auf {assetDisplayName} in {locationDisplayName}.\nJobbeschreibung: {description}',
  },
  job_started: {
    label: '{assetDisplayName}: Neuer Job {displayName} wurde gestartet',
    description: 'Job {displayName} mit Rezept: {startedAtUtc} ist fertig am {finishedAtUtc} auf {assetDisplayName} in {locationDisplayName}.\nJobbeschreibung: {description}',
  },
  timeseries_lag_detected: {
    label: '{assetDisplayName}: Timeseries-Verzögerung erkannt',
    description: 'Timeseries-Verzögerung erkannt auf {assetDisplayName} in {locationDisplayName}',
  },
  alarm_severity_alarm_occurred: {
    label: '{assetDisplayName}: Alarm {alarmDisplayName}',
    description: 'Alarm {alarmDisplayName} aufgetreten auf {assetDisplayName} in {locationDisplayName}',
  },
  alarm_severity_emergency_occurred: {
    label: '{assetDisplayName}: Emergency Alarm {alarmDisplayName}',
    description: 'Alarm {alarmDisplayName} Notfall aufgetreten auf {assetDisplayName} in {locationDisplayName}',
  },
  alarm_severity_warning_occurred: {
    label: '{assetDisplayName}: Warnung {alarmDisplayName}',
    description: 'Alarm {alarmDisplayName} Warnung aufgetreten auf {assetDisplayName} in {locationDisplayName}',
  },
  new_qmulus_release: {
    label: 'Neue Qmulus Version',
    description: 'Qmulus v{version} wurde gerade veröffentlicht. In den Versionshinweisen erfahren Sie mehr',
  },
  control_level_exceeded: {
    label: 'Kontrollniveau überschritten',
    description: 'Das Kontrollniveau der Sensormessungen wurde überschritten',
  },
  drift_detected: {
    label: 'Drift erkannt',
    description: 'Die Sensormessungen weichen von den Referenzwerten ab',
  },
  trend_detected: {
    label: 'Trend erkannt',
    description: 'Qmulus hat einen neuen Trend in den Sensormessungen erkannt',
  },
};

export default {
  markAsRead: 'Als gelesen markieren',
  goToEvent: 'Zum Ereignis gehen',
  event,
};
