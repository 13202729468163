export default {
  namespaced: true,
  state: {
    isUserLoggedIn: false,
  },
  mutations: {
    setIsUserLoggedIn(state, isLoggedIn) {
      state.isUserLoggedIn = isLoggedIn;
    },
  },
  actions: {
    SET_USER_IS_LOGIN(context, isLoggedIn) {
      context.commit('setIsUserLoggedIn', isLoggedIn);
    },
  },
  getters: {
    getIsUserLoggedIn(state) {
      return state.isUserLoggedIn;
    },
  },
};
