import {
  TComponentPT9800Keys, TComputedPT9800PlannedPartInput,
} from '@/types/openapi-type.ts';
import {
  generalTerm,
} from './en.shared.ts';

const recipeListItem: TComponentPT9800Keys<'recipeListItem'> = {
  id: 'id',
  description: generalTerm.description,
  recipe: generalTerm.recipe,
  recipePrefix: 'Recipe prefix',
  version: 'Recipe version',
};

const recipeItemHeader: Partial<TComponentPT9800Keys<'recipeItemHeader'>> = {
  recipeName: 'Name',
  recipePrefix: 'Group',
  description: 'Description',
  processType: 'Process',
  processId: 'Operation',
  comment: 'Comment',
  diff: 'Activate',
  correction: 'Correction',
};

const material: Partial<TComponentPT9800Keys<'material'>> = {
  carbonContent: 'Carbon content',
  carbideLimit: 'Carbide limit',
  alloyFactor: 'Alloy factor',
};

const recipeItemPosition: Partial<TComponentPT9800Keys<'recipeItemPosition'>> = {};

const recipeItemPositionHeader: Partial<TComponentPT9800Keys<'recipeItemPositionHeader'>> = {
  positionNo: 'Step',
  description: 'Step name',
  chamberId: 'Chamber',
  chamberOperationId: 'Chamber operation',
  afterId: 'End condition',
  estimatedDurationInMin: 'Estimated duration',
};

const plannedPartInput: Partial<Record<keyof TComputedPT9800PlannedPartInput, string>> = {
  pos: 'Position',
  partNumber: 'Part number',
  partDescription: 'Part description',
  orderNo: generalTerm.loadIdentifier,
  orderRemark: 'Order remark',
  customerOrderNumber: 'Customer order No',
  amount: 'Amount',
  weight: 'Weight',
  totalWeight: 'Total weight',
};

const planLoadInput: Partial<TComponentPT9800Keys<'planLoadInput'>> = {
  workplaceWoNumber: generalTerm.loadIdentifier,
};

const setPointItem: Partial<TComponentPT9800Keys<'setPointItem'>> & {
  unit: string,
} = {
  userComponentId: 'Setpoint',
  controlId: 'Ctrl',
  value: 'Value',
  unit: 'Unit',
  toleranceMax: 'Tol +',
  toleranceMin: 'Tol -',
  alarmStartTimeInMin: 'Time 1',
  alarmToleranceTimeInMin: 'Time 2',
  endControlId: 'Ctrl',
  endValue: 'End condition',
};

export default {
  material,
  planLoadInput,
  plannedPartInput,
  recipeItemHeader,
  recipeItemPosition,
  recipeItemPositionHeader,
  recipeListItem,
  setPointItem,
};
