import {
  TButtonTranslations,
} from './npm-controller-menu.ts';

const buttonTranslations: TButtonTranslations = {
  LOAD_JOB: {
    label: 'Job laden',
    confirmMessage: '',
    confirmLabel: '',
  },
  START_JOB: {
    label: 'Job starten',
    confirmMessage: 'Bitte bestätige den Start von {recipeCode} auf {assetDisplayName}.',
    confirmLabel: '{recipeCode} starten',
  },
  ABORT_JOB: {
    label: 'Job Abbrechen',
    confirmMessage: 'Soll {jobNo} ({recipeCode}) in {assetDisplayName} wirklich abgebrochen werden?',
    confirmLabel: '{jobNo} abbrechen',
  },
  RESUME_JOB: {
    label: 'Job Fortführen',
    confirmMessage: 'Soll {recipeCode} in {assetDisplayName} forgeführt werden?',
    confirmLabel: '{recipeCode} fortführen',
  },
};

export default {
  button: buttonTranslations,
};
