// @ts-ignore
import {
  AxiosResponse,
} from 'axios';
// @ts-ignore
import {
  paths,
} from '@/types/openapi_pt9800';
// @ts-ignore
import {
  TApiPayload,
} from '@/api/type/export';
// @ts-ignore
import {
  axiosClientRestPT9800,
} from '@/api/client';
// @ts-ignore
import {
  interpolateUrl,
} from '@/utils/url.ts';
// @ts-ignore
import {
  QueryKey,
} from '@tanstack/vue-query';

export const TGetShopLayoutViewPath = '/{ptDbId}/gui/shop/{shopWpId}';
export type TGetShopLayoutViewPath = paths['/{ptDbId}/gui/shop/{shopWpId}']['get']['parameters']['path'];
export type TGetShopLayoutViewPayload = TApiPayload<TGetShopLayoutViewPath, undefined, undefined>;
export type TGetShopLayoutViewResponse200 = paths['/{ptDbId}/gui/shop/{shopWpId}']['get']['responses']['200']['content']['*/*'];
export type TGetShopLayoutViewResponse = TGetShopLayoutViewResponse200;
export const getShopLayoutViewQueryKey = (ptDbId: QueryKey[number], shopWpId: QueryKey[number]): QueryKey => ([...["gui","shop"], ptDbId, shopWpId]);
export function getShopLayoutView(payload: TGetShopLayoutViewPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetShopLayoutViewResponse>> {
  return axiosClientRestPT9800.get(interpolateUrl(TGetShopLayoutViewPath, payload.path), { signal: abortSignal });
}

export const TGetWorkPlaceViewPath = '/{ptDbId}/gui/workplace/{wpId}';
export type TGetWorkPlaceViewPath = paths['/{ptDbId}/gui/workplace/{wpId}']['get']['parameters']['path'];
export type TGetWorkPlaceViewPayload = TApiPayload<TGetWorkPlaceViewPath, undefined, undefined>;
export type TGetWorkPlaceViewResponse200 = paths['/{ptDbId}/gui/workplace/{wpId}']['get']['responses']['200']['content']['*/*'];
export type TGetWorkPlaceViewResponse = TGetWorkPlaceViewResponse200;
export const getWorkPlaceViewQueryKey = (ptDbId: QueryKey[number], wpId: QueryKey[number]): QueryKey => ([...["gui","workplace"], ptDbId, wpId]);
export function getWorkPlaceView(payload: TGetWorkPlaceViewPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetWorkPlaceViewResponse>> {
  return axiosClientRestPT9800.get(interpolateUrl(TGetWorkPlaceViewPath, payload.path), { signal: abortSignal });
}

export const TGetWorkPlaceView_1Path = '/{ptDbId}/gui/workplace/{wpId}/{viewId}';
export type TGetWorkPlaceView_1Path = paths['/{ptDbId}/gui/workplace/{wpId}/{viewId}']['get']['parameters']['path'];
export type TGetWorkPlaceView_1Payload = TApiPayload<TGetWorkPlaceView_1Path, undefined, undefined>;
export type TGetWorkPlaceView_1Response200 = paths['/{ptDbId}/gui/workplace/{wpId}/{viewId}']['get']['responses']['200']['content']['*/*'];
export type TGetWorkPlaceView_1Response = TGetWorkPlaceView_1Response200;
export const getWorkPlaceView_1QueryKey = (ptDbId: QueryKey[number], wpId: QueryKey[number], viewId: QueryKey[number]): QueryKey => ([...["gui","workplace"], ptDbId, wpId, viewId]);
export function getWorkPlaceView_1(payload: TGetWorkPlaceView_1Payload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetWorkPlaceView_1Response>> {
  return axiosClientRestPT9800.get(interpolateUrl(TGetWorkPlaceView_1Path, payload.path), { signal: abortSignal });
}

