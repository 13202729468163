import {
  acceptHMRUpdate,
  defineStore,
} from 'pinia';
import {
  AxiosResponse,
} from 'axios';
import {
  ELanguage,
  ELocalisationStoreDateFormat,
  getDefaultLanguage,
  getDefaultTimeFormat,
} from '@/types/localization-store.ts';
import {
  EDateFormatAmerican,
  EDateFormatEuropian,
  getDayjsDateFormat,
} from '@/utils/date-formats.ts';
import {
  asyncRequestActionList,
} from './util/request.ts';
import {
  useCustomUserStorageStore,
} from './custom-user-storage.ts';
import {
  components,
} from '@/types/openapi.ts';
import {
  getAllAppsWithPermissions,
  getCurrentUserRoute,
  TGetAllAppsWithPermissionsResponse,
  TGetCurrentUserRouteResponse,
} from '@/generated/axios/openapi/core.ts';
import {
  DEFAULT_CHART_TIME_FORMAT, EChartTimeFormat,
} from '@/components/select-chart-time-format/select-chart-time-format.ts';

// Needs to move localization features here from  Vuex "localizationStore" module as customUserStorage
export const useAppStore = defineStore('app', {
  state: () => ({
    appList: undefined as TGetAllAppsWithPermissionsResponse | undefined,
    appListPromise: undefined as Promise<AxiosResponse<TGetAllAppsWithPermissionsResponse>> | undefined,
    // is prefetched in auth-middleware
    // user will be undefined in any element outside of router view, for example header & navbar
    user: undefined as TGetCurrentUserRouteResponse | undefined,
    userPromise: undefined as Promise<AxiosResponse<TGetCurrentUserRouteResponse>> | undefined,
    isNavigating: false,
  }),
  getters: {
    getUserHasPermission: (state) => (
      appId: components['schemas']['AppId'],
      permissionName: components['schemas']['Permission'] | string,
    ) => !!(state.user?.appPermissions?.[appId]?.[permissionName]),
    isUserLoading: (state) => state.userPromise !== undefined,
    appListIsLoading: (state) => state.appListPromise !== undefined,
    locale(): ELanguage {
      const customUserStorage = useCustomUserStorageStore();
      return customUserStorage.customUserSettings?.language || getDefaultLanguage();
    },
    dateFormat(): ELocalisationStoreDateFormat {
      const customUserStorage = useCustomUserStorageStore();
      return customUserStorage.customUserSettings?.timeFormat || getDefaultTimeFormat();
    },
    chartDateFormat(): EChartTimeFormat {
      const customUserStorage = useCustomUserStorageStore();
      return customUserStorage.customUserSettings?.chartTimeFormat || DEFAULT_CHART_TIME_FORMAT;
    },
    dayjsDateFormat(): typeof EDateFormatAmerican | typeof EDateFormatEuropian {
      return getDayjsDateFormat(this.dateFormat);
    },
  },
  actions: {
    async loadCurrentUser(force: boolean) {
      return asyncRequestActionList({
        callback: () => getCurrentUserRoute(),
        list: this.user,
        promise: this.userPromise,
        setList: (data) => {
          this.user = data;
        },
        setPromise: (promise) => {
          this.userPromise = promise;
        },
        force,
      });
    },
    async loadAppList() {
      return asyncRequestActionList({
        callback: () => getAllAppsWithPermissions(),
        list: this.appList,
        promise: this.appListPromise,
        setList: (data) => {
          this.appList = data;
        },
        setPromise: (promise) => {
          this.appListPromise = promise;
        },
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
