import {
  acceptHMRUpdate,
  defineStore,
} from 'pinia';
import {
  AxiosResponse,
} from 'axios';
import {
  asyncRequestAction,
} from './util/request';
import {
  TAssetTreeResponse,
  getAssetTree,
} from '@/api/asset';
import {
  flattenAssetTree,
} from '@/utils/asset-tree.ts';
import {
  EHierarchy,
  FlattenedAssetTreeNode,
} from '@/types/asset-tree.ts';
import {
  getDataSourcesForAsset,
  TGetDataSourcesForAssetPayload,
  TGetDataSourcesForAssetResponse,
} from '@/generated/axios/openapi/core.ts';

type TAssetId = number;

export const useAssetStore = defineStore('asset', {
  state: () => ({
    assetDataSourceListMap: {} as Record<TAssetId, TGetDataSourcesForAssetResponse | undefined>,
    assetDataSourceListPromiseMap: {} as Record<TAssetId, Promise<AxiosResponse<TGetDataSourcesForAssetResponse>> | undefined>,
    assetOverviewMap: undefined as Record<TAssetId, FlattenedAssetTreeNode> | undefined,
    assetOverviewPromise: undefined as Promise<AxiosResponse<TAssetTreeResponse>> | undefined,
  }),
  getters: {
    assetDataSourceListIsLoading: (state) => (assetId: TAssetId) => state.assetDataSourceListPromiseMap[assetId] !== undefined,
    assetOverview: (state) => (assetId: TAssetId): FlattenedAssetTreeNode | undefined => (state.assetOverviewMap ? state.assetOverviewMap[assetId] : undefined),
    isAssetOverviewLoading: (state) => state.assetOverviewPromise !== undefined,
  },
  actions: {
    async loadAssetDataSourceList(payload: TGetDataSourcesForAssetPayload) {
      return asyncRequestAction({
        id: payload.path.assetId,
        callback: () => getDataSourcesForAsset(payload),
        list: this.assetDataSourceListMap,
        promiseMap: this.assetDataSourceListPromiseMap,
      });
    },
    async loadAssetOverview(force?: boolean) {
      if (
        (this.assetOverviewMap !== undefined || this.assetOverviewPromise !== undefined)
          && !force
      ) {
        return;
      }

      const requestPromise = getAssetTree({
        maxIncludedHierarchyLevel: EHierarchy.COMPONENT,
      });
      this.assetOverviewPromise = requestPromise;

      requestPromise.then((response) => {
        this.assetOverviewMap = {};
        flattenAssetTree(response.data).forEach((node) => {
            this.assetOverviewMap![node.assetId] = node;
        });
      }).finally(
        () => {
          this.assetOverviewPromise = undefined;
        },
      );
    },
    /*
     * Return true if the assetId is the same, a descendant or an ancestor of the
     * referenceAssetId.
     */
    isInPathOf(assetId: number, referenceAssetId: number): boolean {
      return assetId === referenceAssetId
          || this.isDescendantOf(assetId, referenceAssetId)
          || this.isAncestorOf(assetId, referenceAssetId);
    },
    /* Return true of the assetId is a descendant (child) of the referenceAssetId */
    isDescendantOf(assetId: number, referenceAssetId: number): boolean {
      const assetParentId = this.assetOverview(assetId)?.parentId;

      // 0 (ROOT_NODE) is also a valid stop condition
      if (!assetParentId) {
        return false;
      }
      return assetParentId === referenceAssetId
          || this.isDescendantOf(assetParentId, referenceAssetId);
    },
    /* Return true of the assetId is an ancestor (parent) of the referenceAssetId */
    isAncestorOf(assetId: number, referenceAssetId: number): boolean {
      // It is more efficient to walk up the tree than to walk down the tree.
      // Thus switching the inputs and check for descendants is better than looping
      // over all children.
      return this.isDescendantOf(referenceAssetId, assetId);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAssetStore, import.meta.hot));
}
