export default {
  header: 'Information for place "{place}"',
  placeStatus: 'Place status',
  reserved: 'Reserved',
  holdingTime: 'Res. holding time [min]',
  loadStatus: 'Load status',
  cellLoadNo: 'Cell Load No.',
  targetStartTime: 'Target start time',
  bookedOnPlace: 'Booked on place',
  plcLoadNo: 'PLC Load No.',
  box: 'Box',
  prio: 'Prio',
  comment: 'Comment',
  noActiveProcessFlow: 'No active process flow',
  noParts: 'No parts',
  processFlow: 'Process flow',
  content: 'Content',
  confirmStartProcessFlowNow: 'You sure you want to start process flow now?',
  name: 'Name',
  description: 'Description',
  booked: 'Booked',
  modification: 'Modification',
  unloaded: 'Unloaded',
  started: 'Started',
  finished: 'Finished',
  loadFinalTest: 'Load final test',
};
