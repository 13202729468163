<template>
  <prm-button
    v-if="appStore.isNavigating"
    v-tooltip="label"
    size="small"
    text
    disabled
    type="button"
    severity="secondary"
    class="navbar-icon"
  >
    <template #icon>
      <component
        :is="icon"
        class="navbar-icon__image"
      />
    </template>
  </prm-button>
  <router-link
    v-else
    v-slot="{ href, navigate, isActive }"
    :to="routeTo"
    custom
  >
    <a
      :href="href"
      :title="label"
      @click="navigate"
    >
      <prm-button
        v-tooltip="label"
        size="small"
        text
        type="button"
        :severity="isActive
          ? 'primary'
          : 'secondary'"
        :class="{'navbar-icon': !isActive}"
      >
        <template #icon>
          <component
            :is="icon"
            class="navbar-icon__image"
          />
        </template>
      </prm-button>
    </a>
  </router-link>
</template>

<script setup lang="ts">
import PrmButton from 'primevue/button';
import {
  RouteLocationRaw,
} from 'vue-router';
import {
  FunctionalComponent, SVGAttributes,
} from 'vue';
import {
  useAppStore,
} from '@/store/pinia/app.ts';

const appStore = useAppStore();

defineProps<{
  label: string,
  routeTo: RouteLocationRaw,
  icon: FunctionalComponent<SVGAttributes, {}, any, {}>,
}>();
</script>

<style scoped lang="scss">
.navbar-icon {
  color: var(--color-224) !important;
}
.navbar-icon__image {
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
</style>
