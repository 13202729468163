import {
  AxiosResponse,
} from 'axios';
import {
  axiosClientPT9800,
  pt9800GraphqlClientQuery,
} from './client';
import {
  actRecipeVersion,
  furnaceView,
  recipeMetadata,
} from '@/utils/pt9800-zeus';

/**
 * @deprecated
 * no types defined anywhere, hardcode from browser response
 */
export type TGetPt9800UserResponse = {
  'Visualization'?: {
    [key: string]: {
      'id': number,
      'accessRights': {
        'run': boolean,
        'newEntry': boolean,
        'save': boolean,
        'delete': boolean,
        'special1': boolean,
        'print': boolean,
        'startRecipeProcessFlow': boolean,
        'modifyRecipeProcessFlow': boolean,
        'stopRecipeProcessFlow': boolean,
        'startCorrectionRecipe': boolean,
      },
      'children': {
        'displayName': string,
        'id': number,
        'accessRights': {
          'run': boolean,
          'newEntry': boolean,
          'save': boolean,
          'delete': boolean,
          'special1': boolean,
          'print': boolean,
          'startRecipeProcessFlow': boolean,
          'modifyRecipeProcessFlow': boolean,
          'stopRecipeProcessFlow': boolean,
          'startCorrectionRecipe': boolean,
        },
        'accessLevel': number,
      }[],
      'displayName': string,
      'description': string,
    }
  },
  'Recipes'?: {
    'displayName': string,
    'children': {
      'displayName': string,
      'id': string | null,
      'pgmId': number,
      'workplaceId': number,
      'description': string,
      'accessRights': {
        'run': boolean,
        'newEntry': boolean,
        'save': boolean,
        'delete': boolean,
        'special1': boolean,
        'print': boolean,
        'startRecipeProcessFlow': boolean,
        'modifyRecipeProcessFlow': boolean,
        'stopRecipeProcessFlow': boolean,
        'startCorrectionRecipe': boolean
      }
    }[]
  },
};
/** @deprecated */
export async function getPt9800User(ptDbId: number): Promise<AxiosResponse<TGetPt9800UserResponse>> {
  return axiosClientPT9800.get('/me', {
    params: {
      ptDbId,
    },
  });
}

export async function getRecipeMetadata({
  ptDbId,
  workplaceId,
  recipePrefix,
}: {
  ptDbId: number,
  workplaceId: number,
  recipePrefix: string,
}) {
  return pt9800GraphqlClientQuery({
    getRecipeMetadata: [
      {
        workplaceId,
        recipePrefix,
      },
      recipeMetadata,
    ],
  }, {
    variables: {
      location: ptDbId,
    },
  });
}

export async function getActualRecipeOfWorkplace(ptDbId: number, workplaceId: number) {
  return pt9800GraphqlClientQuery({
    actualRecipeOfWorkplace: [
      {
        workplaceId,
      },
      actRecipeVersion,
    ],
  }, {
    variables: {
      location: ptDbId,
    },
  });
}

export async function listOfAvailableFurnaces(location: string) {
  return pt9800GraphqlClientQuery({
    listOfAvailableFurnaces: true,
    cptEntries: {
      id: true,
    },
  }, {
    variables: {
      location,
    },
  });
}

export async function getFurnaceView(ptDbId: number, workplaceId: number) {
  return pt9800GraphqlClientQuery({
    furnaceView: [
      {
        workplaceId,
      },
      furnaceView,
    ],
  }, {
    variables: {
      location: ptDbId,
    },
  });
}

export type TGetFurnaceViewResponse = Awaited<ReturnType<typeof getFurnaceView>>
