import {
  AxiosResponse,
} from 'axios';
import {
  EOpenapiPaths,
  computedUrl,
  TComponentValue,
} from '@/types/openapi-type';
import {
  paths,
} from '@/types/openapi';
import {
  TApiPayload,
} from '@/api/type/export';
import axiosClientV3 from './client';

export type TAssetTreeParams = paths[EOpenapiPaths.ASSET_TREE]['get']['parameters']['query'];
export type TAssetTreeResponse = paths[EOpenapiPaths.ASSET_TREE]['get']['responses']['200']['content']['application/json'];
export type TAssetTreeNode = TComponentValue<'AssetTreeNode'>;
export async function getAssetTree(params: TAssetTreeParams, abortSignal?: AbortSignal): Promise<AxiosResponse<TAssetTreeResponse>> {
  return axiosClientV3.get(EOpenapiPaths.ASSET_TREE, {
    params,
    signal: abortSignal,
  });
}

export type TAssetProfileTreePath = paths[EOpenapiPaths.ASSET_PROFILE_TREE]['get']['parameters']['path'];
export type TAssetProfileTreeParams = paths[EOpenapiPaths.ASSET_PROFILE_TREE]['get']['parameters']['query'];
export type TAssetProfileTreePayload = TApiPayload<TAssetProfileTreePath, TAssetProfileTreeParams>;
export type TAssetProfileTreeResponse = paths[EOpenapiPaths.ASSET_PROFILE_TREE]['get']['responses']['200']['content']['application/json'];

export type TAssetProfile = TComponentValue<'ProfileTreeNode'>;

export async function getAssetProfileTree(payload: TAssetProfileTreePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TAssetProfileTreeResponse>> {
  return axiosClientV3.get(computedUrl[EOpenapiPaths.ASSET_PROFILE_TREE](payload.path.assetId), {
    params: payload.query,
    signal: abortSignal,
  });
}

export type TAssetProfileStatusTreePath = paths[EOpenapiPaths.ASSET_PROFILE_STATUS_TREE]['get']['parameters']['path'];
export type TAssetProfileStatusTreePayload = TApiPayload<TAssetProfileStatusTreePath>;
export type TAssetProfileStatusTreeResponse = paths[EOpenapiPaths.ASSET_PROFILE_STATUS_TREE]['get']['responses']['200']['content']['application/json'];

export type TAssetProfileStatusMap = TComponentValue<'ProfileMappingState'>;

export async function getAssetProfileStatusTree(payload: TAssetProfileStatusTreePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TAssetProfileStatusTreeResponse>> {
  return axiosClientV3.get(computedUrl[EOpenapiPaths.ASSET_PROFILE_STATUS_TREE](payload.path.assetId), {
    signal: abortSignal,
  });
}

export type TGetAssetAlarmListQuery = paths[EOpenapiPaths.ASSET_ALARM_LIST]['get']['parameters']['query'];
export type TGetAssetAlarmListPayload = TApiPayload<undefined, TGetAssetAlarmListQuery>;
export type TGetAssetAlarmListResponse = paths[EOpenapiPaths.ASSET_ALARM_LIST]['get']['responses']['200']['content']['application/json'];

export async function getAssetAlarmList(payload: TGetAssetAlarmListPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetAlarmListResponse>> {
  return axiosClientV3.get(EOpenapiPaths.ASSET_ALARM_LIST, {
    params: payload.query,
    signal: abortSignal,
  });
}

export type TGetAssetListQuery = paths[EOpenapiPaths.ASSET_LIST_GET]['get']['parameters']['query'];
export type TGetAssetListPayload = TApiPayload<undefined, TGetAssetListQuery>;
export type TGetAssetListResponse = paths[EOpenapiPaths.ASSET_LIST_GET]['get']['responses']['200']['content']['application/json'];

export async function getAssetList(payload: TGetAssetListPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetListResponse>> {
  return axiosClientV3.get(EOpenapiPaths.ASSET_LIST_GET, {
    params: {
      ...payload.query,
    },
    signal: abortSignal,
  });
}

export type TGetAssetProductivityQuery = paths[EOpenapiPaths.ASSET_PRODUCTIVITY]['get']['parameters']['query'];
export type TGetAssetProductivityPath = paths[EOpenapiPaths.ASSET_PRODUCTIVITY]['get']['parameters']['path'];
export type TGetAssetProductivityPayload = TApiPayload<TGetAssetProductivityPath, TGetAssetProductivityQuery>;
export type TGetAssetProductivityResponse = paths[EOpenapiPaths.ASSET_PRODUCTIVITY]['get']['responses']['200']['content']['application/json'];

export async function getAssetProductivity(payload: TGetAssetProductivityPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetProductivityResponse>> {
  return axiosClientV3.get(computedUrl[EOpenapiPaths.ASSET_PRODUCTIVITY](payload.path), {
    params: {
      ...payload.query,
    },
    signal: abortSignal,
  });
}

export type TGetAssetPath = paths[EOpenapiPaths.ASSET_DETAIL]['get']['parameters']['path'];
export type TGetAssetPayload = TApiPayload<TGetAssetPath>;
export type TGetAssetResponse = paths[EOpenapiPaths.ASSET_DETAIL]['get']['responses']['200']['content']['application/json'];

export async function getAssetDetail(payload: TGetAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetResponse>> {
  return axiosClientV3.get(computedUrl[EOpenapiPaths.ASSET_DETAIL](payload.path.assetId), {
    signal: abortSignal,
  });
}

export type TGetAssetMetadataByTimeseriesPath = paths[EOpenapiPaths.ASSET_METADATA_BY_TIMESERIES_GET]['get']['parameters']['path'];
export type TGetAssetMetadataByTimeseriesQuery = paths[EOpenapiPaths.ASSET_METADATA_BY_TIMESERIES_GET]['get']['parameters']['query'];
export type TGetAssetMetadataByTimeseriesPayload = TApiPayload<TGetAssetMetadataByTimeseriesPath, TGetAssetMetadataByTimeseriesQuery>;
export type TGetAssetMetadataByTimeseriesResponse = paths[EOpenapiPaths.ASSET_METADATA_BY_TIMESERIES_GET]['get']['responses']['200']['content']['application/json'];

export async function getAssetMetadataByTimeseries(payload: TGetAssetMetadataByTimeseriesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetMetadataByTimeseriesResponse>> {
  return axiosClientV3.get(
    computedUrl[EOpenapiPaths.ASSET_METADATA_BY_TIMESERIES_GET](payload.path),
    {
      params: {
        ...payload.query,
      },
      signal: abortSignal,
    },
  );
}
