import {
  generalTerm,
  generalTermFilter,
} from './en.shared.ts';
import ApiTranslations from './en.api.ts';
import npm from '@/generated/locales/en.npm.ts';
import {
  ETimeseriesMeaning,
} from '@/types/openapi-type.ts';
import {
  ERouteName,
} from '@/router/route-name.ts';
import {
  EMaintenanceServiceTab,
  EMaintenanceRecurrencePeriod,
} from '@/types/calendar.ts';
import {
  TFurnacePanelStatus,
} from '@/components/furnace-panel/furnace-panel.ts';
import {
  EMonth,
} from '@/types/date.ts';
import {
  EAttributeLabel,
} from '@/types/attribute-type.ts';
import {
  getComponentScopedTranslationMap,
} from '@/utils/i18n.ts';
import {
  EVisualisation,
} from '@/types/performance-dashboard.ts';

const Visualisation: Record<EVisualisation, string> = {
  [EVisualisation.CLUSTERED_STACKED_BAR_CHART]: 'Clustered stacked bar chart',
  [EVisualisation.PARETO_CHART]: 'Pareto chart',
  [EVisualisation.STACKED_BAR_CHART]: 'Stacked bar chart',
  [EVisualisation.STACKED_POLAR_CHART]: 'Stacked polar chart',
  [EVisualisation.TABLE]: 'Table',
};

export default {
  generalTermFilter,
  generalTerm,
  api: ApiTranslations,
  npm,
  actions: {
    abort: 'Abort',
    accept: 'Accept',
    addFilter: 'Add Filter',
    back: 'Back',
    cancel: 'Cancel',
    change: 'Change',
    clear: 'Clear',
    close: 'Close',
    confirm: 'Confirm',
    delete: 'Delete',
    deselectAll: 'Deselect All',
    edit: 'Edit',
    finish: 'Finish',
    finishMaintenanceOccurrence: 'Finish occurrence',
    goBack: 'Go back',
    login: 'Login',
    next: 'next',
    ok: 'Ok',
    reject: 'Reject',
    rejectMaintenanceOccurrence: 'Reject occurrence',
    reset: 'Reset',
    resume: 'Resume',
    restart: 'Restart',
    restoreRecipe: 'Restore recipe',
    retry: 'Retry',
    save: 'Save',
    saveAs: 'Save as',
    search: 'Search',
    select: 'Select',
    selectAll: 'Select All',
    skip: 'Skip',
    start: 'Start',
    stop: 'Stop',
    unlink: 'Unlink',
    update: 'Update',
    validate: 'Validate',
    validated: 'Validated',
  },
  alarmStatistics: {
    alarmsCausingStop: 'Only alarms causing stop',
    chartjsLimit: 'Chartjs cannot handle that many events',
    duration: 'Duration',
    evaluationFrom: 'Date from',
    history: 'History',
    list: 'List',
    noAlarms: 'No alarms found',
    paretoOccurrences: 'Pareto (occurrences)',
    paretoTime: 'Pareto (time)',
    quantity: 'Quantity',
    resultStatistics: 'Statistics',
    resultVisualization: 'Visual Statistics',
    to: 'Date to',
  },
  alarms: {
    ackTime: 'Ack',
    bit: 'Bit',
    byte: 'Byte',
    endTime: 'End',
    event: 'Event',
    gate: 'Gate',
    id: 'Id',
    message: 'Message',
    priority: 'Priority',
    search: 'Alarm search',
    startTime: 'Start',
  },
  alerts: {
    cancelCurrentStage: 'Close current stage before editing other Stages',
    cancelOrValidateEditedStage: 'Stage has been edited. Please cancel or validate the changes',
    changeStateFailed: 'Change state failed',
    default: '(default)',
    delete: 'Delete!',
    deleteFilter: 'Do you want to delete the filter?',
    deleted: 'Deleted',
    digitsAbove: 'Value should be below 9',
    digitsBelow: 'Value should be above 0',
    edited: '(Edited)',
    filterSaved: 'Your custom filter is saved.',
    groupsDeleted: 'Materials Group deleted',
    materialDeleted: 'Material deleted',
    missingChartData: 'No chart data',
    needValidateOrClose: 'You need to validate/close the open Stage before continuing.',
    noDescription: 'No description',
    noPermission: 'No NPM user stored, or invalid credentials supplied.',
    noStageSetup: 'No stage setup for this stage change',
    overwrite: 'Do you want to overwrite it?',
    rejectOccurrence: 'Please provide some context on why this validated occurrenced was rejected.',
    saveFailed: 'Save failed',
    saveSuccess: 'Saved successfully',
    selectElements: 'Please select elements in the dropdown above',
    stageValid: 'STAGE {stage} : Valid',
    success: 'Success!',
    validReport: '',
    valueAboveMax: 'Value should be below',
    warning: 'Warning!',
  },
  anomalyDetection: {
    createRootCause: 'Create Root Cause',
    createSuggestedAction: 'Create Suggested Action',
    detectionTime: 'Detection time',
    jobFeatures: 'Job feature',
    linkRootCause: 'Link Root Cause',
    linkSuggestedAction: 'Link Suggested Action',
    observations: 'Observations',
    possibleCauses: 'Possible causes',
    rootCausesAnalysis: 'Root Causes Analysis',
    searchJobFeatures: 'Search job feature',
    searchObservationTypes: 'Search Observation types',
    searchRootCause: 'Search root cause',
    searchSuggestedAction: 'Search suggested action',
    selectJobFeature: 'Select job feature',
    selectObservationType: 'Select observation type',
    selectRootCause: 'Select root cause',
    selectSuggestedAction: 'Select suggested action',
  },
  appOverview: {
    search: 'Search apps',
    dataInsight: 'Data/Insight',
    maxNumberOfPinnedApps: 'Max number of {max} pinned items is reached.',
    showOnlyAvailableApps: 'Show only available apps',
  },
  assetManagement: {
    settings: {
      categories: {
        costs: 'Costs',
        maintenance: 'Maintenance',
        operations: 'Operation',
      },
    },
  },
  audit: {
    addBand: 'Add envelope',
    audits: 'Audits',
    band: 'Envelope',
    bandMinus: 'Lower limit',
    bandPlus: 'Upper limit',
    comment: 'Comment',
    completeRateComment: 'Rate and Comment',
    configureBands: 'Add and configure envelops',
    createAudit: 'Create audit',
    deleteBand: 'Delete envelop',
    noAudits: 'No audits',
    selectAudit: 'Select audit',
    selectSensors: 'Select sensors for audit',
    selectStartTimeOnChart: 'Click on the chart area to select the start time.',
    step: 'Step {step}:',
    title: 'Audit Report',
    totalTimeInBand: 'Total time in envelop',
    viewAudit: 'Audit',
  },
  auth: {
    userDoesNotHavePermission: 'User does not have permission {0}',
  },
  batchRating: {
    areYouSure: 'Are you sure?',
    confirmation: 'You are about to rate this Load as',
    notRated: 'Not rated',
    ratedBy: 'Accepted by',
  },
  batchStatuses: {
    allGood: 'All Good',
    notGood: 'Not Good',
    notPassed: 'Did not pass Audit',
    passed: 'Passed Audit',
  },
  buttons: {
    SetInputEndCondition: 'Set Input End Condition',
    ack: 'Acknowledge',
    add: 'Add',
    addAttachments: 'Add attachments',
    addComponent: 'Add component',
    addEquipment: 'Add asset',
    addNewPart: 'Add a new spare part',
    addPlant: 'Add plant',
    addStage: 'Add stage',
    addUser: 'Add User',
    alarm: 'Alarm',
    analogInputs: 'Analog inputs',
    apply: 'Apply',
    attachReport: 'Attach report',
    cancel: 'Cancel',
    changeDateFormat: 'Change time format',
    changeLanguage: 'Change language',
    changeNumberFormat: 'Change decimal separator',
    changePassword: 'Change password',
    chooseKPIs: 'Choose KPIs',
    close: 'Close',
    connect: 'Connect',
    createNewPart: 'Create new part',
    createNewRecipe: 'Create new recipe',
    createReport: 'Create Report',
    customerRequests: 'Service Request',
    delete: 'Delete',
    digitalInputs: 'Digital inputs',
    download: 'Download {fileName}',
    editOverview: 'Edit Overview',
    export: 'Export',
    insertStage: 'Insert stage',
    loadList: 'Load list',
    logout: 'Sign out',
    manageSpareParts: 'Manage spare parts',
    modify: 'Modify',
    new: 'New',
    newCustomerRequests: 'New Service Request',
    pdf: 'Generate PDF',
    preview: 'Preview',
    print: 'Print',
    rejectReason: 'Reject reason',
    remove: 'Remove',
    save: 'Save',
    saveRecipe: 'Save recipe',
    saveUser: 'Save User',
    selectSensors: 'Select sensors',
    startCalculation: 'Calulate',
    submit: 'Submit',
    tryAgain: 'Try again',
    updateComponent: 'Update component',
    updateEquimpent: 'Update asset',
    updateSparePart: 'Update spare part',
    upload: 'Upload files',
    uploadDocuments: 'Upload Documents',
    uploadInstructions: 'Upload Instructions',
    uploadPlural: 'Upload file | Upload files',
    view: 'View',
    viewReport: 'View Report',
  },
  calendar: {
    accepted: 'Accepted',
    addJob: 'Add job',
    addOccurrence: 'Schedule maintenance',
    addTask: 'Add task',
    after: 'after',
    assignedTo: 'Assign to',
    color: 'Color',
    date: generalTerm.date,
    day: 'Day',
    days: 'Days',
    description: 'Description',
    done: 'Done',
    downloadReport: 'Download report',
    durationOverhead: 'Duration overhead',
    editMaintenanceJob: 'Edit maintenance job',
    editMaintenanceTask: 'Edit maintenance task',
    end: 'End',
    endTime: 'End time',
    every: 'Every',
    first: 'First',
    fourth: 'Fourth',
    hideCompletedEvents: 'Hide completed events',
    hours: 'Hours',
    inProgress: 'In progress',
    invalidDate: 'Invalid date',
    last: 'Last',
    listMaintenance: 'List of maintenance',
    maintenanceEnd: 'Maintenance end',
    maintenanceJob: 'Maintenance job',
    maintenanceOccurrence: 'Maintenance Occurrence: {id}',
    maintenanceStart: 'Maintenance start',
    maintenanceType: 'Maintenance type',
    minutes: 'Minutes',
    month: 'Month',
    monthS: 'month(s)',
    monthly: 'Monthly',
    never: 'Never',
    occurrence: 'occurrence | occurrences',
    occurs: 'Occurs',
    on: 'On',
    onThisDay: 'on this day',
    pending: 'Pending',
    projectedDate: 'Projected date',
    recurrence: 'Recurrence',
    repeat: 'Repeat',
    restartOccurrenceWarningMessage: 'Restarting will delete any already uploaded report. Do you want to proceed?',
    searchReport: 'Search report',
    second: 'Second',
    startTime: 'Start time',
    startingOn: 'starting on',
    status: 'Status',
    task: 'task',
    taskName: 'Task name',
    tasks: 'Tasks',
    third: 'Third',
    to: 'TO',
    today: 'Today',
    validated: 'Validated',
    view: 'View',
    week: 'Week',
    weekS: 'week(s)',
    weekday: 'Weekday',
    weekendDay: 'Weekend day',
    weekly: 'Weekly',
    withoutEndDate: 'without end date',
    year: 'Year',
    yearly: 'Yearly',
  },
  cards: {
    criticality: 'Criticality: ',
    duration: 'Expected Duration: ',
  },
  chartComparison: {
    chartDuration: 'Chart duration',
    clickOnFurnaceMsg: 'Click on furnace ID to go to sensor mapping',
    compareBtn: 'Compare',
    create: 'Create Model Process',
    deselectBtn: 'Deselect All',
    loadingComplete: 'Loading complete',
    loadingJob: 'Loading load',
    noMainTC: "No 'Main TC' sensor data found in this recipe",
    noMappedSensors: 'no mapped sensors',
    of: 'of',
    recurringData: 'Recurring data with ID:',
    unavailableData: 'Data unavailable for Load-ID: ',
    warningMsg: 'Warning: Over 10 loads selected. You may experience long wait times and performance issues',
  },
  charts: {
    checkAll: 'Select All',
    fullscreen: 'Full screen',
    groups: {
      derivedValues: 'Derived values',
      gasFlows: 'Gas Flows',
      others: 'Others',
      potentials: 'Potentials',
      pressures: 'Pressures',
      temperatures: 'Temperatures',
    },
    skaleTypes: {
      linear: 'linear',
      logarithmic: 'logarithmic',
    },
    position: {
      left: 'left',
      right: 'right',
    },
    newAxis: 'New axis',
    hoverOnGraph: 'Hover or tab on graph to see values',
    lineWidthSlider: 'Line width',
    lostProductiveTime: 'Lost productive time',
    noAlarms: 'No alarms',
    notRealizedByShiftModel: 'Not realized by shift model',
    plannedOutage: 'Planned outage',
    productiveTime: 'Productive time',
    tooltips: {
      lostProductiveTime: "the difference in days between the planned production time and the 'productive time'",
      notRealizedByShiftModel: 'indicates the number of days per month not covered by working hours due to the underlying shift model',
      plannedOutage: 'indicates the number of days per month scheduled for maintenance and repair on average',
      productiveTime: 'the sum of all production times of the executed orders displayed in days per month',
    },
    totalTime: 'Total time',
    uncheckAll: 'Deselect All',
  },
  chartsVisualization: {
    checkSensorMapping1: 'Please check',
    checkSensorMapping2: 'in Asset Management.',
    dataNotDisplayed: 'No data could be displayed. Please check sensor mapping inasset management',
    noModelProcess: 'No Model Process',
    noSensorMapping: 'No sensor mapping was found for this chart.',
    selectModelProcess: 'Select Model Process',
    sensorMapping: 'Sensor Mapping',
  },
  measurementUnit: {
    noGroup: 'Other',
  },
  attributeUnit: {
    [EAttributeLabel.WEIGHT]: 'Weight',
    [EAttributeLabel.LENGTH]: 'Length',
    [EAttributeLabel.DIAMETER]: 'Diameter',
    [EAttributeLabel.SURFACE_HARDNESS]: 'Surface hardness',
    [EAttributeLabel.SURFACE_HARDNESS_MIN]: 'Minimum surface hardness',
    [EAttributeLabel.SURFACE_HARDNESS_MAX]: 'Maximum surface hardness',
    [EAttributeLabel.CORE_HARDNESS]: 'Core hardness',
    [EAttributeLabel.CORE_HARDNESS_MIN]: 'Minimum core hardness',
    [EAttributeLabel.CORE_HARDNESS_MAX]: 'Maximum core hardness',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_MIN]: 'ECD min',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_MAX]: 'ECD max',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_HARDNESS]: 'ECD hardness',
    neuAttributePlaceholder: 'Attribute Label',
  },
  equipmentGasName: {
    [ETimeseriesMeaning.AIR_FLOW_SETPOINT]: 'Air',
    [ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT]: 'Hydrogen',
    [ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT]: 'Nitrogen',
    [ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT]: 'Oxygen',
    [ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT]: 'Ammonia',
    [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT]: 'Dissociated Ammonia',
    [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT]: 'CO2',
    [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT]: 'CO',
    [ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT]: 'Methane',
    [ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT]: 'Propane',
    [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT]: 'N2O',
    [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT]: 'Methanol',
    [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT]: 'Acetylene',
    [ETimeseriesMeaning.ARGON_FLOW_SETPOINT]: 'Argon',
    [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT]: 'Endo Gas Natural',
    [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT]: 'Endo Gas Propane',
    [ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT]: 'Ethene',
    [ETimeseriesMeaning.HELIUM_FLOW_SETPOINT]: 'Helium',
    [ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT]: 'Natural Gas',
    [ETimeseriesMeaning.WATER_FLOW_SETPOINT]: 'Water',
  },
  // single words or small phrases WITHOUT CONTEXT
  common: {
    fileName: 'File Name',
    ok: 'Ok',
    noAccessToAction: 'You do not have access to this action.',
    tiny: 'Tiny',
    exampleText: 'My text',
    large: 'Large',
    size: 'Size',
    profile: 'Profile',
    appearance: 'Appearance',
    home: 'Home',
    notification: 'Notification | Notifications',
    meaning: 'Meaning| Meanings',
    result: 'Result',
    toggle: 'Toggle',
    statistics: 'Statistics',
    rating: 'Rating',
    showItem: 'Show {item}',
    showTable: 'Show table',
    showChart: 'Show chart',
    table: 'Table',
    configuration: 'Configuration',
    sensor: 'Sensor | Sensors',
    passed: 'Passed',
    failed: 'Failed',
    section: 'Section',
    filterName: 'Filter Name',
    capacity: 'capacity | Capacities',
    gas: 'Gas | Gases',
    capability: 'Capability | Capabilities',
    certificate: 'Certificate | Certificates',
    type: 'Type | Types',
    model: 'Model | Models',
    createItem: 'Create {item}',
    overview: 'Overview',
    documents: 'Documents',
    communications: 'Communications',
    percentageC: '%C',
    legend: 'Legend',
    linkItem: 'Link {item}',
    today: 'Today',
    earlier: 'Earlier',
    addItem: 'Add {item}',
    min: 'Min',
    max: 'Max',
    avg: 'Avg',
    file: 'File',
    image: 'Image',
    pasteFromClipboard: 'Paste from clipboard',
    noItemInClipboard: 'No {item} in clipboard',
    assetNavigation: 'Asset Navigation',
    noRecipeSelected: 'No recipe selected',
    addAssessment: 'Add assessment',
    addRow: 'Add row',
    description: 'Description',
    setAll: 'Set all',
    resetAll: 'Reset all',
    unlock: 'Unlock',
    set: 'Set',
    notAvailable: 'N/A',
    stop: 'Stop',
    modify: 'Modify',
    itemNotFound: '{item} not found',
    user: 'User',
    read: 'Read',
    load: 'Load',
    loadItem: 'Load {item}',
    actions: 'Actions',
    add: 'Add',
    valueAtTime: '{value} at {time}',
    timeline: 'Timeline',
    duration: 'Duration',
    temperature: 'Temperature',
    Ferrite: 'Ferrite',
    Austenite: 'Austenite',
    Martensite: 'Martensite',
    labelInUnit: '{label} in {unit}',
    default: 'Default',
    settings: 'Settings',
    version: 'Version',
    logout: 'Logout',
    confirm: 'Confirm',
    edit: 'Edit',
    start: 'Start',
    end: 'End',
    confirmUnlinkItem: 'Are you sure you want to unlink "{item}"?',
    confirmDeleteItem: 'Are you sure you want to delete "{item}"?',
    confirmUpdateItem: 'Are you sure you want to update "{item}"?',
    selectAll: 'Select all',
    selectItem: 'Select {item}',
    deselectAll: 'Deselect all',
    value: 'Value | Values',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    validate: 'Validate',
    selectEquipment: 'Please select an Asset from the Asset Navigation on the left to see Details',
    required: 'Required',
    date: 'Date',
    traveller: 'Traveller',
    new: 'New',
    calendarWeekAbbreviation: 'CW',
    unlink: 'Unlink',
    clear: 'Clear',
    other: 'Other',
    cancel: 'Cancel',
    error: 'Error',
    errorMessage: {
      errorSignalLoading: 'Error loading {displayName}',
      noAccess: '{entity} does not have "{accessType}" access to {item}',
      default: 'Something went wrong, please reload your page. If error persists, please contact support.',
      pt9800: {
        AxiosError401: 'Usermapping not done or invalid',
        AxiosError401Item: 'Usermapping not done or invalid for {item}',
        AxiosError403: 'User does not have the required permissions to access PT9800 data',
        AxiosError403Item: 'User does not have the required permissions to access PT9800 data for {item}',
      },
    },
    fileGeneration: {
      summary: 'Generating file',
      detail: 'The generation of a file may take a while. Please keep the browser window open. This message will close once the request has completed.',
    },
    defaultError: 'Error',
    update: 'Update',
    create: 'Create',
    createCopy: 'Create copy',
    delete: 'Delete',
    save: 'Save',
    saveItem: 'Save {item}',
    saveAsNew: 'Save as ...',
    yes: 'Yes',
    no: 'No',
    export: 'Export',
    import: 'Import',
    search: 'Search',
    selected: 'Selected',
    back: 'Back',
    forward: 'Forward',
    accept: 'Accept',
    print: 'Print',
    download: 'Download {item}',
    sensorSuffix: {
      actual: 'ACT',
      setpoint: 'SET',
    },
    submit: 'Submit',
    success: 'Success',
    close: 'Close',
    from: 'from',
    to: 'to',
    month: {
      [EMonth.JANUARY]: {
        long: 'January',
        short: 'Jan',
      },
      [EMonth.FEBRUARY]: {
        long: 'February',
        short: 'Feb',
      },
      [EMonth.MARCH]: {
        long: 'March',
        short: 'Mar',
      },
      [EMonth.APRIL]: {
        long: 'April',
        short: 'Apr',
      },
      [EMonth.MAY]: {
        long: 'May',
        short: 'May',
      },
      [EMonth.JUNE]: {
        long: 'June',
        short: 'Jun',
      },
      [EMonth.JULY]: {
        long: 'July',
        short: 'Jul',
      },
      [EMonth.AUGUST]: {
        long: 'August',
        short: 'Aug',
      },
      [EMonth.SEPTEMBER]: {
        long: 'September',
        short: 'Sep',
      },
      [EMonth.OCTOBER]: {
        long: 'October',
        short: 'Oct',
      },
      [EMonth.NOVEMBER]: {
        long: 'November',
        short: 'Nov',
      },
      [EMonth.DECEMBER]: {
        long: 'December',
        short: 'Dec',
      },
    },
    time: {
      second: 'Second | Seconds',
      day: 'Day | Days',
      week: 'Week | Weeks',
      month: 'Month | Months',
      quarter: 'Quarter | Quarters',
      year: 'Year | Years',
    },
    timeAbbreviation: {
      days: 'days',
      hours: 'hr',
      minutes: 'min',
      months: 'mth',
      weeks: 'wk',
      years: 'yr.',
    },
    sum: 'Sum',
    average: {
      long: 'Average',
      short: 'Avg',
    },
    reset: 'Reset',
    comment: 'Comment',
    none: 'None',
    copy: 'Copy',
    copyNoun: 'Copy',
  },
  views: {
    loadList: {
      selectLoad: {
        selectPartButton: 'Select part',
        addPartButton: 'Add part',
      },
    },
  },
  component: {
    qualityForm: {
      jobSelection: 'Load selection',
      partSelection: 'Part selection',
    },
    furnaceViewRecipe: {
      stopConfirm: 'Are you sure you want to stop recipe "{0}"?',
      startConfirm: 'Are you sure you want to start recipe "{0}"?',
      modifyConfirm: 'Are you sure you want to modify recipe "{0}"?',
    },
    createUser: {
      headline: 'Create user',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      role: 'Role',
      permissionsCheckbox: 'Custom Permissions',
      appPermissions: 'App Permissions',
      assetPermissions: 'Asset Permissions',
    },
    pt9800RecipeForm: {
      recipeName: 'Recipe name',
      totalDuration: 'Total duration',
      processSteps: 'Process steps',
      generalHeader: 'General information',
      diffusionModel: 'Diffusion model',
      version: 'version',
      parameters: 'Parameters',
      profile: 'Profile',
      recipeExists: 'Recipe with that name already exists.',
    },
    userSettingsModal: {
      title: 'User settings',
      language: 'Language',
      timeFormat: 'Time format',
    },
    editOrCreateRoleModal: {
      editHeadline: 'Edit role',
      createHeadline: 'Create role',
      appPermission: 'App Permissions',
      assetPermission: 'Asset Permissions',
    },
    appAndAssetPermissionTree: {
      appPermission: 'App Permissions',
      assetPermission: 'Asset Permissions',
    },
    rootCauseLinkModal: {
      title: 'Link root cause',
    },
    sensorTable: {
      tableHeader: 'Asset signals',
      showHidden: 'Show hidden asset signals',
      dataFormat: 'Type',
    },
    materialSelect: {
      placeholder: 'Select material',
      unit: 'Materials',
    },
    materialGroupTable: {
      tableHeader: 'Material groups',
    },
    dashboardPanel: {
      add: 'Add panel',
      defaultTitle: 'My panel',
    },
    presetSelection: {
      modal: {
        defaultTile: 'My preset',
      },
    },
    userUpdateModal: {
      tab: {
        permissionList: 'Permissions',
        controllerUserMapping: 'Controller user mapping',
      },
      permissionList: 'Permission list',
      groupList: 'Group list',
      selectPT9800: 'Select PT9800 user',
      enterCredentials: 'Enter credentials',
    },
    loadModal: {
      startingLoad: 'Starting load...',
      confirmPartHint: 'Please confirm all parts are loaded',
      confirmStart: 'Confirm the start of the jobs.',
      workplaceWoNumber: generalTerm.loadIdentifier,
      createOrUpdate: 'Load planning',
      description: 'Plan your next load by scanning your',
      addTraveller: 'Add new traveller',
      emptyPartsTable: 'No parts added',
      partNotFound: 'Part with id "{id}" was not found',
      partDoNotMatch: 'Not all parts match the recipe',
      readyToScan: 'Ready to scan',
    },
    rootCauseModal: {
      create: 'Create root cause',
      update: 'Update root cause',
    },
    rootCauseGroupModal: {
      create: 'Create root cause group',
      update: 'Update root cause group',
    },
    counterActionModal: {
      create: 'Create counter action',
      update: 'Update counter action',
    },
    counterActionTable: {
      create: 'Create counter action',
      confirmDelete: 'Delete counter action "{counterActionName}"?',
      confirmUnlink: 'Unlink root cause "{rootCauseName}" from counter action "{counterActionName}"?',
    },
    rootCauseTable: {
      create: 'Create root cause',
      confirmDelete: 'Delete root cause "{rootCauseName}"?',
      confirmUnlink: 'Unlink counter action "{counterActionName}" from root cause "{rootCauseName}"?',
    },
    rootCauseGroupTable: {
      create: 'Create root cause group',
      confirmDelete: 'Delete root cause group "{rootCauseGroupName}"?',
      confirmUnlink: 'Unlink root cause "{rootCauseName}" from root cause group "{rootCauseGroupName}"?',
    },
    observationTypeTable: {
      unlinkConfirm: 'Unlink Root Cause "{rootCauseName}" from Observation type "{observationTypeName}"?',
    },
    productionLogsTable: {
      heatTreatmentProcess: 'Heat treatment process',
      hasAlarms: 'Has alarms',
      tab: {
        table: 'Table',
        chart: 'Chart',
      },
    },
    dateRangePicker: {
      currentWeek: 'Current week',
      custom: 'Custom',
      absolute: 'Absolute',
      customRange: 'Custom Range',
      relative: 'Relative',
      timeUnit: 'Time unit',
      relativeMinDateError: 'Date cannot be before: {0}',
      operatingAbdoluteErrorMessage: 'Please choose a date within the specified range: {min} - {max}',
    },
    specificationModal: {
      header: 'Specification',
      specificationSubmitSuccess: 'Specification saved',
      error: {
        envelopeInvalid: 'Envelope is invalid',
        emptyZones: 'Zones cannot be empty',
        nameMissing: 'Specification must have name',
      },
    },
    treeSidebarTemplate: {
      defaultTitle: 'Asset navigation',
    },
    TableJobStages: {
      header: 'Recipe stages',
    },
    observationView: {
      header: 'Observations',
    },
    furnacePanel: {
      status: 'Status',
      process: 'Load No',
      recipeName: 'Recipe name',
      stageNumber: 'Stage number',
      stageName: 'Stage name',
      activeAlarms: 'Active alarms',
      furnaceStatus: {
        IDLE: 'Idle',
        MAINTENANCE: 'Maintenance',
        OFFLINE: 'Off',
        RUNNING: 'Running',
        RUNNING_NO_ALARMS: 'Running',
        RUNNING_ALARM: 'Alarm',
        RUNNING_EMERGENCY: 'Emergency',
        RUNNING_NORMAL: 'Info',
        RUNNING_WARNING: 'Warning',
        LOADING: 'Loading...',
        LOADING_ERROR: 'Loading error',
      } as Record<TFurnacePanelStatus, string>,
    },
    alarmView: {
      header: 'Alarms',
      empty: 'No alarms found',
    },
    accordionAudit: {
      envelopeNamePlaceholder: 'Envelope name',
    },
    page: {
      performanceDashboard: {
        stackedPolarChart: {
          title: 'Productivity (Polar)',
        },
        clusteredStackedBarChart: {
          title: 'Productivity (Clustered Stacked bar)',
        },
        table: {
          title: 'Productivity (Table)',
        },
        stackedBarChart: {
          title: 'Productivity (Stacked bar)',
        },
        paretoChart: {
          title: 'Productivity (Pareto)',
        },
        visualisationModal: {
          title: 'Display settings',
          subtitle: 'Select the charts you want to display on the dashboard from the lists below.',
          aggregationTitle: 'Aggregation',
          aggregationDescription: 'These charts show the efficiency for the complete selected time range.',
        },
      },
      productionLog: {
        presetEditForm: {
          saveSuccessMessage: 'Your custom filter is saved.',
          deleteConfirmation: 'Do you want to delete the filter?',
          deleteSuccessMessage: 'Filter deleted successfully',
        },
      },
      maintenanceCalendar: {
        maintenanceCalendarTemplate: {
          routeName: {
            [ERouteName.MAINTENANCE_CALENDAR]: 'Calendar',
            [ERouteName.MAINTENANCE_CALENDAR_REPORT_LIST]: 'Reports',
            [ERouteName.MAINTENANCE_CALENDAR_JOB_LIST]: 'Jobs',
            [ERouteName.MAINTENANCE_CALENDAR_TASK_LIST]: 'Tasks',
          },
        },
      },
      quality: {
        draft: {
          steps: {
            loadSelection: 'Load selection',
            partSelection: 'Part selection',
            sampling: 'Sampling',
          },
        },
      },
    },
    processChartComponent: {
      treeView: {
        deselectAll: 'Deselect All',
      },
      defaultCustomConfigName: 'New unsaved custom config',
      deselect: 'Deselect All',
      presetConfigModal: {
        configMapPlaceholder: 'Select signal to edit',
        presetConfigSaveSuccess: 'Preset config saved',
        presetNameLabel: 'Preset name',
        presetSaveSuccess: 'Preset saved',
      },
      presetEdit: 'Select preset to edit',
      signalHintList: 'Sensor data comparison',
    },
    maintenanceCalendarJobModal: {
      titleCreate: 'Create maintenance job',
      titleEdit: 'Edit maintenance job',
      createTask: 'Create task',
    },
    maintenanceCalendarTaskModal: {
      titleCreate: 'Create maintenance task',
      titleEdit: 'Edit maintenance task',
    },
    maintenanceOccurrenceTable: {
      occurrenceListTitle: 'List of maintenance',
      hideCompletedEvents: 'Hide completed events',
    },
    maintenanceServiceReportModal: {
      finish: 'Finish occurrence',
      reject: 'Reject occurrence',
      rejectLabel: 'Please provide some context on why this validated occurrenced was rejected.',
    },
    equipmentDocumentUploadModal: {
      headline: 'Upload Documents',
      categoryTitle: 'Category',
      descriptionTitle: 'Description',
      uploadFilesTitle: 'Upload Files',
      errorMessage: 'File already exists',
    },
    PartAttribute: {
      headline: 'Part settings',
      subheadline: 'The attributes defined in this table will be requested to be filled during creation/modification of parts.',
    },
    materialFilter: {
      multiselectPlaceholder: 'Filter by Material',
      unit: 'Materials',
    },
    partForm: {
      partSpecification: 'Part specification',
      treatmentInformation: 'Treatment information',
      treatmentRemark: 'Treatment remark',
      assetsAndRecipes: 'Qualified assets & recipes',
    },
    expressionMeaningSelection: {
      onlyMapped: 'Show mapped meanings only',
    },
    expressionPreviewModal: {
      title: 'New virtual signal',
    },
    specificationSaveModal: {
      nameLabel: 'Name',
      nameDescription: 'Description',
    },
  },
  confirmationMessages: {
    componentAdded: 'The component has been added',
    componentUpdated: 'Component {component} updated successfully',
    confirmDelete: 'Do you want to delete {name}?',
    deleteComponent: 'Do you want to delete {part}?',
    deleteConnection: 'Do you want to delete this connection?',
    deleteFile: 'Do you really want to delete this file?',
    deleteMaterial: 'Do you want to delete {material}?',
    deletePart: 'Do you really want to delete part {name}?',
    deleteRecipe: 'Do you really want to delete recipe {name}?',
    deleteRootCauseLink: 'Do you really want to unlink root cause {rootCause}?',
    deleteRootCauses: 'Do you really want to delete {rootCauses}?',
    deleteStage: 'Do you really want to delete stage {name}?',
    deleteUser: 'Do you really want to delete user {username}?',
    deletedAlready: 'User {username} was already deleted',
    deletedComponent: 'Component has been deleted',
    deletedPart: 'Part has been deleted',
    deletedRecipe: 'Recipe has been deleted',
    deletedSparePart: 'Spare part has been deleted',
    deletedUser: 'User {username} has been deleted',
    equipmentAdded: 'Asset added successfully',
    equipmentEdited: 'Equipment edited successfully',
    equipmentUpdated: 'Asset {equipment} updated successfully',
    materialAdded: 'Material added successfully',
    materialUpdated: 'Material updated successfully',
    materialsGroupAdded: 'Materials Group added successfully',
    materialsGroupUpdated: 'Materials Group updated successfully',
    partAdded: 'Part added successfully',
    recipeAdded: 'Recipe added successfully',
    registrationAlreadyConfirmed: 'User {username} has already confirmed registration',
    registrationEmailResend: 'Registration email has been resent to {username}',
    resendUserEmail: 'Do you want to resend user registration email to {username}?',
    restoreRecipe: 'Please confirm the restore of {recipe} recipe?',
    saveMaterials: 'Do you want to add material?',
    savePart: 'Do you want to save part?',
    saveRecipe: 'Please confirm the save of {recipe} recipe?',
    sensorCopied: 'Successfully copied sensor mapping',
    sensorNotCopied: 'Copying of sensor mapping not possible',
    sparePartAdded: 'Spare parts added successfully',
    sparePartUpdated: 'Spare part {sparePart} updated successfully',
    userAdded: 'User {user} added',
    userTilesUpdated: 'Apps for {user} updated successfully',
  },
  consumables: {
    currMonth: 'Current month',
    forecast: 'Forecast',
    prevMonth: 'Previous month',
  },
  customerSettings: {
    actions: 'Actions',
    errorMsg: 'Wrong value!',
    currencyError: 'Currency should be 3 letters of the currency, for example: EUR, USD',
    hourlyBillingRate: 'Hourly billing rate',
    hourlyDepreciationRate: 'Hourly depreciation rate',
    hoursPerShift: 'Hours per shift',
    plannedOutageDaysPerYear: 'Planned outage days per year',
    rateCurrency: 'Rate currency',
    workingShiftsPerWeek: 'Working shifts per week',
  },
  dateRangePicker: {
    currentWeek: 'Current week',
    custom: 'Custom',
    days: 'Days',
    months: 'Months',
    weeks: 'Weeks',
    years: 'Years',
  },
  details: {
    chart: 'chart',
    images: 'Images',
  },
  dialog: {
    saveChangesConfirm: 'Do you want to save the changes ?',
    saveChangesFailed: 'Failed to save the changes.',
  },
  durationFormat: {
    withValue: {
      minute: '{0} @:durationFormat.minute',
      hour: '{0} @:durationFormat.hour',
      day: '{0} @:durationFormat.day',
      week: '{0} @:durationFormat.week',
      month: '{0} @:durationFormat.month',
      year: '{0} @:durationFormat.year',
    },
    minute: 'minute | minutes',
    hour: 'hour | hours',
    day: 'day | days',
    week: 'week | weeks',
    month: 'month | months',
    year: 'year | years',
  },
  errorMessages: {
    componentAdded: 'The component has not been added',
    componentUpdated: 'The component has not been updated',
    credentials: 'Incorrect username or password',
    deletedUser: 'User {username} was deleted since last page refresh',
    equipmentAdded: 'The asset has not been added',
    equipmentEdited: 'The equipment has not been edited',
    equipmentUpdated: 'The asset has not been updated',
    equipmentViewError: 'No asset view found for given furnace',
    error: 'Error',
    furnaceNotAvailable: 'View not available for this Furnace Type',
    generalMessage: 'Something went wrong, try again later',
    materialNotAdded: 'The material has not been added',
    materialNotUpdated: 'The material has not been updated',
    materialsGroupAdded: 'The materials group has not been added',
    materialsGroupUpdated: 'The materials group has not been updated',
    noEquipmentSelected: 'Please select an Asset from the Asset Navigation on the left to see Details',
    oauth2Fail: 'Oauth2 failed',
    outOfRangeTemperature: 'Temperature is out of range: -200°C - 850°C (-328°F - 1562°F). Please adjust RG or RL value',
    partNotAdded: 'The part has not been added',
    recipeNotAdded: 'The recipe has not been added',
    serverMessage: 'Server problems, please try again later',
    unauthorized: 'Unauthorized',
  },
  filters: {
    dateFrom: 'Date from',
    dateTo: 'Date to',
    filter: 'Filter',
    filterSensors: 'Filter sensors',
    savedFilters: 'Saved filters',
  },
  footer: {
    privacyPolicy: 'Privacy Policy',
  },
  forms: {
    JobValue: 'Chargenwert',
    addNewPlant: 'Add New Plant',
    addNewUser: 'Add New User',
    additionalInformation: 'Additional Information',
    assetId: 'Asset number',
    configureValue: 'Configure Value',
    createRootCause: 'Create a cause',
    credentials: 'Credentials',
    credentialsValid: 'Credentials valid',
    customerName: 'Customer Name',
    customerRequestAdded: 'The Service Request has been added',
    delete: 'File deleted',
    department: 'Department',
    depth: 'Depth',
    derivedValues: 'Derived Values',
    description: 'Description',
    diameter: 'Diameter',
    dimensionsOfLoad: 'Dimensions of load',
    documentsCategoryCreated: 'Category added',
    editMaterial: 'Edit Material',
    editMaterialGroup: 'Edit Material Group',
    editRootCause: 'Edit root cause',
    editUser: 'Edit {user}',
    email: 'E-Mail',
    fileUploaded: 'The file has been uploaded',
    filterCriteria: '4 Filter Criteria',
    fullName: 'Full Name',
    furnaceClass: 'Furnace class',
    furnaceName: 'Furnace name',
    heatTreatment: 'AMS2759: Heat Treatment of steel parts',
    height: 'Height',
    host: 'Host',
    information: 'Information',
    instrumentationType: 'Instrumentation type',
    jobFeatures: 'Load Features',
    jobValue: 'Load Value',
    jobValues: 'Load Values',
    locationName: 'Location Name',
    logValues: 'Log Values',
    malfunctionStartDate: 'Malfunction Start Date',
    manufacturer: 'Manufacturer',
    manufacturerPartNumber: 'Original part number',
    mapTiles: 'User Mapping: Controller',
    maxFlow: 'Max Flow',
    maxLoad: 'Max Load',
    maxTemp: 'Max Temp',
    minTemp: 'Min Temp',
    name: 'Name',
    newMaterial: 'New Material',
    newMaterialGroup: 'New Material Group',
    otherGasses: 'Other Capabilities',
    password: 'Password',
    persistCredentials: 'Persist credentials',
    plantName: 'Location',
    port: 'Port',
    predictions: 'Predictions',
    pyrometry: 'AMS2750: Pyrometry',
    requestCategory: 'Request Category',
    requiredEndDate: 'Required End Date',
    selectBatchesOrTimePeriod: '3 Select loads or time period',
    selectFurnace: 'Copy sensor mapping from current furnace to:',
    selectedBatchesOrTimePeriod: '3 Selected loads or time period',
    selectedLocationsAndAssets: '1 Selected Locations and assets',
    selectedValue: '2 Selected Value',
    spareParts: 'Spare parts',
    stateChanged: 'The state has been changed',
    ticketStatus: 'Ticket status',
    type: 'Type',
    uploadCategory: 'Select or create category',
    uploadEquipment: 'Update asset',
    uploadFile: 'Upload file',
    uploadFiles: 'Upload files',
    uploadedFiles: 'Uploaded files',
    username: 'Username',
    width: 'Width',
  },
  furnaceStatus: {
    alarm: 'Alarm',
    disconnected: 'Disconnected',
    emergencyProcedure: 'Emergency Procedure',
    idle: 'Idle',
    noRemoteConnection: 'No remote connection',
    running: 'Running recipe',
    switchedOff: 'Switched off',
  },
  global: {
    all: 'All',
    comment: 'Comment',
    order: 'Order',
    part: 'Part',
    workplace: 'Workplace',
  },
  hubspotTicketStatus: {
    1: 'New',
    2: 'Waiting on contact',
    3: 'Waiting on us',
    4: 'Closed',
    5: 'Removed',
    34633361: 'New',
    34633362: 'In progress',
    34633364: 'Closed',
  },
  images: {
    addImage: 'Add image',
    delete: 'delete',
    download: 'Download',
    images: 'Images',
    maximumNumberOfImages: 'You can add a maximum of 4 images',
    noImages: 'No Images',
    print: 'print',
    save: 'Save',
    upload: 'Upload',
    uploadImageDescription: 'You can upload image',
    zoom: 'Zoom',
  },
  inputLabels: {
    from: 'from',
    groupname: 'Group name',
    searchRecipe: 'Search recipe',
    to: 'to',
  },
  kpis: {
    cost_avg_ammonia: 'Cost Avg Ammonia',
    cost_avg_carbon_dioxide: 'Cost Avg Carbon Dioxide',
    cost_avg_co_gas: 'Cost Avg CO gas',
    cost_avg_dissociated_ammonia: 'Cost Avg Dissociated Ammonia',
    cost_avg_electricity: 'Cost Avg Electricity',
    cost_avg_endo_gas: 'Cost Avg Endo Gas',
    cost_avg_h2: 'Cost Avg H2',
    cost_avg_nitrogen: 'Cost Avg Nitrogen',
    cost_stddev_ammonia: 'Cost Standard Deviation Ammonia',
    cost_stddev_carbon_dioxide: 'Cost Standard Deviation Carbon Dioxide',
    cost_stddev_co_gas: 'Cost Standard Deviation CO gas',
    cost_stddev_dissociated_ammonia: 'Cost Standard Deviation Dissociated Ammonia',
    cost_stddev_endo_gas: 'Cost Standard Deviation Endo Gas',
    cost_stddev_h2: 'Cost Standard Deviation H2',
    cost_stddev_nitrogen: 'Cost Standard Deviation Nitrogen',
    cost_sum_ammonia: 'Cost Sum Ammonia',
    cost_sum_carbon_dioxide: 'Cost Sum Carbon Dioxide',
    cost_sum_co_gas: 'Cost Sum CO gas',
    cost_sum_dissociated_ammonia: 'Cost Sum Dissociated Ammonia',
    cost_sum_electricity: 'Cost Sum Electricity',
    cost_sum_endo_gas: 'Cost Sum Endo Gas',
    cost_sum_h2: 'Cost Sum H2',
    cost_sum_nitrogen: 'Cost Sum Nitrogen',
    time_avg_load_duration: 'Avg Duration',
    time_max_start_date: 'Start date latest',
    time_min_start_date: 'Start date oldest',
    time_stddev_load_duration: 'Standard Deviation time',
    time_sum_load_duration: 'Sum Duration',
    value_availability: 'Furnace Availability',
    value_avg_ammonia: 'Avg Ammonia',
    value_avg_carbon_dioxide: 'Avg Carbon Dioxide',
    value_avg_co_gas: 'Avg CO gas',
    value_avg_dissociated_ammonia: 'Avg Dissociated Ammonia',
    value_avg_electricity: 'Avg Electricity',
    value_avg_endo_gas: 'Avg End Endo Gas',
    value_avg_h2: 'Avg H2',
    value_avg_nitrogen: 'Avg Nitrogen',
    value_cnt_batch: 'Load count',
    value_furnace_brutto_productivity: 'Furnace Productivity (brutto)',
    value_furnace_netto_productivity: 'Furnace Productivity (Netto)',
    value_stddev_ammonia: 'Standard Deviation Ammonia',
    value_stddev_carbon_dioxide: 'Standard Deviation Carbon Dioxide',
    value_stddev_co_gas: 'Standard Deviation CO gas',
    value_stddev_dissociated_ammonia: 'Standard Deviation Dissociated Ammonia',
    value_stddev_endo_gas: 'Standard Deviation Endo Gas',
    value_stddev_h2: 'Standard Deviation H2',
    value_stddev_nitrogen: 'Standard Deviation Nitrogen',
    value_sum_ammonia: 'Sum Ammonia',
    value_sum_carbon_dioxide: 'Sum Carbon Dioxide',
    value_sum_co_gas: 'Sum CO gas',
    value_sum_dissociated_ammonia: 'Sum Dissociated Ammonia',
    value_sum_electricity: 'Sum Electricity',
    value_sum_endo_gas: 'Sum End Endo Gas',
    value_sum_h2: 'Sum H2',
    value_sum_nitrogen: 'Sum Nitrogen',
  },
  levels: {
    critical: 'Critical',
    high: 'High',
    low: 'Low',
    medium: 'Medium',
  },
  loadList: {
    chargeId: 'Charge Identifier',
    sortingTime: 'Date-Time for Sorting',
    statusSequence: 'Status of Load Sequence',
  },
  localizationDialogs: {
    '12hourTime': '12-Hour Time',
    '24hourTime': '24-Hour Time',
    decimalComma: 'Comma',
    decimalPoint: 'Point',
  },
  maintenanceDashboard: {
    activeAlarms: 'Active alarms',
    alarmsFrequency: 'Alarms Frequency',
    plannedTasks: 'Planned maintenance tasks',
    rollingAverageSize: 'Size of Rolling Average subset',
    showAlarms: 'Alarm Statistics',
    showRollingAverage: 'Show Rolling Average',
    showShewhartGraph: 'Show Shewhart Graph',
  },
  maintenanceDetails: {
    noDatabaseForFurnace: 'No database for Furnace',
  },
  maintenanceRecurrencePeriod: {
    [EMaintenanceRecurrencePeriod.NEVER]: 'Never',
    [EMaintenanceRecurrencePeriod.WEEKLY]: 'Weekly',
    [EMaintenanceRecurrencePeriod.MONTHLY]: 'Monthly',
    [EMaintenanceRecurrencePeriod.YEARLY]: 'Yearly',
  },
  maintenanceServiceTab: {
    [EMaintenanceServiceTab.ATTACHMENTS]: 'Attachments',
    [EMaintenanceServiceTab.DETAILS]: 'Details',
    [EMaintenanceServiceTab.REPORT]: 'Report',
  },
  materials: {
    elements: 'Elements',
    filterGroups: 'Filter material groups',
    materialElements: 'Material Elements',
    materialGroup: 'Material Group',
    materialGroups: 'Material Groups',
    searchMaterialGroup: 'Search material group',
    searchMaterials: 'Search materials',
  },
  materialComposition: {
    min: {
      label: 'Min',
      description: 'A composition with the smallest material properties.',
    },
    max: {
      label: 'Max',
      description: 'A composition with the largest material properties.',
    },
    avg: {
      label: 'Mittelwert',
      description: 'A composition with average material properties.',
    },
    comp: {
      label: 'Mittlere Analyse',
      description: 'A composition with average wt%.',
    },
  },
  modals: {
    imagesOf: 'Images of {entity}',
  },
  modelProcess: {
    STAGE_WISE_TUNNEL: 'Stage Wise Tunnel',
    STATIC_TUNNEL: 'Static Tunnel',
    createModel: 'Create model process',
    createNewModel: 'Create new Model Process',
    creationPage: 'View creation page',
    deleteModelProcess: 'Do you really want to delete Model Process {model}?',
    modelDescription: 'Model process description',
    modelProcessName: 'Model Process Name',
    notAllGood: 'Not all Loads selected for the model process are categorized as good',
    removeProcess: 'Remove model process',
    tunnelType: 'Tunnel Type',
    viewProcess: 'View model process',
  },
  modifyMenu: {
    addNew: 'New {0}',
    createNew: 'Create new',
    delete: 'Delete',
    edit: 'Edit',
    print: 'Print',
    saveAs: 'Save As',
  },
  monitoringDashboard: {
    consumables: 'Consumables',
    furnaceState: 'State',
    productivity: 'Productivity',
    sankeyChartLoadingError: 'Error loading data. Check asset settings',
  },
  nitrexConnect: {
    dashboard: 'Dashboard',
    description: 'Description',
    endCondition: 'End condition',
    furnPress: 'Furnace Press',
    host: 'Host',
    kn: 'Kɴ',
    mainTCSetValue: 'Main TC SP',
    mainTc: 'Main TC',
    port: 'Port',
    process: 'Process',
    processTime: 'Process Time',
    stageName: 'Stage Name',
    stageNumber: 'Stage Number',
    stageTime: 'Stage Time',
    status: 'Status',
    time: 'Time',
    totalFlow: 'Total Flow',
  },
  notes: {
    add: 'Add note',
    author: 'Author:',
    edit: 'Edit note',
    noAuthor: 'Type your note here',
    timestamp: 'Timestamp: ',
  },
  notifications: {
    noPending: 'No pending Notifications',
  },
  operationDashboard: {
    AMMONIA_FLOW: 'Ammonia Flow',
    C2H2_FLOW: 'Acetylene flow rate',
    C2H4_FLOW: 'ethylen flow rate',
    C2H6_FLOW: 'ethane flow rate',
    C3H8_FLOW: 'Propane flow rate',
    CH4_FLOW: 'Methane flow rate',
    CO2_FLOW: 'Carbon dioxide flow rate',
    CO_FLOW: 'Carbon monoxide flow rate',
    CP: 'C Potential',
    C_POTENTIAL: 'C-Potenzial',
    FURNACE_PRESSURE: 'Furnace Pressure',
    FURNACE_TEMPERATURE: 'Furnace Temperature',
    H2_FLOW: 'Hydrogen flow rate',
    KN_POTENTIAL: 'Kn Potential',
    TEMPERATURE: 'Temperature',
    expCompletionTime: 'Expected completion time',
    finishedJobs: 'Loads',
    ganttView: 'Gantt chart',
    hoursUntilCompletion: 'Hours until completion',
    jobs: 'Loads',
    listView: 'Listenansicht',
    missingSensorsMapping: 'Unmapped sensors',
    mustSelectLocation: 'Select location or asset to continue',
    noKpiData: 'No kpi data',
    recipeName: 'Recipe name',
    recipeStatus: 'Recipe status',
    status: 'Status',
    tableView: 'Table view',
    tileView: 'Tile view',
    workOrderNumber: 'Oder number',
  },
  page: {
    notification: {
      title: 'Notifications',
    },
    customerDetails: {
      title: 'Customer details',
    },
    customerEdit: {
      title: 'Edit customer',
    },
    customerCreate: {
      title: 'Create customer',
    },
    userManagement: {
      manageRole: 'Manage roles',
      headline: 'Users ({0})',
      addUser: 'Create User',
      customPermissions: 'Custom permissions',
      searchPlaceholder: {
        name: 'Search by name',
        email: 'Search by e-mail',
        role: 'Search by role',
      },
    },
    roleManagement: {
      roles: 'Roles',
      createRole: 'Create role',
      appPermission: 'App Permissions',
      assetPermission: 'Asset Permissions',
    },
    pt9800RecipeDetails: {
      general: 'General',
      steps: 'Steps',
    },
    home: {
      title: 'Home',
      description: 'Welcome to a new cloud-based solution designed to help you efficiently manage each of your location remotely.',
    },
    releaseNotes: {
      title: 'Release notes',
    },
    privacyPolicy: {
      title: 'Privacy policy',
    },
    userManual: {
      title: 'User manual',
    },
    appOverview: {
      title: 'App overview',
      showOnlyAvailableApp: 'Show only available apps',
      search: 'Search an app',
    },
    partCreate: {
      title: 'Create part',
    },
    partEdit: {
      title: 'Edit part',
    },
    partDetails: {
      title: 'Part details',
    },
    performanceDashboardDetails: {
      datePickerRange: {
        currentWeek: 'Current week',
        lastMonth: 'Previous month',
        currentMonth: 'Current month',
        lastQuarter: 'Previous quarter',
        currentQuarter: 'Current quarter',
        currentYear: 'Current year',
      },
    },
    productionStatisticsDetails: {
      panelSettings: {
        header: 'Panel Settings',
        helpText: 'Choose a title for your panel and select the KPIs that shall be displayed. The type of chart can be chosen on the panel.',
        labelTitle: 'Title',
        labelSize: 'Size',
        labelType: 'Type',
        labelKpiSelection: 'KPI Selection',
      },
      size: {
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
      },
      type: {
        table: 'Table',
        totals: 'Totals',
        totalDistribution: 'Total Distribution',
        childrenTotals: 'Children Totals',
        childrenDistribution: 'Children Distribution',
        childrenComparison: 'Children Comparison',
      },
    },
    productionStatistics: {
      table: {
        header: {
          displayName: 'Name',
        },
      },
    },
    assetManagement: {
      title: 'Asset overview',
      overview: {
        title: 'Asset overview',
        tableHeader: 'Asset tree',
        filter: {
          name: 'Filter by name',
        },
      },
      sensorSettings: {
        placeholder: {
          meaningId: 'Select meaning',
          meaningInstanceNumber: 'Instance number',
        },
      },
      equipmentTab: {
        [ERouteName.ASSET_MANAGEMENT_MAIN]: 'Main',
        [ERouteName.ASSET_MANAGEMENT_MAIN_EQUIPMENT]: 'Main',
        [ERouteName.ASSET_MANAGEMENT_DOCUMENT]: 'Documents',
        [ERouteName.ASSET_MANAGEMENT_SENSOR_MAPPING]: 'Sensor mapping',
        [ERouteName.ASSET_MANAGEMENT_SENSOR_SETTINGS]: 'Sensor settings',
        [ERouteName.ASSET_MANAGEMENT_DATA_SOURCES]: 'Data sources',
      },
    },
    equipmentSettings: {
      headline: 'Profile settings',
      subheadline: 'Update your information',
      currencyRate: 'Rate currency',
      dropdownLabel: {
        usd: '$ USD',
        eur: '€ EUR',
      },
    },
    equipmentDocument: {
      deleteMessage: 'Delete file "{name}"?',
      documentList: {
        headline: 'Documents',
        button: 'Upload document',
        empty: 'No uploaded documents',
        column: {
          fileName: 'File name',
          description: 'Description',
          category: 'Category',
          uploadedBy: 'Uploaded by',
        },
      },
      instructionList: {
        headline: 'Instructions',
        button: 'Upload instruction',
        empty: 'No uploaded instructions',
        column: {
          fileName: 'File name',
          description: 'Description',
          uploadedBy: 'Uploaded by',
        },
      },
    },
    liveApp: {
      equipment: {
        running: 'Running',
        autoUpdate: 'Update automatically (every {0})',
        noActiveJob: 'No active job found',
        noJobs: 'No jobs found',
        chart: 'Chart',
        pinnedSensorModalHeader: 'Select pinned sensors',
      },
    },
    assetManagementEquipmentSensorMapping: {
      showHiddenSignals: 'Show hidden signals',
      sensorTableTitle: 'Sensor mapping',
      signalTableTitle: 'Asset signals',
      mappedSignalTitle: 'Mapped signal',
    },
    maintenanceJobList: {
      jobDeleteConfirm: 'Are you sure you want to delete job {0}?',
      createJob: 'Create job',
    },
    maintenanceCalendar: {
      scheduleMaintenance: 'Schedule maintenance',
    },
    maintenanceDashboardDetail: {
      createOccurrence: 'Schedule maintenance',
      alarmStatisticReference: 'Alarm Statistics',
      alarmTableTitle: 'Alarms',
    },
    pt9800: {
      common: {
        id: 'ID',
        name: 'Name',
        description: 'Description',
        modifiedBy: 'Modified by',
        modifiedOn: 'Modified on',
        number: 'Number',
        comment: 'Comment',
      },
      controllerStatus: {
        title: 'Controller status:',
        template: "connected={connected} ({timeSinceUpdateTime} since update time) {'|'} actualStep={actualStep} {'|'} remote={remote} {'|'} hold={hold}",
      },
      deleteConfirm: 'Are you sure to delete entry: {0}? This action cannot be undone.',
      error: {
        create: 'Failed to create {0}',
        update: 'Failed to update {0}',
        delete: 'Failed to delete {0}',
        clone: 'Failed to clone {0}',
        fetch: 'Failed to fetch {0}',
      },
      form: {
        header: {
          create: 'Create {0}',
          edit: 'Edit {0}',
          delete: 'Delete {0}',
        },
      },
      entryType: {
        alarm: 'Alarm',
        application: 'Application',
        applicationGroup: 'Application Group',
        box: 'Box',
        clientAccess: 'Client Access',
        color: 'Color',
        sms: 'SMS',
        terminal: 'Terminal',
        terminalType: 'Terminal Type',
        user: 'User',
        userAccess: 'User Access',
        userGroup: 'User Group',
        backgroundProcess: 'Background Process',
        tasks: 'Tasks',
        workplace: 'Workplace',
        workplaceType: 'Workplace Type',
        operation: 'Operation',
      },
      backgroundProcess: {
        order: 'Order',
        parameter: 'Parameter',
        active: 'Active',
        activeTime: 'Active Time',
        pid: 'PID',
        header: 'Background Process',
      },
      loadList: {
        selectedLoad: 'Selected load',
        forWorkplace: 'For workplace',
        recipe: 'Recipe',
        loadIdentifier: 'WP - Load-identifier',
        dateSorting: 'Date-Time for sorting',
        pos: 'Pos',
        amount: 'Amount',
        intern: 'Intern',
        customer: 'Customer',
        orderName: 'Order Description',
        orderRemark: 'Order remark',
        weightInKg: 'Weight [kg]',
        totalWeightInKg: 'Tot. weight [kg]',
      },
      operation: {
        header: 'Operation',
        property: 'Property',
        operationType: 'Operation type',
        type: 'Type',
        mode: 'Mode',
        order: 'Order',
        operationName: 'Operation name',
        peProcessType: 'PE process type',
        sortingNo: 'Sorting no',
        automatingPlanningMode: 'Automat planning mode',
        relevantForProductionStatistics: 'Relevant for production statistics',
        relevantForStat: 'Relevant for stat',
        selectProcess: 'Select process',
        selectPart: 'Select part',
        sapNo: 'Sap no.',
        workplaceName: 'Workplace/ WP-groups',
        placeDescription: 'Place description',
      },
      tasks: {
        header: 'Tasks administration',
        active: 'Active',
        type: 'Type',
        command1: 'Command 1',
        command2: 'Command 2',
        command3: 'Command 3',
        command4: 'Command 4',
        command5: 'Command 5',
        command6: 'Command 6',
        command7: 'Command 7',
        command8: 'Command 8',
        command9: 'Command 9',
        command10: 'Command 10',
        startHour: 'Start Hour',
        dateLastActive: 'Date last active',
        intervalInMin: 'Interval in min [min]',
      },
      workplace: {
        logicTypes: 'Logic Types',
        header: 'Workplace',
        inventoryNumber: 'Equipment number',
        belongsTo: 'Belongs to workplace id',
        ip: 'IP address',
        mGuardIp: 'MGuard IP',
        device: 'Device',
        telegramSplit: 'RFC telegram split',
        deactivateOperations: 'Deactivate operations',
        aliveBitErrorNum: 'Alive bit error number',
        logicType: 'Logic type',
        recipePrefix: 'Recipe prefix',
        chamberId: 'Chamber id',
        sharesController: 'Shares controller/copy of chamber-id',
        mainUseMode: 'Main use mode',
        workplaceType: 'Workplace type',
        isActive: 'Is active',
        importantSettings: 'Important settings',
      },
      alarm: {
        alarmAdministration: 'Alarm',
        alarm: 'Alarm',
        alarmText: 'Alarm Text',
        causesTestingLoad: 'Causes a testing load',
        onlyForChamber: 'Only for chamber',
        security: 'Security',
        color: 'Color',
        errorType: 'Error type',
        outputType: 'Output Type',
        alarmGroup: 'Alarm-Group',
      },
      application: {
        header: 'Application',
        command: 'Command',
        description: 'Description',
        groupId: 'Group',
        iconGifName: 'Icon-Gif-Name',
        name: 'Name',
        furnacePicture: 'Furnace picture',
        order: 'Order',
        parameter: 'Parameter',
        tooltip: 'Tooltip',
        typeId: 'Type',
        uid: 'UID',
      },
      applicationGroup: {
        header: 'Application Group',
      },
      recipe: {
        modifyRecipe: 'Modify recipe',
        recipeName: 'Recipe name',
      },
      systemData: {
        active: 'Active',
        addresseeMobilePhoneNo: 'Addressee mobile phone no',
        boxHeader: 'Box Administration',
        colorHeader: 'Color Administration',
        colorValue: 'Color value',
        pin: 'PIN',
        serialInterface: 'Serial interface',
        smsHeader: 'SMS',
        testMessage: 'Test message (1=YES)',
        used: 'Used',
      },
      terminal: {
        header: 'Terminal',
        id: 'Id',
        clientType: 'Terminal Type',
        clientAccess: 'Client Access',
        name: 'Name',
        osType: 'Operating System',
        remark: 'Comment',
        address: 'Address',
        description: 'Description',
        location: 'Location',
        valueLong2: 'INT Value 2',
        valueLong3: 'INT Value 3',
        valueLong4: 'INT Value 4',
        valueLong5: 'INT Value 5',
        valueChar1: 'Character Value 1',
        valueChar2: 'Character Value 2',
        actUserName: 'Current user',
        actLoginTime: 'Logged in since',
        terminalNo: 'Terminal-No',
        lastLoginTime: 'Last Login-Time:',
        lastUserName: 'Last User',
        resolution: 'Screen-Resolution',
        users: 'Users',
        permission: 'Permission',
        rightsToTerminals: 'Rights to Terminals',
      },
      terminalType: {
        header: 'Terminal Type',
      },
      user: {
        header: 'User',
        accessLevel: 'User Access Level',
        changePicture: 'Change Picture',
        comment: 'Comment',
        costCenter: 'Cost Center',
        employeeNumber: 'Employee Number',
        shortName: 'Short Name',
        firstName: 'First Name',
        function: 'Function',
        lastName: 'Last Name',
        language: 'Language',
        loginTimeInMin: 'Login-Time in min.',
        maintenancePopups: 'Maintenance Plan Popups',
        password: 'Password',
        plant: 'Plant',
        playAlarmSound: 'Play alarm sound on Alarms',
        userGroup: 'User Group',
        applicationGroup: 'Application Group',
        alarmSound: 'Play Alarm Sound',
        access: 'Access Level',
      },
      userAccess: {
        header: 'User Access',
        delete: 'Delete',
        newEntry: 'New Entry',
        print: 'Print',
        run: 'Run',
        save: 'Save',
        special1: 'Special-1',
        startRecipeProcessFlow: 'Start Recipe/Process Flow',
        modifyRecipeProcessFlow: 'Modify Recipe/Process Flow',
        startCorrectionRecipe: 'Start Correction Recipe',
        stopRecipeProcessFlow: 'Stop Recipe/Process Flow',
      },
      userGroup: {
        header: 'User group',
      },
      workplaceType: {
        header: 'Workplace Type',
        prio: 'Prio',
      },
    },
    login: {
      title: 'Login',
    },
    anomalyDetectionJobFeatureDetail: {
      title: 'Job feature',
      tab: {
        observationTable: 'Observations',
        rootCauseTable: 'Root Causes',
      },
    },
    anomalyDetectionEquipmentSettings: {
      title: 'Settings',
      tab: {
        counterActionTable: 'Counter Actions',
        rootCauseGroupTable: 'Root Causes Group',
        rootCauseTable: 'Root Causes',
        jobFeatureTable: 'Job Features',
      },
    },
    anomalyDetectionEquipment: {
      title: 'Anomaly detection',
      confirmUnlink: 'Unlink Counter Action "{counterActionName}" from Root Cause "{rootCauseName}"?',
      tab: {
        rootCauseTable: 'Possible Root causes',
        jobFeatureTable: 'Observations grouped by Job Features',
      },
      jobFeatureTable: {
        header: {
          jobFeatureName: 'Job Feature',
          observationCount: 'Observations count',
          rootCauseCount: 'Root Cause count',
        },
      },
      observationTypeByJobFeatureTable: {
        header: {
          observationTypeName: 'Observation type name',
          observationCount: 'Observations count',
          rootCauseIdList: 'Root cause count',
        },
      },
    },
    anomalyDetection: {
      noObservations: 'No observations found',
      jobFeatures: {
        title: 'Job features',
        tab: {
          rootCauseTable: 'Root Causes',
          jobFeatureTable: 'Observations',
        },
      },
      title: 'Anomaly Detection',
    },
    audit: {
      navigation: {
        configure: 'Configure audit',
        load: 'Select load',
        verify: 'Rate audit',
      },
      selectLoad: {
        selectTime: 'Select time range',
        tableHeader: {
          durationInSec: 'Duration',
          endingTime: 'End time',
          header: {
            recipeName: 'Recipe',
          },
          loadName: 'Load No',
          locationName: 'Location',
          startingTime: 'Start time',
          workplace: {
            fullName: 'Asset',
          },
        },
        title: 'Audit / Select load',
      },
      title: 'Audit',
    },
    quality: {
      title: 'Audit & Quality',
      header: {
        title: 'Quality',
        createReport: 'Create report',
      },
    },
    auditResult: {
      passed: 'Passed the audit',
      notPassed: 'Did not pass the audit',
      emptySecondarySensor: 'No selected secondary sensor',
      asset: 'Asset',
      comment: 'Comment',
      duration: 'Duration',
      endTime: 'End time',
      headerTitle: 'Audit report',
      loadNo: 'Load No',
      location: 'Location',
      rating: 'Rating',
      recipe: 'Recipe',
      reportNo: 'Report No.',
      reporter: 'Reporter',
      signature: {
        customer: 'Customer',
        engineering: 'Engineering',
        manufacturing: 'Manufacturing',
        material: 'Material',
        program: 'Program',
        quality: 'Quality',
      },
      startTime: 'Start time',
      title: 'Audit result',
    },
    certification: {
      audit: {
        title: 'Audit & Quality / Audit',
      },
      auditConfiguration: {
        title: 'Audit & Quality / Audit Configuration',
        deleteConfirm: 'Are you sure you want to delete specification "{0}"?',
        deleteSuccess: 'Specification "{0}" successfully deleted',
      },
      quality: {
        title: 'Audit & Quality / Quality',
      },
      qualityConfiguration: {
        title: 'Audit & Quality / Quality configuration',
      },
      qualityStatistics: {
        title: 'Audit & Quality / Quality statistics',
      },
      title: 'Audit & Quality',
    },
    chartRecorder: {
      defaultGroup: 'Other',
    },
    createRootCause: {
      linkRootCause: {
        title: 'Link root cause',
      },
      linkSuggestedAction: {
        title: 'Link suggested action',
      },
      selectJobFeature: {
        title: 'Select job feature',
      },
      selectObservationType: {
        title: 'Select observation type',
      },
      title: 'Create root cause',
    },
    productionLogList: {
      compare: 'Compare',
      export: 'Export',
      filterPreset: 'Saved filters',
      furnaceType: 'Furnace Type',
      presetEditTitle: 'Edit preset',
      includeRunningJobs: 'Include running jobs',
    },
  },
  part: {
    noRecipes: 'No Recipes...',
  },
  parts: {
    amount: 'Amount',
    defineParameterForPartStatistics: 'Define parameter for part statistics',
    description: 'Description',
    forWorkplace: 'for asset',
    name: 'Name',
    order: 'Order',
    orderNo: generalTerm.loadIdentifier,
    part: 'Part',
    partDesc: 'Part Description',
    partNo: 'Part no',
    parts: 'Parts',
    recipe: 'Recipe',
    recipeNo: 'Recipe N°',
    statistics: 'Part Statistics',
    statisticsFor: 'Statistics for:',
    weight: 'Weight',
  },
  passwordDialog: {
    confirm: 'Change password',
    important: 'Important Info',
    newPassword: 'Enter new password',
    newUserPassword: 'Give this password to newly created user: {password}',
    oldPassword: 'Enter current password',
    passwordChanged: 'Password changed!',
    retypePassword: 'Confirm new password',
    title: 'Choose a new password',
  },
  productionLogs: {
    default: 'Default',
    partDataToLoad: 'Part Data to Load:',
    profileForLoad: 'Profile for Load:',
    showInGraph: 'Show in graph',
  },
  productionStatistics: {
    assets: 'assets',
    compareHierarchyTooltip: 'Only elements from the same Hierarchy Layer can be compared',
    comparing: 'Comparing',
    customers: 'customers',
    loads: 'loads',
    locations: 'locations',
    recipes: 'recipes',
    selectedItems: 'Selected items',
  },
  recipeBuilder: {
    alarmDeactivated: 'Alarm deactivated',
    alarmDelay: 'Alarm delay',
    description: 'Description',
    equi: 'Equi',
    highAlarmOffsets: 'HIGH Alarm offsets',
    highWarningOffsets: 'HIGH Warning offsets',
    lowAlarmOffsets: 'LOW Alarm offsets',
    lowWarningOffsets: 'LOW Warning offsets',
    nitrexProcess: 'Protected Nitrex Process',
    recipeName: 'Recipe name',
    referenceValue: 'Reference value',
    setAlarmLimit: 'Set alarm limit',
    setAlarmLimits: 'Set alarm limits',
    setPoints: {
      'Cooling Ramp': 'Cooling Ramp',
      Dissociation: 'Dissociation',
      'Dissociation H2Smart': 'Dissociation H2Smart',
      Furn: {
        Press: 'Furn.Press',
      },
      'Heating Ramp': 'Heating Ramp',
      Kn: 'Kn',
      Kc: 'Kc',
      Turbo: 'Turbo',
      'Main TC': 'Main TC',
      Time: 'Duration',
      'Total Flow': 'Total Flow',
    },
    setValue: 'Set value',
    totalDuration: 'Total duration',
  },
  recipeOnDemand: {
    ammonia: 'Ammonia',
    chart: {
      gasCompositionLabel: {
        ammonia: 'Ammonia: {value} %',
        dissociatedAmmonia: 'Dissociated Ammonia: {value} %',
        nitrogen: 'Nitrogen: {value} %',
        carbonOxide: 'Carbon Dioxide: {value} %',
        carbonDioxide: 'Carbon Dioxide: {value} %',
      },
      processLabel: {
        knAct: 'Kn Act: {value}',
      },
    },
    compoundLayerThickness: 'Compound layer thickness',
    coreHardnessHV: 'Core Hardness (HV)',
    diffusionLayerThickness: 'Diffusion layer thickness',
    dissAmmonia: 'Dissociated Ammonia',
    distance: 'Distance',
    effective: 'Effective',
    gasFlowChart: 'Gas-flow chart',
    hardness: 'Hardness',
    material: 'Material',
    nitridetHardness: 'Nitrided hardness depth',
    nitriding: 'Nitriding',
    nitrocarburizing: 'Nitrocarburizing',
    nitrogen: 'Nitrogen',
    oxideLayerThickness: 'Oxide layer thickness',
    porosity: 'Porosity %',
    postoxidation: 'Postoxidation',
    process: 'Process',
    processChart: 'Process chart',
    profileChart: 'Profile chart',
    stepNumber: 'Step {number}',
    surfaceHardness: 'Surface hardness',
    unit: 'Unit',
    unitSystems: {
      imperial: 'Imperial',
      metric: 'Metric',
    },
    vickersHardness: 'Vickers hardness',
  },
  recipeStep: {
    actualConditions: 'Actual Conditions',
    actualValue: 'Actual value',
    addSetpoint: 'Add setpoint',
    addStep: 'Add recipe step',
    chamber: 'Chamber',
    chamberOperation: 'Chamber operation',
    ctrl: 'Ctrl',
    description: 'Description',
    digitalOutput: 'Digital Output',
    digitalOutputs: 'Digital Outputs',
    endAfter: 'End after',
    endCondition: 'End Condition',
    endStates: 'End states',
    estimatedDuration: 'Estimated Duration',
    modifyStep: 'Modify recipe step',
    next: 'Next',
    operation: 'Operation',
    previous: 'Previous',
    runtime: 'Runtime',
    setpoint: 'Setpoint',
    setpoint1: 'Setpoint 1',
    setpoint2: 'Setpoint 2',
    step: 'Step',
    time1: 'Time1',
    time2: 'Time2',
    tolMinus: 'Tol-',
    tolPlus: 'Tol+',
    unit: 'Unit',
    value: 'Value',
    viewStep: 'View recipe step',
  },
  recipes: {
    alloyFactor: 'Alloy Factor',
    analysis: 'Analysis',
    calculateParameters: 'Calculate Parameters',
    carbideLimit: 'Carbide Limit',
    carbonContent: 'Carbon Content',
    carbonContentPc: 'Carbon Content [%C]',
    carburizingDepth: 'Carburizing Depth',
    comment: 'Comment',
    correction: 'Correction',
    correctionProfile: 'Enter correction profile',
    deleteConfirm: 'Do you really want to delete the recipe?',
    deleteVersionConfirm: 'Do you really want to delete the recipe version?',
    depth: 'Depth',
    desc: 'Desc.',
    desc2: 'Desc. 2',
    description: 'Description',
    diff: 'DIFF',
    endTemperature: 'End Temperature',
    general: 'General',
    grainSize: 'Grain Size',
    incorrectAmountOfElementsInCorrectionProfile: 'Amount of elements is incorrect in correction profile.',
    legFactorN: 'Leg. Factor N',
    material: 'Material',
    name: 'Name',
    nameAlreadyInUse: 'Name {name} already exists.',
    nameChangeNotAllowedInNewRecipeVersionMode: 'Cannot change the name of recipe because length of versions is different than one.',
    nitridingDepth: 'Nitriding Depth',
    operation: 'Operation',
    process: 'Process',
    profile: 'Profile',
    quenchingIntensity: 'Quenching Intensity',
    recipe: 'Recipe',
    representativeDiameter: 'Representative Diameter',
    requiredHardnessHV: 'Req.Hardness [HV]',
    saveNewVersion: 'Save as new version',
    selectRecipe: 'Select Recipe',
    steps: 'Steps',
    thsd: 'thsd',
    versionNo: 'Version Nb:',
    noAccess: 'User has no access to {0}',
  },
  recipesPt9800: {
    unknownRecipeMenu: 'Unknown Recipe Group',
  },
  report: {
    actions: 'Actions',
    approvals: 'Approvals',
    batchNumber: 'Load Number',
    companyName: 'Company Name',
    customer: 'Customer',
    date: generalTerm.date,
    dateTime: 'Date/Time',
    engineering: 'Engineering',
    in: 'IN',
    manufacturing: 'Manufacturing',
    materiel: 'Materiel',
    maxBand: 'Upper limit',
    minBand: 'Lower limit',
    out: 'OUT',
    program: 'Program',
    quality: 'Quality',
    recipeCode: 'Recipe Code',
    reporter: 'Reporter',
    request: 'Request No.',
    systemId: 'Asset Name',
    timeInBand: 'Time in envelop',
    title: 'Request for MRB Action',
    totalTimeInBand: 'Total time in envelop: ',
  },
  requestCategory: {
    bom: 'BOM',
    configuration: 'Configuration',
    drawings: 'Drawings',
    emergency: 'Emergency',
    sales: 'Sales',
    service: 'Service',
    software: 'Software Service',
  },
  settings: {
    commonSensors: {
      customSensorName: 'Custom sensor name',
      introduction: 'Click on any of the displayed sensors to change its name or color.',
      sensorName: 'Sensor name',
      title: 'Common Sensor setting',
    },
    equipmentGroups: {
      deleteEquipmentGroup: 'Do you really want to delete Equipment Group {group}?',
      deleteSensorGroup: 'Do you really want to delete Sensor Group {group}?',
      dragEquipment: 'Drag the asset below',
      equipmentSelect: 'Asset',
      filterEquipments: 'Filter Equipments',
      introduction: 'Choose a location from the tree view on the left to create a custom location group for the selected location.',
      noLocationSelected: 'No location selected',
      title: 'Asset groups',
    },
    operationDashboard: {
      introduction: 'Select the sensors that shall be displayed in the main and secondary charts in the Operation Dashboard.',
      mainChart: 'Main chart',
      secondaryChart: 'Secondary chart',
      title: 'Operation dashboard sensors',
    },
    sensorGroups: {
      createSensorGroup: 'Create sensor group',
      dragSensor: 'Drag the sensor below',
      introduction: 'Create custom group and dragg the sensors into it.',
      title: 'Sensor groups',
    },
    statistics: {
      introduction: 'Choose the sensors to be displayed in Production Statistics. If no sensor is selected all sensors will be selected.',
      title: 'Statistics settings',
    },
  },
  simulator: {
    addNewStage: 'Add New Stage',
    alloyFactor: 'Alloy factor C',
    alloyFactorCarbon: 'Alloy factor [Carbon]',
    alloyFactorNitride: 'Alloy factor [Nitrogen]',
    auto: 'Auto',
    awt: 'Awt',
    buffering: 'Buffering...',
    carbDepth: 'Carburizing Depth',
    carbideLimit: 'Carbide limit [%aC=1]',
    carbonAtDepth: 'Carbon at carb. depth',
    carbonitriding: 'Carbonitriding',
    carburizing: 'Carburizing',
    'co+co2': 'CO + CO2',
    composition: 'Material composition',
    cool: 'Cool',
    coolDown: 'Cool Down',
    coreCarbon: 'Core carbon content',
    coreHardnessUntreatedHv: 'Core Hardness untreated [HV]',
    diameter: 'Diameter',
    effectiveCaseDepth: 'Effective case depth',
    effectiveCaseHardness: 'Effective case hardness',
    endTemperature: 'End temperature',
    estTemperingConditions: 'Est. tempering conditions',
    fixed: 'Fixed',
    generalShapeOfPart: 'General shape of part',
    grabke: 'Grabke',
    grainSize: 'Grain size',
    gunnarson: 'Gunnarson',
    hardnessNormalized: 'Hardness normalized',
    hardnessQuenched: 'Hardness quenched',
    heat: 'Heat',
    heatUp: 'Heat Up',
    hold: 'Hold',
    logReplay: 'Log Replay',
    lowPressureCarbonizing: 'Low Pressure Carbonizing',
    material: 'Material',
    materialModel: 'Material Model',
    materialSimulationData: 'Material simulation data',
    minSurfaceHardness: 'Min. surface hardness',
    minus: 'Minus {type}',
    models: 'Material models',
    neumann: 'Neumann',
    nitride: 'Nitride',
    nitrideLimit: 'Nitride limit [%FexNy]',
    normalized: 'Normalized',
    nothingSelected: 'Nothing selected',
    nxc: 'NX C - Diff',
    'nxc+n': 'NX C[+N] - Diff',
    off: 'Off',
    on: 'On',
    overwrite: 'Overwrite',
    part: 'Part',
    plus: 'Plus {type}',
    preHeatTreatment: 'Pre-heat treatment condition',
    processChart: 'Process values Chart',
    profileChart: 'Profile Chart',
    profileTable: 'Profile Table',
    properties: 'Properties',
    quenched: 'As quenched',
    quenchingIntensity: 'Quenching intensity',
    recipe: 'Recipe',
    recipeStages: 'Recipe stages',
    representativeCoupon: 'Representative coupon diameter',
    restartToApplyParameters: 'Restart to apply new parameters',
    retainedAustenite: 'Retained austenite',
    runtime: 'Runtime',
    selectMaterial: 'Select material',
    selectPart: 'Select Part',
    selectRecipe: 'Select recipe',
    selectRecipeFirst: 'You have to select recipe first',
    setPoints: 'Set points',
    shapes: {
      CYL_HOLLOW_OVER_200MM: 'Cyl. hollow body internal diameter > 200 mm',
      CYL_HOLLOW_UPTO_200MM: 'Cyl. hollow body internal diameter < 200 mm',
      CYL_HOLLOW_UPTO_80MM: 'Cyl. hollow body internal diameter < 80 mm',
      HEXAGON_OR_OCTAGON_STEEL: 'Hexagon or octagon steel',
      HOLLOW_BODY_CLOSED: 'Hollow body closed from one or two sides',
      RING_OVER_200MM: 'Ring (disc internal diameter > 200 mm)',
      ROUND_STEEL: 'Round steel',
      SOLID_STEEL_UPTO_200MM: 'Solid steel (disc internal diameter < 200 mm)',
      SQUARE_OR_FLAT_STEEL: 'Square or flat steel',
    },
    simulation: 'Simulation',
    sootLimit: 'Soot Limit',
    steelGradeEstimation: 'Steel grade estimation',
    surf: 'Surf {type}',
    tempered: 'tempered',
    temperingConditions: 'Est. tempering conditions',
    variable: 'Variable',
    visualizations: 'Visualizations',
  },
  tabHeaders: {
    readings: 'Readings',
    setPoints: 'Setpoints',
  },
  table: {
    getQuote: 'Get in contact to get a quote',
  },
  tableHeaders: {
    NoAnalogOutputsAvailable: 'No analog outputs available',
    RowsPerPage: 'Rows per page',
    actions: 'Actions',
    activity: 'Activity',
    advisedStockQuantity: 'Recommended storage quantity',
    aggregatedProbability: 'Aggregated probability [%]',
    alarm: 'Alarm',
    alarmNumber: 'Alarm number',
    alarmText: 'Alarm text',
    alarmType: 'Alarm type',
    amount: 'Amount',
    analogOutputs: 'Analog Outputs',
    announcementId: 'Notification ID',
    announcementType: 'Announcement Type',
    apps: 'Apps',
    assetSensors: 'Asset sensors',
    assetType: 'Asset Type',
    assignee: 'Assignee',
    atmosphere: 'Atmosphere',
    auditEnvelope: {
      duration: 'Duration',
      durationLimitMinus: 'Duration limi(-)',
      durationLimitPlus: 'Duration limit(+)',
      name: 'Envelope label',
      selectedMainSensor: 'Main sensor',
      selectedSecondarySensorList: 'Secondary sensors',
      selectedStartTime: 'Start timer at',
      setpoint: 'Setpoint',
      setpointLimit: 'Setpoint Limit(±)',
    },
    austenite: 'Austenite',
    avg: 'Avg {unit}',
    batch: 'Load',
    batchCount: 'Load Count',
    batchDuration: 'Load Duration',
    batchNumber: 'Load Number',
    batchRating: 'Load Rating',
    bomQuantity: 'Bill of material quantity',
    c2h2: 'C2H2',
    c8H8: 'C8H8',
    carbonPotential: 'Carbon Potential',
    carburizingPotential: 'Carburizing Potential',
    category: 'Category',
    cause: 'Cause',
    chartToolsData: 'Graph tools and legend',
    co: 'CO',
    commonSensor: 'Generic sensor',
    commonSensors: 'Common sensors',
    comp: 'Comp',
    contentName: 'Content Name',
    coolingRamp: 'Cooling ramp',
    cost: 'Cost',
    count: 'Count',
    created: 'Created',
    createdAt: 'Created at',
    createdBy: 'Created by',
    customAnalogControllers: 'Custom analog controllers',
    customDigitalControllers: 'Custom Digital Controllers',
    customerCode: 'Company Code',
    customerRequestId: 'Service Request ID',
    date: generalTerm.date,
    defaultDeliveredQuantity: 'Standard delivery quantity',
    delete: 'Delete',
    description: 'Description',
    digitalOutputs: 'Digital output - Delay',
    digits: 'Digits',
    dinNumber: 'DIN Number',
    dissociation: 'Dissociation',
    documentId: 'Document ID',
    duration: 'Duration',
    durationAvg: 'Duration Avg',
    durationSum: 'Duration Sum',
    email: 'E-Mail',
    enabled: 'Enabled',
    endCondition: 'End condition',
    endDate: 'End date',
    endTemp: 'End Temp',
    equipmentId: 'Asset name',
    expectedDuration: 'Expected Duration',
    explanation: 'Explanation',
    featureObservationMapping: 'Feature observation mapping',
    fileName: 'File Name',
    filterName: 'Filter Name',
    finishedAt: 'Finished at',
    firstOccurrence: 'First occurrence',
    fullName: 'Full Name',
    furnace: 'Furnace',
    furnaceName: 'Furnace name',
    furnaceSensor: 'Furnace sensor',
    furnaceType: 'Furnace Type',
    group: 'User Group',
    groupSize: 'Group size',
    h2: 'H2',
    heatingRamp: 'Heating ramp',
    highlighted: 'Highlighted',
    hvResult: 'HV result',
    imperialUnit: 'Imperial unit',
    instructionId: 'Instruction ID',
    instructionType: 'Instruction type',
    intern: 'Intern',
    jobCost: 'Load cost',
    jobFeaturesName: 'Job feature name',
    jobValue: 'Load value',
    jobsLocationDetail: {
      asset_name: 'Asset',
      batch_duration_in_sec: 'Duration',
      end_date_utc: 'End time',
      job_name: 'Load No',
      location_name: 'Location',
      recipe: 'Recipe',
      start_date_utc: 'Start time',
    },
    latestOccurrence: 'Latest occurrence',
    linkedObservations: 'Linked Observations',
    linkedRootCauses: 'Linked Root Causes',
    load: 'Load',
    loadIdentifier: 'Load Identifier',
    loadNo: 'Load N*',
    location: 'Location',
    logInterval: 'Log Interval',
    manufacturer: 'Manufacturer',
    manufacturerPartNumber: 'Original part number',
    materialGroup: 'Material Group(s)',
    max: 'Max {unit}',
    maxFlow: 'Max Flow',
    maxProbability: 'Max probability [%]',
    measurement: 'Measurement',
    message: 'Message',
    metricUnit: 'Metric unit',
    min: 'Min {unit}',
    minFlow: 'Min Flow',
    modifiedAt: 'Modified at',
    n2: 'N2',
    name: 'Name',
    nitridingPotential: 'Nitriding Potential',
    nitrogenPotential: 'Nitrogen Potential',
    noCustomAnalogControllersAvailable: 'No custom analog controllers available',
    noCustomDigitalControllersAvailable: 'No Custom digital controllers available',
    notificationId: 'Notification ID',
    numberOfObservation: 'Number of observation',
    numberOfOccurrences: 'Number of occurrences',
    numberOfRootCauses: 'Number of root causes',
    observation: 'Observation',
    observationType: 'Observation type',
    occurrences: 'Occurrences',
    ofTotalFlow: '{type} Of Total Flow',
    orderName: 'Order name',
    orderRemark: 'Order remark',
    outputs: 'Outputs',
    partDescription: 'Part description',
    partName: 'Partname',
    plantName: 'Location',
    pos: 'Position',
    possibleRootCauses: 'Possible root causes',
    probability: 'Probability',
    probabilityPercentages: 'Probability [%]',
    process: 'Process',
    processMode: 'Process mode',
    protected: 'Protected',
    publishedOn: 'Published On',
    recent: 'last changed',
    recipe: 'Recipe',
    recipeCode: 'Recipe Code',
    recipeDescription: 'Recipe Description',
    recipeHash: 'Recipe hash value',
    recipeName: 'Recipe name',
    requestId: 'Request ID',
    requestStatus: 'Request status',
    rootCause: 'Root Cause',
    rootCauses: 'Root Causes',
    sensorName: 'Sensor name',
    sensorSelection: 'Sensor selection',
    set: 'Set',
    setPoint: 'Set point',
    setPoints: 'Setpoints',
    siUnit: 'SI unit',
    sparePartsId: 'Spare part ID',
    stage: 'Stage',
    stageDuration: 'Stage duration',
    stageName: 'Stage name',
    startDate: 'Start date',
    startDateLatest: 'Start Date (latest)',
    startDateOldest: 'Start Date (oldest)',
    status: 'Status',
    step: 'Step',
    suggestedAction: 'Suggested action',
    systemId: 'Asset Name',
    task: 'Task',
    temperature: 'Temperature',
    time: 'Time',
    timeOfDeclaration: 'Time of declaration',
    timeStamp: 'Timestamp',
    toolsAndSpareParts: 'Tools and Spare Parts',
    totalDuration: 'Total duration',
    totalFlow: 'Total Flow',
    totalWeightInKg: 'Total weight [kg]',
    tunnelFunction: 'Tunnel Function',
    type: 'Type',
    updated: 'Updated',
    upload: 'Uploaded by',
    username: 'Username',
    value: 'value',
    valueWhenDeclared: 'Value when declared',
    volumeFlow: 'Volume Flow',
    weightInKg: 'Weight [kg]',
    workOrderNo: 'Work Order no',
  },
  tabs: {
    job_list: 'Jobs',
    task_list: 'Tasks',
    absolute: 'Absolute',
    alarms: 'Alarms',
    attachments: 'Attachments',
    audit: 'Audit',
    calendar: 'Calendar',
    chartView: 'Chart View',
    charts: 'Charts',
    communication: 'Communication',
    controller: 'Controller',
    customerSettings: 'Settings',
    data: 'Data',
    details: 'Details',
    documents: 'Documents',
    equipments: 'Assets',
    filters: 'Filters',
    graph: 'Graph',
    legend: 'Legend',
    main: 'Main',
    'material-groups': 'Material Groups',
    materials: 'Materials',
    materialsGroups: 'Materials Groups',
    modify: 'Modify',
    notifications: 'Notification',
    relative: 'Relative',
    report: 'Report',
    reports: 'Reports',
    save: 'Save',
    sensorGroups: 'Sensor Groups',
    sensorMapping: 'Sensor mapping',
    singleSensor: 'Single Sensor',
    stages: 'Stages',
    status: 'Status',
  },
  tiles: {
    userManagement: 'User Management',
    agentChat: 'Agent Chat',
    alarmStatistics: 'Alarm Statistics',
    anomalyDetection: 'Anomaly Detection',
    appOverview: 'Application Overview',
    assetManagement: 'Asset Management',
    certification: 'Audit & Quality',
    customerSettings: 'Asset settings',
    drSommerMaterialDatabase: 'Dr. Sommer Material Database',
    grafana: 'Grafana',
    historyAndTrends: 'History and Trends',
    home: 'Home',
    liveApp: 'Live app',
    maintenanceCalendar: 'Maintenance Calendar',
    materials: 'Materials',
    mlPrototyping: 'ML Prototyping',
    modelProcess: 'Model Process Builder',
    nitrexLive: 'Nitrex Connect',
    part: 'Parts',
    performanceDashboard: 'Performance Dashboard',
    productionLogs: 'Production Logs',
    productionStatistics: 'Production Statistics',
    pt9800Cloud: 'PT9800 Web Client',
    pt9800Configuration: 'PT9800 Configuration',
    recipeOnDemand: 'Recipe on Demand',
    recipes: 'Recipes',
    recipesNitride: 'Recipes (Nitride)',
    recipesPt9800: 'Recipes (PT9800)',
    remoteControl: 'Remote Control',
    shopLayout: 'Shop Layout',
    simulator: 'Simulators',
    universalChart: 'Universal Chart',
    upcPro: 'UPC Toolbox',
    userManual: 'User Manual',
    userSettings: 'User Settings',
    customer: 'Customer',
  },
  titles: {
    alarms: 'Alarms',
    ams: 'AMS',
    announcements: 'QMULUS Announcements',
    capabilities: 'Capabilities',
    certificates: 'Certificates',
    components: 'Components',
    composition: 'Composition',
    copySensors: 'Copy Mapping',
    customerRequests: 'Service Requests',
    dimensions: 'Dimensions',
    documents: 'Documents',
    drawings: 'Drawings',
    equipment: 'Asset',
    filters: 'Filters',
    gasses: 'Gasses',
    general: 'General',
    heatTreatmentType: 'Heat treatment type',
    instructions: 'Instructions',
    jobUsage: 'Load usage',
    locations: 'Locations',
    materialsGroups: 'Materials Groups',
    modelProcess: 'Model Process',
    modelProcesses: 'Model Processes',
    nitridingTemplates: 'Nitriding Templates',
    no: 'No',
    noData: 'No Data',
    notifications: 'Notifications',
    npmController: 'NPM Controller',
    others: 'Others',
    overview: 'Overview',
    partsSimulationData: 'Parts simulation data',
    plants: 'Plants',
    pt9800Controller: 'PT9800 Controller',
    recipe: 'Recipe on Demand',
    recipeStages: 'Recipe stages',
    selectAnalogInputForEndCondition: 'Select Analog Input For EndCondition',
    selectDigitalInputForEndCondition: 'Select Digital Input For EndCondition',
    selectedBatch: 'Selected Loads',
    settings: 'Settings',
    showAlarms: 'Show alarms',
    spareParts: 'Spare Parts',
    stageChange: 'Stage Change',
    stageSetup: 'Stage Setup',
    step: 'Step',
    steps: 'Steps',
    temperature: 'Temperature',
    threeDots: '...',
    users: 'Users',
    valuesAndCalculations: 'Values and Calculations',
    yes: 'Yes',
  },
  type: {
    Visualisation,
    auditEnvelope: {
      duration: 'Duration',
      durationLimitMinus: 'Duration limit(-)',
      durationLimitPlus: 'Duration limit(+)',
      name: 'Envelope label',
      selectedMainSensor: 'Main sensor',
      selectedSecondarySensorList: 'Secondary sensors',
      selectedStartTime: 'Start timer at',
      selectedStartTimeLimit: 'Limit',
      setpoint: 'Setpoint',
      setpointLimit: 'Setpoint limit(±)',
    },
  },
  units: {
    days: 'days',
    hours: 'hr',
    minutes: 'min',
    months: 'mth',
    weeks: 'wk',
    years: 'yr.',
  },
  upcPro: {
    analyzerReading: 'Analyzer Reading',
    convertsTo: 'converts to',
    endoGeneratorDataError: 'DATA INPUT ERROR:\nCO[%] + CO₂[%] + CH₄[%] + H₂[%] > 100 \nPLEASE CORRECT VALUES.',
    floMeterGlossary: 'Glossary of terms:\n\nSG1 = specific gravity of gas Flo-Meter is calibrated for\n\nSG2 = specific gravity of gas Flo-Meter to be used\n\nTemp.1 = temperature that Flo-Meter is calibrated for\n\nTemp.2 = temperature that Flo-Meter will be used for\n\nP1 = pressure that Flo-Meter is calibrated for\n\nP2 = pressure that Flo-Meter will be used with\n',
    flows: 'Input Flows',
    furnaceAtmosphere: 'Furnace Atmosphere',
    imperialUnits: 'Imperial',
    inputGroup: 'Input',
    inputs: {
      CELCIUS_TO_FAHRENHEIT: 'Celsius to Fahrenheit',
      FAHRENHEIT_TO_CELCIUS: 'Fahrenheit to Celsius',
      FT3PH_TO_M3PH: 'cfh to m³/h',
      GPM_TO_LMIN: 'US gpm to l/min',
      HARDNESS_TABLE1: 'Rockwell C Range',
      HARDNESS_TABLE2: 'Rockwell B Range',
      HBS: {
        name: 'HBS',
        subtitle: 'Brinell Scale',
      },
      HBS2: {
        name: 'HBS',
        subtitle: 'Brinell Scale',
      },
      HBW: {
        name: 'HBW',
        subtitle: 'Brinell Scale',
      },
      HK: {
        name: 'HK',
        subtitle: 'Knoop Hardness',
      },
      HK2: {
        name: 'HK',
        subtitle: 'Knoop Hardness',
      },
      HR15N: {
        name: 'HR15N',
        subtitle: 'Rockwell 15-N Scale',
      },
      HR15T: {
        name: 'HR15T',
        subtitle: 'Rockwell 15-T Scale',
      },
      HR30N: {
        name: 'HR30N',
        subtitle: 'Rockwell 30-N Scale',
      },
      HR30T: {
        name: 'HR30T',
        subtitle: 'Rockwell 30-T Scale',
      },
      HR45N: {
        name: 'HR45N',
        subtitle: 'Rockwell 45-N Scale',
      },
      HR45T: {
        name: 'HR45T',
        subtitle: 'Rockwell 45-T Scale',
      },
      HRA: {
        name: 'HRA',
        subtitle: 'Rockwell A Scale',
      },
      HRA2: {
        name: 'HRA',
        subtitle: 'Rockwell A Scale',
      },
      HRB: {
        name: 'HRB',
        subtitle: 'Rockwell B Hardness',
      },
      HRC: {
        name: 'HRC',
        subtitle: 'Rockwell C Hardness',
      },
      HRD: {
        name: 'HRD',
        subtitle: 'Rockwell D Scale',
      },
      HRF: {
        name: 'HRF',
        subtitle: 'Rockwell F Hardness',
      },
      HSC: {
        name: 'HSc',
        subtitle: 'HSc Scleroscope Hardness',
      },
      HV: {
        name: 'HV',
        subtitle: 'Vickers Hardness',
      },
      HV2: {
        name: 'HV',
        subtitle: 'Vickers Hardness',
      },
      LMIN_TO_GPM: 'l/min to US gpm',
      M3PH_TO_FT3PH: 'm³/h to cfh',
      ac: 'ac',
      actualScale: 'Actual Scale',
      aisi: 'AISI',
      al: 'Al',
      alloyFactor: 'Alloy Factor',
      baseC: 'Base C',
      c: 'C',
      carbonPotential: 'Carbon Potential',
      cch4: 'C + CH₄',
      ccontent: 'C-Content',
      ch3oh: 'CH₃OH',
      ch4: 'CH₄',
      ch4Factor: 'CH₄ Factor',
      co: 'CO',
      co2: 'CO₂',
      coTotalFlow: 'CO/Total Flow',
      coco2: 'CO/CO₂',
      coldJunctionTemp: 'Cold-Junction Temp.',
      cpatm: 'CPatm',
      cr: 'Cr',
      cu: 'Cu',
      dewPoint: 'Dew Point',
      din: 'DIN',
      dissBurette: 'Diss. Burette',
      dissDegree: 'Diss. Degree',
      dnh3: 'D-NH₃',
      dpt: 'DPT',
      emf: 'EMF',
      floMeterScale: 'Flo-Meter Scale',
      flow: 'Flow',
      gas: 'Gas',
      h2: 'H₂',
      h2n2: 'H₂:N₂',
      h2o: 'H₂O',
      hardness: 'Hardness',
      kcb: 'Kᴄʙ',
      kh: 'Kʜ',
      kn: 'Kɴ',
      ko: 'Kᴏ',
      mn: 'Mn',
      mo: 'Mo',
      mv: 'mV',
      n2: 'N₂',
      n2Percentage: 'N₂',
      n2ch3oh: 'N₂/CH₃OH',
      nc2: 'NC₂',
      nh3: 'NH₃',
      ni: 'Ni',
      o2: 'O₂',
      o2Percentage: 'O₂',
      o2Probe: 'O₂ Probe',
      others: 'Others',
      p1: 'P1',
      p2: 'P2',
      po2: 'pO₂',
      preload: 'Preload',
      pt10: {
        description: '(Pt10) Platinum RTD with Nominal Resistance of 10 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt10) Platinum RTD',
      },
      pt100: {
        description: '(Pt100) Platinum RTD with Nominal Resistance of 100 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt100) Platinum RTD',
      },
      pt1000: {
        description: '(Pt1000) Platinum RTD with Nominal Resistance of 1000 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt1000) Platinum RTD',
      },
      pt500: {
        description: '(Pt500) Platinum RTD with Nominal Resistance of 500 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt500) Platinum RTD',
      },
      resistance: 'Resistance',
      rg: 'RG',
      rl: 'RL',
      scaleFactor: 'Scale Factor',
      sg1: 'SG1',
      sg2: 'SG2',
      si: 'Si',
      sootLimit: 'Soot Limit',
      tc_b: {
        description: 'Plus:Platinum 30% Rhodium(Pt30Rh)\nMinus:Platinum 6% Rhodium (Pt6Rh)\nRange: -50°C-1820°C (-58°F-3308°F)\nColors according to ANSI 96.6.\nAccording to NFC 42-324, colors are yellow and gray',
        name: 'Type B = Pt30Rh-Pt6Rh',
      },
      tc_e: {
        description: 'Plus:Nickel-Chromium (NiCr), also\nknown as Chromel\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -270°C-1000°C (-454°F-1832°F)\nColors according to DIN EN 60584-1',
        name: 'Type E = NiCr-CuNi',
      },
      tc_j: {
        description: 'Plus:Copper(Cu), Copper Color\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -270°C-400°C (-454°F-752°F)\nColors according to DIN EN 60584-1',
        name: 'Type J = Fe-CuNi',
      },
      tc_k: {
        description: 'Plus:Nickel-Chromium (NiCr), also\nknown as Chromel\nMinus:Nickel Aluminum (NiAl) also\nknown as Alumel, magnetic wire\nRange: -270°C-1372°C (-454°F-2502°F)\nColors according to DIN EN 60584-1',
        name: 'Type K = NiCr-Ni',
      },
      tc_l: {
        description: 'Plus:Iron (Fe), magnetic wire\nMinus:Copper-Nicker(CuNi), also\nknown as Constantan\nRange: 0°C-900°C (32°F-1652°F)\nColors according to DIN EN 43714',
        name: 'Type L = Fe-CuNi',
      },
      tc_n: {
        description: 'Plus:Nickel-Chromium-Silicon (NiCrSi),\nalso known as Nicrosil\nMinus:Nickel-Silicon (NiSi), also\nknown as Nisil\nRange: -270°C-1300°C (-454°F-2372°F)\nColors according to DIN EN 60584-1',
        name: 'Type N = NiCrSi-NiSi',
      },
      tc_r: {
        description: 'Plus:Platinum 13% Rhodium (Pt13Rh)\nMinus:Platinum(Pt), minus wire softer\nthan plus wire\nRange: -50°C-1768°C (-58°F-3214°F)\nColors according to DIN EN 60584-1',
        name: 'Type R = Pt13Rh-Pt',
      },
      tc_s: {
        description: 'Plus:Platinum 10% Rhodium (Pt10Rh)\nMinus:Platinum(Pt), minus wire softer\nthan plus wire\nRange: -50°C-1768°C (-58°F-3214°F)\nColors according to DIN EN 60584-1',
        name: 'Type S = Pt10Rh-Pt',
      },
      tc_t: {
        description: 'Plus:Iron (Fe), magnetic wire\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -210°C-1200°C (-346°F-2192°F)\nColors according to DIN EN 60584-1',
        name: 'Type T = Cu-CuNi',
      },
      tc_u: {
        description: 'Plus:Copper(Cu), Copper Color\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: 0°C-600°C (32°F-1112°F)\nColors according to DIN EN 43714',
        name: 'Type U = Cu-CuNi',
      },
      temp1: 'Temp.1',
      temp2: 'Temp.2',
      temperature: 'Temperature',
      total: 'Total',
      totalFlow: 'Total Flow',
      v: 'V',
      voltage: 'Voltage',
      weight1: 'Weight₁',
      weight2: 'Weight₂',
    },
    metricUnits: 'Metric',
    mustBeInteger: 'Must be integer',
    norm: 'Norm',
    outOfRange: 'Out of Range',
    potentials: 'Potentials',
    range: 'Range',
    units: 'Units',
  },
  validation: {
    ProcessCodeRequired: 'Process code and description required field',
    decimalValue: 'Max {decimalValue} decimal places',
    file: 'File is required',
    filesTooBig: 'Total files size should be less than 5MB',
    greaterBandValue: 'Envelope plus must be greater than minus',
    greaterOrEqual: 'Value should be equal or above {minValue}',
    idIsUsed: 'Id is used',
    integerValue: 'Value should be an integer',
    invalidEmailFormat: 'Invalid email format',
    isInteger: 'The value must be an integer number',
    lessOrEqual: 'Value should be equal or below {maxValue}',
    lowerBandValue: 'Envelope minus must be lower than plus',
    maxValue: 'Value should be below {maxValue}',
    minValue: 'Value should be above {minValue}',
    nameAlreadyExists: 'Name already exists',
    notEmptySpace: 'Text cannot contain only spaces',
    numberMustBeGreaterThan: 'Number must greater than {number}',
    oneValue: 'Must contain at least one value',
    required: 'Required',
    requiredFormat: 'Required format {format}',
    tooBigFile: 'Files size should be less than 50MB',
    tooManyFiles: 'You can upload no more than 3 files',
    userAlreadyExists: 'User with given e-mail already exists',
    valueBelowMin: 'Value is below min',
    // new
    fileTooBig: 'Files size should be less than {size}.',
  },
  util: {
    schaefflerChart: {
      title: 'Schaeffler Diagram',
      CrEquivalent: 'Cr-Equivalent',
      NiEquivalent: 'Ni-Equivalent',
    },
  },
  useQueryDateRange: {
    parseFailureHeadline: 'Time filter could not be applied.',
    parseFailureDetails: 'The time filter in the URL is invalid and could not be applied. The default filter has been applied. You may want to update the bookmark.',
  },
  ...getComponentScopedTranslationMap('page', 'en'),
  ...getComponentScopedTranslationMap('components', 'en'),
  ...getComponentScopedTranslationMap('pt9800', 'en'),
};
