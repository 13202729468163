<template>
  <prm-button
    v-tooltip="(isInlineAction || tooltip)
      ? { value: tooltip || label }
      : undefined"
    class="qmulus-button"
    :class="{
      'qmulus-button_table-button': isInlineAction || iconOnly,
      'p-button-icon-only': isInlineAction || iconOnly,
    }"
    v-bind="severityMap[severity]"
    :text="isInlineAction || severityMap[severity].text"
    :title="label"
    :label="isInlineAction || iconOnly
      ? undefined
      : label"
    :disabled="disabled"
    :loading="loading"
    icon-pos="left"
    :icon="typeof icon === 'string'
      ? icon
      : undefined"
    :pt="{
      label: {
        class: {
          'overflow-ellipsis': truncateLabel,
        },
      },
    }"
    @click="onClick"
  >
    <template
      v-if="typeof icon === 'object'"
      #icon
    >
      <component :is="icon" />
    </template>
  </prm-button>
</template>

<script setup lang="ts">
import PrmButton from 'primevue/button';
import {
  IQmulusButtonProps,
  severityMap,
} from './qmulus-button.ts';

const props = withDefaults(defineProps<IQmulusButtonProps>(), {
  severity: 'tertiary',
});

if (props.isInlineAction && !props.icon) {
  throw new Error('Table button requires icon');
}
</script>

<style lang="scss" scoped>
.qmulus-button {
  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  &_table-button {
    svg {
      margin-right: 0;
    }
  }
}
</style>
