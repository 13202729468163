import {
  components,
} from '@/types/openapi.ts';

export enum ESubscriptionTreeLabel {
  ALARMS = 'ALARMS',
  ALARM_SEVERITY_EMERGENCY_OCCURRED = 'ALARM_SEVERITY_EMERGENCY_OCCURRED',
  ALARM_SEVERITY_WARNING_OCCURRED = 'ALARM_SEVERITY_WARNING_OCCURRED',
  JOBS = 'JOBS',
  JOB_FINISHED = 'JOB_FINISHED',
  JOB_STARTED = 'JOB_STARTED',
  TIMESERIES_LAG_DETECTED = 'TIMESERIES_LAG_DETECTED',
  NEW_QMULUS_RELEASE = 'NEW_QMULUS_RELEASE',
  ANOMALIES_AND_OBSERVATIONS = 'ANOMALIES_AND_OBSERVATIONS',
  ALARM_SEVERITY_ALARM_OCCURRED = 'ALARM_SEVERITY_ALARM_OCCURRED',
  CONTROL_LEVEL_EXCEEDED = 'CONTROL_LEVEL_EXCEEDED',
  TREND_DETECTED = 'TREND_DETECTED',
  DRIFT_DETECTED = 'DRIFT_DETECTED',
}

export const SubscriptionTreeLabelMap: Record<components['schemas']['SubscriptionTreeLabel'], keyof typeof ESubscriptionTreeLabel> = {
  ALARMS: 'ALARMS',
  ANOMALIES_AND_OBSERVATIONS: 'ANOMALIES_AND_OBSERVATIONS',
  ALARM_SEVERITY_ALARM_OCCURRED: 'ALARM_SEVERITY_ALARM_OCCURRED',
  ALARM_SEVERITY_EMERGENCY_OCCURRED: 'ALARM_SEVERITY_EMERGENCY_OCCURRED',
  ALARM_SEVERITY_WARNING_OCCURRED: 'ALARM_SEVERITY_WARNING_OCCURRED',
  JOBS: 'JOBS',
  JOB_FINISHED: 'JOB_FINISHED',
  JOB_STARTED: 'JOB_STARTED',
  TIMESERIES_LAG_DETECTED: 'TIMESERIES_LAG_DETECTED',
  NEW_QMULUS_RELEASE: 'NEW_QMULUS_RELEASE',
  CONTROL_LEVEL_EXCEEDED: 'CONTROL_LEVEL_EXCEEDED',
  TREND_DETECTED: 'TREND_DETECTED',
  DRIFT_DETECTED: 'DRIFT_DETECTED',
};
