export default {
  header: 'Information für Ort "{place}"',
  placeStatus: 'Ortstatus',
  reserved: 'Reserviert',
  holdingTime: 'Res. Haltezeit [min]',
  loadStatus: 'Ladestatus',
  cellLoadNo: 'Zellenladungsnummer',
  targetStartTime: 'Zielstartzeit',
  bookedOnPlace: 'Am Ort gebucht',
  plcLoadNo: 'PLC Ladungsnummer',
  box: 'Box',
  prio: 'Priorität',
  comment: 'Kommentar',
  noActiveProcessFlow: 'Kein aktiver Prozessablauf',
  noParts: 'Keine Teile',
  processFlow: 'Prozessablauf',
  content: 'Inhalt',
  confirmStartProcessFlowNow: 'Sind Sie sicher, dass Sie den Prozessablauf jetzt starten möchten?',
  name: 'Name',
  description: 'Beschreibung',
  booked: 'Gebucht',
  modification: 'Änderung',
  unloaded: 'Entladen',
  started: 'Gestartet',
  finished: 'Beendet',
  loadFinalTest: 'Endtest laden',
};
