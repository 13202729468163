import {
  TComponentPT9800Keys,
  TComputedPT9800PlannedPartInput,
} from '@/types/openapi-type.ts';
import {
  generalTerm,
} from '@/constants/locales/de.shared.ts';

const plannedPartInput: Partial<Record<keyof TComputedPT9800PlannedPartInput, string>> = {
  pos: 'Position',
  partNumber: 'Teilenummer',
  partDescription: 'Teilebeschreibung',
  orderNo: generalTerm.loadIdentifier,
  orderRemark: 'Bestellbemerkung',
  customerOrderNumber: 'Kundenbestellnummer',
  amount: 'Menge',
  weight: 'Gewicht',
  totalWeight: 'Gesamtgewicht',
};

const planLoadInput: Partial<TComponentPT9800Keys<'planLoadInput'>> = {
  workplaceWoNumber: generalTerm.loadIdentifier,
};

const recipeListItem: TComponentPT9800Keys<'recipeListItem'> = {
  id: 'id',
  description: generalTerm.description,
  recipe: generalTerm.recipe,
  recipePrefix: 'Rezeptpräfix',
  version: 'Rezept version',
};

const recipeItemHeader: Partial<TComponentPT9800Keys<'recipeItemHeader'>> = {
  recipeName: 'Name',
  recipePrefix: 'Gruppe',
  description: 'Beschreibung',
  processType: 'Prozess',
  processId: 'Betrieb',
  comment: 'Kommentar',
  diff: 'Aktivieren',
  correction: 'Korrektur',
};

const material: Partial<TComponentPT9800Keys<'material'>> = {
  carbonContent: 'Kohlenstoffgehalt',
  carbideLimit: 'Karbidgrenze',
  alloyFactor: 'Legierungsfaktor',
};

const recipeItemPosition: Partial<TComponentPT9800Keys<'recipeItemPosition'>> = {};

const recipeItemPositionHeader: Partial<TComponentPT9800Keys<'recipeItemPositionHeader'>> = {
  positionNo: 'Schritt',
  description: 'Schrittname',
  chamberId: 'Kammer',
  chamberOperationId: 'Kammerbetrieb',
  afterId: 'Endbedingung',
  estimatedDurationInMin: 'Geschätzte Dauer',
};

const setPointItem: Partial<TComponentPT9800Keys<'setPointItem'>> & {
  unit: string,
} = {
  userComponentId: 'Sollwert',
  controlId: 'Ctrl',
  value: 'Wert',
  unit: 'Einheit',
  toleranceMax: 'Tol +',
  toleranceMin: 'Tol -',
  alarmStartTimeInMin: 'Zeit 1',
  alarmToleranceTimeInMin: 'Zeit 2',
  endControlId: 'Ctrl',
  endValue: 'Endbedingung',
};

export default {
  material,
  planLoadInput,
  plannedPartInput,
  recipeItemHeader,
  recipeItemPosition,
  recipeItemPositionHeader,
  recipeListItem,
  setPointItem,
};
